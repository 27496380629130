import React, { ChangeEvent, HTMLInputTypeAttribute, useCallback, useEffect, useRef, useState } from "react";
import { TableParam } from "../../domain/types/ClauseParams";
import { initArray } from "../../utils/array";
import { AiOutlineUpload } from "react-icons/ai";
import * as XLSX from "xlsx"
import { useTranslation } from "../../contexts/TranslationProvider";
import { validNumberInput } from "../../domain/validators";

function TableRowInput({ currentValue, param, onSave }:
  {
    currentValue: (string | Date | number)[],
    param: TableParam,
    onSave: (tableRow: (string | Date | number)[]) => void,
  }) {
  const [tableRow, setTableRow] = useState<(string | Date | number)[]>(currentValue);
  const handleCellChange = (event: ChangeEvent<HTMLInputElement>, colIndex: number) => {
    let value = event.target.value;
    tableRow[colIndex] = value;
    setTableRow([...tableRow])
    onSave(tableRow)
  };
  const typeMap: Record<(typeof param)['args'][number]['type'], HTMLInputTypeAttribute> = {
    'date': 'date',
    'number': 'number',
    'string': 'text',
  }

  return (
    <React.Fragment>
      {param.args.map((arg, idx) =>
        <td key={idx}  >
          <input
            style={{ paddingLeft: "2px" }}
            placeholder={arg.header}
            type={typeMap[arg.type] == 'number' ? undefined : typeMap[arg.type]}
            className="form-control custom-placeholder"
            value={tableRow[idx] as any}
            onChange={(e) => {
              if (typeMap[arg.type] == 'number') {
                e.target.value = validNumberInput(e.target.value) ? e.target.value
                  : validNumberInput(tableRow[idx]) ? tableRow[idx]
                    : '' as any
              }
              handleCellChange(e, idx)
            }
            }
          />
        </td>
      )}
    </React.Fragment>
  )
}

function EditTableModal({ param, onClose, onSave, currentTableValues }:
  {
    param: TableParam,
    onClose: () => void,
    onSave: (tableData: (string | Date | number)[][]) => void,
    currentTableValues: (string | Date | number)[][];
  }) {

  const [tableData, setTableData] = useState<{ id: number, row: (string | Date | number)[] }[]>(currentTableValues.map((row, idx) => {
    return { id: idx, row }
  }));
  useEffect(() => {
    return () => {
    }
  }, [tableData])

  let [idCounter, setCounter] = useState(currentTableValues.length);
  const { t, language } = useTranslation();


  const removeRow = (rowIdx: number) => () => {
    const newTableData = [...tableData.slice(0, rowIdx), ...tableData.slice(rowIdx + 1)]
    setTableData([...newTableData])
  };
  const addRow = (rowIdx: number) => () => {
    // Create a copy of the original tableData array
    const newTableData = [...tableData];
    // Insert a new row with initial values
    newTableData.splice(rowIdx + 1, 0, { id: idCounter++, row: initArray('', param.args.length) });
    setCounter(idCounter)
    // Update the state with the new tableData
    setTableData(newTableData);
  };

  const onSaveRow = (rowIdx: number) => (rowData: (string | Date | number)[]) => {
    tableData[rowIdx].row = rowData
    setTableData([...tableData])
  };
  const saveValue = async () => {
    const tableValues = tableData.map(rowData => rowData.row)
    onSave(tableValues);
    onClose();
  }
  const headers = param.args.map((arg) => arg.header)
  const fillDataFromCSV = (data: any[]) => {
    console.log(data);
    const newTableData = [];
    for (const dataRow of data) {
      let row = param.args.map((arg) => {
        const header = arg.header
        const value = dataRow[header] ?? ""
        if (value == "")
          return value
        switch (arg.type) {
          case 'date':
            const ssfValue = XLSX.SSF.parse_date_code(value)
            return `${ssfValue.y}-${String(ssfValue.m).padStart(2, '0')}-${String(ssfValue.d).padStart(2, '0')}`
          case 'number':
            return Number(value)
          case 'string':
            return String(value)
        }

      })
      newTableData.push({ id: idCounter++, row })
    }
    console.log(newTableData);
    setCounter(idCounter)
    setTableData(newTableData);
  }

  const fileInputRef = useRef(null);
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array', cellDates: false });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        fillDataFromCSV(jsonData)
      };

      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <>
      <>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{t("pages.editionContract.popups.table.title")}</h5>
                  <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                    style={{ marginRight: language == "ar" && "88%" }}
                  ></button>
                </div>
                {(
                  <div className="modal-body">
                    <div style={{ maxHeight: "59vh", overflowY: "auto" }}>
                      <table className="my-0" style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead>
                          <tr>
                            {headers.map((header, idx) =>
                              <th key={idx} className={"thead-table"} >
                                {header}
                              </th>)}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.length > 0 ? tableData.map((rowData, rowIdx) => <tr key={rowData.id}>
                            <TableRowInput currentValue={rowData.row} param={param} onSave={onSaveRow(rowIdx)} />
                            <td >
                              <button className="btn btn-light btn-handler" style={{ boxShadow: "none" }} onClick={addRow(rowIdx)}>
                                +
                              </button>
                            </td>
                            <td >
                              <button className="btn btn-light btn-handler" style={{ boxShadow: "none" }} onClick={removeRow(rowIdx)}>
                                -
                              </button>
                            </td>
                          </tr>
                          ) :
                            <td >
                              <button className="btn btn-light btn-handler" onClick={addRow(0)}>
                                +
                              </button>
                            </td>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div className="modal-footer edit-table-footer">
                  <button type="submit" className="btn btn-primary save-btn" onClick={saveValue}>
                    {t("pages.editionContract.popups.table.save")}
                  </button>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 4px 5px 10px",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="d-flex ms-1">

                      <input
                        type="file"
                        style={{ display: "none", border: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <AiOutlineUpload
                        style={{ marginRight: "0.5rem", color: "#2f14e5", cursor: "pointer" }}
                        onClick={triggerFileInput}
                      />
                    </div>
                    <p style={{ margin: 0 }}>{t("pages.editionContract.popups.table.import")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    </>
  );
}

export default EditTableModal;
