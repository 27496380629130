import React from 'react'
import RenderSingleSegment from './RenderSingleSegment';

function RenderListSegmentedText({  list, changedParamBoundaries, isSelected, isEditing, editingSegmentId, openFilePreview, stateSync, spanRef, segmentation, inputValues }) {
  const listType = list?.type ?? "bulleted-list";
  return (<>
    {
        listType === "bulleted-list" ? (<>
          <ul>
            {list.children.map((listElement, idx) => (
              <li key={idx} style={{ textAlign: listElement?.alignment ?? "left", width: "100%", marginBottom: "10px" }}>
                {listElement.children.map((segment, idx) => (
                  <RenderSingleSegment
                    key={segment.id}
                    segment={segment}
                    isSelected={isSelected}
                    changedParamBoundaries={changedParamBoundaries}
                    isEditing={isEditing}
                    editingSegmentId={editingSegmentId}
                    openFilePreview={openFilePreview}
                    stateSync={stateSync}
                    spanRef={spanRef}
                    segmentIdx={(segment as any).actualIdx}
                    segmentation={segmentation}
                    inputValues={inputValues}
                  />
                ))
                }
              </li>
            ))}
          </ul>
        </>) 
        : (<>
          <ol>
            {list.children.map((listElement, idx) => (
              <li key={idx} style={{ textAlign: listElement?.alignment ?? "left",width: "100%", marginBottom: "10px" }}>
                {listElement.children.map((segment, idx) => (
                  <RenderSingleSegment
                    key={segment.id}
                    segment={segment}
                    isSelected={isSelected}
                    changedParamBoundaries={changedParamBoundaries}
                    isEditing={isEditing}
                    editingSegmentId={editingSegmentId}
                    openFilePreview={openFilePreview}
                    stateSync={stateSync}
                    spanRef={spanRef}
                    segmentIdx={(segment as any).actualIdx}
                    segmentation={segmentation}
                    inputValues={inputValues}
                  />
                ))
                }
              </li>
            ))}
          </ol>
        </>)
      }
      </>
    )
}

export default RenderListSegmentedText