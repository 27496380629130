import { ProjectEntity } from "../domain/entities";
import { cellsToXLSXWorkbook } from "../utils/csv";
import { virtualbeneficialGetters } from "../helpers/Beneficial";
import { LanguageType } from "../contexts/TranslationProvider";
import DATELib from "../utils/date";


type GroupedCommit = {
	name: string;
	commits: ProjectEntity['commits'];
}
export function groupCommits(commits: ProjectEntity['commits']) {
	if (!commits) return [];

	const sortedCommits = commits.sort((a, b) =>
		new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
	);

	const finalGroupedCommits: GroupedCommit[] = [];
	let currentGroup: GroupedCommit = null;

	sortedCommits.forEach(commit => {
		const groupName = commit.assignedToGroup?.name || 'Commit';

		if (!currentGroup || currentGroup.name !== groupName) {
			if (currentGroup) {
				finalGroupedCommits.push(currentGroup);
			}
			currentGroup = { name: groupName, commits: [commit] };
		} else {
			currentGroup.commits.push(commit);
		}
	});

	if (currentGroup) {
		finalGroupedCommits.push(currentGroup);
	}

	return finalGroupedCommits;
}
export const serializeProjectAuditCommitsSpreadSheet = (project: ProjectEntity, commits: ProjectEntity['commits'], t: (string) => string, language: LanguageType) => {

	let spreadsheets: Record<string, string[][]> = {
		"default": [
			['GROUPES', 'DELAIS DE TRAITEMENT', 'DATES MODIFICATION', 'DATE ASSIGNATION', 'DATE NEGOCIATION'],
		],
	}
	const finalGroupedCommits: GroupedCommit[] = groupCommits(commits);
	for (const groupedCommit of finalGroupedCommits) {
		const firstCommitDate = groupedCommit.commits[0].createdAt!
		const lastCommitDate = commits.filter(c => new Date(c.createdAt) > new Date(firstCommitDate)
			&& c.assignedToGroupId != groupedCommit.commits[0].assignedToGroupId
		)[0]?.createdAt

		spreadsheets['default'].push([
			//GROUPES
			groupedCommit.name,
			//DELAIS DE TRAITEMENT
			lastCommitDate ? DATELib.getDateDifference(new Date(firstCommitDate), new Date(lastCommitDate), language) : "",
			//DATES MODIFICATION
			lastCommitDate?.toString() ?? "",
			//DATES ASSIGNATION
			firstCommitDate.toString(),
			//DATE NEGOCIATION
			""
		])
	}
	return spreadsheets

};

export const serializeProjectAuditCommits = (project: ProjectEntity, commits: ProjectEntity['commits'], t: (string) => string, language: LanguageType) => {

	let spreadsheets: Record<string, string[][]> = serializeProjectAuditCommitsSpreadSheet(project, commits, t, language)

	return cellsToXLSXWorkbook(spreadsheets)

};
