import React, { useState } from "react";
import Loading from "../../common/Loading";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./confirmDeleteClauseContractTemplate.scss";

interface ConfirmDeleteClauseContractTemplateProps {
  onClose: (value: boolean) => void;
  clause?: any;
  onDelete?: (value) => void;
  confirmMessage?: string;
}

function ConfirmDeleteClauseContractTemplate({
  clause,
  onDelete,
  onClose = () => {},
  confirmMessage,
}: ConfirmDeleteClauseContractTemplateProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("modals.confirmDelete.title")}
                </h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body pb-3">
                  <div className="form-group">
                    <h1 className="question-modal-delete">{confirmMessage}</h1>
                    <div className="modal-buttons">
                      <button
                        className="btn submit-delete"
                        onClick={() => onDelete(clause)}
                      >
                        {t("modals.confirmDelete.delete")}
                      </button>

                      <button
                        onClick={() => onClose(false)}
                        className="btn btn-secondary cancel-delete"
                        style={{
                          marginLeft: "20px",
                          backgroundColor: "gray",
                        }}
                      >
                        {t("modals.confirmDelete.cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmDeleteClauseContractTemplate;
