import React, { useContext, useState, useEffect } from "react";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import { useSubmitContract } from "../../../hooks/useSubmitContract";
import EventManager from "../../../services/EventManager";
import EditFormContext from "../../../contexts/EditFormContext";
import { useTranslation } from "../../../contexts/TranslationProvider";


export const handleContractSaveLogic = async (
  inputValues,
  processParamValues,
  submitContract,
  setLoading,
  templateId,
  commentsOverrides,
  excludedSubClauses,
  excludedClauses,
  completionPercentage,
  contractId
) => {
  try {
    const { processedParamValues, hasError } = await processParamValues(
      inputValues
    );
    if (!hasError) {
      const body = {
        templateId: templateId,
        paramValues: processedParamValues,
        commentsOverrides,
        excludedSubClauses: excludedSubClauses,
        excludedClauses: excludedClauses,
        status: completionPercentage === 100 ? "Done" : "Draft",
        name: inputValues.contractName,
      };
      setLoading(true);
      const savedContractId = await submitContract(body, contractId);
      setLoading(false);
      return savedContractId;
    }
  } catch (error) {
    setLoading(false);
  }
  return false;
};

const SaveDraftModel = ({ onClose, onSave, type, label, handleSave }: {
  onClose: () => void;
  onSave: (success: boolean) => void;
  type: 'contract' | 'form' | 'project' | '';
  label?: string;
  handleSave?: () => Promise<boolean>;
}) => {
  const context = useContext(EditFormContext);
  useEffect(() => {
    EventManager.invoke('EditFormContext', context)
    return () => { }
  }, [JSON.stringify(context)])
  const [loading, setLoading] = useState(false);
  const { t, language } = useTranslation();
  const {
    inputValues,
    commentsOverrides,
    excludedClauses,
    excludedSubClauses,
    templateId,
    completionPercentage,
    contractId,
  } = useContext(InputValuesContext);

  const processParamValues = useParamProcessing();
  const submitContract = useSubmitContract(null);
  const handelNotSave = async () => {
    onSave(false);
  };
  const handleSaveContract = async () => {
    try {
      const success = await handleContractSaveLogic(
        inputValues,
        processParamValues,
        submitContract,
        setLoading,
        templateId,
        commentsOverrides,
        excludedSubClauses,
        excludedClauses,
        completionPercentage,
        contractId
      );

      if (success) {
        onSave(true);
      } else {
        onSave(false);
      }
    } catch (error) {
      setLoading(false);
      onSave(false);
    }
  };

  const handleSaveForm = async () => {
    try {
      EventManager.invoke('SubmitForm', context)
      onSave(true)
    } catch (error) {
      setLoading(false);
      onSave(false);
    }
  };
  const handleSaveProject = async () => {
    try {
      EventManager.invoke('SubmitProject', true)
      onSave(true)
    } catch (error) {
      setLoading(false);
      onSave(false);
    }
  };
  const handleSaveDefault = async () => {
    try {
      const result = await handleSave?.() ?? false
      setLoading(false);
      onSave(result)
    } catch (error) {
      setLoading(false);
      onSave(false);
    }
  };


  return (
    <>
      <>
        <div
          className="modal-backdrop"
        ></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {t("modals.saveDraft.title")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={onClose}
                    aria-label="Close"
                    style={{ marginRight: language == "ar" && "80%" }}
                  ></button>
                </div>
                {loading ? (
                  <Loading height="50vh" />
                ) : (
                  <div className="modal-body pb-3">
                    <div className="form-group">
                      <h1 className="question-modal">
                        {type === "project" && t("modals.saveDraft.saveProjectQuestion")
                          || type == 'contract' && t("modals.saveDraft.question")
                          || type == 'form' && t("modals.saveDraft.question")
                          || label
                          || t("modals.saveDraft.question")
                        }
                      </h1>
                      <div className="modal-buttons">
                        <button className="btn submit-draft" onClick={() =>
                          type === "contract" && handleSaveContract()
                          || type === "project" && handleSaveProject()
                          || type === "form" && handleSaveForm()
                          || handleSaveDefault()
                        }>
                          {t("modals.saveDraft.saveDraft")}
                        </button>
                        <button
                          onClick={handelNotSave}
                          className="btn btn-secondary cancel-draft"
                          style={{
                            marginLeft: "20px",
                            backgroundColor: "gray",
                          }}
                        >
                          {t("modals.saveDraft.cancel")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SaveDraftModel;
