import "../commonmodal.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";

import { useTranslation } from "../../../contexts/TranslationProvider";

import { DocumentClient } from "../../../services/api/DocumentClient";
import CheckBoxInput from "../../common/CheckBoxInput";
import { toast } from "react-toastify";
import { AiFileClient } from "../../../services/rag/AiFileClient";
import Loading from "../../common/Loading";

interface ChatWithDocumentType {
  onClose: () => void;
}
function ChatWithDocument({onClose}: ChatWithDocumentType) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const [checkContractExistsTimeout, setCheckContractExistsTimeout] =
    useState<NodeJS.Timeout>(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isScanned, setIsScanned] = useState(false);
  const { t, language } = useTranslation();
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  const deleteFile = (onChange) => {
    setFile(null);

    // Create a new Event
    const event = new Event("input", { bubbles: true });

    // Reset the value of the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;

      // Dispatch the event
      fileInputRef.current.dispatchEvent(event);
    }

    // Call onChange with the event
    onChange(event);
  };

  const apiClient = useApiClientWithLoading();
  const aiFileClient = new AiFileClient(apiClient);
  const documentClient = new DocumentClient(apiClient);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      setLoading(true);
      const result = await aiFileClient.createFromFile(file);
      if (!result.row) throw new Error("Error uploading file");
      const aiFile = result.row;
      // compile file
      const compiledFileResult = await aiFileClient.compile(aiFile.id)
      if(!compiledFileResult.row) throw new Error("Error compiling file")
        try{
          const { row: { id: documentId } } = await documentClient.upsertMultiForm({
            file: file,
            name: file.name,
            isScanned,
            aiFileId : aiFile.id
          });
          if(!documentId) throw new Error("Error uploading file")
            navigate(`/chat-document/${documentId}`);
            setLoading(false);
        }catch(e){
          const deleted = await aiFileClient.delete(aiFile.id)
        }
    } catch (error) {
      console.error("Error fetching AI contract data:", error);
      toast.error(t("pages.pilot.popups.contract.errorImport"));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loading height="20vh" />
      ) : (
        <form
          action=""
          className="form-group"
          style={{
            maxHeight: "500px",
            marginBottom: "70px",
            overflowY: "auto",
            overflowX: "hidden",
            paddingLeft: "20px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="fw-bolder">{t("evaluateModal.chat.select")}</p>

          <Controller
            name="file"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "dashed 2px #ccc",
                  padding: "0.5rem",
                  cursor: "pointer",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }}
                className="mt-3"
              >
                <div className="d-flex ms-1">
                  {file?.name && (
                    <AiFillDelete
                      style={{
                        marginRight: "0.5rem",
                        color: "#2f14e5",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteFile(field.onChange)}
                    />
                  )}
                  <AiOutlineUpload
                    style={{
                      marginRight: "0.5rem",
                      color: "#2f14e5",
                    }}
                    onClick={triggerFileInput}
                  />
                </div>
                <p style={{ margin: 0 }}>
                  {file?.name ||
                    t("pages.listener.popups.contract.inputs.file.placeholder")}
                </p>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileChange(e);
                    field.onChange(e);
                  }}
                />
              </div>
            )}
          />
          {errors.file && (
            <p className="text-danger py-2 my-0 py-0 my-0">
              {t("pages.listener.popups.contract.inputs.file.error")}
            </p>
          )}
          <CheckBoxInput
            checked={isScanned}
            name={t("pages.listener.popups.contract.inputs.isScanned")}
            onChange={() => setIsScanned(!isScanned)}
            disabled={false}
          />

          <div className="modal-footer footer-form-pilotage">
            <button
              disabled={contractExistsError || !!checkContractExistsTimeout}
              type="submit"
              className="btn btn-primary"
              style={{ left: "4.5%" }}
            >
              {t("pages.listener.popups.contract.button")}
            </button>
          </div>
        </form>
      )}
    </>
  );
}

export default ChatWithDocument;
