import React, { useEffect } from 'react'
import { ConversationEntity, ReglementEntity } from '../../../domain/entities';
import { RiDeleteBinLine } from "react-icons/ri";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";

function ReglementConversationList({conversations, reglement, currentConversation, setCurrentConversation, setPendingQuestion, handleDelete, currentReglement}: {
  conversations: ConversationEntity[];
  reglement: ReglementEntity;
  currentConversation: ConversationEntity | null;
  setCurrentConversation: any;
  setPendingQuestion: any;
  handleDelete: any;
  currentReglement: ReglementEntity;
}) {
  const [expanded, setExpanded] = React.useState(true);
  const [selected, setSelected] = React.useState(false);
  useEffect(() => {
    if (currentReglement && reglement && currentReglement.id === reglement.id) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }
  , [currentReglement, reglement])

  return (
    <div className={`conversation-group-container ${selected && "selected"}`}>
    <div className='reglement-header'>
      {reglement && <span className='title'>{reglement.name}</span>}
      <span className='icon' onClick={() => setExpanded(!expanded)}>
        {expanded ? <GoChevronUp /> : <GoChevronDown />}
      </span>
      </div>
      {expanded && <div className='reglement-conversation-list'>
        {conversations && conversations.map((conversation, index) => {
          return (
            <div
                style={{ color: currentConversation?.id === conversation.id ? "blue" : "black" }}
                className='conversation'
                key={index}
                onClick={() => {
                  setCurrentConversation(conversation)
                  setPendingQuestion(null)
                }}>
                <span className='title'>
                  {conversation.name ? conversation.name : "conversation"}
                </span>
                <div className='icon-section'>
                  <span className='icon'>
                <RiDeleteBinLine onClick={(e) => handleDelete(e, {...conversation, type: "conversation"})} />
                </span>
                </div>
              </div>
          )
        })}
        </div>}
    </div>
  )
}

export default ReglementConversationList