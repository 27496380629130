import { TaskClient } from "../services/api/TaskClient";
import { TaskEntity } from "../domain/entities/Task";

export const onDragEnd = async (
  result: any, 
  columns: any, 
  setColumns: React.Dispatch<React.SetStateAction<any>>, 
  setData: React.Dispatch<React.SetStateAction<TaskEntity[] | undefined>>, 
  taskClient: TaskClient
) => {
  if (!result.destination) return;
  
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    
    const [movedTask] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, movedTask);
    const newStatus = destColumn.name;
    const updatedTask = { ...movedTask, status: newStatus };
    setColumns({
      ...columns,
      [source.droppableId]: { ...sourceColumn, items: sourceItems },
      [destination.droppableId]: { ...destColumn, items: destItems },
    });
    try {
      await taskClient.update(updatedTask.id, { status: newStatus });
      setData((prevData: TaskEntity[] | undefined) => {
        if (!prevData) return prevData;
        return prevData.map(task => (task.id === updatedTask.id ? updatedTask : task));
      });
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    
    setColumns({
      ...columns,
      [source.droppableId]: { ...column, items: copiedItems },
    });
  }
};