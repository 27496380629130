import React, {useState} from 'react'
import ContractCardActionsModal from '../../components/modals/ContractCardActionsModal'
import { FiMoreHorizontal } from "react-icons/fi";

function ActionsPopup({onView, onDelete}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <div className='actions'>
      <button className="plus-icon" onClick={(e) => togglePopup(e)}>
        <FiMoreHorizontal size={20} />
      </button>
      {isPopupOpen && (
          <ContractCardActionsModal
            onView={() => onView()}
            onDelete={() => onDelete()}
            onClose={()=>setIsPopupOpen(false)}
            isOpen={isPopupOpen}
          />
      )}
    </div>
  )
}

export default ActionsPopup