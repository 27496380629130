import "./ressources.scss";
import MyRessources from ".";
import { useTranslation } from "../../contexts/TranslationProvider";

function SharedRessources() {
  const { t } = useTranslation();

  return (
    <MyRessources
      scope='granted'
      title={t("sidebar.sharedDocuments")}
    />
  );
}

export default SharedRessources;
