import { AxiosInstance } from "axios";
import { ConversationEntity } from "../../domain/entities/Conversation";
import { ModelClient } from "../ModelClient";
import axios from "axios";

export class ConversationClient extends ModelClient<ConversationEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Conversation')
  }
  async getAll(): Promise<any> {
    const response = await this.apiClient.get<{ rows: ConversationEntity[] }>(`${this.modelUrl}/all`);
    return response.data;
  }
  async getAllFromReglement(reglementId: string): Promise<any> {
    const response = await this.apiClient.get<{ rows: ConversationEntity[] }>(`${this.modelUrl}/byReglement/${reglementId}`);
    return response.data;
  }
  async query(conversation_id: string, query: string, language: string = "english"): Promise<any> {
    const response = await this.apiClient.post<{ rows: ConversationEntity[] }>(`${this.modelUrl}/query`, {
      conversation_id,
      query,
      language
    });
    return response.data;
  }
  async create(data: ConversationEntity): Promise<ConversationEntity> {
    const response = await this.apiClient.post<{ row: ConversationEntity }>(`${this.modelUrl}`, data);
    return response.data.row;
  }
  async delete(id: string): Promise<{ deleted: boolean; }> {
    const response = await this.apiClient.delete<{ deleted: boolean; }>(`${this.modelUrl}/${id}`);
    return response.data;
  }
  async createAndQuery(question: string, reglement: string, language: string = "english"): Promise<any> {
    const response = await this.apiClient.post<{ rows: ConversationEntity[] }>(`${this.modelUrl}/createAndQuery`, {
      question,
      reglement,
      language
    });
    return response.data;
  }
  async queryAndCreateStream(regulation_id: string, query: string): Promise<any> {
    return fetch(`${this.modelUrl}/queryAndCreateStream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-token': localStorage.getItem('user-token') ?? ''
      },
      body: JSON.stringify({
        regulation_id,
        query
      }) // Adjust if you need to send data
    });
  }
  async queryStream(conversation_id: string, query: string): Promise<any> {
    return fetch(`${this.modelUrl}/queryStream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-token': localStorage.getItem('user-token') ?? ''
      },
      body: JSON.stringify({
        conversation_id,
        query
      }) // Adjust if you need to send data
    });
  }
}