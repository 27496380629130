import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ContractEntity } from "../../domain/entities/Contract";
import { ContractParamValueEntity, GroupEntity, UserEntity, ValidateReq } from "../../domain/entities";
import { ContractCommit } from "../../domain/types/ContractCommit";
import { UserGrantEntity } from "../../domain/entities/UserGrant";
import { UserRestrictionEntity } from "../../domain/entities/UserRestriction";
import { ContractSignatureEntity } from "../../domain/entities/ContractSignature";


export interface ContractSignRequest {
  signers: {
    email: string;
    name: string;
  }[];
  //url returned from dataToDocx api
  url: string;
}

export class ContractClient extends ModelClient<ContractEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Contract')
  }
  async getContractParamValues(id: ContractEntity['id']) {
    const response = await this.apiClient.get<{
      contractParamValues: ContractParamValueEntity[],
      commits: ContractCommit[],
      fileNames: ContractEntity['fileNames'],
      beneficialsMap: ContractEntity['beneficialsMap'],
    }>(`${this.modelUrl}/${id}/get/contractParamValues`);
    return response.data;
  }
  async validate(id: ContractEntity['id'], validationData: ValidateReq) {
    const response = await this.apiClient.post<{ count: number, rows: ContractEntity[] }>(`${this.modelUrl}/${id}/validate`, validationData);
    return response.data;
  }

  async getAssignedGroupContracts(schema: string = 'nested') {
    const reponse = await this.apiClient.get<{ count: number, rows: ContractEntity[], groups: GroupEntity[] }>(`${this.modelUrl}/assignedGroupContracts?schema=${schema}`);
    return reponse.data;
  }
  async inviteUserToContract(id: ContractEntity['id'], email: UserEntity['email'], access: UserGrantEntity['access'], meta: UserGrantEntity['meta']) {
    try {
      const reponse = await this.apiClient.post<{ grant: UserGrantEntity } | { restriction: UserRestrictionEntity }>(`${this.modelUrl}/${id}/invite`, {
        email,
        access,
        meta: meta || {},
      });
      const grant = (reponse.data as { grant: UserGrantEntity }).grant || (reponse.data as { restriction: UserRestrictionEntity }).restriction
      return grant;
    } catch (error) {
      console.error("Error inviting user to contract: ", error);
      throw error;
    }
  };

  async sign(id: ContractEntity['id'], payload: ContractSignRequest) {
    try {
      const response = await this.apiClient.post<{ contractSignature: ContractSignatureEntity }>(`${this.modelUrl}/${id}/sign`, payload);
      const contractSignature = response.data.contractSignature
      return contractSignature;
    } catch (error) {
      console.error("Error inviting user to contract: ", error);
      throw error;
    }
  };
  async cancelSign(id: ContractEntity['id']) {
    try {
      const response = await this.apiClient.post<{ contract: ContractEntity }>(`${this.modelUrl}/${id}/cancelSign`);
      const contract= response.data.contract
      return contract;
    } catch (error) {
      console.error("Error canceling signature: ", error);
      throw error;
    }
  };

};

