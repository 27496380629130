import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import "./EditTransition.scss";
import {
  ClauseEntity,
  FormTemplatePageEntity,
  GroupEntity,
  ProjectTemplateRequirementEntity,
} from "../../../domain/entities";
import { FormParam } from "../../../domain/types/FormParams";
import CustomCombobox from "../../common/CustomCombobox";
import { statuses, RessourceStatus } from "../../../domain/types/status";
import { validateConstraint } from "../../../domain/Form";
import useConstraint, { ConstraintProps } from "../../../hooks/useConstraint";
import { WorkflowTransition, WorkflowTransitionCondition, WorkflowType } from "../../../domain/Workflow";




const ConstraintRenderContainer = (props: ConstraintProps) => {
  const { t, language } = useTranslation();
  const { constraintLabel,
    constraintInput,
    handleConstraintChange,
    setConstraintLabel,
    showConstraintDropDown,
    filteredConstraintParams,
    filteredFormulas,
    replaceLastPart,
    unvalidConstraint,
    closeConstraintDropDown, } = useConstraint(props)
  return <React.Fragment>
    <div>
      <label>{t("workflow.message")}</label>
      <div>
        <input
          value={constraintLabel}
          onChange={(e) => setConstraintLabel(e.target.value)}
        />
      </div>
      {unvalidConstraint && (
        <div className="error-message-dialog">
          {t("pages.editionFormTemplate.edition.unvalidConstraint")}
        </div>
      )}
    </div>
    <div>
      <label>{t("workflow.constraint")}</label>
      <div>
        <input
          className="formula"
          value={constraintInput}
          onChange={handleConstraintChange as any}
          onBlur={() => setTimeout(() => {
            closeConstraintDropDown()
          }, 500)}
        />
        {showConstraintDropDown &&
          (filteredConstraintParams.length > 0 || filteredFormulas.length > 0) && (
            <ul className="dropdown-list-formula">
              {filteredConstraintParams.map((p, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    replaceLastPart(
                      p.name,
                      constraintInput,
                    );
                  }}
                >
                  {p.name}
                </li>
              ))}
              {filteredFormulas.map((f, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    replaceLastPart(
                      f,
                      constraintInput,
                    );
                  }}
                >
                  {f}
                </li>
              ))}
            </ul>
          )}

      </div>
    </div>
  </React.Fragment>
};

type EditGroupTransitionConditionProps = {
  type: WorkflowType;
  types: WorkflowTransitionCondition['type'][];
  condition: WorkflowTransitionCondition;
  groups: GroupEntity[];
  params: FormParam[];
  clauses?: ClauseEntity[];
  pages?: FormTemplatePageEntity[];
  requirements?: ProjectTemplateRequirementEntity[];

  onSave: (condition: WorkflowTransitionCondition) => void;
  onRemove: () => void;
}
function EditGroupTransitionCondition(props: EditGroupTransitionConditionProps) {
  const { types, onSave, onRemove, condition, groups, params, clauses, pages, requirements } = props


  const [conditionData, setConditionData] = useState(condition);
  const [isOpen, setIsOpen] = useState<'type' | 'label' | 'value' | 'status' | 'groupId' | 'clauseId' | 'pageId' | 'requirementId' | null>(null);

  const { t, language } = useTranslation();

  const availableParams = useMemo(() => params
    .filter(p => ['number', 'boolean', 'enum', 'date', 'string'].includes(p.type))
    , [params]);
  const onTypeChange = (type: WorkflowTransitionCondition['type']) => {
    if (conditionData.type != type) {
      switch (type) {
        case 'clause':
          return setConditionData({
            type: "clause",
            clauseId: null
          })
        case 'page':
          return setConditionData({
            type: "page",
            pageId: null
          })
        case 'constraint':
          return setConditionData({
            type: "constraint",
            label: "",
            value: "",
          })
        case 'status':
          return setConditionData({
            type: "status",
            status: null,
          })
        case 'requirement-status':
          return setConditionData({
            type: "requirement-status",
            status: null,
            requirementId: null,
          })
        case 'requirement-validation':
          return setConditionData({
            type: "requirement-validation",
            groupId: null,
            requirementId: null,
          })

        default:
          break;
      }
    }
  }

  useEffect(() => {
    onSave(conditionData)
  }, [conditionData])


  return <div className="d-flex justify-content-between align-items-center">
    <div>
      <label>{t("workflow.type")}</label>
      <CustomCombobox
        options={types}
        value={conditionData.type}
        optionDisplay={option => option && t("workflow")[option] || option}
        onChange={onTypeChange}
        isOpen={isOpen == 'type'}
        setIsOpen={(value) => setIsOpen(value && 'type' || null)}
      />
    </div>
    {conditionData.type == 'constraint' && <React.Fragment>
      <ConstraintRenderContainer
        availableParams={availableParams}
        constraint={conditionData}
        onSingleConstraintChange={(label, value) => setConditionData({ ...conditionData, label, value })}
      />
    </React.Fragment>
    }
    {(conditionData.type == 'requirement-status' || conditionData.type == 'requirement-validation') && <React.Fragment>
      <div>
        <label>{t("workflow.requirement")}</label>
        <CustomCombobox
          options={requirements ?? []}
          optionDisplay={requirement => requirement?.name ?? ""}
          value={requirements.find(c => c.id == conditionData.requirementId)}
          onChange={(requirement: ProjectTemplateRequirementEntity) => setConditionData({ ...conditionData, requirementId: requirement?.id ?? null })}
          isOpen={isOpen == 'requirementId'}
          setIsOpen={(value) => setIsOpen(value && 'requirementId' || null)}
        />
      </div>
    </React.Fragment>
    }
    {(conditionData.type == 'status' || conditionData.type == 'requirement-status') && <React.Fragment>
      <div>
        <label>{t("workflow.status")}</label>
        <CustomCombobox
          options={statuses}
          optionDisplay={option => option && t("status")[option]}
          value={conditionData.status}
          onChange={(status: RessourceStatus) => setConditionData({ ...conditionData, status })}
          isOpen={isOpen == 'status'}
          setIsOpen={(value) => setIsOpen(value && 'status' || null)}
        />
      </div>

    </React.Fragment>
    }
    {conditionData.type == 'requirement-validation' && <React.Fragment>
      <div>
        <label>{t("workflow.validation")}</label>
        <CustomCombobox
          options={groups}
          optionDisplay={group => group?.name ?? ""}
          value={groups.find(g => g.id == conditionData.groupId)}
          onChange={(group: GroupEntity) => setConditionData({ ...conditionData, groupId: group?.id ?? null })}
          isOpen={isOpen == 'groupId'}
          setIsOpen={(value) => setIsOpen(value && 'groupId' || null)}
        />
      </div>
    </React.Fragment>
    }

    {conditionData.type == 'clause' && <React.Fragment>
      <div>
        <label>{t("workflow.clause")}</label>
        <CustomCombobox
          options={clauses ?? []}
          optionDisplay={clause => clause?.name ?? ""}
          value={clauses.find(c => c.id == conditionData.clauseId)}
          onChange={(clause: ClauseEntity) => setConditionData({ ...conditionData, clauseId: clause?.id ?? null })}
          isOpen={isOpen == 'clauseId'}
          setIsOpen={(value) => setIsOpen(value && 'clauseId' || null)}
        />
      </div>
    </React.Fragment>
    }
    {conditionData.type == 'page' && <React.Fragment>
      <div>
        <label>{t("workflow.page")}</label>
        <CustomCombobox
          options={pages ?? []}
          optionDisplay={page => page?.name ?? ""}
          value={pages.find(c => c.id == conditionData.pageId)}
          onChange={(page: ClauseEntity) => setConditionData({ ...conditionData, pageId: page?.id ?? null })}
          isOpen={isOpen == 'pageId'}
          setIsOpen={(value) => setIsOpen(value && 'pageId' || null)}
        />
      </div>
    </React.Fragment>
    }
    <button className="btn btn-light btn-handler" style={{ boxShadow: "none" }} onClick={onRemove}>
      -
    </button>
  </div>
}

type EditTransitionProps = {
  type: WorkflowType;
  conditionTypes: WorkflowTransitionCondition['type'][];
  transition: WorkflowTransition;
  groups: GroupEntity[];
  params: FormParam[];
  clauses?: ClauseEntity[];
  pages?: FormTemplatePageEntity[];
  requirements?: ProjectTemplateRequirementEntity[];
  onClose: () => void;
  onSave: (transition: WorkflowTransition) => void;

}

function EditTransition({
  type,
  conditionTypes,
  onClose, onSave,
  groups, params,
  transition,
  clauses,
  pages,
  requirements,
}: EditTransitionProps) {

  const [transitionData, setTransitionData] = useState(transition);
  const { t, language } = useTranslation();

  const handleSave = async (e) => {
    transitionData.conditions = transitionData.conditions.filter((cond) =>
      (cond.type == 'constraint' && cond.label && cond.value && validateConstraint(cond.value, params))
      || (cond.type == 'status' && cond.status)
      || (cond.type == 'clause' && cond.clauseId)
      || (cond.type == 'page' && cond.pageId)
      || (cond.type == 'requirement-status' && cond.requirementId)
      || (cond.type == 'requirement-validation' && cond.requirementId)

    )
    onSave({
      ...transitionData,
    })
    onClose()

  };

  const handleEditCondition = (editedCondition, id) => setTransitionData((prev) => ({
    ...prev,
    conditions: transitionData.conditions.map((c, idx) => id == idx ? editedCondition : c)
  }))
  const handleRemoveCondition = (id) => setTransitionData((prev) => ({
    ...prev,
    conditions: transitionData.conditions.filter((c, idx) => id != idx)
  }))


  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="type-add-popup" className="type-add-popup">
        <div className="modal modal-add-type d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {t("workflow.editTransition")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "75%" }}
                ></button>
              </div>
              <div className="modal-body body-add-type">
                <div className="form-group" style={{ position: "relative" }}>
                  <label >{ }</label>
                  {transitionData.conditions.map((condition, id) => (
                    <div className="d-flex justify-content-between align-items-center">
                      <EditGroupTransitionCondition
                        key={id}
                        type={type}
                        types={conditionTypes}
                        groups={groups}
                        params={params}
                        clauses={clauses}
                        pages={pages}
                        requirements={requirements}
                        condition={condition}
                        onSave={(editedCondition) => handleEditCondition(editedCondition, id)}
                        onRemove={() => handleRemoveCondition(id)}
                      />
                    </div>
                  ))}
                </div>
                <div className="actions-popup-type">
                  <button className="add-signle-type"
                    onClick={handleSave}
                  >
                    {t("workflow.save")}
                  </button>
                  <button className="add-signle-type"
                    onClick={() => setTransitionData((prev) => ({
                      ...prev,
                      conditions: [...prev.conditions, {} as any]
                    }))}
                  >
                    {t("workflow.add")}
                  </button>
                  <button
                    type="reset"
                    className="cancel-adding-type"
                    onClick={onClose}
                  >
                    {t("workflow.quit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTransition;
