import React, { useEffect, useMemo } from 'react'
import { BeneficialEntity, BeneficialMacroEntity, ClauseEntity } from '../../../../domain/entities'
import { companyEntitySample, personBeneficialEntitySample, minorEntitySample, generateParamsFromEntity } from './beneficialHelper';
import { RenderSegments, SegmentedClauseParams, SegmentedText, SegmentedTextType } from '../../../../domain/types/ClauseParams';
import { deleteSegment, idfySegmentation, insertParamInSegment, removeUnusedParams, styleSegmentation } from './segmentation';
import { genIdFactory } from '../../../../domain/ContractTemplate';
import { getMacroRenderSegments } from '../../../../helpers/macroSegmentation';
import useTranslation from '../../../../hooks/useTranslation';
import SlateEditor from './StaleEditor/SlateEditor';
import { LanguageType } from '../../../../contexts/TranslationProvider';

function MacroEditor(
  {macro, handleSegmentationChange, paramValues}: {macro: BeneficialMacroEntity, handleSegmentationChange: (segmentation: BeneficialMacroEntity['segmentation'], id: BeneficialMacroEntity['id']) => void, paramValues}
  ) {
  const [macroParams, setMacroParams] = React.useState<SegmentedClauseParams>([]);
  const [segmentation, setSegmentation] = React.useState<ClauseEntity['segmentation']>(macro?.segmentation);
  const { t, language } = useTranslation();


  useEffect(() => {
    if (!macro || !macro.type) return;
    const type = macro.type;
    let sample = null;
    switch (type) {
      case 'Person':
        sample = personBeneficialEntitySample;
        break;
      case 'Company':
        sample = companyEntitySample;
        break;
      case 'Minor':
        sample = minorEntitySample;
        break;
      default:
        sample = personBeneficialEntitySample;
        break;
    }
    setMacroParams(generateParamsFromEntity(sample, 'Beneficial.', 'Beneficial '));
    setSegmentation(prev => {
      const actualSegmentation = macro.segmentation;
      if (!actualSegmentation.segmentedText || actualSegmentation.segmentedText.length === 0) {
        return {
          segmentedText: [["_", "_", SegmentedTextType.STATIC]],
          segmentedParams: []
        }
      }
      return actualSegmentation
    })
  }, [macro]);


  const onApplySegmentation = async (styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => {
    const styledSegmentation = styleSegmentation(segmentation, styledSegments, deletedSegments)
    const newSegmentation = idfySegmentation(styledSegmentation, genIdFactory("1"))
    const cleanedSegmentation = removeUnusedParams(newSegmentation)
    setSegmentation(cleanedSegmentation)
    handleSegmentationChange(cleanedSegmentation, macro.id)
  }
  const onAddParam = (nodeId, param, textBefore, textAfter, segments, field, deletedSegments) => {
    const definitionCount = segmentation.segmentedParams.filter(p => p.name === param.name).length
    let newSegmentation = insertParamInSegment(segmentation.segmentedText, segmentation.segmentedParams, nodeId, param, definitionCount, textBefore, textAfter, field, segments, deletedSegments)
    newSegmentation = idfySegmentation(newSegmentation, genIdFactory("1"))
    newSegmentation = removeUnusedParams(newSegmentation)
    setSegmentation(newSegmentation)
    handleSegmentationChange(newSegmentation, macro.id)
  }
  const onSegmentDelete = (id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => {
    let newSegmentation = deleteSegment(segmentation.segmentedText, segmentation.segmentedParams, id, styledSegments, deletedSegments)
    newSegmentation = idfySegmentation(newSegmentation, genIdFactory("1"))
    newSegmentation = removeUnusedParams(newSegmentation)
    setSegmentation(newSegmentation)
    handleSegmentationChange(newSegmentation, macro.id)
  }

  const persistentSegments = useMemo(() => {
    const segmenntedText: SegmentedText = segmentation.segmentedText?.length > 0 ? segmentation.segmentedText : [["_", "_", SegmentedTextType.STATIC]];
    const renderSegments = getMacroRenderSegments(segmenntedText, paramValues, segmentation.segmentedParams, t, language)
      .map(seg => {
        return {
          ...seg,
          value: seg.value?.replaceAll(/\r\n/g, "\n"),
        }
      })
    return renderSegments
  }, [macro, macroParams, paramValues, segmentation, language, t])
  return (
    <div key={`${macro.language}-${macro.name}-${macro.type}`}>
      {
        persistentSegments.length > 0 && <SlateEditor
        segments={persistentSegments}
        onApplySegmentation={onApplySegmentation}
        params={macroParams}
        onAddParam={onAddParam}
        onSegmentDelete={onSegmentDelete}
      />
      }
      
    </div>

  )
}

export default MacroEditor