import React, { useState } from 'react'
import { RiDeleteBinLine } from "react-icons/ri";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import ChatWithDocumentContext from '../../contexts/ChatWithDocumentContext';
import { useTranslation } from '../../contexts/TranslationProvider';

function FileChatHistory() {
  const [expanded, setExpanded] = useState(true);
  const { t } = useTranslation()
  const { currentFile, currentConversation, conversations, setCurrentConversation, setPendingQuestion, handleDelete } = React.useContext(ChatWithDocumentContext)
  return (
    <div className='conversations-container-by-reglement'>
      <div className='grouped-conversation-list'>
      {
        currentConversation && <div className='start-new-conversation-button' onClick={() => setCurrentConversation(null)}>
          {t("pages.chat.startConversation")}
        </div>

      }
        <div className={`conversation-group-container selected`}>
          <div className='reglement-header'>
            {currentFile && <span className='title'>{currentFile.name}</span>}
            <span className='icon' onClick={() => setExpanded(!expanded)}>
              {expanded ? <GoChevronUp /> : <GoChevronDown />}
            </span>
          </div>
          {expanded && <div className='reglement-conversation-list'>
            {conversations && conversations.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
            .map((conversation, index) => {
              return (
                <div
                  style={{ color: currentConversation?.id === conversation.id ? "blue" : "black" }}
                  className='conversation'
                  key={index}
                  onClick={() => {
                    setCurrentConversation(conversation)
                    setPendingQuestion(null)
                  }}>
                  <span className='title'>
                    {conversation.name ? conversation.name : "conversation"}
                  </span>
                  <div className='icon-section'>
                    <span className='icon'>
                      <RiDeleteBinLine onClick={(e) => handleDelete(e, { ...conversation })} />
                    </span>
                  </div>
                </div>
              )
            })}
          </div>}
        </div>
      </div>
    </div>
  )
}

export default FileChatHistory