import React, { useState, useEffect, useMemo } from 'react'
import "./ressource.scss"
import ContractCardActionsModal from '../../components/modals/ContractCardActionsModal';
import { useTranslation } from '../../contexts/TranslationProvider';
import { FiMoreHorizontal } from "react-icons/fi";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import calculate from "../../assets/new-design-icons/Calculate.svg"
import chat from "../../assets/new-design-icons/Chat.svg"
import { ar, fr, enUS, da } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { ContractEntity, FormEntity, DocumentEntity } from '../../domain/entities';
import { getTypeLevels } from '../../helpers/helper';
import { LoadingRessourceContext } from '../../contexts/LoadingRessourceContext';
import Loading from '../../components/common/Loading';
import { RessourceEntity } from '../../domain/entities/Ressource';
import { LinkedRessources } from '../../domain/Project';
import FormBody from '../../components/common/newDesign/RessourceCard/content/FormBody';
import ContractBody from '../../components/common/newDesign/RessourceCard/content/ContractBody';
import DocumentBody from '../../components/common/newDesign/RessourceCard/content/DocumentBody';
const aiIcon = require('../../assets/new-design-icons/ai.png')
const docIcon = require('../../assets/new-design-icons/doc.png')

function RequirementRessourceCard({
  data,
  onDelete,
  onClick
}: {
  data: RessourceEntity;
  onDelete: () => void;
  onClick: () => void;
}) {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const [updatedSince, setUpdatedSince] = useState("")
  const [createdSince, setCreatedSince] = useState("")
  const [template, setTemplate] = useState(null);
  const { loadingRessources } = React.useContext(LoadingRessourceContext);
  const isLoading = loadingRessources.some(ressource => ressource.id === data.id && ressource.type === data.type);
  const calculateIcon = calculate.toString()
  const chatIcon = chat.toString()
  const { t, language } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };
  const typeDisplay = useMemo(() => {
    if (data.type === 'Document' && data.level1) {
      const level1 = data.level1 || null;
      const level2 = data.level2 || null;
      const level3 = data.level3 || null;
      return [level1, level2, level3].filter((level) => level !== null).map((level) => {
        const translatedName = level.translations.find(t => t.language === language);
        if (translatedName) {
          return translatedName.name;
        }
        return level.name;
      }).join("/");
    }
    if (data.type == 'Document' && data.document?.summary) {
      const level1 = data.document.summary.level1 || null;
      const level2 = data.document.summary.level2 || null;
      const level3 = data.document.summary.level3 || null;
      return [level1, level2, level3].filter((level) => level !== null).map((level) => {
        const translatedName = level.translations.find(t => t.language === language);
        if (translatedName) {
          return translatedName.name;
        }
        return level.name;
      }).join("/");
    } else {
      return getTypeLevels(data).map(
        level => {
          const translatedName = level.translations.find(t => t.language === language)
          if (translatedName) {
            return translatedName.name
          } else {
            return level.name
          }
        }
      ).join("/")
    }

  }, [data, language]);

  useEffect(() => {
    const updateDate = new Date(data?.updatedAt);
    const creationDate = new Date(data?.createdAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setUpdatedSince(time);
    }
    if (Date.parse(creationDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(creationDate, { addSuffix: true, locale });
      setCreatedSince(time);
    }
  }, [data, language]);
  const bodyRender = (data: RessourceEntity, typeDisplay: string) => {
    console.log("bodyRender", data);

    switch (data.type) {
      case ('Form'):
        return (<FormBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
      case ('Contract'):
        return (<ContractBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
      case ('RessourceScoring'):
        return (<ContractBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
      case ('Document'):
        if (data.level1Id == null) {
          return (<DocumentBody data={data} updatedSince={updatedSince} createdSince={createdSince} />)
        } else {
          return (<ContractBody type={typeDisplay} data={data} updatedSince={updatedSince} createdSince={createdSince} />)
        }

      default:
        return null;
    }
  }
  const handleClick = () => {
    if (isLoading)
      return;
    onClick()
  }
  return (
    <div onClick={handleClick} key={data.id + '-' + data.type} className='ressource-required-card-container'>
      <div className='header'>
        <div className='title'>
          <div className='icon'>
            {
              data.type === 'Form' ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                data.type === 'Contract' || (data.type === 'Document' && data.level1Id) ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                  data.type === 'Document' ? <img src={docIcon} alt="icon" style={{ width: "25px", height: "25px" }} /> :
                    <></>
            }
          </div>
          <div className='name'>
            {data.name}
          </div>
        </div>
        <div className='actions'>
          <button className="plus-icon" onClick={(e) => togglePopup(e)}>
            <FiMoreHorizontal size={20} />
          </button>
          {
            data.hasAIProcessing &&
            <img src={aiIcon} alt="ai-icon" className="ai-icon" />
          }
          {isPopupOpen &&
            <ContractCardActionsModal
              onView={handleClick}
              onDelete={onDelete}
              onClose={() => setIsPopupOpen(false)}
              isOpen={isPopupOpen}
            />
          }
        </div>
      </div>
      {
        bodyRender(data, typeDisplay)
      }
      <div className='footer'>
        <div className={`users-display ${language == "ar" && "users-arabic-display"}`}>
          {(data as ContractEntity)?.ressource?.users?.slice(0, 2).map((user, index) => (
            <div key={user.id} className={`avatar-circle layer-${2 - index}`}>
              {user?.email ? <p>{user.email.charAt(0).toUpperCase()}</p> : null}
            </div>
          ))}
          {((data as ContractEntity)?.ressource?.users.length > 2) ? (
            <div className="avatar-circle layer-3">
              <p>+{((data as ContractEntity)?.ressource?.users.length - 2)}</p>
            </div>
          ) : null}
          {(data as FormEntity)?.ressource?.users?.slice(0, 2).map((user, index) => (
            <div key={user.id} className={`avatar-circle layer-${2 - index}`}>
              {user?.email ? <p>{user.email.charAt(0).toUpperCase()}</p> : null}
            </div>
          ))}
          {((data as FormEntity)?.ressource?.users.length > 2) ? (
            <div className="avatar-circle layer-3">
              <p>+{((data as FormEntity)?.ressource?.users.length - 2)}</p>
            </div>
          ) : null}
          {data.users && data.users.length > 0 && data.users[0] && data.users[0].fullName &&
            <div className='user-name'>
              {data.users[0].fullName}
            </div>
          }
        </div>
        {data.status || isLoading ?
          <div className='status'>
            {isLoading ? <Loading /> : t("status." + data.status)}
          </div>
          : ""}
      </div>
    </div>
  )
}

export default RequirementRessourceCard