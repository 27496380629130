import React, { useState } from "react";
import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'
import ContractSignModal from "../../../modals/ContractSignModal";

function SignButton() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return <>
    <button className="navbar-button" onClick={() => setShowModal(true)}>
      {t("navbar.signature")}
    </button>
    {showModal && <ContractSignModal onClose={() => setShowModal(false)} />}
  </>
}

export default SignButton;
