import React, { useContext, useEffect, useMemo, useState } from 'react'
import Loading from '../../../components/common/Loading';
import EditFormTemplateInputList from './EditFormTemplateInputList';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { MdOutlineAddBox } from "react-icons/md";
import { set } from 'react-hook-form';
import EventManager from '../../../services/EventManager';
import { toast } from 'react-toastify';
import { FormTemplateClient } from '../../../services/api/FormTemplateClient';
import useApiClientWithLoading from '../../../services/api/ApiClient';
import "./editFormTemplate.scss"
import { formatDistanceToNow } from 'date-fns';
import { ar, fr, enUS } from 'date-fns/locale';
import { FiEdit2 } from "react-icons/fi";
import EditFormTemplateSidebar from './EditFormTemplateSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveWorkbook } from '../../../utils/csv';
import { downloadFileJSON } from '../../../utils/json';
import { LoadingTemplateContext } from "../../../contexts/LoadingTemplateContext";
import * as XLSX from "xlsx"
import EditFormTemplateContext from '../../../contexts/EditFormTemplateContext';
import GroupsForm from './GroupsForm';
import MiddlewareManager from '../../../services/MiddlewareManager';
import { createPromiseSub, PromiseSub } from '../../../utils/promise';
import SaveDraftModel from '../../../components/modals/SaveDraftModal';
import WorkflowSettingsModal from '../../../components/modals/WorkflowSettingsModal';
import { FormParam } from '../../../domain/types/FormParams';
import { WorkflowGraph } from '../../../domain/Workflow';
import { BsNodePlusFill } from 'react-icons/bs';
const { default: EyeOffIcon } = require("../../../assets/eye-off.svg");
const { default: EyeOnIcon } = require("../../../assets/eye-on.svg");

function EditFormTemplate() {
  const navigate = useNavigate()
  const {
    formTemplate,
    setFormTemplate,
    onTemplateRename
  } = useContext(EditFormTemplateContext);
  const { loadingTemplates, setLoadingTemplates } = useContext(LoadingTemplateContext)
  const translationPath = "pages.editionForm"
  const [currentIndexPage, setIndexCurrentPage] = useState(0)
  const [timeSinceUpdate, setTimeSinceUpdate] = useState("")
  const [renameTrigger, setRenameTrigger] = useState(false)
  const [changeGroups, setChangeGroups] = useState(false)
  const { t, language, setLanguage } = useTranslation();

  const [showTransitionsSettings, setShowTransitionsSettings] = useState(false)


  useEffect(() => {
    const arabicRegex = /[\u0600-\u06FF]/;
    if (arabicRegex.test(formTemplate?.name)) {
      setLanguage("ar")
    }
  }, [formTemplate])
  useEffect(() => {
    const updateDate = new Date(formTemplate?.updatedAt);
    if (Date.parse(updateDate.toString())) {
      const localeMap = {
        en: enUS,
        fr: fr,
        ar: ar
      };
      const locale = localeMap[language] || enUS;
      const time = formatDistanceToNow(updateDate, { addSuffix: true, locale });
      setTimeSinceUpdate(time)
    }
  }, [formTemplate, language])
  const apiClient = useApiClientWithLoading();
  const formTemplateClient = new FormTemplateClient(apiClient)
  let index = formTemplate?.pages?.length || 0;
  const saveTemplateAsCSV = async () => {
    try {

      const { url } = await formTemplateClient.export(formTemplate?.id);
      const apiUrl = new URL(url);
      const response = await fetch(apiUrl.toString());
      const arrayBuffer = await response.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      saveWorkbook(workbook, `${formTemplate?.name}.xlsx`);
    } catch (error) {
      console.log('error', error);
    }
  };
  const saveTemplateAsJSON = async () => {
    try {
      if (!formTemplate?.id) {
        console.error('No form template ID found');
        return;
      }
      const { url } = await formTemplateClient.export(formTemplate.id, 'json');
      await downloadFileJSON(url, `${formTemplate.name}.json`);
    } catch (error) {
      console.error('Error saving template as JSON:', error);
    }
  };


  useEffect(() => {
    EventManager.subscribe('ExportFormTemplateCSV', saveTemplateAsCSV)
    return () => {
      EventManager.unsubscribe('ExportFormTemplateCSV', saveTemplateAsCSV)
    }
  }, [formTemplate?.id])
  useEffect(() => {
    EventManager.subscribe('ExportFormTemplateJSON', saveTemplateAsJSON)
    return () => {
      EventManager.unsubscribe('ExportFormTemplateJSON', saveTemplateAsJSON)
    }
  }, [formTemplate?.id])


  const location = useLocation()
  const [draft, setSaveDraft] = useState(false);
  const [sub, setSub] = useState<PromiseSub>(null);

  const onLocation = async (pathname: string) => {
    if (location.pathname.includes("/edition-form-template") && !pathname.includes("/edition-form-template")) {
      const newSub = createPromiseSub()
      setSaveDraft(true);
      setSub(newSub);
      await newSub.promise
    }
  };

  useEffect(() => {
    MiddlewareManager.subscribe('location', onLocation);
    return () => {
      MiddlewareManager.unsubscribe('location', onLocation);
    };
  }, []);
  const saveFormTemplateHandler = async () => {
    try {
      setLoadingTemplates(prev => [...prev, { id: formTemplate.id, type: "FormTemplate" }]);
      const updatedTemplate = await formTemplateClient.update(formTemplate.id, formTemplate);
      toast.success(t("pages.contractTemplateParam.toasts.saveTemplate.success"));
      setLoadingTemplates(prev => prev.filter(temp => temp.id !== formTemplate.id && temp.type === "FormTemplate"));
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error(t("pages.contractTemplateParam.toasts.saveTemplate.error"));
      setLoadingTemplates(prev => prev.filter(temp => temp.id !== formTemplate.id && temp.type === "FormTemplate"));
    }
  };
  const activateFormTemplateHandler = async () => {
    try {
      await saveFormTemplateHandler().then(async () => {
        formTemplateClient.activate(formTemplate.id).then(() => {
          navigate("/form-templates")
          toast.success(t("pages.contractTemplateParam.toasts.activateTemplate.success"));
        })
      })
    } catch (error) {
      console.error('Error activating template:', error);
      toast.error(t("pages.contractTemplateParam.toasts.activateTemplate.error"));
    }
  }
  useEffect(() => {
    EventManager.subscribe('ActivateFormTemplate', activateFormTemplateHandler);
    EventManager.subscribe('SubmitFormTemplate', saveFormTemplateHandler);
    return () => {
      EventManager.unsubscribe('SubmitFormTemplate', saveFormTemplateHandler);
      EventManager.unsubscribe('ActivateFormTemplate', activateFormTemplateHandler);
    };

  }, [formTemplate]);
  const formIcon = require("../../../assets/new-design-icons/form-icon.png")

  const params = useMemo(() => {
    return formTemplate?.pages?.reduce<FormParam[]>((acc, page) => acc.concat(page.params), []) ?? []
  }, [formTemplate])

  const onSaveGroupsGraph = async (groupsGraph: WorkflowGraph) => {

    setFormTemplate((prev) => (
      {
        ...prev,
        groupsGraph,
      }
    ))
  }

  return (
    <>
      <div className="d-flex align-items-start justify-content-between border-bottom w-100 pr-3 pt-2 pb-2 pl-3" style={{ paddingRight: "55px", paddingLeft: "18px" }}>
        <div className='form-template-header'>
          <div className='form-template-title'>
            <div style={{ overflow: "hidden", width: "40px", height: "40px", display: "flex", alignItems: "center", justifyContent: "center", marginRight: "10px" }}>
              <img src={formIcon} alt="form-icon" style={{ width: "175px", height: "175px", marginTop: "80px" }} />
            </div>
            <div className='form-template-title-details'>
              <span className='title'>
                {
                  renameTrigger ?
                    <input
                      className='input-title'
                      value={formTemplate?.name}
                      onChange={(e) => {
                        onTemplateRename(e.target.value)
                      }}
                    />
                    :
                    formTemplate?.name
                }
                &nbsp;
                &nbsp;
                <div style={{ position: "relative" }}>
                  <FiEdit2 style={{ cursor: "pointer" }} color={`${renameTrigger ? "blue" : "gray"}`} onClick={() => setRenameTrigger(!renameTrigger)} />
                  <img
                    id="select_group"
                    src={changeGroups ? EyeOnIcon : EyeOffIcon}
                    alt="#"
                    className="edit-template-name"
                    style={{ color: "gray", maxHeight: "20px", marginLeft: "10px", cursor: "pointer", marginRight: "10px" }}
                    onClick={() => setChangeGroups(prev => !prev)}
                  />
                  {
                    changeGroups ?
                      <div className="set-groups-container">
                        <GroupsForm onClose={() => setChangeGroups(false)} />
                      </div>
                      : null
                  }
                  <BsNodePlusFill
                    className="edit-template-name"
                    style={{ color: "gray", maxHeight: "20px", marginRight: "15px", marginLeft: "10px" }}
                    onClick={() => setShowTransitionsSettings(true)}
                  />
                </div>
              </span>
              <span className='modified'>
                {t("pages.contractTemplateParam.modified")} : {timeSinceUpdate}
              </span>
            </div>
          </div>
          <div className='form-template-pagination'>
            {t(translationPath + ".page")} ({currentIndexPage + 1}/{index})
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="form-template-sidebar-section">
          <EditFormTemplateSidebar currentIndexPage={currentIndexPage} setIndexCurrentPage={setIndexCurrentPage} />
        </div>
        <div style={{ borderRadius: "0px" }} className="edit-form-input-list-container">
          {formTemplate ? <EditFormTemplateInputList currentPage={formTemplate?.pages[currentIndexPage]} setIndexCurrentPage={setIndexCurrentPage} currentIndexPage={currentIndexPage} /> : <Loading />}
        </div>
      </div>
      {draft && (
        <SaveDraftModel
          type=''
          onClose={() => { setSaveDraft(false); sub.reject() }}
          onSave={(saved: boolean) => { sub.resolve() }}
          handleSave={async () => {
            await saveFormTemplateHandler()
            return true
          }}
        />
      )}
      {
        showTransitionsSettings && (
          <WorkflowSettingsModal
            type='Form'
            groups={formTemplate.groups}
            groupsGraph={formTemplate.groupsGraph}
            params={params}
            onClose={() => setShowTransitionsSettings(false)}
            onSave={onSaveGroupsGraph}
            pages={formTemplate.pages}
          />
        )
      }
    </>
  )
}

export default EditFormTemplate