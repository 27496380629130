import { useEffect, useMemo, useRef, useState } from "react";
import { InputValuesContextType } from "../contexts/InputValuesContext";
import {
  ClauseEntity,
  ContractEntity,
  ContractTemplateEntity,
  GroupEntity,
  ProjectEntity,
  ProjectTemplateRequirementEntity,
  SubClauseEntity,
} from "../domain/entities";
import parseTemplateData from "../helpers/ParseTemplateData";
import {
  RenderSegments,
  SegmentedClauseParam,
} from "../domain/types/ClauseParams";
import {
  deleteSegment,
  genIdFactory,
  idfySegmentation,
  insertParamInSegment,
  styleSegmentation,
  updateSegment,
} from "../domain/ContractTemplate";

export function useProvideContextInputValues(): InputValuesContextType {
  const [inputValues, setInputValues] = useState({});
  const [status, setContractStatus] = useState(null);
  const [clausesOverrides, setClausesOverrides] =
    useState<ContractEntity["clausesOverrides"]>();
  const [fileNames, setFileNames] = useState<ContractEntity["fileNames"]>({});
  const [beneficialsMap, setBeneficialsMap] = useState<
    ContractEntity["beneficialsMap"]
  >({});
  const [commentsOverrides, setCommentsOverrides] = useState({});
  const [additionalClauses, setAdditionalClauses] = useState<
    ContractEntity["additionalClauses"]
  >([]);
  const [excludedSubClauses, setExcludedSubClauses] = useState([]);
  const [userGroup, setUserGroup] = useState<number[]>([]);
  const [accessUser, setAccessUser] = useState<Record<string, boolean>>({});
  const [groupContract, setGroupContract] = useState<string>();
  const [project, setProject] = useState<ProjectEntity>();
  const [requirement, setRequirement] =
    useState<ProjectTemplateRequirementEntity>();
  const [requirementType, setRequirementType] = useState<number>();
  const [excludedClauses, setExcludedClauses] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const [contractId, setContractId] = useState(null);
  const [contract, setContract] = useState(null);
  const [contractTemplate, setContractTemplate] =
    useState<ContractTemplateEntity>(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);

  const contractListParentRef = useRef(null);
  const onSegmentDelete = (
    clauseId: ClauseEntity["id"],
    subClauseId: SubClauseEntity["id"],
    id: string,
    styledSegments: ClauseEntity["segmentation"]["segmentedText"],
    deletedSegments: RenderSegments
  ) => {
    let clause: ClauseEntity;
    let subClause: SubClauseEntity;
    let clauseIdx: number;
    let subClauseIdx: number;
    let segmentation: ClauseEntity["segmentation"];
    clauseIdx = contractTemplate.clauses!.findIndex((c) => c.id == clauseId);
    clause = contractTemplate.clauses[clauseIdx];

    if (subClauseId) {
      subClauseIdx = clause.subClauses!.findIndex((sc) => sc.id == subClauseId);
      subClause = clause.subClauses[subClauseIdx];
      segmentation = subClause.segmentation;
    } else {
      segmentation = clause.segmentation;
    }
    segmentation = deleteSegment(
      segmentation.segmentedText,
      segmentation.segmentedParams,
      id,
      styledSegments,
      deletedSegments
    );

    if (subClauseId) {
      segmentation = idfySegmentation(
        segmentation,
        genIdFactory(subClause.index)
      );
      subClause.segmentation = segmentation;
      clause.subClauses = [...clause.subClauses];
    } else {
      segmentation = idfySegmentation(segmentation, genIdFactory(clause.index));
      clause.segmentation = segmentation;
    }
    let newContractTemplateValue = { ...contractTemplate };
    newContractTemplateValue.clauses[clauseIdx] = clause;
    setContractTemplate(newContractTemplateValue);
  };
  const [combinedTemplateParams, setCombinedTemplateParams] = useState<
    SegmentedClauseParam[]
  >([]);
  const onAddParam = (
    clauseId: ClauseEntity["id"],
    subClauseId: ClauseEntity["id"],
    id: string,
    newParam: SegmentedClauseParam,
    textBefore: string,
    textAfter: string,
    segments: ClauseEntity["segmentation"]["segmentedText"],
    field: string,
    deletedSegments: RenderSegments
  ) => {
    let clause: ClauseEntity;
    let subClause: SubClauseEntity;
    let segmentation: ClauseEntity["segmentation"];
    let clauseIdx: number;
    let subClauseIdx: number;

    clauseIdx = contractTemplate.clauses!.findIndex((c) => c.id == clauseId);
    clause = contractTemplate.clauses[clauseIdx];

    if (newParam.type == "beneficial" || newParam.type == "beneficial[]") {
      if (
        combinedTemplateParams.find(
          (p) => p.name === newParam.name && p.type === newParam.type
        )
      ) {
        newParam.args = (
          combinedTemplateParams.find(
            (p) => p.name === newParam.name && p.type === newParam.type
          ) as any
        ).args;
      }
    }

    if (newParam.type == "number") {
      newParam.format = `formatWithSpaces(${newParam.name})`;
    }

    if (subClauseId) {
      subClauseIdx = clause.subClauses!.findIndex((sc) => sc.id == subClauseId);
      subClause = clause.subClauses[subClauseIdx];
      segmentation = subClause.segmentation;
    } else {
      segmentation = clause.segmentation;
    }
    const existingParam = segmentation.segmentedParams.find(
      (p) => p.name === newParam.name && p.type === newParam.type
    );
    let newSegmentation;
    if (!existingParam) {
      newSegmentation = insertParamInSegment(
        segmentation.segmentedText,
        segmentation.segmentedParams,
        id,
        newParam,
        0,
        textBefore,
        textAfter,
        field,
        segments,
        deletedSegments
      );
    } else {
      const definitions = segmentation.segmentedParams.filter(
        (param) => param.name == newParam.name && param.type == newParam.type
      );
      const definition =
        definitions.reduce(
          (prev, curr) => Math.max(prev, (curr as any).definition || 0),
          0
        ) + 1;
      newSegmentation = insertParamInSegment(
        segmentation.segmentedText,
        segmentation.segmentedParams,
        id,
        newParam,
        definition,
        textBefore,
        textAfter,
        field,
        segments,
        deletedSegments
      );
    }
    if (subClauseId) {
      segmentation = idfySegmentation(
        newSegmentation,
        genIdFactory(subClause.index)
      );
      subClause.segmentation = segmentation;
      clause.subClauses = [...clause.subClauses];
    } else {
      segmentation = idfySegmentation(
        newSegmentation,
        genIdFactory(clause.index)
      );
      clause.segmentation = segmentation;
    }

    let newContractValue = { ...contract };
    newContractValue.clausesOverrides = {
      ...newContractValue.clausesOverrides,
      [clause.id]: {
        segmentation: clause.segmentation,
        subClauses:
          clause.subClauses?.map((subClause) => ({
            subClauseId: subClause.id,
            subClauseIndex: subClause.index,
            subClauseName: subClause.name,
            segmentation: subClause.segmentation,
          })) || [],
      },
    };
    setClausesOverrides(newContractValue.clausesOverrides);
    setContract(newContractValue);
  };
  const onApplySegmentation = async (
    clauseId: ClauseEntity["id"],
    subClauseId: SubClauseEntity["id"],
    styledSegments: ClauseEntity["segmentation"]["segmentedText"],
    deletedSegments: RenderSegments
  ) => {
    let clause: any;
    let subClause: any;
    let clauseIdx: number;
    let subClauseIdx: number;
    let segmentation: ClauseEntity["segmentation"];
    let newClausesOverrides: any;
    clauseIdx = contract.template.clauses!.findIndex((c) => c.id == clauseId);

    const clauseOverride = contract.clausesOverrides?.[clauseId];

    clauseIdx = contract.template.clauses!.findIndex((c) => c.id == clauseId);

    clause = clauseOverride
      ? { ...contract.template.clauses![clauseIdx], ...clauseOverride }
      : contract.template.clauses![clauseIdx];

    if (subClauseId) {
      const subClauseOverride = clauseOverride?.subClauses?.find(
        (sc) => sc.id === clauseIdx
      );
      if (clauseOverride) {
        subClause = clause.subClauses?.find(
          (sc) => sc.subClauseId === subClauseId
        );
        subClauseIdx = subClause ? subClause.subClauseIndex : -1;
      } else {
        subClauseIdx = clause.subClauses!.findIndex(
          (sc) => sc.id == subClauseId
        );
        subClause = clause.subClauses![subClauseIdx];
      }
      segmentation = subClause.segmentation;
    } else {
      segmentation = clause.segmentation;
    }

    const styledSegmentation = styleSegmentation(
      segmentation,
      styledSegments,
      deletedSegments
    );

    const newSegmentation = idfySegmentation(
      styledSegmentation,
      genIdFactory(
        subClauseId
          ? clause.subClauses!.find((sc) => sc.id == subClauseId)?.index
          : clause.index
      )
    );

    if (subClauseId) {
      if (clauseOverride) {
        let updatedSubClauses = clause.subClauses!.map((sc) =>
          sc.subClauseId === subClauseId
            ? {
              subClauseId: sc.subClauseId,
              subClauseIndex: sc.subClauseIndex,
              subClauseName: sc.subClauseName,
              segmentation: newSegmentation,
            }
            : sc
        );
        clause = { ...clause, subClauses: updatedSubClauses };
        newClausesOverrides = {
          ...contract.clausesOverrides,
          [clause.id]: {
            segmentation: clause.segmentation,
            subClauses:
              clause.subClauses?.map((subClause) => ({
                subClauseId: subClause.subClauseId,
                subClauseIndex: subClause.subClauseIndex,
                subClauseName: subClause.subClauseName,
                segmentation: subClause.segmentation,
              })) || [],
          },
        };
        console.log("1")
      } else {
        let updatedSubClauses = clause.subClauses!.map((sc) =>
          sc.id === subClauseId
            ? { id: sc.id, name: sc.name, index: sc.index, segmentation: newSegmentation }
            : sc
        );
        clause = { ...clause, subClauses: updatedSubClauses };
        newClausesOverrides = {
          ...contract.clausesOverrides,
          [clause.id]: {
            segmentation: clause.segmentation,
            subClauses:
              clause.subClauses?.map((subClause) => ({
                subClauseId: subClause.id,
                subClauseIndex: subClause.index,
                subClauseName: subClause.name,
                segmentation: subClause.segmentation,
              })) || [],
          },
        };
        console.log("2")
      }
    } else {
      clause = { ...clause, segmentation: newSegmentation };
      newClausesOverrides = {
        ...contract.clausesOverrides,
        [clause.id]: {
          segmentation: clause.segmentation,
          subClauses:
            clause.subClauses?.map((subClause) => ({
              subClauseId: subClause.id,
              subClauseIndex: subClause.index,
              subClauseName: subClause.name,
              segmentation: subClause.segmentation,
            })) || [],
        },
      };
      console.log("3")
    }
    let newContractValue = {
      ...contract,
      clausesOverrides: newClausesOverrides,
    };
    setContract(newContractValue);
    setClausesOverrides(newClausesOverrides);
  };
  const onSegmentaionParamChanged = (
    segmentation: ClauseEntity["segmentation"],
    param: SegmentedClauseParam
  ) => {
    switch (param.type) {
      case "beneficial":
      case "beneficial[]":
      case "comment":
      case "csv":
      case "date":
      case "list":
      case "table":
      case "property":
      case "number":
        segmentation.segmentedParams = segmentation.segmentedParams.map(
          (segParam) => {
            if (segParam.name == param.name) {
              return {
                ...param,
              };
            }
            return segParam;
          }
        );
        break;
      case "enum":
      case "boolean":
        segmentation.segmentedParams = segmentation.segmentedParams.map(
          (segParam) => {
            if (segParam.name == param.name) {
              return {
                ...segParam,
                label: param.label,
              };
            }
            return segParam;
          }
        );
        break;

      default:
        break;
    }
    return segmentation;
  };
  const onSegmentChange = (
    clauseId: ClauseEntity["id"],
    subClauseId: ClauseEntity["id"],
    id: string,
    segmentText: string
  ) => {
    let clause: ClauseEntity;
    let subClause: SubClauseEntity;
    let clauseIdx: number;
    let subClauseIdx: number;
    let segmentation: ClauseEntity["segmentation"];
    clauseIdx = contractTemplate.clauses!.findIndex((c) => c.id == clauseId);
    clause = contractTemplate.clauses[clauseIdx];

    if (subClauseId) {
      subClauseIdx = clause.subClauses!.findIndex((sc) => sc.id == subClauseId);
      subClause = clause.subClauses[subClauseIdx];
      segmentation = subClause.segmentation;
    } else {
      segmentation = clause.segmentation;
    }
    segmentation = updateSegment(
      segmentation.segmentedText,
      segmentation.segmentedParams,
      id,
      segmentText
    );
    segmentation = idfySegmentation(
      segmentation,
      genIdFactory(subClause?.index || clause.index)
    );

    if (subClauseId) {
      segmentation = idfySegmentation(
        segmentation,
        genIdFactory(subClause.index)
      );
      subClause.segmentation = segmentation;
      clause.subClauses = [...clause.subClauses];
    } else {
      segmentation = idfySegmentation(segmentation, genIdFactory(clause.index));
      clause.segmentation = segmentation;
    }
    let newContractValue = { ...contract };
    newContractValue.clausesOverrides = {
      ...newContractValue.clausesOverrides,
      [clause.id]: {
        segmentation: clause.segmentation,
        subClauses:
          clause.subClauses?.map((subClause) => ({
            subClauseId: subClause.id,
            subClauseIndex: subClause.index,
            subClauseName: subClause.name,
            segmentation: subClause.segmentation,
          })) || [],
      },
    };
    setContract(newContractValue);
    setClausesOverrides(newContractValue.clausesOverrides);
  };
  const onParamChanged = (
    clauseId: ClauseEntity["id"],
    subClauseId: SubClauseEntity["id"],
    param: SegmentedClauseParam
  ) => {
    let clause: ClauseEntity;
    let subClause: SubClauseEntity;
    let clauseIdx: number;
    let subClauseIdx: number;
    let segmentation: ClauseEntity["segmentation"];

    clauseIdx = contractTemplate.clauses!.findIndex((c) => c.id == clauseId);
    clause = contractTemplate.clauses[clauseIdx];

    if (subClauseId) {
      subClauseIdx = clause.subClauses!.findIndex((sc) => sc.id == subClauseId);
      subClause = clause.subClauses[subClauseIdx];
      segmentation = subClause.segmentation;
    } else {
      segmentation = clause.segmentation;
    }
    switch (param.type) {
      case "enum":
      case "boolean":
        segmentation.segmentedParams = segmentation.segmentedParams.map(
          (segParam) => {
            if (
              segParam.name == param.name &&
              param.definition == (segParam as any).definition
            ) {
              return {
                ...param,
              };
            }
            return segParam;
          }
        );
        break;
      default:
        segmentation.segmentedParams = segmentation.segmentedParams.map(
          (segParam) => {
            if (segParam.name == param.name) {
              return {
                ...param,
              };
            }
            return segParam;
          }
        );
        break;
    }

    if (subClauseId) {
      segmentation = idfySegmentation(
        segmentation,
        genIdFactory(subClause.index)
      );
      subClause.segmentation = segmentation;
      clause.subClauses[subClauseIdx] = subClause;
      clause.subClauses = [...clause.subClauses];
    } else {
      segmentation = idfySegmentation(segmentation, genIdFactory(clause.index));
      clause.segmentation = segmentation;
    }
    let newContractValue = { ...contract };
    // update all params with the same name and type
    newContractValue.clauseOverrides.forEach((clause) => {
      clause.segmentation = onSegmentaionParamChanged(
        clause.segmentation,
        param
      );
      clause.subClauses.forEach((subClause) => {
        subClause.segmentation = onSegmentaionParamChanged(
          subClause.segmentation,
          param
        );
        return subClause;
      });
    });

    newContractValue.clausesOverrides = {
      ...newContractValue.clausesOverrides,
      [clause.id]: {
        segmentation: clause.segmentation,
        subClauses:
          clause.subClauses?.map((subClause) => ({
            subClauseId: subClause.id,
            subClauseIndex: subClause.index,
            subClauseName: subClause.name,
            segmentation: subClause.segmentation,
          })) || [],
      },
    };
    setContract(newContractValue);
    setClausesOverrides(newContractValue.clausesOverrides);
  };
  const templateData = useMemo(
    () => parseTemplateData(contractTemplate, additionalClauses),
    [contractTemplate, additionalClauses]
  );
  const inputValuesContextValue = useMemo(
    () => ({
      contract,
      setContract,
      status,
      setContractStatus,
      contractTemplate,
      setContractTemplate,
      templateData,
      inputValues,
      setInputValues,
      fileNames,
      setFileNames,
      beneficialsMap,
      setBeneficialsMap,
      commentsOverrides,
      setCommentsOverrides,

      completionPercentage,
      setCompletionPercentage,
      excludedSubClauses,
      setExcludedSubClauses,
      excludedClauses,
      setExcludedClauses,
      templateId,
      setTemplateId,
      groups,
      setGroups,
      contractId,
      setContractId,
      contractListParentRef,
      setUserGroup,
      userGroup,
      setAccessUser,
      accessUser,
      groupContract,
      setGroupContract,
      project,
      setProject,
      requirement,
      requirementType,
      setRequirementType,
      setRequirement,
      onSegmentDelete,
      combinedTemplateParams,
      onAddParam,
      onApplySegmentation,
      onParamChanged,
      onSegmentChange,
      clausesOverrides,
      setClausesOverrides,
    }),
    [
      contract,
      status,
      contractTemplate,
      templateData,
      inputValues,
      fileNames,
      beneficialsMap,
      commentsOverrides,
      additionalClauses,
      completionPercentage,
      excludedSubClauses,
      excludedClauses,
      templateId,
      groups,
      contractId,
      contractListParentRef,
      userGroup,
      accessUser,
      groupContract,
      project,
      requirement,
      requirementType,
      onSegmentDelete,
      combinedTemplateParams,
      onAddParam,
      onApplySegmentation,
      onParamChanged,
      onSegmentChange,
      clausesOverrides,
      setClausesOverrides,
    ]
  );
  return inputValuesContextValue;
}
