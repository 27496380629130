import { ContractEntity, DocumentEntity, FormEntity, ProjectEntity, ProjectRessourceValues, ProjectTemplateRequirementEntity } from "./entities";
import { RessourceContractEntity, RessourceDocumentEntity, RessourceEntity, RessourceFormEntity } from "./entities/Ressource";

export enum ProjectTemplateRequirementType {
	DOCUMENT = 1,
	CONTRACT = 2,
	CONTRACTAI = 4,
	FORM = 8,
	// TABLE = 16,
}

export function getProjectRessourceValues(project: ProjectEntity): ProjectRessourceValues {
	const ressources = project.linkedRessources
	let result: ProjectRessourceValues = {}

	for (const requirementId in project.values) {
		const value = project.values[requirementId]
		let ressource = null
		switch (value.type) {
			case ProjectTemplateRequirementType.CONTRACT:
				ressource = ressources.find(r => r.type == 'Contract' && r.contractId == value.id)
				break;
			case ProjectTemplateRequirementType.CONTRACTAI:
			case ProjectTemplateRequirementType.DOCUMENT:
				ressource = ressources.find(r => r.type == 'Document' && r.documentId == value.id)
				break;
			case ProjectTemplateRequirementType.FORM:
				ressource = ressources.find(r => r.type == 'Form' && r.formId == value.id)
				break;
			default:
				break;
		}
		result[requirementId] = ressource
	}
	return result

}

// Function to convert list of enum values to a number
export function enumListToNumber(enumList: ProjectTemplateRequirementType[]): number {
	return enumList.reduce((acc, val) => acc | val, 0);
}

// Function to convert number to a list of enum values
export function numberToEnumList(value: number): ProjectTemplateRequirementType[] {
	const enumList: ProjectTemplateRequirementType[] = [];
	for (const enumValue in ProjectTemplateRequirementType) {
		const enumNumericValue = parseInt(enumValue);
		if (!isNaN(enumNumericValue) && (value & enumNumericValue) === enumNumericValue) {
			enumList.push(enumNumericValue as ProjectTemplateRequirementType);
		}
	}
	return enumList;
}

export type ProjectRequirementValue = {
	type: ProjectTemplateRequirementType;
	id: ContractEntity['id'] | DocumentEntity['id'] | FormEntity['id'];
}

export type LinkedRessources = {
	DOCUMENT: RessourceDocumentEntity[];
	CONTRACT: RessourceContractEntity[];
	CONTRACTAI: RessourceDocumentEntity[];
	FORM: RessourceFormEntity[];
}

// Function to convert ProjectTemplateRequirementType to string
export function enumToString(value: ProjectTemplateRequirementType): keyof LinkedRessources {
	return ProjectTemplateRequirementType[value] as any;
}

// Function to convert string to ProjectTemplateRequirementType
export function stringToEnum(value: string): ProjectTemplateRequirementType {
	const key = (<any>ProjectTemplateRequirementType)[value.toUpperCase()];
	return key;
}
export interface ValidationResult {
	isValid: boolean;
	errors: {
		name?: {
			isValid: boolean;
		};
		templateId?: {
			isValid: boolean;
		};
	};
}

export function validateProjectCreation(data: ProjectEntity): ValidationResult {
	const { name, templateId } = data;
	const errors: ValidationResult['errors'] = {};
	if (typeof name !== 'string' || name === "") {
		errors.name = {
			isValid: false,
		};
	} else {
		errors.name = {
			isValid: true,
		};
	}
	if (!templateId) {
		errors.templateId = {
			isValid: false,
		};
	} else {
		errors.templateId = {
			isValid: true,
		};
	}
	const isValid = Object.values(errors).every((field) => field.isValid);
	return {
		isValid,
		errors,
	};
}