import React from 'react'
import { FaArrowUp } from "react-icons/fa";
import { useTranslation } from '../../contexts/TranslationProvider';
import ChatWithDocumentContext from '../../contexts/ChatWithDocumentContext';

function ChatQuery({displayFileName=true}) {
  const { handleQueryChange, query, handleSubmit, pendingQuestion } = React.useContext(ChatWithDocumentContext)
  const { t } = useTranslation()
  return (
    <div className='body-interactions-input-container'>
      <div className='input-section'>
        <input 
        className='query-input' 
        type='text' 
        placeholder={t("pages.chat.placeholder")}
        onChange={handleQueryChange} value={query} 
        onKeyDown={(e) => e.key === "Enter" && handleSubmit("")}
        />
      </div>
      <div className='button-section'>
        <button onClick={() => handleSubmit("")} className='ask-btn'>
          <FaArrowUp className='icon' style={{
            color: pendingQuestion ? "lightgray" : "gray",
          }}/>
        </button>
      </div>
  </div>
  )
}

export default ChatQuery