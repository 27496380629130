import "./Home.scss";
import Navbar from "./components/common/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingProvider from "./contexts/LoadingContext";
import { TranslationProvider } from './contexts/TranslationProvider';
import RestrictedContent from "./RestrictedContent";
import { useProvideContextInputValues } from "./hooks/useProvideContextInputValues";
import InputValuesContext from "./contexts/InputValuesContext";
import LoadingRessourceProvider from "./contexts/LoadingRessourceContext";
import AuthUserProvider from "./contexts/AuthUserContext";
import { CustomPropertiesProvider } from "./contexts/CustomPropertiesContext";
import { MacroProvider } from "./contexts/MacroContext";
import { EditFormContextProvider } from "./contexts/EditFormContext";

export const RestrictedHome: React.FC = () => {

  const expanded = false;
  return (
    <TranslationProvider>
      <MacroProvider>
        <LoadingProvider>
          <AuthUserProvider>
            <LoadingRessourceProvider>
              <CustomPropertiesProvider>
                <EditFormContextProvider>
                  <InputValuesContext.Provider value={useProvideContextInputValues()}>
                    <div className="app-container">
                      <Navbar expanded={expanded} restricted={true} />
                      <div style={{ display: "flex" }}>
                        <RestrictedContent />
                      </div>
                    </div>
                  </InputValuesContext.Provider>
                </EditFormContextProvider>
              </CustomPropertiesProvider>
              <ToastContainer hideProgressBar={true} autoClose={3000} />
            </LoadingRessourceProvider>
          </AuthUserProvider>
        </LoadingProvider>
      </MacroProvider>
    </TranslationProvider>
  );
}