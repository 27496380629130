import React, { useState, useEffect, useContext } from "react";
import "./versionControl.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { ClauseEntity, ClauseHistoryEntity } from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { ClauseHistoryClient } from "../../../services/api/ClauseHistory";
import Version from "./Version";
import VersionsList from "./VersionList";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { toast } from "react-toastify";
import { set } from "lodash";
import Loading from "../../common/Loading";

function GenerateParamHistoryModal({
  onClose,
  clause,
  history
}: {
  onClose: () => void;
  clause: ClauseEntity;
  history: ClauseHistoryEntity[];
}) {
  const { t } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const clauseHistoryClient = new ClauseHistoryClient(apiClient);
  const translationPath = "modals.generateParamHistory.";
  const [chosenVersion, setChosenVersion] = useState<ClauseHistoryEntity | null>(null);
  const [loading, setLoading] = useState(false);
  const [allVersions, setAllVersions] = useState<ClauseHistoryEntity[]>(history ?? []);
  const [paramValues, setParamValues] = useState<Record<string, any>>({});
  const { contractTemplate, setContractTemplate } = useContext(EditContractTemplateContext);

  useEffect(() => {

  }, [chosenVersion]);

  useEffect(() => {
    const fetchClauseHistory = async () => {
      try {
        const clauseHistoryResponse = await clauseHistoryClient.getAllByFilter({
          clauseId: clause.id,
        })
        if (clauseHistoryResponse.rows) {
          setAllVersions(clauseHistoryResponse.rows)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchClauseHistory();
  }, []);

  const handleConfirm = async () => {
    try {
      if (chosenVersion) {
        setLoading(true)
        const res = await clauseHistoryClient.revertVersion(clause.id, chosenVersion.id)
        if (res) {
          const { row: updatedClause } = res;
          const newContractTemplate = { ...contractTemplate }
          const clauseIdx = newContractTemplate.clauses.findIndex(c => c.id == clause.id)
          newContractTemplate.clauses[clauseIdx] = updatedClause
          setContractTemplate(newContractTemplate)
          toast.success(t(translationPath + "revertSuccess"))
          setLoading(false)
          onClose();
        }
      }
    } catch (error) {
      setLoading(false)
      toast.error(t(translationPath + "revertError"))
      console.log("error", error)
    }
  };

  const handleSelect = (choice: ClauseHistoryEntity) => {
    if (choice) {
      const selectedVersion = allVersions.find((version) => version.id === choice.id);
      setChosenVersion(selectedVersion);
    }
  }
  return (
    <>
      <div className="macro-modal" onClick={() => onClose()}>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="clause-comparison-modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <div className="">
                  <div
                    className={``}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <h5 className="modal-title">
                        {t(translationPath + "title")}
                      </h5>
                    </div>
                  </div>
                </div>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
              </div>
              <div className="body-comparison">
                {loading ? <Loading/> : <div className="clause-comparison-container">
                  <div className="clause-exchange-current">
                    <Version
                      version={chosenVersion}
                      paramValues={paramValues}
                    />
                  </div>
                  <div className="clause-exchange-found">
                    <VersionsList
                      chosenVersion={chosenVersion}
                      handleSelect={handleSelect}
                      inputValues={paramValues}
                      versions={allVersions}
                      setInputValues={(param, target) => {
                        console.log("updating", param, target.value)
                        if (!target) return;
                        setParamValues((prev) => {
                          return {
                            ...prev,
                            [param.name]: target.value
                          }
                        })
                      }}
                    />
                  </div>
                </div>}
              </div>
              <div className="modal-actions">
                <button
                  disabled={!chosenVersion || (chosenVersion && chosenVersion.type == "revert")}
                  className={`confirm-button ${(!chosenVersion || (chosenVersion && chosenVersion.type == "revert")) && "disabled-confirm-button"
                    }`}
                  onClick={handleConfirm}
                >
                  {t(translationPath + "revert")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenerateParamHistoryModal;
