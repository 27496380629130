import { calculateContractClauseCompletionPercentage, calculateFormPageCompletionPercentage } from "../helpers/CompletionPercentage";
import { BeneficialMacroEntity, ClauseEntity, ContractEntity, FormEntity, FormTemplatePageEntity, GroupEntity, ProjectEntity, ProjectTemplateRequirementEntity } from "./entities";
import { RessourceEntity } from "./entities/Ressource";
import { evaluateSingleConstraint } from "./Form";
import { SegmentedClauseParam } from "./types/ClauseParams";
import { FormParam } from "./types/FormParams";
import { RessourceStatus, statuses } from "./types/status";
import { WorkflowTransitionCondition } from "./Workflow";


type GroupTransitionConditionData = {
  groups: GroupEntity[];
  status: RessourceStatus;
  params: (FormParam | SegmentedClauseParam)[];
  values: FormEntity['paramValues'] | ContractEntity['paramValues'] | Record<ProjectTemplateRequirementEntity['id'], RessourceEntity['id']>;
  clauses?: ClauseEntity[];
  pages?: FormTemplatePageEntity[];
  requirements?: ProjectTemplateRequirementEntity[];
  ressources?: RessourceEntity[];
}
export function isConditionMet(macros: BeneficialMacroEntity[], condition: WorkflowTransitionCondition, data: GroupTransitionConditionData): boolean {
  const { groups, status, values, params, clauses, pages, requirements, ressources } = data
  let currentIndex = null
  let targetIndex = null
  let ressource: RessourceEntity = null
  switch (condition.type) {
    case 'constraint':
      return evaluateSingleConstraint(condition.value, values as ContractEntity['paramValues'], params)
    case 'status':
      currentIndex = statuses.findIndex((s) => s == status)
      targetIndex = statuses.findIndex((s) => s == condition.status)
      return targetIndex <= currentIndex
    case 'clause':
      const clause = clauses?.find(c => c.id == condition.clauseId)
      const clauseCompletion = calculateContractClauseCompletionPercentage(values, clause, macros)
      return clauseCompletion == 100
    case 'page':
      const page = pages?.find(c => c.id == condition.pageId)
      const pageCompletion = calculateFormPageCompletionPercentage(values, page)
      return pageCompletion == 100
    case 'requirement-status':
      ressource = values[condition.requirementId]
      if (!ressource)
        return false
      currentIndex = statuses.findIndex((s) => s == ressource.status)
      targetIndex = statuses.findIndex((s) => s == condition.status)
      return targetIndex <= currentIndex
    case 'requirement-validation':
      ressource = values[condition.requirementId]
      if (!ressource)
        return false
      const validatedGroup = ressource.validationGroups?.find(g => g.id == condition.groupId)
      return !!validatedGroup
  }
}

export function areConditionsMet(macros: BeneficialMacroEntity[], conditions: WorkflowTransitionCondition[], data: GroupTransitionConditionData): WorkflowTransitionCondition[] {
  return conditions.filter((condition) => !isConditionMet(macros, condition, data))
}