import "./taskRessourceCard.scss";
import form from "../../../assets/new-design-icons/Form.svg";
import contract from "../../../assets/new-design-icons/Contract.svg";
import stats from "../../../assets/new-design-icons/Stats.svg";
import { RessourceEntity } from "../../../domain/entities/Ressource";
import { navigateToRessource } from "../../../helpers/navigation";
import { useNavigate } from "react-router-dom";
const docIcon = require("../../../assets/new-design-icons/doc.png");

interface TaskModalProps {
  ressource: RessourceEntity;
  removeRessource?: (ressource: RessourceEntity) => void;
}

const TaskRessourceCard = ({ ressource, removeRessource }: TaskModalProps) => {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const statsIcon = stats.toString();
  const navigate = useNavigate();
  const handleClick = navigateToRessource(navigate, true);
  return (
    <>
      <div
        className="ressource-badge"
        key={ressource.id}
        onClick={() => handleClick(ressource)}
      >
        <div className="icon">
          {(ressource.type === "Form" && (
            <img
              src={formIcon}
              alt="icon"
              style={{ width: "30px", height: "30px" }}
            />
          )) ||
            (ressource.type === "RessourceScoring" && (
              <img
                src={statsIcon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              />
            )) ||
            (ressource.type === "Contract" && (
              <img
                src={contractIcon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              />
            )) ||
            (ressource.type === "Document" &&
              (ressource.hasAIProcessing ? (
                <img
                  src={contractIcon}
                  alt="icon"
                  style={{ width: "30px", height: "30px" }}
                />
              ) : (
                <img
                  src={docIcon}
                  alt="icon"
                  style={{ width: "25px", height: "25px" }}
                />
              ))) || <></>}
        </div>
        {ressource.name}{" "}
        {removeRessource && (
        <span
          onClick={(event) => {
            event.stopPropagation();
            removeRessource(ressource);
          }}
        >
          x
        </span>
      )}
      </div>
    </>
  );
};
export default TaskRessourceCard;
