import React, { useState, useRef, useContext, useEffect } from "react";
import { ClauseEntity, ContractEntity } from "../../../domain/entities";
import "./clauseComparison.scss"
import ComparisonClause from "./ComparisonClause";
import ComparableClauseSelector from "./ComparableClauseSelector";
import ComparableClauseRender from "./ComparableClauseRender";
import { useTranslation } from "../../../contexts/TranslationProvider";
import InputValuesContext from "../../../contexts/InputValuesContext";


function ClauseComparisonModal({
	onClose,
	currentClause,
	inputValues,
	fileNames,
	beneficialsMap,
	excludedSubClauses
}: {
	onClose: () => void;
	currentClause: ClauseEntity;
	fileNames: ContractEntity['fileNames'];
	beneficialsMap: ContractEntity['beneficialsMap'];
	inputValues: Record<string, any>;
	excludedSubClauses: ContractEntity['excludedSubClauses'];
}) {
	const {
	contract,setContract,setClausesOverrides
	} = useContext(InputValuesContext)
	const modalComparableRef = useRef(null);
	const [selectedClause, setSelectedClause] = useState<ClauseEntity>();
	const [comparableClauses, setComparableClauses] = useState<ClauseEntity[][]>([]);
	const replaceClause = () => {
		if (!selectedClause) {
			console.error("selectedClause", selectedClause);
			return
		}
		let newContractValue = { ...contract };

		const updatedClause = {
			segmentation: selectedClause.segmentation,
			...(selectedClause.subClauses?.length
				? {
						subClauses: selectedClause.subClauses.map(subClause => ({
							subClauseId: subClause.id,
							subClauseIndex: subClause.index,
							subClauseName:subClause.name,
							segmentation: subClause.segmentation,
						})),
					}
				: {}),
		};
		
		newContractValue.clausesOverrides = {
			...newContractValue.clausesOverrides,
			[currentClause.id]: updatedClause,
		} as ContractEntity["clausesOverrides"];
    setClausesOverrides(newContractValue.clausesOverrides);
    setContract(newContractValue);
		onClose()
	}
	const { t, language } = useTranslation()
	return (
		<>
			<div className="clause-comparison-modal">
				<div className="modal-backdrop"></div>
				<div id="contractz-lab">
					<div className="modal d-flex justify-content-center align-items-center">
						<div className="clause-comparison-modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{t("modals.clauseComparaison.title")}</h5>
								<button type="button" className="btn-close" onClick={onClose} aria-label="Close"
									style={{ marginRight: language == "ar" && "90%" }}
								></button>
							</div>
							<div className="body-comparison">
								<div className="clause-comparison-container">
									<ComparisonClause
										clause={currentClause}
										fileNames={fileNames}
										beneficialsMap={beneficialsMap}
										inputValues={inputValues}
										excludedSubClauses={excludedSubClauses}
									/>

									<div className="clause-comparison-levels" ref={modalComparableRef} style={{ position: 'relative' , paddingLeft:"0"}}>
										<>
											<ComparableClauseSelector clauseName={currentClause.name} modalComparableRef={modalComparableRef} setComparableClauses={setComparableClauses} setSelectedClause={setSelectedClause} />
											</>
											<div className="confirm-comparison-buttons" >
													{selectedClause &&
														<button className="confirm-comparison-button" onClick={() => replaceClause()}>
															{t("modals.clauseComparaison.replaceClause")}
														</button>
													}
												</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}

export default ClauseComparisonModal;
