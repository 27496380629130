import { useNavigate } from "react-router-dom";
import RessourceCard from "../../components/common/newDesign/RessourceCard";
import React, { useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import { RessourceClient } from "../../services/api/RessourceClient";
import { getName } from "../../helpers/Translation";
import { useTranslation } from "../../contexts/TranslationProvider";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import { navigateToRessource } from "../../helpers/navigation";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { GroupEntity } from "../../domain/entities";

function GroupRessourcesList({ ressources, groups, reloadRessources }
  : {
    ressources: RessourceEntity[];
    groups: GroupEntity[];
    reloadRessources: () => void
  }
) {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading()
  const ressourceClient = new RessourceClient(apiClient)

  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [ressourceToHandle, setRessourceToHandle] = useState<RessourceEntity | null>(null);

  const handleClick = navigateToRessource(navigate)


  const handleDelete = async (ressource: RessourceEntity) => {
    try {
      const { deleted } = await ressourceClient.deleteFromChild(ressource)
      if (deleted) {
        await reloadRessources();
      }
    } catch (err) {
      console.error("Error deleting ressource: ", err);
    }
  };

  const GroupColumn = (group) => {
    const groupRessources = ressources.filter((ressource) => ressource.assignedToGroupId === group.id);
    return (
      <div className="ressources-space" key={group.id}>
        <h3 className="m-3 ressources-space-title">{getName(group, language)}</h3>
        <div className="ressources-per-level">
          {groupRessources.map((ressource) => {
            return (
              <div key={ressource.id} className="m-2 ressource-card">
                <RessourceCard data={ressource}
                  onClick={() => handleClick(ressource)}
                  onDelete={() => { setRessourceToHandle(ressource); setConfirmDelete(true) }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <section id="ressources-list" className="group-ressources-list">
      <div className="ressources-list-container">
        {groups.map((group) => GroupColumn(group))}
      </div>
      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t(
            "pages.ressources.msgDeleteRessource"
          )}
          onClose={() => {
            setConfirmDelete(false);
            setRessourceToHandle(null);
          }}
          entityToDelete={(ressourceToHandle?.name || "")}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default GroupRessourcesList;
