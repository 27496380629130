import { BeneficialBaseEntity } from "./domain/entities";
import * as defaultClientConfig from './configs/default/config.json'

export const REACT_APP_IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION ? true : false;
export const REACT_APP_DEV_MODE = process.env.REACT_APP_DEV_MODE ? true : false;
export const REACT_APP_GENERATE_CLAUSE = process.env.REACT_APP_GENERATE_CLAUSE ? true : false;
export const DEV_MODE = process.env.DEV_MODE ? true : false;
export const REACT_APP_LISTENER_ON = process.env.REACT_APP_LISTENER_ON ? true : false;
export const REACT_APP_FORM_AI_ON = process.env.REACT_APP_FORM_AI_ON ? true : false;
export const ADMIN_ADD_USER = process.env.REACT_APP_ADMIN_ADD_USER ? true : false;
export const ENABLE_PROJECT = process.env.REACT_APP_ENABLE_PROJECT ? true : false;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const RAG_BASE_URL = process.env.REACT_APP_RAG_BASE_URL;
export const COMPANY_CODE = process.env.REACT_APP_COMPANY_CODE;
export const APP_ENV = process.env.REACT_APP_ENV;

export const DEFAULT_PARAM_BOLD_STYLE = process.env.REACT_APP_DEFAULT_PARAM_BOLD_STYLE ? true : false;


export const LIST_INDENTATION_LEFT = Number(process.env.REACT_APP_LIST_INDENTATION_LEFT) || 0
export const LIST_INDENTATION_HANGING = Number(process.env.REACT_APP_LIST_INDENTATION_HANGING) || 0


/**
 * REACT_APP_AI_JOBS_ON=true

REACT_APP_AI_COMPARE_CONTRACTS_ON=true

REACT_APP_AI_CLAUSE_GENERATION_ON=true

REACT_APP_AI_QUERY_REGULATION_ON=true

REACT_APP_AI_MANAGE_REGULATION_ON=true
REACT_APP_GENERATE_CLAUSE_PARAMS_ON

REACT_APP_AI_IMPORT_CONTRACT_ON=true

REACT_APP_AI_IMPORT_FORM_ON=true
 */
export const AI_JOBS_ON = process.env.REACT_APP_AI_JOBS_ON ? true : false;
export const AI_COMPARE_CONTRACTS_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_COMPARE_CONTRACTS_ON ? true : false : false;
export const AI_CLAUSE_GENERATION_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_CLAUSE_GENERATION_ON ? true : false : false;
export const AI_QUERY_REGULATION_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_QUERY_REGULATION_ON ? true : false : false;
export const AI_MANAGE_REGULATION_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_MANAGE_REGULATION_ON ? true : false : false;
export const AI_GENERATE_CONTRACT_TEMPLATE_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_GENERATE_CONTRACT_TEMPLATE_ON ? true : false : false;
export const AI_GENERATE_FORM_TEMPLATE_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_GENERATE_FORM_TEMPLATE_ON ? true : false : false;
export const AI_GENERATE_CLAUSE_PARAMS_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_GENERATE_CLAUSE_PARAMS_ON ? true : false : false;
export const AI_IMPORT_CONTRACT_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_IMPORT_CONTRACT_ON ? true : false : false;
export const AI_IMPORT_FORM_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_IMPORT_FORM_ON ? true : false : false;
export const AI_CHAT_WITH_DOCUMENT_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_CHAT_WITH_DOCUMENT_ON ? true : false : false;
export const AI_CONFORMITY_CHECK_ON = process.env.REACT_APP_AI_JOBS_ON ?
  process.env.REACT_APP_AI_CONFORMITY_CHECK_ON ? true : false : false;


export type ClientConfig = typeof defaultClientConfig
const companyCode = COMPANY_CODE || 'default'
export const clientConfig: ClientConfig = require(`./configs/${companyCode}/config.json`)

export const config = {
  defaultBeneficial: {
    maritalStatus: 'Single',
    nationality: 'TUN',
    gender: 'M',
  } as BeneficialBaseEntity
}

