import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../contexts/TranslationProvider";
import PendingAi from "../../components/common/newDesign/PendingAi";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { DocumentUseCaseClient } from "../../services/api/DocumentUseCaseClient";
import { DocumentClient } from "../../services/api/DocumentClient";
import { RessourceScoringClient } from "../../services/api/RessourceScoring";
import { useForm } from "react-hook-form";
import { RessourceScoringEntity } from "../../domain/entities";
import { toast } from "react-toastify";
import Loading from "../../components/common/Loading";
import ComingSoon from "../../components/modals/ComingSoon";
import CheckBoxInput from "../../components/common/CheckBoxInput";
import FileInput from "../../components/common/FileInput";
import SVGRender from "../../helpers/SVGRender";
import { useCaseIcons } from "../../assets/icons/useCaseIcons";

interface RegulatoryModalType {
  onClose: () => void;
  documentId?: number;
  documentName?: string;
}

function RegulatoryModal({
  onClose,
  documentId,
  documentName,
}: RegulatoryModalType) {
  const { t, language } = useTranslation();
  const modalContentRef = useRef(null);
  const navigate = useNavigate();
  const [topics, setTopics] = useState([]);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [document, setDocument] = useState<File>(null);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [loading, setLoading] = useState(false);
  const usecases = t("pages.topic.usecases");
  const [isScanned, setIsScanned] = useState(false);
  const apiClient = useApiClientWithLoading();
  const documentUseCaseClient = new DocumentUseCaseClient(apiClient);
  const documentClient = new DocumentClient(apiClient);
  const ressourceScoringClient = new RessourceScoringClient(apiClient);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await documentUseCaseClient.getAll(language, "nested");
        const sortedTopics = data.rows.sort((a, b) => (b.activated ? 1 : -1));
        setTopics(sortedTopics);
        setLoading(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [language]);

  useEffect(() => {
    if (selectedTopic !== null) {
      setSelectedUseCase(null);
    }
  }, [selectedTopic]);

  const handleUseCaseClick = (index) => {
    setSelectedUseCase(index);
  };
  const handleTopicClick = (index) => {
    setSelectedTopic(index);
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      setLoading(false);
      if (documentId) {
        const response = await documentUseCaseClient.invokeUseCase(
          topics[selectedTopic]?.id,
          documentId
        );
        const data: RessourceScoringEntity = {
          name: documentName,
          operationType: "COMPLIANCE",
          documentId: response.row.documentId,
          useCaseResponseId:response?.row.id
        };
        const ressourceScoring = await ressourceScoringClient.create(data);
        navigate(`/ai-document-response/${response?.row?.id}`);
      }
    } catch (error) {
      console.error("Error fetching AI contract data:", error);
      toast.error(t("pages.pilot.popups.contract.errorImport"));
    } finally {
      setLoading(true);
    }
  };

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"> {t("pages.document.regulatoryAnalysisAssistant")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div
                className="modal-body"
                ref={modalContentRef}
              >
                {!loading ? <Loading height="40vh" /> :<>
                <section
                  style={{ paddingRight: "1rem" }}
                  id="main-function"
                  className={` ${
                    selectedTopic !== null && !topics[selectedTopic]?.activated
                      ? "coming-soon-message"
                      : ""
                  }`}
                >
                  <form
                    action=""
                    className="form-group"
                    style={{
                      maxHeight: "500px",
                      marginBottom: "70px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      paddingLeft: "20px",
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="topics-container-modal">
                      {topics?.map((topic, index) => (
                        <div
                          key={index}
                          onClick={() => handleTopicClick(index)}
                          className={`topic-item ${
                            selectedTopic === index ? "activated" : ""
                          }`}
                        >
                          <span>{topic?.name}</span>
                        </div>
                      ))}
                    </div>

                    {selectedTopic !== null && topics[selectedTopic]?.activated ? (
            <>
                 
                        
                    
                        <div className="flex-grow-1 d-flex">
                          <div className="vertical-line me-3"></div>
                          <button className="action-calculate-compliance">
                            {t("pages.topic.action")}
                          </button>
                        </div>
                        </>
          ) : (
            <ComingSoon />
          )}  
                  </form>
                
                </section>
                </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegulatoryModal;
