import React, {useState, useEffect} from 'react'
import useTranslation from '../../../hooks/useTranslation';
import { BooleanParam, ClauseParam, SegmentedBooleanParam, SegmentedClauseParam, SegmentedEnumParam } from '../../../domain/types/ClauseParams';
import "./macroParameter.scss";
const { default: EditIcon } = require("../../../assets/edit_icon.svg");
const { default: EditIconOn } = require("../../../assets/edit_icon_on.svg");
function MacroParameter(
  {param, currentValue, setInputValues, onDelete}:
  {param: SegmentedBooleanParam | SegmentedEnumParam, currentValue: any, setInputValues: (param: any, value: any) => void, onDelete: (param: SegmentedClauseParam) => void}
) {
  const { t, language } = useTranslation();
  const [isOptionEditing, setIsOptionEditing] = useState(false);
  const [searchEnumOptions, setSearchEnumOptions] = useState("");
  const [enumOptions, setEnumOptions] = useState((param.args as SegmentedEnumParam['args']));
  const translationTypes = "pages.contractTemplateParam.params";
  const translationPath = t("pages.contractTemplateParam.edition");

  useEffect(() => {
    if (param.type === 'enum') {
      if(!searchEnumOptions)
        setEnumOptions(param.args);
      const filteredOptions = param.args.filter((arg, index) => arg.option.includes(searchEnumOptions) || index === currentValue);
      setEnumOptions(filteredOptions);
    }
  }, [searchEnumOptions]);

    switch(param.type) {
      case "boolean":
        return( <div
        className={`d-flex flex-column grey-border macro-param-container`}
        style={{ paddingRight: language == "ar" && "5px" }}
      >
        <div className="d-flex flex-column">
          <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
          <div className="d-flex flex-column gap-2  py-2 justify-content-between  align-items-center form-group">
            {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
              <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterLabel)} {param?.label}</label>
            </div> : (
              <></>
            )}
            <div className="radio-group boolean-group">
              <label className="form-check-label">
                <input
                  type="radio"
                  value="true"
                  checked={currentValue === true}
                  className={`form-check-input boolean-check ${currentValue === true && "checked-input"}`}
                  onChange={(e) => {
                    if(e.target.checked)
                      setInputValues(param, {value: true})
                  }}
                  name={param.name}
                />
                {t(translationPath.boolean.oui)}
              </label>
              <label className="form-check-label">
                <input
                  type="radio"
                  value="false"
                  checked={currentValue === false}
                  className={`form-check-input boolean-check ${currentValue === false && "checked-input"}`}
                  onChange={(e) => {
                    if(e.target.checked)
                      setInputValues(param, {value: false})
                  }}
                  name={param.name}
                />
                {t(translationPath.boolean.non)}
              </label>
            </div>
          </div>
        </div>
      </div>)
        break;
        case 'enum':
          const hasValue = null;
          return (
            <>
              <div className="form-group macro-param-container">
                <div
                  className={`d-flex flex-column  ${!hasValue ? "grey-border" : "green-border"
                    }`}
                  style={{ paddingRight: language == "ar" && "5px" }}
                >
                  <div className="param-type ms-2">{t(`${translationTypes}.${param.type}`)}</div>
                  <div className="d-flex align-items-start justify-content-between py-2 px-2">
                    {!isOptionEditing ? <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                      <label style={{ wordBreak: "break-all" }} className="ms-2">{t(translationPath.parameterName)} {param?.name}</label>
                    </div> : (
                     <></>
                    )}
                  </div>
                  {
                    param && param.args && param.args.length > 7 && (
                      <input type="text" className="macro-control-search-enum-options" placeholder={"Search"} value={searchEnumOptions} onChange={(e) => setSearchEnumOptions(e.target.value)} />
                    )
                  }
                  <div className=" flex-column align-items-start px-3 pb-1 gap-0 enum-options-list">
                    {enumOptions?.map((option, index) => (
                      <div key={`${"macro"}_${index}`} className="form-check">
                        <div style={{ display: isOptionEditing ? "flex" : "" }}>
                          {!isOptionEditing ? <input
                            type={"radio"}
                            className={"form-check-input"}
                            value={index}
                            checked={currentValue === index}
                            onChange={(e) => {
                              if(e.target.checked)
                                setInputValues(param, {value: index})
                            }}
                          /> : <></>}
                          {!isOptionEditing ? <label className="form-check-label" htmlFor={String(index)}>
                            {option.option}
                          </label> : <></>}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          );
          break;
      default:
        <></>
    }
}

export default MacroParameter