import React, { useRef, useCallback, useImperativeHandle } from 'react'
import { AiOutlineUpload } from 'react-icons/ai'
import { useTranslation } from '../../../contexts/TranslationProvider';

function AddFile({ handleUpload }) {
  const fileInputRef = useRef(null);
  const { t } = useTranslation()
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);
  const handleFileChange = (e) => {
    const files: FileList = e.target.files;
    const file = files[0];
    if (file) {
      handleUpload(file);
    }
  };

  return (
    <div onClick={triggerFileInput} className="add-card">
      <div className="d-flex ms-1">
        <AiOutlineUpload
          style={{
            marginRight: "0.5rem",
            color: "#2f14e5",
            cursor: "pointer",
          }}
          className='icon'
        />
      </div>
      <input
        type="file"
        style={{ display: "none", border: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <span className='title'>
        {t('pages.reglement.upload')}
      </span>
    </div>
  )
}

export default AddFile