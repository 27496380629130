import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/common/Loading";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { fetchTextFileUrl, formatDate } from "../../helpers/helper";
import { LoadingContext } from "../../contexts/LoadingContext";
import {
  DocumentEntity,
  DocumentScoringEntity,
  RessourceScoringEntity,
} from "../../domain/entities";
import useTranslation from "../../hooks/useTranslation";
import "./documentPage.scss";
import stats from "../../assets/new-design-icons/Stats.svg";
import { DocumentClient } from "../../services/api/DocumentClient";
import EventManager from "../../services/EventManager";
import {
  AI_CHAT_WITH_DOCUMENT_ON,
  AI_COMPARE_CONTRACTS_ON,
  AI_CONFORMITY_CHECK_ON,
  AI_MANAGE_REGULATION_ON,
  AI_QUERY_REGULATION_ON,
} from "../../config";
import { AiFileClient } from "../../services/rag/AiFileClient";
import RegulatoryModal from "./RegulatoryModal";
import CompareModal from "./CompareModal";
import { toast } from "react-toastify";
import { AiOutlineMessage } from "react-icons/ai";
import { MdCompare, MdOutlineGppGood } from "react-icons/md";
import { AuthUserContext } from "../../contexts/AuthUserContext";
function DocumentPage() {
  const statsIcon = stats.toString();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoading, setLoading } = useContext(LoadingContext);
  const [document, setDocument] = useState<DocumentEntity>(null);
  const [regulatoryModalOpen, setRegulatoryModalOpen] =
    useState<boolean>(false);
  const [compareModalOpen, setCompareModalOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>(null);
  const { documentId } = useParams<{ documentId?: string }>() || {};
  const { previousPathname } = location?.state || { previousPathname: [] };
  const { t, language, setLanguage } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);
  const documentClient = new DocumentClient(apiClient);
  const aiFileClient = new AiFileClient(apiClient);
  const saveDoc = async () => {
    window.history.length > 1 ? navigate(-1) : navigate("/ressources");
  };
  const { activeRole } = useContext(AuthUserContext);
  useEffect(() => {
    (async () => {
      const row = await documentClient.getById(parseInt(documentId));
      const text = await fetchTextFileUrl(row?.textFile?.url);
      setDocument(row);
      setText(text);
      const arabicRegex = /[\u0600-\u06FF]/;
      if (arabicRegex.test(text)) {
        setLanguage("ar");
      }
    })();
    EventManager.subscribe("SaveDoc", saveDoc);
    return () => {
      EventManager.unsubscribe("SaveDoc", saveDoc);
    };
  }, []);

  const handleUseCaseClick = (item) => {
    navigate(`/ai-document-response/${item?.id}`, {});
  };

  useEffect(() => {
    const handleDocumentSummary = () => {
      navigate(`/document-summary/${documentId}`);
    };
    EventManager.subscribe("DocumentSummary", handleDocumentSummary);
    return () => {
      EventManager.unsubscribe("DocumentSummary", handleDocumentSummary);
    };
  }, []);

  useEffect(() => {
    EventManager.invoke("hasSummary", !!document?.summary?.id);
  }, [document?.summary]);

  const renderContentBasedOnFileExtension = () => {
    switch (document?.fileExtenstion.toLowerCase()) {
      case "pdf":
        return (
          <div className="document-pdf-viewer">
            <iframe src={document?.file.url} width="100%" height="600px" />
          </div>
        );
      case "docx":
      case "doc":
        return (
          <div className="document-docx-viewer">
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                document?.file.url
              )}`}
              width="100%"
              height="600px"
            />
          </div>
        );
      case "jpeg":
      case "jpg":
      case "png":
        return (
          <div className="document-image-viewer">
            <img
              src={document?.file.url}
              alt={document?.name}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        );
      default:
        return (
          <div className="ai-text-container">
            <div className="ai-text">{text}</div>
          </div>
        );
    }
  };
  const handleScoringClick = (scoring) => {
    navigate(`/contract-compliance/${scoring?.id}`, {});
  };

  async function handleAIChatClick(): Promise<void> {
    if (document.aiFileId) {
      navigate(`/chat-document/${documentId}`);
    } else {
      try {
        const result = await aiFileClient.createFromFileUrl(document.file.key, document.file.url);
        if (!result.row) throw new Error("Error uploading file");
        const aiFile = result.row;
        const compiledFileResult = await aiFileClient.compile(aiFile.id);
        if (!compiledFileResult.row) throw new Error("Error compiling file");
        const updatedDocData = {
          ...document,
          aiFileId: aiFile.id,
        };
        const updatedDoc = await documentClient.update(document.id, updatedDocData);
        navigate(`/chat-document/${document.id}`);
      } catch (e) {
        toast.error(t("pages.pilot.popups.contract.errorImport"));
      }
    }
  }

  function handleCompliaceClick(scoring: RessourceScoringEntity) {
    navigate(`/ai-document-response/${scoring?.useCaseResponseId}`, {});
  }
  const isCoContractor = activeRole === "CoContractor";
  return document ? (
    <div className="row w-100 document-page-container">
      {previousPathname?.length > 0 && (
        <div className="px-2 container-paths">
          {previousPathname?.map((item, index) => (
            <span key={index}>
              <span
                className="previous-pathname"
                onClick={() => navigate(item.link)}
              >
                {item.label}
              </span>
              <span className="path-separator"> {">"} </span>
            </span>
          ))}
          <span className="current-pathname">{document.name}</span>
        </div>
      )}
      <div className="document-page-text-section">
        <h1 className="contract-name">{document.name}</h1>
        {renderContentBasedOnFileExtension()}
      </div>

      <div className="document-page-use-case-section px-5 mt-2">
        {(AI_CHAT_WITH_DOCUMENT_ON && !isCoContractor) && (
          <button className="chat-document-button" onClick={handleAIChatClick}>
            <AiOutlineMessage className="icon-doc" color="#268AFD" />
            {t("pages.document.chatWithAI")}
          </button>
        )}

        {(AI_CONFORMITY_CHECK_ON && !isCoContractor) && (
          <button
            className="chat-document-button"
            onClick={() => setRegulatoryModalOpen(true)}
          >
            <MdOutlineGppGood className="icon-doc" color="#35CB4D" />
            {t("pages.document.regulatoryAnalysisAssistant")}
          </button>
        )}

        {(AI_COMPARE_CONTRACTS_ON && !isCoContractor) && (
          <button
            className="chat-document-button"
            onClick={() => setCompareModalOpen(true)}
          >
            <MdCompare className="icon-doc" color="#F6D839" />
            {t("pages.document.compareThisContract")}
          </button>
        )}
        {regulatoryModalOpen && (
          <RegulatoryModal
            documentId={document.id}
            documentName={document.name}
            onClose={() => setRegulatoryModalOpen(false)}
          />
        )}
        {compareModalOpen && (
          <CompareModal
            documentId={document.id}
            onClose={() => setCompareModalOpen(false)}
          />
        )}
        {document?.documentScorings?.map((scoring, index) => (
          <div
            key={`${index}_${scoring?.createdAt}`}
            className="border p-2 mt-2"
            style={{ cursor: "pointer", borderRadius: "15px" }}
            onClick={() => {
              if (scoring && scoring.operationType === "SIMILARITY") {
                handleScoringClick(scoring);
              } else {
                handleCompliaceClick(scoring);
              }
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img
                style={{ width: "30px", height: "30px" }}
                src={statsIcon}
                alt="calculate"
              />
              <h4>
                {scoring && scoring.operationType === "SIMILARITY"
                  ? t("pages.document.similarity")
                  : scoring.operationType === "COMPLIANCE"
                    ? t("pages.document.compliance")
                    : t("pages.document.otherType")}
              </h4>
            </div>
            <p>
              {scoring?.createdAt
                ? formatDate(new Date(scoring?.createdAt).toISOString())
                : ""}
            </p>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <Loading height="90vh" />
  );
}

export default DocumentPage;
