import React, { useEffect, useState } from 'react'
import { AnalyticsClient } from '../../services/api/AnalyticsClient'
import useApiClientWithLoading from '../../services/api/ApiClient'
import './adminAnalytics.scss'
import Loading from '../../components/common/Loading'
import BarChart from './charts/BarChart'
import { useTranslation } from '../../contexts/TranslationProvider'
import PieChart from './charts/PieChart'
import PeriodLineChart from './charts/PeriodLineChart'
import StatusPicker from './StatusPicker'
import { ProjectClient } from '../../services/api/ProjectClient'
import { ModelClient } from '../../services/ModelClient'
import { ProjectValidationCommitEntity } from '../../domain/entities/ProjectValidationCommit'
import { serializeProjectAuditCommits, serializeProjectAuditCommitsSpreadSheet } from '../../hooks/serializeProjectAuditCommits'
import { WorkBook } from 'xlsx'
import { cellsToXLSXWorkbook, saveWorkbook } from '../../utils/csv'

function AdminAnalytics() {
  const { t, language } = useTranslation()
  const apiClient = useApiClientWithLoading()
  const analyticsClient = new AnalyticsClient(apiClient, 'Analytics')
  const projectClient = new ProjectClient(apiClient)
  const projectValidationCommit = new ModelClient<ProjectValidationCommitEntity>(apiClient, "ProjectValidationCommit")
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState([])
  const [forms, setForms] = useState([])
  const [contracts, setContracts] = useState([])
  const [contractStatus, setContractStatus] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [projectStatus, setProjectStatus] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await analyticsClient.getAllRessources()
        if (data.ressources && data.ressources.length > 0) {
          const projects = data.ressources.filter((ressource) => ressource.type === 'Project')
          const forms = data.ressources.filter((ressource) => ressource.type === 'Form')
          const contracts = data.ressources.filter((ressource) => ressource.type === 'Contract')
          setProjects(projects)
          setForms(forms)
          setContracts(contracts)
        }
        setLoading(false)
      } catch (error) {
        console.error("Error fetching ressources: ", error)
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  async function handleExportProjectsReport() {
    try {
      const allProjects = await projectClient.getAll("nested")
      const allCommits = await projectValidationCommit.getAll()
      let allProjectsSpreadsheets: Record<string, string[][]> = {}
      for (const project of allProjects.rows) {
        const commits = allCommits.rows.filter(pvc => pvc.projectId == project.id)
          .sort((a, b) => new Date(b.updatedAt)!.getTime() - new Date(a.updatedAt)!.getTime())
        const spreedsheet = serializeProjectAuditCommitsSpreadSheet(project, commits, t, language)
        allProjectsSpreadsheets[project.name!] = spreedsheet["default"]

      }
      const workbook = cellsToXLSXWorkbook(allProjectsSpreadsheets)
      saveWorkbook(workbook, `PROJECTS.xlsx`);
    } catch (error) {
      console.error(error);
    }
  }

  const statusFiledValues = [
    { value: 'Draft', display: t('status.Draft') },
    { value: 'Done', display: t('status.Done') },
    { value: 'Negotiation', display: t('status.Negotiation') },
    { value: 'ToBeSigned', display: t('status.ToBeSigned') },
    { value: 'Signed', display: t('status.Signed') }];
  const typeFiledValues = [
    { value: 'Project', display: t('ressourceType.project') },
    { value: 'Form', display: t('ressourceType.form') },
    { value: 'Contract', display: t('ressourceType.contract') }];
  const direction = language === 'ar' ? 'right' : 'left'
  return (
    <>
      {loading ? <div className="loading-container"><Loading /></div> :
        <div className="ressources-analytics">
          <span className="current-pathname">
            {t('pages.adminAnalytics.Title')}
          </span>
          <span className="current-pathname" onClick={handleExportProjectsReport}>
            {"Exporter Rapport des projets"}
          </span>

          <div className="borderB charts-row" style={{ display: "flex", justifyContent: direction, alignItems: direction, marginTop: "20px" }}>
            <BarChart
              incomingData={[...projects, ...forms, ...contracts]}
              filedValues={typeFiledValues}
              filed='type'
              label={t('pages.adminAnalytics.ressource.label')}
              chartTitle={t('pages.adminAnalytics.ressource.title')}
            />
          </div>
          <h6 style={{ marginTop: "10px" }}>
            {t('pages.adminAnalytics.Contracts')}
          </h6>
          <div className="borderB charts-row" style={{ display: "flex", justifyContent: "left", alignItems: "left", marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "left", alignItems: "left", gap: "20px" }}>
              <BarChart
                incomingData={contracts}
                filedValues={statusFiledValues}
                filed='status'
                label={t('pages.adminAnalytics.contract.statusBarChart.label')}
                chartTitle={t('pages.adminAnalytics.contract.statusBarChart.title')}
              />
              <PieChart
                incomingData={contracts}
                filedValues={statusFiledValues}
                filed='status'
                label={t('pages.adminAnalytics.contract.statusPieChart.label')}
                chartTitle={t('pages.adminAnalytics.contract.statusPieChart.title')}
              />
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <StatusPicker status={contractStatus} setStatus={setContractStatus} t={t} type={t('pages.adminAnalytics.Contracts')} />
              <PeriodLineChart
                data={contracts.filter((contract) => contractStatus === '' || contract.status === contractStatus)}
                title={t('pages.adminAnalytics.contract.periodLineChart.title')}
                label={t('pages.adminAnalytics.contract.periodLineChart.label')}
              />
            </div>
          </div>
          <h6 style={{ marginTop: "10px" }}>
            {t('pages.adminAnalytics.Forms')}
          </h6>
          <div className="borderB charts-row" style={{ display: "flex", justifyContent: "left", alignItems: "left", marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "left", alignItems: "left", gap: "20px" }}>
              <BarChart
                incomingData={forms}
                filedValues={statusFiledValues}
                filed='status'
                label={t('pages.adminAnalytics.form.statusBarChart.label')}
                chartTitle={t('pages.adminAnalytics.form.statusBarChart.title')}
              />
              <PieChart
                incomingData={forms}
                filedValues={statusFiledValues}
                filed='status'
                label={t('pages.adminAnalytics.form.statusPieChart.label')}
                chartTitle={t('pages.adminAnalytics.form.statusPieChart.title')}
              />
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <StatusPicker status={formStatus} setStatus={setFormStatus} t={t} type={t('pages.adminAnalytics.Forms')} />
              <PeriodLineChart
                data={forms.filter((form) => formStatus === '' || form.status === formStatus)}
                title={t('pages.adminAnalytics.form.periodLineChart.title')}
                label={t('pages.adminAnalytics.form.periodLineChart.label')}
              />
            </div>
          </div>
          <h6 style={{ marginTop: "10px" }}>
            {t('pages.adminAnalytics.Projects')}
          </h6>
          <div className="borderB charts-row" style={{ display: "flex", justifyContent: "left", alignItems: "left", marginTop: "20px" }}>
            <div style={{ display: "flex", justifyContent: "left", alignItems: "left", gap: "20px" }}>
              <BarChart
                incomingData={projects}
                filedValues={statusFiledValues}
                filed='status'
                label={t('pages.adminAnalytics.project.statusBarChart.label')}
                chartTitle={t('pages.adminAnalytics.project.statusBarChart.title')}
              />
              <PieChart
                incomingData={projects}
                filedValues={statusFiledValues}
                filed='status'
                label={t('pages.adminAnalytics.project.statusPieChart.label')}
                chartTitle={t('pages.adminAnalytics.project.statusPieChart.title')}
              />
            </div>
            <div style={{ flex: 1, display: "flex" }}>
              <StatusPicker status={projectStatus} setStatus={setProjectStatus} t={t} type={t('pages.adminAnalytics.Projects')} />
              <PeriodLineChart
                data={projects.filter((project) => projectStatus === '' || project.status === projectStatus)}
                title={t('pages.adminAnalytics.project.periodLineChart.title')}
                label={t('pages.adminAnalytics.project.periodLineChart.label')}
              />
            </div>
          </div>
        </div>}
    </>
  )
}

export default AdminAnalytics