import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useEffect, useState, useRef, useContext } from "react";
import { onDragEnd } from "../../helpers/onDragEnd";
import { IoAddOutline } from "react-icons/io5";
import Task from "../../components/Task";
import TaskModal from "../../components/modals/TaskModal";

import "./task.scss";
import { TaskEntity } from "../../domain/entities/Task";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { UserEntity } from "../../domain/entities";
import { RessourceClient } from "../../services/api/RessourceClient";
import apiClientWithLoading from "../../services/api/ApiClient";
import { UserClient } from "../../services/api/UserClient";
import { TaskClient } from "../../services/api/TaskClient";
import TaskViewModal from "../../components/modals/TaskViewModal";
import TaskDeleteModal from "../../components/modals/TaskDeleteModal";
import { useTranslation } from "../../contexts/TranslationProvider";
import { toast } from "react-toastify";
import { TaskConfigClient } from "../../services/api/TaskConfigClient";
import { TaskConfigEntity } from "../../domain/entities/TaskConfig";
import { AuthUserContext } from "../../contexts/AuthUserContext";
import TaskConfigModal from "../../components/modals/TaskConfigModal";

interface Column {
  name: string;
  items: TaskEntity[];
}

interface ColumnState {
  [key: string]: Column;
}

interface ModalState {
  isOpen: boolean;
  isEdit: boolean;
  selectedTask?: TaskEntity;
  selectedColumn?: string;
}
interface ViewModalState {
  isOpen: boolean;
  selectedTask?: TaskEntity;
}
interface DeleteModalState {
  isOpen: boolean;
  selectedTask?: TaskEntity;
}
interface taskConfigModalState {
  isOpen:boolean;
  taskConfig?:TaskConfigEntity;
}

const createInitialBoardFromConfig = (statuses: string[]): ColumnState => {
  return statuses.reduce((acc, status) => {
    acc[status] = { name: status, items: [] };
    return acc;
  }, {} as ColumnState);
};

const TasksPage = () => {
  const { t } = useTranslation();
  const { activeRole } = useContext(AuthUserContext);
  const [columns, setColumns] = useState<ColumnState>();
  const [config, setConfig] = useState<TaskConfigEntity | undefined>(undefined);
  const [data, setData] = useState<TaskEntity[] | undefined>(undefined);
  const [modalState, setModalState] = useState<ModalState>({
    isOpen: false,
    isEdit: false,
  });
  const [viewModalState, setViewModalState] = useState<ViewModalState>({
    isOpen: false,
  });
  const [deleteModalState, setDeleteModalState] = useState<DeleteModalState>({
    isOpen: false,
  });
  const [taskConfigModalState,setTaskConfigModalState]=useState<taskConfigModalState>({
    isOpen: false,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [ressources, setRessources] = useState<RessourceEntity[]>([]);
  const [users, setUsers] = useState<UserEntity[]>([]);
  const initialDataLoadDone = useRef<boolean>(false);

  const apiClient = apiClientWithLoading();
  const userClient = new UserClient(apiClient);
  const ressourceClient = new RessourceClient(apiClient);
  const taskClient = new TaskClient(apiClient);
  const taskConfigClient = new TaskConfigClient(apiClient);
  const getConfig = async (): Promise<void> => {
    try {
      const taskConfigResponse = await taskConfigClient.getAll();
      if (taskConfigResponse) {
        setConfig(taskConfigResponse.rows[0]);
      }
    } catch (error) {
      toast.error(t("pages.tasks.messages.fetchError"));
    }
  };
  const getData = async (): Promise<void> => {
    try {
      const taskResponse = await taskClient.getAll("full");
      if (taskResponse.rows) {
        setData(taskResponse.rows);
      }
    } catch (error) {
      toast.error(t("pages.tasks.messages.fetchError"));
    }
  };

  const getUsersRessources = async (): Promise<void> => {
    try {
      const userResponse = await userClient.getAll("nested");
      const ressourceResponse = await ressourceClient.getAll("nested");

      if (ressourceResponse.rows) {
        setRessources(ressourceResponse.rows);
      }
      if (userResponse.rows) {
        setUsers(userResponse.rows);
      }
    } catch (error) {
      toast.error(t("pages.tasks.messages.fetchError"));
    }
  };
  useEffect(() => {
    const loadInitialData = async (): Promise<void> => {
      setLoading(true);
      await Promise.all([getUsersRessources(), getData(), getConfig()]);
      setLoading(false);
    };

    loadInitialData();
  }, []);
  useEffect(() => {
    if (!config || !data) return;

    const statuses = config.statuses;
    const updatedBoard = createInitialBoardFromConfig(statuses);

    data.forEach((task) => {
      const status = task.status;
      if (updatedBoard[status]) {
        updatedBoard[status].items.push(task);
      } else {
        // Optionally handle unknown status
        updatedBoard[status] = { name: status, items: [task] };
      }
    });

    setColumns(updatedBoard);
  }, [config, data]);

  const openAddModal = (columnId: string): void => {
    setModalState({
      isOpen: true,
      isEdit: false,
      selectedColumn: columnId,
    });
  };
  const openEditModal = (task: TaskEntity): void => {
    setModalState({
      isOpen: true,
      isEdit: true,
      selectedTask: task,
    });
  };
  const openDeleteModal = (task: TaskEntity): void => {
    setDeleteModalState({
      isOpen: true,
      selectedTask: task,
    });
  };
  const openTaskConfigModal = (taskConfig:TaskConfigEntity):void=>{
    setTaskConfigModalState({
      isOpen: true,
      taskConfig:taskConfig,
    });
  }
  const openViewModal = (task: TaskEntity): void => {
    setViewModalState({
      isOpen: true,
      selectedTask: task,
    });
  };
  const closeModal = (): void => {
    setModalState({
      isOpen: false,
      isEdit: false,
    });
  };
  const closeViewModal = (): void => {
    setViewModalState({
      isOpen: false,
    });
  };
  const closeDeleteModal = (): void => {
    setDeleteModalState({
      isOpen: false,
    });
  };
  const closeTaskConfigModal = (): void => {
    setTaskConfigModalState({
      isOpen: false,
    });
  };
  async function handleTaskDelete(task: TaskEntity): Promise<void> {
    try {
      const del = await taskClient.delete(task.id);
      setColumns((prevColumns) => {
        const category = task.status;
        return {
          ...prevColumns,
          [category]: {
            ...prevColumns[category],
            items: prevColumns[category].items.filter(
              (item) => item.id !== task.id
            ),
          },
        };
      });
      closeDeleteModal();
      toast.success(t("pages.tasks.messages.taskDeleted"));
    } catch (error) {
      toast.error(t("pages.tasks.messages.deleteError"));
    }
  }

  async function handleTaskSubmit(taskData: TaskEntity): Promise<void> {
    try {
      if (modalState.isEdit && taskData.id) {
        const PurifiedTaskData: TaskEntity = {
          id: taskData.id,
          color: taskData.color,
          comment: taskData.comment,
          estimatedEndDate: taskData.estimatedEndDate,
          estimatedStartDate: taskData.estimatedStartDate,
          name: taskData.name,
          priority: taskData.priority,
          progress: taskData.progress,
          ressourcesId: taskData.ressourcesId,
          startDate: taskData.startDate,
          endDate: taskData.endDate,
          status: taskData.status,
          usersId: taskData.usersId,
          category: taskData.category,
        };
        const result = await taskClient.update(
          taskData.id,
          PurifiedTaskData,
          "full"
        );
        if (result) {
          toast.success(t("pages.tasks.messages.taskUpdated"));
          setData((prevData) => {
            if (!prevData) return [result];
            return prevData.map((task) =>
              task.id === result.id ? result : task
            );
          });
          const oldTask = data?.find((task) => task.id === result.id);
          if (oldTask && oldTask.status !== result.status) {
            const oldCategory = oldTask.status;
            const newCategory = result.status;

            setColumns((prevColumns) => {
              const oldColumnItems = prevColumns[oldCategory].items.filter(
                (item) => item.id !== result.id
              );
              const newColumnItems = [
                ...prevColumns[newCategory].items,
                result,
              ];
              return {
                ...prevColumns,
                [oldCategory]: {
                  ...prevColumns[oldCategory],
                  items: oldColumnItems,
                },
                [newCategory]: {
                  ...prevColumns[newCategory],
                  items: newColumnItems,
                },
              };
            });
          } else {
            const category = result.status;
            setColumns((prevColumns) => ({
              ...prevColumns,
              [category]: {
                ...prevColumns[category],
                items: prevColumns[category].items.map((item) =>
                  item.id === result.id ? result : item
                ),
              },
            }));
          }
        }
      } else {
        const PurifiedTaskData: TaskEntity = {
          color: taskData.color,
          comment: taskData.comment,
          estimatedEndDate: taskData.estimatedEndDate,
          estimatedStartDate: taskData.estimatedStartDate,
          name: taskData.name,
          priority: taskData.priority,
          progress: taskData.progress,
          category: taskData.category,
          ressourcesId: taskData.ressourcesId,
          startDate: taskData.startDate,
          endDate: taskData.endDate,
          status: taskData.status,
          usersId: taskData.usersId,
        };
        const addedTask = await taskClient.create(PurifiedTaskData, "full");
        if (addedTask) {
          toast.success(t("pages.tasks.messages.taskCreated"));
          setData((prevData: TaskEntity[] | undefined) => {
            if (!prevData) return [addedTask];
            return [...prevData, addedTask];
          });
          const category = addedTask.status;
          setColumns((prevColumns) => ({
            ...prevColumns,
            [category]: {
              ...prevColumns[category],
              items: [...prevColumns[category].items, addedTask],
            },
          }));
        }
      }
      closeModal();
    } catch (error) {
      toast.error(t("pages.tasks.messages.submitError"));
    }
  }

  async function handleUpdateTaskConfig(updatedConfig: TaskConfigEntity): Promise<void> {
    try {
      const result = await taskConfigClient.update(updatedConfig.id, updatedConfig);
      if (result) {
        toast.success(t("pages.tasks.messages.configUpdated"));
        setConfig(result);
        const updatedBoard = createInitialBoardFromConfig(result.statuses);
        setColumns(updatedBoard);
      }
      closeTaskConfigModal();
    } catch (error) {
      toast.error(t("pages.tasks.messages.configError"));
    }
  }

  return (
    <>
      {!loading && (
        <>
          <div className="task-header">
            <span className="current-pathname">{t("pages.tasks.page")}</span>
            {activeRole === "Admin" ? (
              <button className="edit-task-button" onClick={()=>{openTaskConfigModal(config)}}>{t("pages.tasks.edit")}</button>
            ) : null}
          </div>
          <DragDropContext
            onDragEnd={(result) =>
              onDragEnd(result, columns, setColumns, setData, taskClient)
            }
          >
            <div className="Task-container">
              {Object.entries(columns).map(([columnId, column]) => (
                <div className="Task-column" key={columnId}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="Task-droppable"
                      >
                        <div className="Task-column-header">{column.name}</div>
                        {column.items.map((task, index) => (
                          <Draggable
                            key={task.id.toString()}
                            draggableId={task.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <Task
                                provided={provided}
                                task={task}
                                onTaskClick={openViewModal}
                                onEditClick={openEditModal}
                                onDeleteClick={openDeleteModal}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div
                    onClick={() => openAddModal(columnId)}
                    className="Task-add-btn"
                  >
                    <IoAddOutline className="Task-add-icon" />
                    {t("pages.tasks.buttons.add")}
                  </div>
                </div>
              ))}
            </div>
          </DragDropContext>
        </>
      )}
      {viewModalState.isOpen && (
        <TaskViewModal
          task={viewModalState.selectedTask}
          onEditClick={handleTaskSubmit}
          onClose={closeViewModal}
        />
      )}
      {deleteModalState.isOpen && (
        <TaskDeleteModal
          task={deleteModalState.selectedTask}
          onDeleteClick={handleTaskDelete}
          onClose={closeDeleteModal}
        />
      )}
      {taskConfigModalState.isOpen && (
        <TaskConfigModal
          taskConfig={taskConfigModalState.taskConfig}
          onClose={closeTaskConfigModal}
          onUpdate={handleUpdateTaskConfig}
        />
      )}
      

      {modalState.isOpen && (
        <TaskModal
          onClose={closeModal}
          onSubmit={handleTaskSubmit}
          ressources={ressources}
          users={users}
          task={modalState.selectedTask}
          isEdit={modalState.isEdit}
          categories={config.categories}
          statuses={config.statuses}
        />
      )}
    </>
  );
};

export default TasksPage;
