import { AxiosInstance } from "axios";
import { AiFileEntity } from "../../domain/entities/AiFile";
import { ModelClient } from "../ModelClient";

export class AiFileClient extends ModelClient<AiFileEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'AiFile')
  }
  async createFromFile(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.apiClient.post<{ row: AiFileEntity }>(`${this.modelUrl}/createFromFile`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }
  async createFromFileUrl(fileName: string, fileUrl: string): Promise<any> {

    const response = await this.apiClient.post<{ row: AiFileEntity }>(
      `${this.modelUrl}/createFromFileUrl`,
      { fileName, fileUrl }
    );
    return response.data;
  }

  async compile(id: string): Promise<any> {
    const response = await this.apiClient.post<{ row: AiFileEntity }>(`${this.modelUrl}/activate/${id}`);
    return response.data;
  }

  async delete(id: string): Promise<{ deleted: boolean; }> {
    const response = await this.apiClient.delete<{ deleted: boolean; }>(`${this.modelUrl}/${id}`);
    return response.data;
  }

  async getById(id: string): Promise<AiFileEntity> {
    const response = await this.apiClient.get<{ row: AiFileEntity }>(`${this.modelUrl}/${id}`);
    return response.data.row;
  }
  async update(aiFileId: string, data: AiFileEntity): Promise<any> {
    const response = await this.apiClient.put<{ row: AiFileEntity }>(`${this.modelUrl}/${aiFileId}`, data);
    return response.data.row;
  }
}