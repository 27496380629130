import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { BeneficialEntity } from "../../domain/entities/Beneficial";

type FillBeneficialFormRequest = {
	file: Blob & { name: string };
	type: BeneficialEntity["type"]
}

type FillBeneficialFormResponse = {
  fields: Record<string, string>
  text: string
}
export class BeneficialClient extends ModelClient<BeneficialEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Beneficial')
  }
  async fillForm(req: FillBeneficialFormRequest) {
    const url = new URL(this.modelUrl + "/fillForm")

    const formData = new FormData();

    formData.append("file", req.file);
    req.type && url.searchParams.append("type", String(req.type))
    const response = await this.apiClient.post<{ row: FillBeneficialFormResponse }>(url.href, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  }
};

