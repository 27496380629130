import React, { useState, useRef, useContext, useEffect } from "react";
import "./editMacro.scss";
import ComparisonClause from "./ComparisonClause";
import { LanguageType, useTranslation } from "../../../contexts/TranslationProvider";
import FlagSelector from "./flagSelector";
import MacrosList from "./MacrosList";
import { COMPANY_CODE } from "../../../config";
import { BeneficialEntity, BeneficialMacroEntity, ClauseEntity } from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { BeneficialMacroClient } from "../../../services/api/BeneficialMacroClient";
import { toast } from "react-toastify";

function EditBeneficialMacrosModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const beneficialMacroClient = new BeneficialMacroClient(apiClient);
  const translationPath = "modals.editMacros.";
  const [currentMacroName, setCurrentMacroName] = useState<string>(null);
  const [macrosByLanguage, setMacrosByLanguage] = useState<BeneficialMacroEntity[]>([]);
  const [allMacros, setAllMacros] = useState<BeneficialMacroEntity[]>([]);
  const [allLanguages, setAllLanguages] = useState<LanguageType[]>(["en", "fr", "ar"]);
  const [paramValues, setParamValues] = useState<Record<string, any>>({});
  const companyCode = COMPANY_CODE || 'default'
  const [chosenLanguage, setChosenLanguage] = useState<"en" | "fr" | "ar">(language);
  const [chosenType, setChosenType] = useState<BeneficialEntity['type'] | null>(null);
  const [macroToEdit, setMacroToEdit] = useState<BeneficialMacroEntity>(null);
  useEffect(() => {
    const fetchBeneficialMacros = async () => {
      try {
        const macros = await beneficialMacroClient.getAll();
        if(macros && macros.rows)
          setAllMacros(macros.rows);
      } catch (error) {
        console.log(error)
      }
    }
    fetchBeneficialMacros();
  }, []);
  useEffect(() => {
    if (allMacros) {
      if(!chosenLanguage) setMacrosByLanguage([])
      if(!chosenType) return setMacrosByLanguage([])
      const macrosFilteredByLanguage = allMacros.filter((macro) => macro.language === chosenLanguage && macro.type === chosenType);
      setMacrosByLanguage(macrosFilteredByLanguage);
    }
  }, [allMacros, chosenLanguage, chosenType]);
  useEffect(() => {
    // identify the macro to edit based on the chosen language, type and name
    if (!macrosByLanguage || macrosByLanguage.length == 0) return;
    if (!currentMacroName) return;
    if (!chosenType || !currentMacroName) return;
    const chosenMacro = macrosByLanguage.find((macro) => macro.name === currentMacroName && macro.type === chosenType);
    if (!chosenMacro) return;
    setMacroToEdit(chosenMacro);
  }, [macrosByLanguage, chosenType, currentMacroName]);

  const handleConfirm = async () => {
    try {
      const updatePromises = allMacros.map(async (macro) => {
        const updatedMacro = await beneficialMacroClient.update(macro.id, macro);
        return updatedMacro;
      });
      await Promise.all(updatePromises);
      toast.success('All macros have been successfully updated.');
      console.log('All macros have been successfully updated.');
      onClose();
    } catch (error) {
      toast.error('Error updating macros');
      console.error('Error updating macros:', error);
    }
  };
  
  const handleSelect = (macroName) => {
    setCurrentMacroName(macroName);
  }
  const handleSegmentationChange = (
    segmentation: BeneficialMacroEntity['segmentation'],
    id: BeneficialMacroEntity['id'],
  ) => {
    // update the segmentation of the macro to edit
    const macroToUpdate = allMacros.find((macro) => macro.id === id);
    const updatedMacro = { ...macroToUpdate, segmentation };
    //allMacros[language][name][type] = newMacro;
    setAllMacros((prev) => {
      return prev.map((macro) => {
        if (macro.id === id) {
          return updatedMacro;
        }
        return macro;
      });
    });
  }
  return (
    <>
      <div className="macro-modal" onClick={() => onClose()}>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="clause-comparison-modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                {/* <button onClick={() => console.log(allMacros)}>all macros</button> */}
                <div className="">
                  <div
                    className={``}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      {t(translationPath + "title")}
                      <FlagSelector
                        optionValue={(option) => option?.language || null}
                        optionDisplay={(option) => option?.flag || ""}
                        language={chosenLanguage}
                        handleLanguageChange={(value) => setChosenLanguage(value)}
                      />
                    </div>
                  </div>
                </div>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
              </div>
              <div className="body-comparison">
                <div className="clause-comparison-container">
                  <div className="clause-exchange-current">
                    <ComparisonClause
                      macro={macroToEdit}
                      type={chosenType}
                      setType={setChosenType}
                      handleSegmentationChange={handleSegmentationChange}
                      paramValues={paramValues}
                    />
                  </div>
                  <div className="clause-exchange-found">
                    <MacrosList 
                    macros={macrosByLanguage} 
                    handleSelect={handleSelect} 
                    currentMacro={currentMacroName} 
                    currentType={chosenType}
                    inputValues={paramValues}
                    setInputValues={(param, target) => {
                      console.log("updating", param, target.value)
                      if(!target) return;
                      setParamValues((prev) => {
                        return {
                          ...prev,
                          [param.name]: target.value
                        }
                      })
                    }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-actions">
                <button
                  className={`confirm-button`}
                  onClick={handleConfirm}
                >
                  {t(translationPath + "save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditBeneficialMacrosModal;
