import React, { useContext, useState, useEffect, useRef } from "react";
import Loading from "../../common/Loading";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { downloadFile } from "../../../utils/file";
import { serializeParamValues } from "../../../hooks/serializeParamValues";
import { saveWorkbook } from "../../../utils/csv";
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { getContractExportData } from "../../../domain/Contract";
import { ContractDocxExportClient } from "../../../services/api/ContractDocxExportClient";
import { toast } from "react-toastify";
import { ParagraphProps } from "../../../domain/entities/ParagraphProps";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import { ContractClient } from "../../../services/api/ContractClient";
import { updateContract } from "../../../helpers/UpdateContract";
import MacroContext from "../../../contexts/MacroContext";
import { ContractEntity } from "../../../domain/entities";
import { useSubmitContract } from "../../../hooks/useSubmitContract";
import { useLocation } from "react-router-dom";
// import csv from "csv.png"


const ContractExportModal = ({ onClose }) => {
  const modalref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalref.current && !modalref.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalref]);
  const [loading, setLoading] = useState(false);
  const { inputValues, templateData, excludedClauses, excludedSubClauses, contract, fileNames, beneficialsMap } = useContext(InputValuesContext);
  const contractName = inputValues.contractName ?? contract?.name
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading(setLoading);
  const {
    commentsOverrides,
    templateId,
    clausesOverrides,
    completionPercentage,
    contractId
  } = useContext(InputValuesContext);
  const {macros} = useContext(MacroContext);
  const submitContract = useSubmitContract("",false);

  const processParamValues = useParamProcessing();
  const createContractUpdateBody = async (inputValues, templateId, commentsOverrides, excludedSubClauses, excludedClauses,clausesOverrides, contract) => {
    const { processedParamValues } = await processParamValues(inputValues);

    return {
      templateId,
      paramValues: processedParamValues,
      commentsOverrides,
      clausesOverrides,
      excludedSubClauses,
      excludedClauses,
      status: contract.status,
      name: inputValues.contractName,
    };
  };



  const contractClient = new ContractClient(apiClient);
  const GenerateDocxUrl = async () => {
    setLoading(true);

    try {
      const { processedParamValues, hasError } = await processParamValues(
        inputValues
      );
  
      if (!hasError) {
  
        let status: ContractEntity['status'] = "Draft";
        if (completionPercentage === 100) {
          status = "Done";
        }
        const body = {
          templateId: templateId,
          paramValues: processedParamValues,
          commentsOverrides,
          excludedSubClauses: excludedSubClauses,
          excludedClauses: excludedClauses,
          status: status,
          name: inputValues.contractName,
          clausesOverrides:clausesOverrides
        };
  
        await submitContract(body, contractId);
      }
      let contractDocxExportId = contract.template?.contractDocxExportId;
      const contractDocxExportClient = new ContractDocxExportClient(apiClient);
      if (!contractDocxExportId) {
        contractDocxExportId = await getDefaultContractDocxExportId(contractDocxExportClient);
        if (!contractDocxExportId) return closeWithToast("No default ContractDocxExport");
      }

      const paragraphsProps = await contractDocxExportClient.getDocxProps(contractDocxExportId);
      const data = getContractExportData(macros, contract, t, paragraphsProps);
      if (!data) return closeWithToast("No data available for Docx conversion.");
      const url = await contractDocxExportClient.dataToDocx(contractDocxExportId, data);
      url ? downloadFile(url, `${contract?.name}.docx`) : toast("Failed to generate the document.");

    } catch (error) {
      console.error("Error in GenerateDocxUrl:", error);
      toast("An error occurred while generating the document.");
    }
  };
  const GenerateXlsxUrl = async () => {
    setLoading(true);

    try {
      const body = await createContractUpdateBody(
        inputValues,
        templateId,
        commentsOverrides,
        excludedSubClauses,
        clausesOverrides,
        excludedClauses,
        contract
      );

      const updatedContract = await updateContract(contractClient, contract?.id, body, "full");
      if (updatedContract) {
        const workbook = serializeParamValues(updatedContract.paramValues, templateData, updatedContract.fileNames, updatedContract.beneficialsMap, t, language)
        saveWorkbook(workbook, `${contractName}.xlsx`)
        onClose()
      }
    }
    catch (err) {
      console.log('err :>> ', err);
    }
  }

  const getDefaultContractDocxExportId = async (client) => {
    const defaultContractDocxExport = await client.getDefault();
    return defaultContractDocxExport?.id || null;
  };

  const closeWithToast = (message) => {
    toast(message);
    setLoading(false);
    onClose();
  };



  return (
    <>
      <>
        <div id="contractz-lab" ref={modalref} className="contractz-lab-export" style={{ position: "absolute", top: "9vh", right: "35vh" }}>
          <div className="modal-dialog export-modal-specific">
            <div className="modal-content" style={{ width: "fit-content" }}>
              <div className="modal-header header-export" >
                <h5 className="modal-title export-title">{t("pages.editionContract.popups.export.title")}</h5>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-export">
                  <div className="form-group">
                    <p className="export-contract-text">{t("pages.editionContract.popups.export.description")}</p>
                    <div className="form-floating mb-3 export-buttons">
                      <button className="btn export-btn" onClick={GenerateXlsxUrl} style={{ margin: "10px 0px" }}>
                        <div className="csv-img"></div>
                        <p>{t("pages.editionContract.popups.export.exportCSV")}</p>
                      </button>

                      <>
                        <button className="btn export-btn" onClick={GenerateDocxUrl} style={{ margin: "10px 0px" }}>
                          <div className="word-img"></div>
                          <p>{t("pages.editionContract.popups.export.exportWord")}</p>
                        </button>
                      </>

                    </div>
                    <p className="export-contract-text">{t("pages.editionContract.popups.export.footer")}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ContractExportModal;
