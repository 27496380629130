import { AxiosInstance } from "axios";
import { ReglementEntity } from "../../domain/entities/Reglement";
import { ModelClient } from "../ModelClient";

export class ReglementClient extends ModelClient<ReglementEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Regulation');
  }
  async getAll(): Promise<any> {
    const response = await this.apiClient.get<{ rows: ReglementEntity[] }>(`${this.modelUrl}/all`);
    return response.data;
  }
  async getById(id: string): Promise<ReglementEntity> {
    const response = await this.apiClient.get<{ row: ReglementEntity }>(`${this.modelUrl}/${id}`);
    return response.data.row;
  }
  async delete(id: string): Promise<{ deleted: boolean; }> {
    const response = await this.apiClient.delete<{ deleted: boolean; }>(`${this.modelUrl}/${id}`);
    return response.data;
  }
  async create(data: ReglementEntity): Promise<ReglementEntity> {
    const response = await this.apiClient.post<{ row: ReglementEntity }>(`${this.modelUrl}`, data);
    return response.data.row;
  }
  async addFile(reglementId: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.apiClient.post<{ rows: ReglementEntity[] }>(`${this.modelUrl}/addFile/${reglementId}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  }

  async commit(reglementId: string): Promise<any> {
    const response = await this.apiClient.post<{ rows: ReglementEntity[] }>(`${this.modelUrl}/activate/${reglementId}`);
    return response.data;
  }

  async desactivate(reglementId: string): Promise<any> {
    const response = await this.apiClient.post<{ rows: ReglementEntity[] }>(`${this.modelUrl}/desactivate/${reglementId}`);
    return response.data;
  }

  async getRelevantInfo(query: string, reglementId: string): Promise<any> {
    const response = await this.apiClient.post<{ relevantDocs: string }>(`${this.modelUrl}/getRelevantInfo`, { 
      query,
      regulationId: reglementId
     });
    return response.data;
  }
  async deleteFile(reglementId: string, fileId: string): Promise<any> {
    const response = await this.apiClient.delete<{ rows: ReglementEntity[] }>(`${this.modelUrl}/deleteFile/${reglementId}?fileId=${fileId}`);
    return response.data;
  }
  async update(reglementId: string, data: ReglementEntity): Promise<any> {
    const response = await this.apiClient.put<{ row: ReglementEntity }>(`${this.modelUrl}/${reglementId}`, data);
    return response.data.row;
  }
}