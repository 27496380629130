import "../commonmodal.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";

import ShowCase from "../../ShowCase";
import CustomCombobox from "../../common/CustomCombobox";
import { useTranslation } from "../../../contexts/TranslationProvider";
import {
  ProjectEntity,
  ProjectTemplateRequirementEntity,
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../../domain/entities";
import { numberToEnumList } from "../../../domain/Project";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { getName } from "../../../helpers/Translation";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import { DocumentClient } from "../../../services/api/DocumentClient";
import { RessourceScoringClient } from "../../../services/api/RessourceScoring";
import CheckBoxInput from "../../common/CheckBoxInput";
import { on } from "events";
import { toast } from "react-toastify";
import PendingAi from "../../common/newDesign/PendingAi";
import { previous } from "slate";
interface CreateContractComparisonType {}
function CreateContractComparison({}: CreateContractComparisonType) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contractExistsError, setContractExistsError] = useState(false);
  const [checkContractExistsTimeout, setCheckContractExistsTimeout] =
    useState<NodeJS.Timeout>(null);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const modalContentRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isScanned, setIsScanned] = useState(false);
  const { t, language } = useTranslation();
  const triggerFileInput = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };
  const deleteFile = (onChange) => {
    setFile(null);

    // Create a new Event
    const event = new Event("input", { bubbles: true });

    // Reset the value of the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;

      // Dispatch the event
      fileInputRef.current.dispatchEvent(event);
    }

    // Call onChange with the event
    onChange(event);
  };
  const [levelsFilter, setLevelsFilter] = useState<{
    level1Id: TypeLevel1Entity["id"];
    level2Id: TypeLevel2Entity["id"];
    level3Id: TypeLevel3Entity["id"];
  }>({
    level1Id: null,
    level2Id: null,
    level3Id: null,
  });
  const [displayLevels, setDisplayLevels] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[];
    level2: TypeLevel2Entity[];
    level3: TypeLevel3Entity[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });
  const apiClient = useApiClientWithLoading();
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const documentClient = new DocumentClient(apiClient);
  const ressourceScoringClient = new RessourceScoringClient(apiClient);

  useEffect(() => {
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithContractTemplate(language);
      setOptions((prevOptions) => ({
        ...prevOptions,
        level1: data.rows,
      }));
    };

    fetchData();
  }, []);
  const handleLevel1Change = (value) => {
    const selectedLevel1 = options.level1.find(
      (item) => getName(item, language as any) === value
    );
    if (selectedLevel1) {
      setLevelsFilter({
        level1Id: selectedLevel1.id,
        level2Id: null,
        level3Id: null,
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        level2: selectedLevel1.levels2,
        level3: [],
      }));
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level2: selectedLevel1.levels2.length > 0,
        level3: false,
      }));

      // Clear the values of Type2 and Type3 comboboxes
      setValue("Type2", t("pages.listener.popups.contract.inputs.type2.value"));
      setValue("Type3", t("pages.listener.popups.contract.inputs.type3.value"));
    }
  };

  const handleComboboxClick = () => {
    setTimeout(() => {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }, 10);
  };

  const handleLevel2Change = (value) => {
    const selectedLevel2 = options.level2.find(
      (item) => getName(item, language as any) === value
    );
    if (selectedLevel2) {
      setLevelsFilter({
        level1Id: levelsFilter.level1Id,
        level2Id: selectedLevel2.id,
        level3Id: null,
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        level3: selectedLevel2.levels3 || [],
      }));

      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3:
          Array.isArray(selectedLevel2.levels3) &&
          selectedLevel2.levels3.length > 0,
      }));
    } else {
      setDisplayLevels((prevLevels) => ({
        ...prevLevels,
        level3: false,
      }));
    }
    setValue("Type3", t("pages.listener.popups.contract.inputs.type3.value"));
  };
  const handleLevel3Change = (value) => {
    const selectedLevel3 = options.level3.find(
      (item) => getName(item, language as any) === value
    );
    if (selectedLevel3) {
      setLevelsFilter({
        ...levelsFilter,
        level3Id: selectedLevel3.id,
      });
    }
  };
  const createFromFile = useCallback(
    async (file, name) => {
      setLoading(true);
      const {
        row: { id: documentId },
      } = await documentClient.upsertMultiForm({ file, name, isScanned });
      const { row: template } = await contractTemplateClient.select(
        levelsFilter.level1Id,
        levelsFilter.level2Id,
        levelsFilter.level3Id,
        language,
        "pure"
      );
      const templateId = template?.id;
      if (templateId) {
        const ressourceScoring =
          await ressourceScoringClient.createFromDocument(
            documentId,
            name,
            templateId
          );
        if (ressourceScoring && ressourceScoring.id) {
          const mappedRessource =
            await ressourceScoringClient.mapRessourceClauses(
              ressourceScoring.id
            );
            if(mappedRessource && mappedRessource.id){
              const analyzedRessource = await ressourceScoringClient.analyzeRessourceClauses(mappedRessource.id);
            }
          return mappedRessource;
        }
      }
      return;
    },
    [apiClient, setLoading]
  );
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    let selectedLevel, selectedValue;
    // Check which level the user has selected
    if (data.Type3 !== "Type Niveau 3") {
      selectedLevel = "level3.name";
      selectedValue = data.Type3;
    } else if (data.Type2 !== "Type Niveau 2") {
      selectedLevel = "level2.name";
      selectedValue = data.Type2;
    } else if (data.Type1 !== "Type Niveau 1") {
      selectedLevel = "level1.name";
      selectedValue = data.Type1;
    }
    try {
      const ressource = await createFromFile(file, data.contractName);
      setLoading(false);
      if (ressource && ressource.id)
        navigate(`/contract-compliance/${ressource.id}`, {
          state: {
            fromHomePage: true,
          },
        });
    } catch (error) {
      console.error("Error fetching AI contract data:", error);
      toast.error(t("pages.pilot.popups.contract.errorImport"));
    } finally {
      setLoading(false);
    }
  };
  const contractName = watch("contractName");
  useEffect(() => {
    if (checkContractExistsTimeout) {
      clearTimeout(checkContractExistsTimeout);
    }
    setCheckContractExistsTimeout(
      setTimeout(async () => {
        try {
          const count = await documentClient.countAllByFilter({
            name: contractName,
          });
          setContractExistsError(count >= 1);
        } catch (error) {
          console.error(error);
        } finally {
          setCheckContractExistsTimeout(null);
        }
      }, 500)
    );
  }, [contractName]);
  return (
    <>
      <div
        style={{ overflowY: "hidden" }}
        className="modal-body modal-body-pilotage"
        ref={modalContentRef}
      >
        {loading ? (
          <PendingAi height="100%" />
        ) : (
          <>
            <form
              action=""
              className="form-group"
              style={{
                maxHeight: "500px",
                marginBottom: "70px",
                overflowY: "auto",
                overflowX: "hidden",
                paddingLeft: "20px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <p className="fw-bolder">
                {t("pages.listener.popups.contract.required")}
              </p>
              <div className="form-input-content">
                <div className="form-input-first-content w-50">
                  <label htmlFor="contractName" className="case-form-labelName">
                    {t(
                      "pages.listener.popups.contract.inputs.contractName.name"
                    )}{" "}
                  </label>
                  <div className="form-floating mb-3 case-form-labelInput">
                    <input
                      type="text"
                      className="form-control custom-color-input input-popup"
                      id="contractName"
                      placeholder={t(
                        "pages.listener.popups.contract.inputs.contractName.placeholder"
                      )}
                      onChange={() => {
                        setContractExistsError(false);
                      }}
                      {...register("contractName", { required: true })}
                    />
                  </div>
                  {errors.contractName && (
                    <p className="text-danger py-0 my-0 py-0 my-0">
                      {t(
                        "pages.listener.popups.contract.inputs.contractName.error1"
                      )}
                    </p>
                  )}
                  {contractExistsError && (
                    <p className="text-danger py-0 my-0 py-0 my-0">
                      {t(
                        "pages.listener.popups.contract.inputs.contractName.error2"
                      )}
                    </p>
                  )}
                </div>
                <div className="" style={{ width: "40%" }}>
                  <Controller
                    name="Type1"
                    control={control}
                    defaultValue={t(
                      "pages.listener.popups.contract.inputs.type1.value"
                    )}
                    rules={{
                      required: true,
                      validate: (value) =>
                        value !==
                        t("pages.listener.popups.contract.inputs.type1.value"),
                    }}
                    render={({ field }) => (
                      <CustomCombobox
                        fullWidth
                        label={t(
                          "pages.listener.popups.contract.inputs.type1.name"
                        )}
                        options={options.level1.map((item) =>
                          getName(item, language as any)
                        )}
                        value={field.value}
                        onDropdownOpen={handleComboboxClick}
                        onChange={(value) => {
                          handleLevel1Change(value);
                          field.onChange(value);
                        }}
                        isOpen={openCombobox === "level1"}
                        setIsOpen={(isOpen) =>
                          setOpenCombobox(isOpen ? "level1" : null)
                        }
                      />
                    )}
                  />

                  {errors.Type1 && (
                    <p className="text-danger py-0 my-0">
                      {t("pages.listener.popups.contract.inputs.type1.error")}
                    </p>
                  )}
                  {displayLevels.level2 && (
                    <Controller
                      name="Type2"
                      control={control}
                      defaultValue={t(
                        "pages.listener.popups.contract.inputs.type2.value"
                      )}
                      rules={{
                        required: true,
                        validate: (value) =>
                          value !==
                          t(
                            "pages.listener.popups.contract.inputs.type2.value"
                          ),
                      }}
                      render={({ field }) => (
                        <CustomCombobox
                          fullWidth
                          label={t(
                            "pages.listener.popups.contract.inputs.type2.name"
                          )}
                          onDropdownOpen={handleComboboxClick}
                          options={options.level2.map((item) =>
                            getName(item, language as any)
                          )}
                          value={field.value}
                          onChange={(value) => {
                            handleLevel2Change(value);
                            field.onChange(value);
                          }}
                          isOpen={openCombobox === "level2"}
                          setIsOpen={(isOpen) =>
                            setOpenCombobox(isOpen ? "level2" : null)
                          }
                        />
                      )}
                    />
                  )}
                  {options.level2.length > 0 && errors.Type2 && (
                    <p className="text-danger py-0 my-0">
                      {t("pages.listener.popups.contract.inputs.type2.error")}
                    </p>
                  )}
                  {displayLevels.level3 && (
                    <Controller
                      name="Type3"
                      control={control}
                      defaultValue={t(
                        "pages.listener.popups.contract.inputs.type3.value"
                      )}
                      rules={{
                        required: true,
                        validate: (value) =>
                          value !==
                          t(
                            "pages.listener.popups.contract.inputs.type3.value"
                          ),
                      }}
                      render={({ field }) => (
                        <CustomCombobox
                          fullWidth
                          label={t(
                            "pages.listener.popups.contract.inputs.type3.name"
                          )}
                          onDropdownOpen={handleComboboxClick}
                          options={options.level3.map((item) =>
                            getName(item, language as any)
                          )}
                          value={field.value}
                          onChange={(value) => {
                            handleLevel3Change(value);
                            field.onChange(value);
                          }}
                          isOpen={openCombobox === "level3"}
                          setIsOpen={(isOpen) =>
                            setOpenCombobox(isOpen ? "level3" : null)
                          }
                        />
                      )}
                    />
                  )}

                  {options.level3.length > 0 && errors.Type3 && (
                    <p className="text-danger py-0 my-0">
                      {t("pages.listener.popups.contract.inputs.type3.error")}
                    </p>
                  )}
                </div>
              </div>
              <Controller
                name="file"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "dashed 2px #ccc",
                      padding: "0.5rem",
                      cursor: "pointer",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                    }}
                    className="mt-3"
                  >
                    <div className="d-flex ms-1">
                      {file?.name && (
                        <AiFillDelete
                          style={{
                            marginRight: "0.5rem",
                            color: "#2f14e5",
                            cursor: "pointer",
                          }}
                          onClick={() => deleteFile(field.onChange)}
                        />
                      )}
                      <AiOutlineUpload
                        style={{
                          marginRight: "0.5rem",
                          color: "#2f14e5",
                        }}
                        onClick={triggerFileInput}
                      />
                    </div>
                    <p style={{ margin: 0 }}>
                      {file?.name ||
                        t(
                          "pages.listener.popups.contract.inputs.file.placeholder"
                        )}
                    </p>

                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileChange(e);
                        field.onChange(e);
                      }}
                    />
                  </div>
                )}
              />
              {errors.file && (
                <p className="text-danger py-2 my-0 py-0 my-0">
                  {t("pages.listener.popups.contract.inputs.file.error")}
                </p>
              )}
              <CheckBoxInput
                checked={isScanned}
                name={t("pages.listener.popups.contract.inputs.isScanned")}
                onChange={() => setIsScanned(!isScanned)}
                disabled={false}
              />

              <div className="modal-footer footer-form-pilotage">
                <button
                  disabled={contractExistsError || !!checkContractExistsTimeout}
                  type="submit"
                  className="btn btn-primary"
                  style={{ left: "4.5%" }}
                >
                  {t("pages.listener.popups.contract.button")}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default CreateContractComparison;
