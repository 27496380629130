import React, { useEffect, useState, useContext, useMemo, useCallback, useRef } from "react";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import { intersectionByKey } from "../../../utils/array";
import CustomCombobox from "../../common/CustomCombobox";
import { useNavigate } from "react-router-dom";
import { getUserGroups } from "../../../services/api/UserAuth";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import { useSubmitForm } from "../../../hooks/useSubmitForm";
import { FormEntity, FormTemplatePageEntity, GroupEntity, UserEntity, ValidateReq } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { GroupClient } from "../../../services/api/GroupClient";
import { UserClient } from "../../../services/api/UserClient";
import { FormClient } from "../../../services/api/FormClient";
import { getName } from "../../../helpers/Translation";
import { getAllParams } from "../../../domain/FormTemplate";
import { areConditionsMet } from "../../../domain/Group";
import { WorkflowStep, WorkflowTransitionCondition } from "../../../domain/Workflow";
import { statuses } from "../../../domain/types/status";
import MacroContext from "../../../contexts/MacroContext";
import ReactFlow, {
  Node,
  Edge,
  Connection,
  addEdge,
  Background,
  ReactFlowProvider,
  Handle,
  Position,
  reconnectEdge,
  NodeMouseHandler,
  useReactFlow,
  ReactFlowInstance,
} from 'reactflow';
import { NodeContextMenu, useWorkflowGraph, WorkflowNode } from "../WorkflowSettingsModal";

import { calculateFormCompletionPercentage } from "../../../helpers/CompletionPercentage";
import EditFormContext from "../../../contexts/EditFormContext";

const { default: WorkflowStepSvg } = require('../../../assets/workflow_step.svg');
const { default: WorkflowStepCurrentSvg } = require('../../../assets/workflow_step_current.svg');
const { default: WorkflowStepTargetSvg } = require('../../../assets/workflow_step_target.svg');
const { default: ActiveStepSvg } = require('../../../assets/active_step.svg');
const { default: InactiveStepSvg } = require('../../../assets/inactive_step.svg');

interface ValidateFormModalProps {
  form: FormEntity;
  onClose: () => void;
}

const ValidateFormWorkflowModal: React.FC<ValidateFormModalProps> = ({ onClose }) => {

  const {
    form,
    template,
    paramValues,
    completionPercentage,
  } = useContext(EditFormContext);

  const { t, language } = useTranslation();
  const allSteps = template?.groupsGraph?.steps?.filter(s => s.id) ?? []

  const { macros } = useContext(MacroContext)


  const [validationData, setValidationData] = useState<ValidateReq>({
    stepsId: form?.stepsId ?? [],
    validateGroupId: form?.assignedToGroupId,
    assignToGroupId: undefined,
    assignToUserId: undefined,
  });


  const formSteps = form?.stepsId?.map(id => allSteps?.find(s => s.id == id)).filter(Boolean) ?? []

  //excluding the target
  const validationSteps = useMemo(() => validationData.stepsId?.map(id => allSteps?.find(s => s.id == id)).filter(Boolean) ?? [], [validationData])
  const startingStepId = form?.stepsId?.[form.stepsId.length - 1]
  const currentStep = validationSteps[validationSteps.length - 1]




  const revokeStep = useMemo(() => startingStepId != currentStep?.id && currentStep, [currentStep])


  const initialStep = allSteps?.find(s => {
    const transitionsTo = template?.groupsGraph.transitions.find(t => t.toId == s.id)
    return !transitionsTo
  })

  const targetSteps = useMemo(() => {
    if (!currentStep) {
      return [initialStep]
    }
    return allSteps?.filter(s => template?.groupsGraph?.transitions.find(t => t.fromId == currentStep.id && t.toId == s?.id))
  }, [currentStep])

  const [targetStepId, setTargetStepId] = useState<WorkflowStep['id']>(targetSteps?.[0]?.id || null)
  const targetStep = useMemo(() => allSteps?.find(s => s.id == targetStepId), [targetStepId])
  useEffect(() => {
    if (targetStep) {
      setValidationData((prev) => ({
        ...prev,
        assignToGroupId: targetStep?.assignGroupId || null,
        validateGroupId: targetStep?.validateGroupId || null,
      }))
    }
    if (revokeStep) {
      setValidationData((prev) => ({
        ...prev,
        assignToGroupId: revokeStep?.assignGroupId || null,
        validateGroupId: revokeStep?.validateGroupId || null,
      }))
    }

    return () => {
    }
  }, [targetStep, currentStep])

  const [invalidData, setInvalidData] = useState(false);


  const [userGroups, setUserGroups] = useState<GroupEntity[]>([]);
  const [openCombobox, setOpenCombobox] = useState<'destination' | 'source' | 'assignee' | 'step' | null>(null);


  const validateGroupOptions: GroupEntity[] = useMemo(() => {
    if (targetStep) {
      return template?.groups?.filter(g => targetStep.validateGroupId == g.id) ?? []
    }
    if (revokeStep) {
      return template?.groups?.filter(g => revokeStep.validateGroupId == g.id) ?? []
    }
    return intersectionByKey(template?.groups ?? [], userGroups, "id")
  }, [template, targetStep, revokeStep, userGroups]);

  const assignToGroupOptions = useMemo(() => {
    if (targetStep) {
      return template?.groups?.filter(g => targetStep.assignGroupId == g.id)
    }
    if (revokeStep) {
      return template?.groups?.filter(g => revokeStep.assignGroupId == g.id)
    }
    return template?.groups?.filter(g => formSteps.find(s => s.assignGroupId == g.id)) ?? []
  }, [template, targetStep, revokeStep, formSteps]);

  const [assignToUserOptions, setAssignToUserOptions] = useState<UserEntity[]>([]);

  const currentValidateGroupOption = validateGroupOptions.find(
    (o) => o.id == validationData.validateGroupId
  );

  const currentAssignToGroupOption = template?.groups.find(
    (o) => o.id == validationData.assignToGroupId
  );
  const currentAssignToUserOption = assignToUserOptions.find(
    (o) => o.id == validationData.assignToUserId
  );

  const navigate = useNavigate();

  const params = useMemo(() => getAllParams(template), [template])

  const toBeValidatedGroup = useMemo(() => {
    return [...form?.validationGroups ?? [], template?.groups?.find(g => g.id == validationData.validateGroupId)].filter(Boolean)
  }, [form?.validationGroups, validationData.validateGroupId])

  const canValidateGroup = (group: GroupEntity): boolean => {
    if (targetStep)
      return group?.id == targetStep.validateGroupId
    if (revokeStep)
      return group?.id == revokeStep.validateGroupId

    return false
  }

  let allParams = useMemo(() => {
    let allParams: FormTemplatePageEntity['params'] = []
    template?.pages?.map(page => allParams.push(...page.params))
    return allParams
  }, [template])

  const unmetConditions: WorkflowTransitionCondition[] = useMemo(() => {
    let result: WorkflowTransitionCondition[] = []
    if (!currentStep)
      return []
    if (!targetStep)
      return []
    const groupsGraph = template?.groupsGraph

    const transition = groupsGraph?.transitions?.find(transition => transition.fromId == currentStep.id && transition.toId == targetStep.id)
    if (transition) {
      result = areConditionsMet(macros, transition.conditions, {
        status: form?.status,
        groups: template?.groups,
        values: paramValues,
        params,
        clauses: [],
        pages: template?.pages,
      })
    }
    const targetStatusIndex = statuses.findIndex(s => s === targetStep.status)
    const doneStatusIndex = statuses.findIndex(s => s === 'Done')
    if (targetStep.validateGroupId && !userGroups.find(g => g.id == targetStep.validateGroupId)) {
      result.push({
        type: 'unauthorized',
      })
    }
    if (targetStatusIndex >= doneStatusIndex && completionPercentage !== 100) {
      result.push({
        type: 'completion',
        requirementId: null
      })
    }
    return result

  }, [validationData])



  const [loading, setLoading] = useState(false);
  const apiClient = useApiClientWithLoading();
  useEffect(() => {
    getUserGroups(apiClient).then(({ rows }) => {
      setUserGroups(rows);
      if (validationData.validateGroupId && !rows.find(g => g.id == validationData.validateGroupId)) {
        setValidationData((prev) => {
          return {
            ...prev,
            validateGroupId: null,
          }
        })
      }
    });
  }, []);

  const formClient = new FormClient(apiClient)
  const groupClient = new GroupClient(apiClient)
  const userClient = new UserClient(apiClient)

  useEffect(() => {
    setAssignToUserOptions([]);
    if (validationData.assignToGroupId) {
      groupClient.getUsers(validationData.assignToGroupId).then(
        ({ rows }) => {
          setAssignToUserOptions(rows);
          if (rows.find((row) => row.id == form?.assignedToUserId))
            setValidationData({
              ...validationData,
              assignToUserId: form?.assignedToUserId,
            });
          else
            setValidationData({
              ...validationData,
              assignToUserId: null,
            });
          setLoading(false);
        }
      );
    } else
      setAssignToUserOptions([])
  }, [validationData.assignToGroupId]);

  const handleInputChange = (field: keyof ValidateReq) => (value) => {
    setValidationData({
      ...validationData,
      [field]: value,
    });
  };


  const submitValidationData = async () => {
    try {
      if (validationData.assignToGroupId && validationData.assignToUserId && validationData.assignToGroupId) {

        setLoading(true);
        if (targetStepId)
          validationData.stepsId.push(targetStepId)
        await formClient.validate(form.id, validationData);
        setLoading(false);
        toast.success(t("modals.validateForm.messages.success"));
        onClose();
      }
      else {
        setInvalidData(true)
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("modals.validateForm.messages.error"));
      console.error(error);
      onClose();
    }
  };

  useEffect(() => {
    setInvalidData(false)
  }, [validationData])


  const handleSelectStep = (stepId: WorkflowStep['id']) => {
    const validationStepIndex = validationData.stepsId.findIndex(id => id === stepId)
    const formStepIndex = form.stepsId?.findIndex(id => id === stepId)
    if (formStepIndex > -1) {
      validationData.stepsId = form.stepsId?.slice(0, formStepIndex + 1)
      setTargetStepId(null)
      setValidationData({
        ...validationData,
      })
    } else if (validationStepIndex > -1) {
      validationData.stepsId = validationData.stepsId.slice(0, validationStepIndex + 1)
      setTargetStepId(null)
      setValidationData({
        ...validationData,
      })

    } else if (targetStepId == stepId) {
      setTargetStepId(null)
    } else if (targetSteps.find(s => s.id === stepId)) {
      setTargetStepId(stepId)
    } else {
      console.warn({
        validationSteps,
        formSteps,
        stepId
      });
    }
  }

  const { groupsGraph, groups = [] } = template ?? {}
  const {
    ref,
    stepNodes, setStepNodes,
    transitionNodes, setTransitionNodes,
    transitions, setTransitions,
    edges, setEdges,
    allNodes,
    menu, setMenu,
    StepNode, TransitionNode, nodeTypes,
    onPaneClick,
    onConnect,
    onReconnect, onReconnectStart, onReconnectEnd,
    refreshWorkflowGraph
  } = useWorkflowGraph({
    groupsGraph,
    groups,
    includeTransitionNodes: false,
    stepNodeStyle(step) {
      if (validationSteps.find(s => s.id == step.id)) {
        if (step.id == currentStep?.id) {
          //step is current step
          return {
            border: '1px solid blue', borderRadius: '5px',
          }
        } else {
          //step is in history
          return {
          }
        }
      }
      if (step.id == targetStep?.id) {
        //step is selected target step
        return {
          border: unmetConditions.length ? '1px solid red' : '1px solid green', borderRadius: '5px',
        }
      }

      return {
      }
    },
    transitionEdge(transition) {
      const step = allSteps.find(s => s.id == transition.toId)
      const fromStep = allSteps.find(s => s.id == transition.fromId)
      let defaultEdge: Partial<Edge> = {
        deletable: false,
      }
      if (!step)
        return defaultEdge
      if (validationSteps.find(s => s.id == step.id)
        && (!fromStep || validationSteps.find(s => s.id == fromStep.id))
      ) {
        if (step.id == currentStep?.id) {
          //step is current step
          return {
            ...defaultEdge,
            animated: false,
          }
        } else {
          //step is in history
          return {
            ...defaultEdge,
            animated: false,
          }
        }
      }
      if (step.id == targetStep?.id &&
        (!fromStep || fromStep.id == currentStep.id)
      ) {

        return {
          ...defaultEdge,
          animated: true,
          style: { stroke: unmetConditions.length ? '#e44545' : '#45e445', strokeWidth: 1 },
        }
      }

      return {
        ...defaultEdge,
        animated: true,
      }
    },
  })
  useEffect(() => {
    refreshWorkflowGraph()
    return () => {
    }
  }, [validationSteps, currentStep, targetStep, !!unmetConditions.length])



  const onNodeClick: NodeMouseHandler = (event: any, element: WorkflowNode) => {
    if (element.type == 'StepNode') {
      const step = element.data
      handleSelectStep(step.id)
    }
  }
  const onNodeContextMenu = useCallback(
    (event, node: WorkflowNode) => {

      // Prevent native context menu from showing
      event.preventDefault();
      if (node.type == 'StepNode') {
      }
    },
    [setMenu],
  );

  // useEffect(() => {
  //   if (reactFlowInstance && allNodes.length > 0) {
  //     // Automatically fit the view to show all nodes
  //     // reactFlowInstance.fitView({ padding: 0.1, includeHiddenNodes: false });
  //   }
  // }, [reactFlowInstance, allNodes]); // Re-run when nodes or instance change


  return (
    <ReactFlowProvider>
      <div
        className="modal-backdrop"
      ></div>
      <div id="formz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content" style={{ width: "960px" }}>
              <div className="modal-header">
                <h5 className="modal-title">{t("modals.validateForm.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body" style={{
                  paddingTop: "0", paddingBottom: "0",
                  marginTop: "0", marginBottom: "0",
                }
                }>
                  <div
                    style={{ paddingBottom: '5%', position: 'relative', height: '30vh' }}
                  >
                    <ReactFlow
                      ref={ref}
                      fitView
                      minZoom={0.25} // Allow zooming out to 10% of the normal size
                      maxZoom={3}   // Allow zooming in to 300% of the normal size
                      nodes={allNodes}
                      edges={edges}
                      style={{ width: '100%', height: '100%' }}
                      onNodeClick={onNodeClick}
                      onConnect={undefined}
                      nodesConnectable={false}
                      nodesDraggable={false}
                      nodeTypes={nodeTypes}
                      onPaneClick={onPaneClick}
                      className="workflow-graph-client"
                    >
                      {menu && <NodeContextMenu
                        {...menu} />}
                      <Background />
                    </ReactFlow>

                  </div>
                  <div className="form-group validate"
                    style={{
                      paddingTop: "0", paddingBottom: "0",
                      marginTop: "0", marginBottom: "0",
                    }}
                  >
                    <div className={`audit-workflow-steps  ${language == "ar" && "audit-workflow-steps-arabic"} `}>
                    </div>
                    <div className="form-group validate">
                      <div className="form-floating mb-3">
                        <CustomCombobox
                          isValid={(invalidData && !validationData.assignToGroupId) ? false : true}
                          label={t("workflow.assignToGroup")}
                          onDropdownOpen={() => { }}
                          options={assignToGroupOptions}
                          value={currentAssignToGroupOption}
                          onChange={handleInputChange("assignToGroupId")}
                          optionValue={(option) => option?.id || null}
                          optionDisplay={(option) => getName(option, language)}
                          isOpen={!targetStep && !revokeStep && openCombobox === "destination"}
                          setIsOpen={(isOpen) => !targetStep && setOpenCombobox(isOpen ? "destination" : null)}
                        />
                      </div>
                      <div className="form-floating mb-3"
                        style={{
                          visibility: targetStep?.validateGroupId ? 'visible' : 'hidden'
                        }}
                      >
                        <CustomCombobox
                          canDeselect={!targetStep && !revokeStep}
                          label={t("workflow.validateGroup")}
                          onDropdownOpen={() => { }}
                          options={validateGroupOptions}
                          value={currentValidateGroupOption}
                          onChange={handleInputChange("validateGroupId")}
                          optionValue={(option) => option?.id || null}
                          optionDisplay={(option) => getName(option, language)}
                          optionIsEnabled={(option) =>
                            canValidateGroup(option)
                          }
                          isOpen={openCombobox === "source"}
                          setIsOpen={(isOpen) => !targetStep && !revokeStep && setOpenCombobox(isOpen ? "source" : null)}
                        />
                      </div>
                      <div className="form-floating mb-3">
                        <CustomCombobox
                          isValid={(invalidData && !validationData.assignToUserId) ? false : true}
                          label={t("modals.validateForm.assignee")}
                          onDropdownOpen={() => { }}
                          options={assignToUserOptions}
                          value={currentAssignToUserOption}
                          onChange={handleInputChange("assignToUserId")}
                          optionValue={(option) => option?.id || null}
                          optionDisplay={(option: UserEntity) => option?.fullName}
                          isOpen={openCombobox === "assignee"}
                          setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "assignee" : null)}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    unmetConditions.length > 0 &&
                    <div>
                      {unmetConditions.map((condition) => {
                        let message = ""
                        switch (condition.type) {
                          // case 'validation':
                          //   const group = template?.groups.find(g => g.id == condition.groupId)
                          //   message = t("modals.validateForm.messages.error.validation")
                          //     .replace("${groupName}", group.name)
                          //   break;
                          case 'status':
                            message = t("modals.validateForm.messages.error.status")
                              .replace("${status}", t("status")[condition.status])
                            break;
                          case 'constraint':
                            message = condition.label
                            break;
                          case 'page':
                            const page = template?.pages?.find(c => c.id == condition.pageId)
                            message = t("modals.validateForm.messages.error.page")
                              .replace("${pageName}", page?.name)
                            break;
                          case 'completion':
                            message = t("modals.validateForm.messages.error.completion")
                            break;
                          case 'unauthorized':
                            const group = template?.groups.find(g => g.id == targetStep.validateGroupId)
                            message = t("modals.validateContract.messages.error.unauthorized")
                              .replace("${groupName}", group.name)
                            break;
                          default:
                            break;
                        }
                        return (
                          <div className="error-message-dialog">
                            {message}
                          </div>
                        )
                      })}
                    </div>
                  }
                </div >
              )}
              <div className="modal-footer">
                <button
                  disabled={unmetConditions.length > 0}
                  type="submit"
                  className="btn btn-primary submit-validate-form-btn"
                  onClick={submitValidationData}
                >
                  {t("modals.validateForm.validate")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactFlowProvider>
  );
}

export default ValidateFormWorkflowModal;
