import "./editFormPage.scss";
import EditForm from "./components/EditForm";
import Loading from "../../components/common/Loading";
import { useContext, useEffect } from "react";
import { LoadingContext } from "../../contexts/LoadingContext";
import { useFormData } from "../../hooks/useFormData";
import EditFormContext from "../../contexts/EditFormContext";
import { useParams } from "react-router-dom";
function EditFormPage() {
  const { isLoading, setLoading } = useContext(LoadingContext);
  const { selectedFormId } = useParams<{ selectedFormId?: string }>() || {};
  const context = useContext(EditFormContext);


  useEffect(() => {
    if (selectedFormId) {
      const parsedId = parseInt(selectedFormId);
      if (!isNaN(parsedId)) {
        context.setFormId(parsedId);
      }
    }
  }, [selectedFormId]);
  useFormData(context);

  return (
    <div className="form-container form-page">
      <div className="d-flex flex-column">
        {isLoading ? (<Loading height="90vh" />) : (
          <EditForm />
        )}
      </div>
    </div>
  );
}

export default EditFormPage;
