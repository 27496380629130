import React, { useEffect, useState } from 'react'
import './reglements.scss'
import { BsSearch } from "react-icons/bs";
import { useTranslation } from '../../contexts/TranslationProvider';
import Loading from '../../components/common/Loading';
import { ReglementClient } from '../../services/rag/ReglementClient'
import AdminRessourceCard from '../../components/common/newDesign/AdminRessourceCard';
import { useNavigate } from "react-router-dom";
import useApiClientWithLoading from '../../services/api/ApiClient';

function ReglementsPage() {
  const navigate = useNavigate();
  const translationPath = "pages.reglements";
  const apiClient = useApiClientWithLoading();
  const reglementClient = new ReglementClient(apiClient)
  const { t, language } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [reglements, setReglements] = useState([]);
  const [searchReglements, setSearchReglements] = useState([]);
  const fetchReglements = async () => {
    try{
      const allReglements = await reglementClient.getAll();
      setReglements(allReglements.rows);
      setSearchReglements(allReglements.rows);
    }catch(errr){
      console.log(errr)
    }
  }
  useEffect(() => {
    fetchReglements()
  }, []);
  const handleClick = (reglementId) => {
    navigate(`/edition-reglement/${reglementId}`)
  };
  const handleDelete = async (reglementId) => {
    try{
      await reglementClient.delete(reglementId);
      fetchReglements()
    }catch(err){
      console.log(err)
    }
  };
  const handleDesactivate = async (reglementId) => {
    console.log(reglementId)
  };
  return (
    <div className="reglements px-5">
      <section id="templates" className="pt-2">
        <span className="current-pathname">
          {t(`${translationPath}.title`)}
        </span>
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar w-75">
              <BsSearch className="search-icon" style={{ left: language == "ar" && "84px" }} />
              <input
                type="text"
                placeholder={t('pages.templates.search.placeholder')}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            <div className="filter d-flex gap-4 align-items-center">

            </div>
          </div>
        </div>
        <div className="mt-5">
          {loading ? (
            <Loading height="75vh" />
          ) : (
            <div className="templates-list-container">
              {
                searchReglements
                  .filter((reglement) => reglement.name.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((reglement) => {
                    return (
                      <div style={{minWidth: "450px"}} key={reglement.id} className="m-2" id={`reglement ${reglement.id}`}>
                        <AdminRessourceCard
                          data={{ type: 'Reglement', ...reglement }}
                          onClick={() => handleClick(reglement.id)}
                          onDelete={() => { handleDelete(reglement.id)}}
                          loading={loading}
                        />
                      </div>
                    )}
          
          )}
          </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default ReglementsPage