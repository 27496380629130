import "../commonmodal.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { ReglementClient } from "../../../services/rag/ReglementClient";
import { ReglementEntity } from "../../../domain/entities";
import InputField from "../../common/newDesign/InputField";
import "./reglement.scss"
import { set } from "react-hook-form";
import useApiClientWithLoading from "../../../services/api/ApiClient";

interface CreateReglementModalType {
  onClose: () => void;
}
function CreateReglementModal({ onClose }: CreateReglementModalType) {
  const modalContentRef = useRef(null);
  const { t, language } = useTranslation();
  const [name, setName] = useState("");
  const [error, setError] = useState([""]);
  const [fieldsErrors, setFieldsErrors] = useState({} as any);
  const [predefinedQuestions, setPredefinedQuestions] = useState([]);
  const [chunkSize, setChunkSize] = useState(null);
  const [similarityThreshold, setSimilarityThreshold] = useState(null);
  const [chunkOverlap, setChunkOverlap] = useState(null);
  const [topK, setTopK] = useState(null);

  const [compressionChunkSize, setCompressionChunkSize] = useState(null);
  const [compressionTopK, setCompressionTopK] = useState(null);
  const [useCompression, setUseCompression] = useState(false);

  const [newQuestion, setNewQuestion] = useState("");
  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const navigate = useNavigate();
  const apiClient = useApiClientWithLoading()
  const reglementClient = new ReglementClient(apiClient)

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!name) {
      setError(["Please enter reglement name"])
      return
    }
    const errors = validateInputs();
    if (Object.keys(errors).length > 0) {
      console.log(errors)
      setFieldsErrors(errors);
      return;
    }
    try {
      const regulationData: ReglementEntity = {
        name,
        predefined_questions: predefinedQuestions,
        chunk_size: chunkSize,
        similarity_threshold: similarityThreshold,
        chunk_overlap: chunkOverlap,
        k: topK,
        compression_chunk_size: compressionChunkSize,
        compression_k: compressionTopK
        }
      const result = await reglementClient.create(regulationData)
      if (result && result.id) {
        onClose()
        navigate("/edition-reglement/" + result.id)
      }
    } catch (err) {
      console.log(err)
      onClose()
    }
  };
  const validateInputs = () => {
    const errors: any = {};

    // Validate chunk size
    if (chunkSize && (chunkSize < 100 || chunkSize > 10000)) {
      errors.chunkSizeError = [t("pages.pilot.popups.reglement.editForm.chunkSize.error1")];
    }
  
    // Validate chunk overlap
    if ((chunkOverlap && chunkSize) && chunkOverlap >= chunkSize) {
      errors.chunkOverlapError = [t("pages.pilot.popups.reglement.editForm.chunkOverlap.error1")];
    }
  
    // Validate similarity thresholds
    if (similarityThreshold && (similarityThreshold < 0.2 || similarityThreshold > 0.8)) {
      errors.similarityThresholdError = [t("pages.pilot.popups.reglement.editForm.similarityThreshold.error1")];
    }
  
    // Validate compression chunk size
    if (compressionChunkSize) {
      if(compressionChunkSize > 1000 || compressionChunkSize < 100){
        errors.compressionChunkSizeError = [t("pages.pilot.popups.reglement.editForm.compressionChunkSize.error1")];
      }
      else if(chunkSize && compressionChunkSize >= chunkSize){
        errors.compressionChunkSizeError = [t("pages.pilot.popups.reglement.editForm.compressionChunkSize.error2")];
      }
    }
  
    // Validate Top K
    if (topK && (topK < 1 || topK > 20)) {
      errors.topKError = [t("pages.pilot.popups.reglement.editForm.topK.error1")];
    }
    if (useCompression && compressionTopK && (compressionTopK < 1 || compressionTopK > 10)) {
      errors.compressionTopKError = [t("pages.pilot.popups.reglement.editForm.compressionTopK.error1")];
    }
    console.log("errors", errors)
    return errors;
  };
  useEffect(() => {
    if(!name){
      setError([t("pages.pilot.popups.reglement.nameError")])
    }else{
      setError([])
    }
  }, [name])
  
  useEffect(() => {
    setFieldsErrors({})
    const errors = validateInputs()
    setFieldsErrors(errors)
  }, [chunkSize, similarityThreshold, chunkOverlap, topK, compressionChunkSize, compressionTopK, useCompression])

  return (
    <>
      <div className="modal-backdrop fade show "></div>
      <div id="contractz-lab" className="reglement-modal">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.pilot.popups.reglement.title")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div className="modal-body modal-body-pilotage" ref={modalContentRef}>
                <div className="reglement-modal-content">
                <p>{t("pages.pilot.popups.reglement.create")}</p>
                <div className="general-info-section">
                  <span className="section-title">
                    {t("pages.pilot.popups.reglement.general")}
                  </span>
                  <div style={{ width: "100%", display: "flex" }}>
                  <InputField
                    onChange={(e) => { setName(e.target.value) }}
                    value={name}
                    placeholder={t("pages.pilot.popups.reglement.reglementNamePlaceHolder")}
                    label={t("pages.pilot.popups.reglement.reglementName")}
                    name="name"
                    type={"text"}
                    error={error}
                  />
                </div>
                <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
                <span className="section-title">
                  {t("pages.pilot.popups.reglement.predifinedQuestions")}:
                </span>
                  <div className="suggestions">
                    {predefinedQuestions.length < 4 && <div onClick={(e) => {
                      e.stopPropagation()
                      !addNewQuestion && setAddNewQuestion(true)
                    }} className="suggest-add" key={-1}>
                      {
                        addNewQuestion ?
                          <>
                            <InputField
                              onChange={(e) => {
                                setNewQuestion(e.target.value)
                              }}
                              value={newQuestion}
                              placeholder={t("pages.pilot.popups.reglement.enterQuestion")}
                              label={t("pages.pilot.popups.reglement.question")}
                              name="question"
                              required={true}
                              type={"text"}
                            />
                            <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                            <button onClick={(e) => {
                              if(newQuestion){
                              setPredefinedQuestions([...predefinedQuestions, newQuestion])
                              }
                              setAddNewQuestion(false)
                              setNewQuestion("")
                            }}
                              style={{ marginTop: "10px" }}
                            >
                              {t("pages.pilot.popups.reglement.add")}
                              </button>
                            <button onClick={(e) => {
                              setAddNewQuestion(false)
                              setNewQuestion("")
                            }}
                              style={{ marginTop: "10px" }}
                            >
                              {t("pages.pilot.popups.reglement.cancel")}
                            </button>
                            </div>
                          </>
                          :
                          t("pages.pilot.popups.reglement.addQuestion")
                      }
                    </div>}
                    {predefinedQuestions.map((question, index) => (
                      <div className="suggest-card" key={index}>
                        <span 
                        className="delete-suggest-card"
                        onClick={()=>{
                          setPredefinedQuestions(predefinedQuestions.filter((item, i) => i !== index))
                        }}
                        >
                          X
                        </span>
                        {question}
                      </div>
                    ))}
                  </div>
                </div>
                </div>
                <span className="section-title">
                  {t("pages.pilot.popups.reglement.hyperParameters")}
                </span>
                <div className="hyper-params-section">
                  <div className="hyper-params-col">
                    <div className="hyper-param-container">
                      <InputField
                        onChange={(e) => { 
                          const value = parseFloat(e.target.value)
                          setChunkSize(value) 
                        }}
                        value={chunkSize}
                        placeholder={t("pages.pilot.popups.reglement.editForm.chunkSize.placeholder")}
                        label={t("pages.pilot.popups.reglement.editForm.chunkSize.name")}
                        name="chunkSize"
                        type={"number"}
                        description={t("pages.pilot.popups.reglement.editForm.chunkSize.description")}
                        error={fieldsErrors.chunkSizeError || []}
                      />
                    </div>
                    <div className="hyper-param-container">
                      <InputField
                        onChange={(e) => { 
                          const value = parseFloat(e.target.value)
                          setSimilarityThreshold(value) 
                        }}
                        value={similarityThreshold}
                        placeholder={t("pages.pilot.popups.reglement.editForm.similarityThreshold.placeholder")}
                        label={t("pages.pilot.popups.reglement.editForm.similarityThreshold.name")}
                        name="similarityThreshold"
                        description={t("pages.pilot.popups.reglement.editForm.similarityThreshold.description")}
                        type={"number"}
                        error={fieldsErrors.similarityThresholdError || []}
                      />
                    </div>
                  </div>
                  <div className="hyper-params-col">
                    <div className="hyper-param-container">
                      <InputField
                        onChange={(e) => { 
                          const value = parseFloat(e.target.value)
                          setChunkOverlap(value)
                         }}
                        value={chunkOverlap}
                        placeholder={t("pages.pilot.popups.reglement.editForm.chunkOverlap.placeholder")}
                        label={t("pages.pilot.popups.reglement.editForm.chunkOverlap.name")}
                        name="chunkOverlap"
                        description={t("pages.pilot.popups.reglement.editForm.chunkOverlap.description")}
                        type={"number"}
                        error={fieldsErrors.chunkOverlapError || []}
                      />
                    </div>
                    <div className="hyper-param-container">
                      <InputField
                        onChange={(e) => { 
                          const value = parseFloat(e.target.value)
                          setTopK(value) 
                        }}
                        value={topK}
                        placeholder={t("pages.pilot.popups.reglement.editForm.topK.placeholder")}
                        label={t("pages.pilot.popups.reglement.editForm.topK.name")}
                        name="topK"
                        description={t("pages.pilot.popups.reglement.editForm.topK.description")}
                        type={"number"}
                        error={fieldsErrors.topKError || []}
                      />
                    </div>
                  </div>
                </div>
                <div className="compression-params-header">
                  <span className="title">
                    {t("pages.pilot.popups.reglement.compressionParameters")}
                  </span>
                  <div className="form-check">
                    <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="flexCheckDefault" 
                    onChange={(e) => setUseCompression(e.target.checked)}
                    />
                  </div>
                </div>
                {useCompression && <div className="hyper-params-section">
                  <div className="hyper-params-col">
                    <div className="hyper-param-container">
                      <InputField
                        onChange={(e) => { 
                          const value = parseFloat(e.target.value)
                          setCompressionChunkSize(value) 
                        }}
                        value={compressionChunkSize}
                        placeholder={t("pages.pilot.popups.reglement.editForm.compressionChunkSize.placeholder")}
                        label={t("pages.pilot.popups.reglement.editForm.compressionChunkSize.name")}
                        name="compressionChunkSize"
                        type={"number"}
                        description={t("pages.pilot.popups.reglement.editForm.compressionChunkSize.description")}
                        error={fieldsErrors.compressionChunkSizeError || []}
                      />
                    </div>
                  </div>
                  <div className="hyper-params-col">
                    <div className="hyper-param-container">
                      <InputField
                        onChange={(e) => { 
                          const value = parseFloat(e.target.value)
                          setCompressionTopK(value) 
                        }}
                        value={compressionTopK}
                        placeholder={t("pages.pilot.popups.reglement.editForm.compressionTopK.placeholder")}
                        label={t("pages.pilot.popups.reglement.editForm.compressionTopK.name")}
                        name="compressionTopK"
                        description={t("pages.pilot.popups.reglement.editForm.compressionTopK.description")}
                        type={"number"}
                        error={fieldsErrors.compressionTopKError || []}
                      />
                    </div>
                  </div>
                </div>}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={onSubmit}>{t("pages.pilot.popups.reglement.createButton")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateReglementModal;
