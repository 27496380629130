import { useContext, useEffect, useState } from "react";
import "./sidebar.scss";
import { useLocation } from "react-router-dom";
import { getAuthUser } from "../../../services/api/UserAuth";
import SidebarItem from "./SidebarItem";
import SidebarUser from "./SidebarUser";
import { useTranslation } from '../../../contexts/TranslationProvider';
import { icons } from "../../../assets/icons/sidebarIcon"
import { ENABLE_PROJECT, REACT_APP_LISTENER_ON } from "../../../config";
import SidebarList from "./SidebarList";
import chat from "../../../assets/new-design-icons/Chat.svg"
import { useNavigate } from "react-router-dom";
import { AI_MANAGE_REGULATION_ON, AI_QUERY_REGULATION_ON } from "../../../config";
import { AuthUserContext } from "../../../contexts/AuthUserContext";

function
  Sidebar({ expanded }) {
  const { authUser, activeRole } = useContext(AuthUserContext)
  const navigate = useNavigate();
  const chatIcon = chat.toString();
  const location = useLocation();
  const { user } = getAuthUser();
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();
  const handleMouseOver = () => {
    if (!expanded)
      setIsHovering(true);
  };
  const handleMouseOut = () => {
    if (!expanded)
      setIsHovering(false);
  };
  useEffect(() => {
    const handlePathnameChange = () => {
      handleMouseOver();
      handleMouseOut();
    };
    handlePathnameChange();
  }, [location.pathname]);

  return (
    <div
      className={"sidebar-container"}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{ position: "relative" }}
    >
      <div className="ps-3 sidebar-wrapper">
        <div className="d-flex flex-column justify-content-between sidebar-basic-items">
          <div className="d-flex flex-column align-items-start gap-3 sidebar-list ">
            {
              activeRole == 'SuperAdmin' &&
              <>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/admin" location={location.pathname} text={t("sidebar.admin")} icon={icons.admin} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/users" location={location.pathname} text={t("sidebar.users")} icon={icons.users} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/properties" location={location.pathname} text={t("sidebar.properties")} icon={icons.templates} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/contract-docx-exports" location={location.pathname} text={t("sidebar.docxExport")} icon={icons.templates} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/form-docx-exports" location={location.pathname} text={t("sidebar.formDocxExport")} icon={icons.templates} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/ressources-status" location={location.pathname} text={t("sidebar.AdminAnalytics")} icon={icons.analytics} />
                {
                  AI_MANAGE_REGULATION_ON ? <SidebarItem isHovering={expanded ? true : isHovering} to="/reglements" location={location.pathname} text={t("sidebar.reglements")} icon={icons.templates} />
                    : null
                }
              </>
            }
            {
              activeRole == 'Admin' &&
              <>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/admin" location={location.pathname} text={t("sidebar.admin")} icon={icons.admin} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/users" location={location.pathname} text={t("sidebar.users")} icon={icons.users} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/admin/list-groups" location={location.pathname} text={t("sidebar.groups")} icon={icons.groups} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/beneficials" location={location.pathname} text={t("sidebar.beneficials")} icon={icons.users} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/admin/import-type-levels" location={location.pathname} text={t("sidebar.typeLevels")} icon={icons.groupContracts} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/properties" location={location.pathname} text={t("sidebar.properties")} icon={icons.templates} />
                <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.templates")} location={location.pathname} destinations={[]}>
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/contract-templates" location={location.pathname} text={t("sidebar.contractTemplates")} icon={icons.templates} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/form-templates" location={location.pathname} text={t("sidebar.formTemplates")} icon={icons.templates} />
                  {ENABLE_PROJECT &&
                    <SidebarItem isHovering={expanded ? true : isHovering} to="/project-templates" location={location.pathname} text={t("sidebar.projectTemplates")} icon={icons.templates} />
                  }
                </SidebarList>
                {
                  AI_MANAGE_REGULATION_ON ? <SidebarItem isHovering={expanded ? true : isHovering} to="/reglements" location={location.pathname} text={t("sidebar.reglements")} icon={icons.templates} />
                    : null
                }
                <SidebarItem isHovering={expanded ? true : isHovering} to="/admin/import-rules" location={location.pathname} text={t("sidebar.rules")} icon={icons.rules} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/tasks" location={location.pathname} text={t("sidebar.tasks")} icon={icons.tasks} />
              </>
            }
            {
              activeRole == 'SeniorManager' &&
              <>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/" location={location.pathname} text={t("sidebar.pilotage")} icon={icons.admin} />
                {ENABLE_PROJECT &&
                  <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.projects")} location={location.pathname} destinations={["/projets", "/group-projets"]}>

                    <SidebarItem isHovering={expanded ? true : isHovering} to="/projets" location={location.pathname} text={t("sidebar.myProjects")} icon={icons.projects} />
                    <SidebarItem isHovering={expanded ? true : isHovering} to="/group-projets" location={location.pathname} text={t("sidebar.ourProject")} icon={icons.projects} />

                  </SidebarList>
                }
                <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.documents")} location={location.pathname} destinations={["/ressources", "/shared-ressources", "/our-documents"]}>
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/ressources" location={location.pathname} text={t("sidebar.myDocuments")} icon={icons.ressources} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/our-documents" location={location.pathname} text={t("sidebar.ourDocuments")} icon={icons.groupContracts} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/shared-ressources" location={location.pathname} text={t("sidebar.sharedDocuments")} icon={icons.ressources} />

                </SidebarList>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/beneficials" location={location.pathname} text={t("sidebar.beneficials")} icon={icons.users} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/ressources-status" location={location.pathname} text={t("sidebar.AdminAnalytics")} icon={icons.analytics} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/tasks" location={location.pathname} text={t("sidebar.tasks")} icon={icons.tasks} />
                {AI_QUERY_REGULATION_ON && (expanded ? true : isHovering) && <div onClick={() => navigate("/chat")} className="lets-chat">
                  <div className="container">
                    <img src={chatIcon} alt="chat" className="icon" />
                    <h5 className="title">
                      {t("sidebar.letsChat")}
                    </h5>
                    <div className="triangle"></div>
                  </div>
                </div>
                }
              </>
            }
            {
              activeRole == 'Client' &&
              <>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/" location={location.pathname} text={t("sidebar.pilotage")} icon={icons.admin} />
                {ENABLE_PROJECT &&
                  <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.projects")} location={location.pathname} destinations={["/projets", "/group-projets"]}>

                    <SidebarItem isHovering={expanded ? true : isHovering} to="/projets" location={location.pathname} text={t("sidebar.myProjects")} icon={icons.projects} />
                    <SidebarItem isHovering={expanded ? true : isHovering} to="/group-projets" location={location.pathname} text={t("sidebar.ourProject")} icon={icons.projects} />

                  </SidebarList>
                }
                <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.documents")} location={location.pathname} destinations={["/ressources", "/shared-ressources", "/our-documents"]}>
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/ressources" location={location.pathname} text={t("sidebar.myDocuments")} icon={icons.ressources} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/our-documents" location={location.pathname} text={t("sidebar.ourDocuments")} icon={icons.groupContracts} />
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/shared-ressources" location={location.pathname} text={t("sidebar.sharedDocuments")} icon={icons.ressources} />

                </SidebarList>
                <SidebarItem isHovering={expanded ? true : isHovering} to="/beneficials" location={location.pathname} text={t("sidebar.beneficials")} icon={icons.users} />
                <SidebarItem isHovering={expanded ? true : isHovering} to="/tasks" location={location.pathname} text={t("sidebar.tasks")} icon={icons.tasks} />
                {AI_QUERY_REGULATION_ON && (expanded ? true : isHovering) && <div onClick={() => navigate("/chat")} className="lets-chat">
                  <div className="container">
                    <img src={chatIcon} alt="chat" className="icon" />
                    <h5 className="title">
                      {t("sidebar.letsChat")}
                    </h5>
                    <div className="triangle"></div>
                  </div>
                </div>
                }
              </>
            }
            {
              activeRole == 'CoContractor' &&
              <>
                <SidebarList isHovering={expanded ? true : isHovering} text={t("sidebar.documents")} location={location.pathname} destinations={["/ressources", "/shared-ressources"]}>
                  <SidebarItem isHovering={expanded ? true : isHovering} to="/shared-ressources" location={location.pathname} text={t("sidebar.sharedDocuments")} icon={icons.ressources} />

                </SidebarList>
              </>
            }
          </div>
        </div>
        <div className="d-flex flex-column align-items-start gap-3 support">
          <SidebarItem isHovering={expanded ? true : isHovering} to="/settings" location={location.pathname} text={t("sidebar.support")} icon={icons.support} />
          <SidebarUser isHovering={expanded ? true : isHovering} user={user} />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
