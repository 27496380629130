import React, { createContext } from "react";
import { AiFileEntity, AiFileConversationEntity } from "../domain/entities";


export interface ChatWithDocumentContextType {
conversations: AiFileConversationEntity[];
setConversations: React.Dispatch<React.SetStateAction<AiFileConversationEntity[]>>;
currentConversation: AiFileConversationEntity | null;
setCurrentConversation: React.Dispatch<React.SetStateAction<AiFileConversationEntity | null>>;
pendingQuestion: any;
setPendingQuestion: React.Dispatch<React.SetStateAction<any>>;
handleQueryChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
handleSubmit: (incomingQuery: string) => void;
query: string;
handleDelete: (e: React.MouseEvent<SVGElement, MouseEvent>, conversation: AiFileConversationEntity) => void;
currentFile: AiFileEntity | null;
setCurrentFile: React.Dispatch<React.SetStateAction<AiFileEntity | null>>;
}

const ChatWithDocumentContext = createContext<ChatWithDocumentContextType>({

} as any);

export default ChatWithDocumentContext;