import { useContext, useRef, useState, useEffect } from "react";
import InputValuesContext from "../../../contexts/InputValuesContext";
import RenderSegmentedText from "../../../components/RenderSegmentedText";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { ClauseScoringResultEntity } from "../../../domain/entities";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { RessourceScoringClient } from "../../../services/api/RessourceScoring";
import FlagCombobox from "../../../components/common/newDesign/CustomCombobox/FlagCombobox";
import FlagSelector from "./FlagSelector";

function AIResponseOnClause({
  response,
}: {
  response: ClauseScoringResultEntity;
}) {
  const { t, language } = useTranslation();
  const [comment, setComment] = useState<ClauseScoringResultEntity["comment"] | null>(response.comment);
  const [chosenLanguage, setChosenLanguage] = useState<"en" | "fr" | "ar">(language);
  const [chosenComment, setChosenComment] = useState<ClauseScoringResultEntity["comment"][0] | null>(comment && Array.isArray(comment) ? comment.find((c) => c.lang === chosenLanguage) : null);
  useEffect(() => {
    setComment(response.comment);
  }, [response.comment]);
  useEffect(() => {
    setChosenComment(comment && Array.isArray(comment) ? comment.find((c) => c.lang === chosenLanguage) : null);
  }, [comment, chosenLanguage]);

  return (
    <>
        <div className="compliance-description-container mt-4 mb-2">
          <div className="compliance-description-header">
            <strong>{t("contractCompliancePage.aiResponse.analysis")}</strong>
            <FlagSelector
              optionValue={(option) => option?.language || null}
              optionDisplay={(option) => option?.flag || ""}
              language={chosenLanguage}
              handleLanguageChange={(value) => setChosenLanguage(value)}
            />
          </div>
          <div style={{ direction: chosenLanguage === "ar" ? "rtl" : "ltr" }}>
          {
            chosenComment && (<>
              {chosenComment.differences}
              <br />
              <br />
              {chosenComment.legalImplications}
              <br />
              <br />
              {chosenComment.recommendations}
            </>)
          }
            </div>
        </div>
    </>
  );
}

export default AIResponseOnClause;
