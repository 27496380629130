import React, { useEffect } from 'react';
import RenderTemplateSingleSegmentedText from './RenderTemplateSingleSegmentedText';
import { SegmentedTextType } from '../../../domain/types/ClauseParams';

function RenderTemplateSegmentedTable({ tableSegments, segmentation, changedParamBoundaries, isSelected, style = {} }) {
    const rows = [];
    let currentRow = null;
    const cells = [];
    let currentCell = { cellSegments: [], cellStyle: {} };

    tableSegments.forEach(segment => {
        const { type, id, value } = segment;

        // Start a new row
        if (type === SegmentedTextType.STATIC_TABLE_ROW_START) {
            currentRow = []; // Initialize a new row
        }

        // Add cells to the current row
        if (type === SegmentedTextType.STATIC_TABLE_CELL_START) {
            currentCell = { cellSegments: [], cellStyle: segment.style }; // Initialize a new cell
        }

        // Add content to the current cell
        if (type === SegmentedTextType.STATIC || type === SegmentedTextType.PARAM || type === SegmentedTextType.COMMENT || type === SegmentedTextType.PARAM_VALUE) {
            currentCell.cellSegments.push(segment);
        }

        // End the cell
        if (type === SegmentedTextType.STATIC_TABLE_CELL_END) {
            if (currentCell) {
                currentRow.push(currentCell);
                currentCell = null;
            }
        }

        // End the row
        if (type === SegmentedTextType.STATIC_TABLE_ROW_END) {
            if (currentRow) {
                rows.push(currentRow);
                currentRow = null;
            }
        }
    });

    return (
        <table className="segmented-table" style={{ borderCollapse: 'collapse', width: '100%', ...style }}>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map(({cellSegments, cellStyle}, cellIndex) => (
                            <td key={cellIndex} style={{ border: '1px solid black', padding: '8px', ...cellStyle, ...style, }}>
                                {cellSegments.map((segment) => (
                                    <>
                                        <RenderTemplateSingleSegmentedText
                                            key={segment.id}
                                            idx={(segment as any).actualIdx}
                                            segment={segment}
                                            isSelected={isSelected}
                                            segmentation={segmentation}
                                            changedParamBoundaries={changedParamBoundaries}
                                        />
                                    </>
                                ))}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default RenderTemplateSegmentedTable;
