import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { BeneficialMacroEntity } from "../../domain/entities/BeneficialMacro";


export class BeneficialMacroClient extends ModelClient<BeneficialMacroEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'BeneficialMacro')
  }
};

