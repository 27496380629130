import React, { useState, useRef, useCallback } from 'react'
import AskYourQuestionsContext from '../../../contexts/AskYourQuestionsContext'
import chat from "../../../assets/new-design-icons/Chat.svg"
import ChatSuggestions from './ChatSuggestions'
import Loading from '../../../components/common/Loading'
import { useTranslation } from '../../../contexts/TranslationProvider'
import ChatQuery from './ChatQuery'

function ConversationChatBody({ listRef, scorllRef }) {
  const { currentConversation, pendingQuestion } = React.useContext(AskYourQuestionsContext)
  const chatIcon = chat.toString()
  const { t } = useTranslation()

  return (
    <>
      <div ref={listRef} className='body-chats-container'>
        {
          (!currentConversation || !currentConversation.messages.length) && !pendingQuestion &&
          <div className='body-start-chat'>
            <div className='start-chat-title'>
              <img src={chatIcon} alt="icon" style={{ width: "150px", height: "150px" }} />
              <span className='title'>
                {t("pages.chat.start")}
              </span>
            </div>
            <ChatSuggestions />
          </div>

        }
        {
          currentConversation &&
          currentConversation.messages &&
          currentConversation.messages.map((qNa, index) => {
            const breakedHTMLText = qNa.answer?.replaceAll(/\n/g, "<br/>").replaceAll(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
            return <div key={index} className='body-chat'>
              <div className='question-container'>
                <div className='question-wrapper'>
                  <div className='added-file'>
                  </div>
                  <div className='question'>
                    {qNa.question}
                  </div>
                </div>

              </div>
              <div className='answer'>
                <span dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
              </div>
            </div>
          }
          )
        }
        {
          pendingQuestion &&
          <div ref={scorllRef} className='body-chat'>
            <div className='question-container'>
              <div className='question-wrapper'>
                <div className='added-file'>

                </div>
                <div className='question'>
                  {pendingQuestion.question}
                </div>
              </div>

            </div>
            <div className='answer'>
              {
                !pendingQuestion.response && <Loading height="7vh" />
              }
              <span dangerouslySetInnerHTML={{ __html: pendingQuestion?.response?.replaceAll(/\n/g, "<br/>").replaceAll(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') }}></span>
            </div>
          </div>
        }
      </div>
      <ChatQuery />
    </>
  )
}

export default ConversationChatBody