import React from 'react'
import "./comingSoon.scss"
import { useTranslation } from '../../../contexts/TranslationProvider';
import Logo from "../../../assets/Logo.svg"
function ComingSoon() {
    const { t } = useTranslation();
    return (
        <div className='comingSoon-container'>
            <img src={String(Logo)} width={150} className='img'/>
            <div className='comingSoon-sub-content'>
                <h1 className='header'>{t("pages.topic.comingSoon")}!</h1>
              
               
            </div>
        </div>
    )
}

export default ComingSoon