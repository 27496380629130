import "../commonmodal.scss";
import React, {useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from '../../../contexts/TranslationProvider';
import CreateContractComparison from "./createContractComparaison";
import ChatWithDocument from "./ChatWithDocument";
import CreateDocumentRegulatory from "./CreateDocumentRegulatory";
import { AI_COMPARE_CONTRACTS_ON, AI_CHAT_WITH_DOCUMENT_ON, AI_CONFORMITY_CHECK_ON } from "../../../config";

interface EvaluateModalType {
  onClose: () => void;
}
function EvaluateModal({ onClose }: EvaluateModalType) {
    const [operation, setOperation] = useState<'Compare' | 'Chat' | 'Regulatory'>('Compare');
  const { t, language } = useTranslation();
  
  return (
    <>
      <div
        className="modal-backdrop fade show"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{t("evaluateModal.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              <div style={{ overflowY: "hidden" }} className="modal-body modal-body-pilotage">
                    <div className="modal-body-multi-choice">
                      {AI_COMPARE_CONTRACTS_ON && <div className={`modal-body-choice-${operation == 'Compare' ? "active" : "inactive"}`} onClick={() => setOperation('Compare')}>
                        {t("evaluateModal.compare.title")}
                      </div>}
                      {AI_CHAT_WITH_DOCUMENT_ON && <div className={`modal-body-choice-${operation == 'Chat' ? "active" : "inactive"}`} onClick={() => setOperation('Chat')}>
                      {t("evaluateModal.chat.title")}
                      </div>}
                      {AI_CONFORMITY_CHECK_ON && <div className={`modal-body-choice-${operation == 'Regulatory' ? "active" : "inactive"}`} onClick={() => setOperation('Regulatory')}>
                       {t("evaluateModal.regulatory.title")}
                      </div>}
                    </div>
                  {operation=="Compare" && AI_COMPARE_CONTRACTS_ON && <>
                    <CreateContractComparison  />
                  </>}
                  {operation=="Chat" && AI_CHAT_WITH_DOCUMENT_ON && <>
                    <ChatWithDocument  onClose={onClose} />
                  </>}
                  {operation=="Regulatory" && AI_CONFORMITY_CHECK_ON && <>
                    <CreateDocumentRegulatory  />
                  </>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EvaluateModal;
