import React, { useState } from 'react'
import { ClauseEntity } from '../../../../domain/entities'
import './generateClauseParams.scss'
import { GenerateClauseParamType } from '../../../../domain/types/Clause'
import { useTranslation } from '../../../../contexts/TranslationProvider'
import PromptInput from '../PromptInput'
import Loading from '../../../common/Loading'

function GenerateClauseParamsSection({
  loading,
  clause,
  generateParamsWithAIHandler
}: {
  loading: boolean,
  clause: ClauseEntity,
  generateParamsWithAIHandler: (promptTypes: any, comments: string) => any
}) {
  const { t } = useTranslation()
  const translationPath = "pages.contractTemplateParam.generateAIportal"
  const genrateTranslationPath = 'modals.clauseParamGenerate.'
  const [promptIDs, setPromptIDs] = useState([])
  const [errors, setErrors] = useState([])
  const [comments, setComments] = useState("")
  const handleGenerate = () => {
    if (promptIDs.length === 0) {
      setErrors([t(translationPath + ".error")])
      return
    }
    setErrors([])
    let promptTypes: GenerateClauseParamType[] = []
    promptIDs.forEach((promptID) => {
      switch (promptID) {
        case 0:
          promptTypes.push('BOOLEAN')
          break
        case 1:
          promptTypes.push('BENEFICIAL')
          break
        case 2:
          promptTypes.push('TABLE')
          break
        case 3:
          promptTypes.push('PRIMITIVE')
          break
      }
    })
    setComments('')
    generateParamsWithAIHandler(promptTypes, comments)
  }

  const prompts = [
    {
      display: t(genrateTranslationPath + 'enumAndBoolean'),
      name: "EnumAndBoolean",
      id: 0
    },
    {
      display: t(genrateTranslationPath + 'beneficial'),
      name: "Beneficial",
      id: 1
    },
    {
      display: t(genrateTranslationPath + 'table'),
      name: "Table",
      id: 2
    },
    {
      display: t(genrateTranslationPath + 'primitive'),
      name: "Primitive",
      id: 3
    }
  ]
  const handleChange = (id: number) => {
    setErrors([])
    if (promptIDs.includes(id)) {
      setPromptIDs(promptIDs.filter((promptID) => promptID !== id).sort())
    } else {
      setPromptIDs([...promptIDs, id].sort())
    }
  }

  return (
    <div className='generate-params-section' style={{ position: loading ? "relative" : "initial" }}>
      {
        loading && <div className="loading-backdrop">
          <Loading height="50vh" />
        </div>
      }
      <div className='generate-params-prompt-select-section'>
        <div className='generate-params-prompt-select-title'>
          {t(genrateTranslationPath + 'title')}
        </div>
        <div className="generate-ai-content">
          <div className='generate-ai-prompt-checkbox-container'>
            {prompts.map((prompt, index) => {
              return (
                <>
                  <div
                    className={`generate-ai-prompt-checkbox ${promptIDs.includes(prompt.id) ? 'generate-ai-prompt-checkbox-active' : ''}`}
                    key={index}
                    onClick={() => handleChange(prompt.id)}
                  >
                    <div className='generate-ai-prompt-checkbox-label'>
                      {prompt.display}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
          {errors.length > 0 && <div className="generate-ai-errors">{errors.map((error, index) => <div key={index}>{error}</div>)}</div>}
        </div>
      </div>
      <PromptInput
        handleSubmit={() => handleGenerate()}
        loading={false}
        triggerSpecialAction={() => { }}
        query={comments}
        handleQueryChange={(query) => setComments(query)}
        specialActionOn={false}
        enableSpecialAction={false}
      />
    </div>
  )
}

export default GenerateClauseParamsSection