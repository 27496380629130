import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { DocumentUseCaseClient } from "../../../services/api/DocumentUseCaseClient";
import Loading from "../../common/Loading";
import SVGRender from "../../../helpers/SVGRender";
import { useCaseIcons } from "../../../assets/icons/useCaseIcons";
import ImportDocumentModal from "../ImportDocumentModal";
import ComingSoon from "../ComingSoon";
import CheckBoxInput from "../../common/CheckBoxInput";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.scss";
import { DocumentClient } from "../../../services/api/DocumentClient";
import FileInput from "../../common/FileInput";
import ShowCase from "../../ShowCase";
import { RessourceScoringClient } from "../../../services/api/RessourceScoring";
import { RessourceScoringEntity } from "../../../domain/entities";

interface CreateDocumentRegulatoryType {}
function CreateDocumentRegulatory({}: CreateDocumentRegulatoryType) {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const [topics, setTopics] = useState([]);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [document, setDocument] = useState<File>(null);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [loading, setLoading] = useState(false);
  const usecases = t("pages.topic.usecases");
  const [isScanned, setIsScanned] = useState(false);
  const apiClient = useApiClientWithLoading();
  const documentUseCaseClient = new DocumentUseCaseClient(apiClient);
  const documentClient = new DocumentClient(apiClient);
  const ressourceScoringClient = new RessourceScoringClient(apiClient);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await documentUseCaseClient.getAll(language, "nested");
        const sortedTopics = data.rows.sort((a, b) => (b.activated ? 1 : -1));
        setTopics(sortedTopics);
        setLoading(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [language]);

  useEffect(() => {
    if (selectedTopic !== null) {
      setSelectedUseCase(null);
    }
  }, [selectedTopic]);

  const handleUseCaseClick = (index) => {
    setSelectedUseCase(index);
  };
  const handleTopicClick = (index) => {
    setSelectedTopic(index);
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    try {
      setLoading(false);
      if (document) {
        const {
          row: { id: documentId,name:documentName },
        } = await documentClient.upsertMultiForm({
          file: document,
          name: document.name,
          isScanned,
        });
        const response = await documentUseCaseClient.invokeUseCase(
          topics[selectedTopic]?.id,
          documentId
        );

        const data:RessourceScoringEntity={
          name:documentName,
          operationType:"COMPLIANCE",
          documentId:response.row.documentId,
          useCaseResponseId:response?.row.id
        }
        const ressourceScoring = await ressourceScoringClient.create(data)
        navigate(`/ai-document-response/${response?.row?.id}`);
      }
    } catch (error) {
      console.error("Error fetching AI contract data:", error);
      toast.error(t("pages.pilot.popups.contract.errorImport"));
    } finally {
      setLoading(true);
    }
  };
  if (!loading) {
    return <Loading  height="40vh"/>;
  }
  return (
    <>
      <section
        style={{ paddingRight: "1rem" }}
        id="main-function"
        className={` ${
          selectedTopic !== null && !topics[selectedTopic]?.activated
            ? "coming-soon-message"
            : ""
        }`}
      >
        <form
          action=""
          className="form-group"
          style={{
            maxHeight: "500px",
            marginBottom: "70px",
            overflowY: "auto",
            overflowX: "hidden",
            paddingLeft: "20px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="topics-container-modal">
            {topics?.map((topic, index) => (
              <div
                key={index}
                onClick={() => handleTopicClick(index)}
                className={`topic-item ${
                  selectedTopic === index ? "activated" : ""
                }`}
              >
                <span>{topic?.name}</span>
              </div>
            ))}
          </div>

          {selectedTopic !== null && topics[selectedTopic]?.activated ? (
            <>
								<div className="modal-body mt-3">
									<div className="form-floating">
										<FileInput
											label={t("pages.listener.popups.question.add_doc")}
											onChange={(file) => setDocument(file)}
											value={document}
											borderClassName={document ? "green-border" : "grey-border"}
										/>
									</div>
									<div className="form-floating mb-3">
										<CheckBoxInput
											checked={isScanned}
											name={t("pages.listener.popups.contract.inputs.isScanned")}
											onChange={() => setIsScanned(!isScanned)}
											disabled={false}
										/>
									</div>
								
								</div>
							
                <div className="modal-footer footer-form-pilotage">
             
                
                <button className="btn btn-primary">
                  {t("pages.topic.action")}
                </button>
              </div>
            </>
          ) : (
            <ComingSoon />
          )}
        </form>
      </section>
    </>
  );
}

export default CreateDocumentRegulatory;
