import React, {useEffect, useRef} from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";

const ProjectTemplateCardActionsModal = ({ onDelete, onEdit, onView, onDesactivate, onClose, isOpen}:{
  onDelete?: () => void;
  onEdit?: () => void;
  onView?: () => void;
  onDesactivate?: () => void;
  onClose: () => void;
  isOpen: boolean;

}) => {
  const { t, language } = useTranslation();
  
  const actionCaller = (e, action) => {
    e.stopPropagation();
    action();
  };
  const modalRef = useRef(null);
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      event.stopPropagation()
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  return (
    <div className="popup" ref={modalRef} style={{ zIndex: "99" }}>
    <div className="contract-card-actions-modal" style={{ marginRight: language === "ar" && "-30px" }}>
      <ul>
      {onView && (
          <li onClick={(e) => {actionCaller(e, onView) ;e.preventDefault(); e.stopPropagation()}}>
            {t("pages.ressources.actions.view")}
          </li>
        )}
        {onEdit && (
          <li onClick={(e) => actionCaller(e, onEdit)}>
            {t("pages.ressources.actions.edit")}
          </li>
        )}
         <li onClick={(e) => actionCaller(e, onDelete)}>
          {t("pages.ressources.actions.delete")}
        </li>
          {
          onDesactivate && <li onClick={(e) =>{actionCaller(e, onDesactivate)
             ;e.preventDefault(); e.stopPropagation()}}>{t("pages.ressources.actions.desactivate")}</li>
        }
      </ul>
    </div>
    </div>
  );
};

export default ProjectTemplateCardActionsModal;
