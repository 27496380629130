import { useTranslation } from '../../../../contexts/TranslationProvider';
import '../buttons.scss'


function EditBeneficialMacrosButton({onClick}) {
  const { t } = useTranslation();
  const translationPath = "modals.editMacros.";
  return (
    <button className="navbar-button" type="submit" onClick={onClick}>
      {t(translationPath + "title")}
    </button>
  );
}

export default EditBeneficialMacrosButton;
