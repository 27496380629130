import { useEffect, useRef, useState } from "react";
import "./searchableDropdown.scss";
import { FaChevronDown } from "react-icons/fa";

interface SearchableDropdownProps {
  options: any[]; // Replace with a more specific type
  label: string;
  id: string;
  selectedVal: any[];
  handleChange: (val: any[]) => void;
  multi?: boolean;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options = [],
  label,
  id,
  selectedVal = [],
  handleChange,
  multi = false,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery("");

    if (multi) {
      const isSelected = selectedVal.some((val) => val[id] === option[id]);

      if (isSelected) {
        // Remove if already selected
        handleChange(selectedVal.filter((val) => val[id] !== option[id]));
      } else {
        // Add new selection
        handleChange([...selectedVal, option]);
      }
    } else {
      handleChange(option);
      setIsOpen(false);
    }
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (multi && Array.isArray(selectedVal)) {
      return "";
    }
    return selectedVal ? selectedVal[label] : "";
  };

  const filter = (options) => {
    return options.filter(
      (option) =>
        option[label]?.toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            autoComplete="off"
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              if (!multi) handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <FaChevronDown className="arrow" size={12} />
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => {
          const isSelected = selectedVal.some((val) => val[id] === option[id]);
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${isSelected ? "selected" : ""}`}
              key={`${id}-${index}`}
            >
              {option[label]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
