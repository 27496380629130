import { Descendant } from 'slate'
import { RenderSegment, RenderSegments, SegmentedText, SegmentedTextType, segmentedTextStyle } from '../../../../../../domain/types/ClauseParams'
import { useEffect } from "react";
import { ClauseEntity } from '../../../../../../domain/entities';

export const buildBlocks = (segments, params) => {
  try{
    if(!segments || segments.length === 0) return [{ type: 'paragraph', children: [{ text: '', id: "1" }], align: "left" }];
    const newBlocks = []; // Final array of paragraphs and tables
    const firstAlignment = segments[0].type === SegmentedTextType.PARAGRAPH_START ? segments[0].textAlign : null;
    let currentParagraph: any = { type: 'paragraph', children: [], align: firstAlignment ?? "left", tableName: undefined }; // Initialize a new paragraph
    let children = [];
    let currentTable = { type: 'table', children: [], border: "#000000" };
    let currentList = { type: 'bulleted-list', children: [] };
    let currentListItem = { type: 'list-item', children: [], align: "left" };
    let currentRow = null;
    let currentCell = null;
    let insideTable = false; // Flag to check if we are inside a table
    let insideList = false; // Flag to check if we are inside a list
  
    const processSegmentContent = (segment, params) => {
      if (segment.type === SegmentedTextType.STATIC) {
        const isHighlighted = segment.isHighlighted || false;
        const styles = segment.style || {};
        return {
          text: segment.value,
          id: segment.id,
          highlight: isHighlighted,
          ...styles
        };
      }
  
      if (segment.type === SegmentedTextType.PARAM) {
        const param = params.find(p => p.name === segment.paramName);
        const label = param?.label || '';
        const fullLabel = label;
          const style = segment.style || {};
        return {
          type: 'mention',
          character: fullLabel,
          paramName: segment.value,
          id: segment.id,
          children: [{ text: segment.paramName }],
          ...style
        };
      }
  
      if (segment.type === SegmentedTextType.PARAM_VALUE) {
        const paramValueStyle = segment.style || {};
        return {  
          type: 'mention',
          character: segment.value,
          paramName: segment.value,
          id: segment.id,
          children: [{ text: segment.value }],
          ...paramValueStyle
      }
    }
      if (segment.type === SegmentedTextType.COMMENT) {
        return {
          text: segment.value,
          id: segment.id,
          comment: true
        };
      }
  
      return null;
    };
  
    segments.forEach((segment) => {
      // Handle PARAGRAPH_START by starting a new paragraph
      if (!insideTable && segment.type === SegmentedTextType.PARAGRAPH_START) {
        if(insideList){
          if(currentListItem.children.length > 0){
            currentList.children.push(currentListItem);
            currentListItem = { type: 'list-item', children: [], align: "left" };
          }
          if(currentList.children.length > 0){
            newBlocks.push(currentList);
            currentList = { type: 'bulleted-list', children: [] };
          }
          insideList = false;
        }
        if (children.length > 0) {
          currentParagraph.children = children;
          newBlocks.push(currentParagraph);
          children = [];
        }
        currentParagraph = { type: 'paragraph', children: [], align: segment?.style?.textAlign }; // Start a new paragraph
      }
  
      // Handle STATIC segments and PARAMs for paragraphs if we are outside the table and outside the list
      if (!insideTable && !insideList && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT || segment.type === SegmentedTextType.PARAM_VALUE)) {
        children.push(processSegmentContent(segment, params));
      }
  
      // Start of STATIC_TABLE
      if (segment.type === SegmentedTextType.STATIC_TABLE_START) {
        insideTable = true; // We are now inside a table
        insideList = false; // We are not inside a list
        // Push the current paragraph if it has children
        if (children.length > 0) {
          currentParagraph.children = children;
          newBlocks.push(currentParagraph);
          children = [];
          currentParagraph = { type: 'paragraph', children: [], align: "left" }; // Reset paragraph
        }
        if(currentListItem.children.length > 0){
          currentList.children.push(currentListItem);
          currentListItem = { type: 'list-item', children: [], align: "left" };
        }
        if(currentList.children.length > 0){
          newBlocks.push(currentList);
          currentList = { type: 'bulleted-list', children: [] };
        }
  
        currentTable = { type: 'table', children: [], border: segment?.style?.borderColor }; // Initialize a new table
        return;
      }
  
      // Start of a Table Row
      if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_ROW_START) {
        currentRow = { type: 'table-row', children: [] }; // Initialize a new row
        return;
      }
  
      // Start of a Table Cell
      if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_CELL_START) {
        currentCell = { type: 'table-cell', children: [], align: segment?.style?.textAlign ?? "left", borderColor: segment?.style?.borderColor ?? "#000000", tableName: segment.paramName, rowIdx: segment.rowIdx, cellIdx: segment.cellIdx };
        return;
      }
  
      // Handle content inside a cell
      if (insideTable && currentCell && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT || segment.type === SegmentedTextType.PARAM_VALUE)) {
        currentCell.children.push(processSegmentContent(segment, params)); // Add content to the cell
        return;
      }
  
      // End of a Table Cell
      if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_CELL_END) {
        currentRow.children.push(currentCell); // Add cell to the row
        currentCell = null; // Reset cell
        return;
      }
  
      // End of a Table Row
      if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_ROW_END) {
        currentTable.children.push(currentRow); // Add row to the table
        currentRow = null; // Reset row
        return;
      }
  
      // End of STATIC_TABLE
      if (insideTable && segment.type === SegmentedTextType.STATIC_TABLE_END) {
        newBlocks.push(currentTable); // Push the completed table to the blocks
        currentTable = null; // Reset table
        insideTable = false; // Exit table mode
        return;
      }
  
      // Handle content inside a list element
      if (insideList && (segment.type === SegmentedTextType.STATIC || segment.type === SegmentedTextType.PARAM || segment.type === SegmentedTextType.COMMENT || segment.type === SegmentedTextType.PARAM_VALUE)) {
        currentListItem.children.push(processSegmentContent(segment, params));
        return;
      }
  
      // Handle LIST_ELEMENT_START
      if (segment.type === SegmentedTextType.LIST_ITEM_START) {
        if(insideList){
          if(currentListItem.children.length > 0){
            currentList.children.push(currentListItem);
            currentListItem = { type: 'list-item', children: [], align: segment?.style?.textAlign };
          }
          const isListNew = segment.style.listType ? true : false;
          if(isListNew){
            if(currentList.children.length > 0){
              newBlocks.push(currentList);
              currentList = { type: segment.style.listType, children: [] };
            }
          }
        }else{
          if (children.length > 0) {
            currentParagraph.children = children;
            newBlocks.push(currentParagraph);
            children = [];
            currentParagraph = { type: 'paragraph', children: [], align: "left" }; // Reset paragraph
          }
          currentList = { type: segment?.style?.listType, children: [] };
          currentListItem = { type: 'list-item', children: [], align: segment?.style?.textAlign };
          insideList = true;
        }
      }
  
    });
  
    // handle remaining list elements 
    if(insideList){
      if(currentListItem.children.length > 0){
        currentList.children.push(currentListItem);
        currentListItem = { type: 'list-item', children: [], align: "left" };
      }
      if(currentList.children.length > 0){
        newBlocks.push(currentList);
      }
    }
  
    // Add any remaining paragraph content after processing segments
    if (children.length > 0 && !insideTable) {
      currentParagraph.children = children;
      newBlocks.push(currentParagraph);
    }
  
    return newBlocks;
  }catch(e){
    console.log(e)
  }
};




export function useOutsideAlerter(ref, func, currentSegment) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func, currentSegment]);
}
export function blockToSegment(block) {
  if (block.type === 'mention') {
    return {
      id: block.id,
      value: block.character,
      paramName: block.paramName,
      type: SegmentedTextType.PARAM
    }
  }
  if (block.comment) {
    return {
      id: block.id,
      value: block.text,
      type: SegmentedTextType.COMMENT
    }
  }
  return {
    id: block.id,
    value: block.text,
    type: SegmentedTextType.STATIC
  }
}

// recursive function to convert blocks to segments
export function blocksToSegments(blocks, parent = null): ClauseEntity['segmentation']['segmentedText'] {
  const segments: ClauseEntity['segmentation']['segmentedText'] = []

  if (parent?.type === 'paragraph') {
    segments.push([
      "new-paragraph",
      null,
      SegmentedTextType.PARAGRAPH_START,
      {
        textAlign: parent.align,
      }
    ]);
  }

  blocks.forEach((block, index) => {
    if (parent && (parent.type === 'bulleted-list' || parent.type === 'numbered-list')) {
      if (block.type === 'list-item') {
        segments.push([
          "new-list-item",
          null,
          SegmentedTextType.LIST_ITEM_START,
          {
            listType: index === 0 ? parent.type : null,
            textAlign: block.align,
          }
        ]);
      }
    }

    if (block.id) {
      if (block.type === 'mention') {
        segments.push([
          block.id,
          block.paramName,
          SegmentedTextType.PARAM,
          {
            bold: block.bold,
            italic: block.italic,
            underline: block.underline,
          }
        ]);
      }
      if (block.comment) {
        segments.push([
          block.id,
          block.text,
          SegmentedTextType.COMMENT,
        ]);
      }
      if (block.text || block.text === '') {
        segments.push([
          block.id,
          block.text,
          SegmentedTextType.STATIC,
          {
            bold: block.bold,
            italic: block.italic,
            underline: block.underline,
          },
        ]);
      }
    }

    // Recursively process children, passing the current block as the parent
    if (block.children && block.children.length > 0) {
        segments.push(...blocksToSegments(block.children, block));
    }
  });

  return segments;
}

