import { useEffect, useState } from "react";
import { TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../domain/entities";

const useSelectTypeLevels = () => {

  const [options, setOptions] = useState<{
    level1: TypeLevel1Entity[];
    level2: TypeLevel2Entity[];
    level3: TypeLevel3Entity[];
  }>({
    level1: [],
    level2: [],
    level3: [],
  });

  const [selected, setSelected] = useState<{
    level1: TypeLevel1Entity;
    level2: TypeLevel2Entity;
    level3: TypeLevel3Entity;
  }>({
    level1: null,
    level2: null,
    level3: null,
  });


  const setLevels1 = (levels1: TypeLevel1Entity[],
    level1Id: TypeLevel1Entity['id'] = null,
    level2Id: TypeLevel2Entity['id'] = null,
    level3Id: TypeLevel3Entity['id'] = null,
  ) => {
    const level1 = levels1.find((item) => item.id === level1Id);
    const level2 = level1?.levels2?.find((item) => item.id === level2Id);
    const level3 = level2?.levels3?.find((item) => item.id === level3Id);
    setOptions({
      level1: levels1,
      level2: level1?.levels2 ?? [],
      level3: level2?.levels3 ?? [],
    })
    setSelected({
      level1,
      level2,
      level3,
    })
  }

  const handleLevel1Change = (selectedLevel: TypeLevel1Entity) => {
    const selectedLevel1 = options.level1.find((item) => item.id === selectedLevel.id);
    setSelected({
      level1: selectedLevel1,
      level2: null,
      level3: null,
    });
  };
  const handleLevel2Change = (selectedLevel: TypeLevel2Entity) => {
    const selectedLevel2 = options.level2.find((item) => item.id === selectedLevel.id);
    setSelected({
      ...selected,
      level2: selectedLevel2,
      level3: null,
    });
  };
  const handleLevel3Change = (selectedLevel: TypeLevel3Entity) => {
    const selectedLevel3 = options.level3.find((item) => item.id === selectedLevel.id);
    setSelected({
      ...selected,
      level3: selectedLevel3,
    });
  };
  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      level2: selected.level1?.levels2 ?? [],
      level3: selected.level2?.levels3 ?? [],
    }))
    console.log(options);

    return () => {
    }
  }, [selected])

  return {
    options,
    selected,
    setLevels1,
    handleLevel1Change,
    handleLevel2Change,
    handleLevel3Change,
  };
};

export default useSelectTypeLevels;