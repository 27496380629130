import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { beneficialTypes } from '../../../../domain/entities';
import { lowerCase } from 'lodash';
import { FormType } from '../../../../domain/types/FormParams';
import { sortByOriginalOrder } from '../../../../utils/array';

function BeneficialRender({ children, param }: {
  children: React.JSX.Element;
  param: FormType.BeneficialParam;
}) {
  const { onParamChanged } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"


  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
      {beneficialTypes.map((type) => (
        <div className="checkbox-beneficial">
          <input
            type="checkbox"
            checked={
              param?.args?.beneficialTypes?.includes(type) || false
            }
            name={type}
            onChange={(e) => {
              const updatedParam: FormType.BeneficialParam = { ...param };
              if (!updatedParam.args) {
                updatedParam.args = { beneficialTypes: ['Company', 'Minor', 'Person'] };
              }
              updatedParam.args.beneficialTypes = e.target.checked
                ? sortByOriginalOrder(beneficialTypes, [...new Set([...updatedParam.args.beneficialTypes, type])])
                : updatedParam.args.beneficialTypes?.filter(i => i != type);

              if (!updatedParam.args?.beneficialTypes?.length) {
                updatedParam.args = { beneficialTypes: ['Company', 'Minor', 'Person'] };
              }
              onParamChanged(param.name, updatedParam);
            }}
            disabled={false}
          />
          <label htmlFor={type}>
            {translationPath.beneficial[lowerCase(type)]}{" "}
          </label>
        </div>
      ))}
    </div>
  )
}

export default BeneficialRender