import React from "react";
import "./taskViewModal.scss"; 
import { TaskEntity } from "../../../domain/entities/Task";
import { format } from "date-fns";
import { TbAlertTriangleFilled, TbChevronsDown, TbChevronsUp, TbEqual } from "react-icons/tb";
import TaskRessourceCard from "../TaskModal/TaskRessourceCard";
import { useTranslation } from "../../../contexts/TranslationProvider";

interface TaskViewModalProps {
  onClose: () => void;
  task: TaskEntity;
  onEditClick: (task: TaskEntity) => void;
}

const renderPriorityIcon = (priority: string) => {
  switch (priority) {
    case "critical":
      return <TbAlertTriangleFilled className="priority-icon priority-critical" size={24}/>;
    case "high":
      return <TbChevronsUp className="TaskViewModal-priority-icon high" />;
    case "medium":
      return <TbEqual className="TaskViewModal-priority-icon medium" />;
    default:
      return <TbChevronsDown className="TaskViewModal-priority-icon low" />;
  }
};

const TaskViewModal = ({ onClose, task, onEditClick }: TaskViewModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-backdrop" onClick={onClose}></div>
      <div className="modal d-flex justify-content-center align-items-center">
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-header" >
            <h5 className="modal-title">{t("pages.tasks.modals.view.title")}</h5>
            <div className="modal-actions">
              <button
                type="button"
                className="btn-close"
                onClick={onClose}
                aria-label="Close"
              ></button>
            </div>
          </div>

          <div className="TaskViewModal-body">
            <div className="TaskViewModal-grid">
              {/* Left Column */}
              <div className="TaskViewModal-column">
                <div className="TaskViewModal-section">
                  <h3 className="TaskViewModal-title">{task.name}</h3>
                  <div className="TaskViewModal-status-badge" data-status={task.status}>
                   {task.status}
                  </div>
                  <div className="TaskViewModal-priority">
                   {t("pages.tasks.modals.view.priority")}: {t(`pages.tasks.priority.${task.priority}`)}
                    {renderPriorityIcon(task.priority)}
                  </div>
                  <div className="TaskViewModal-priority"> {t("pages.tasks.modals.view.category")}: {task.category}</div>
                </div>

                <div className="TaskViewModal-section">
                  <h4 className="TaskViewModal-section-title">{t("pages.tasks.modals.view.description")}</h4>
                  <p className="TaskViewModal-comment">
                  {task.comment || t("pages.tasks.modals.view.noDescription")}
                  </p>
                </div>

              </div>

              {/* Right Column */}
              <div className="TaskViewModal-column">
              <div className="TaskViewModal-section">
                  <h4 className="TaskViewModal-section-title">{t("pages.tasks.modals.view.creator")}</h4>
                    <div className="TaskViewModal-users">
                      
                        <div className="TaskViewModal-user">
                          <div className="TaskViewModal-user-avatar">
                            {task.client?.email ? task.client?.email.charAt(0).toUpperCase() : "U"}
                          </div>
                          <div className="TaskViewModal-user-info">
                            <div className="TaskViewModal-user-name">{task.client?.fullName}</div>
                            <div className="TaskViewModal-user-email">{task.client?.email}</div>
                          </div>
                        </div>
                    
                    </div>
                  
                </div>
                <div className="TaskViewModal-section">
                  <h4 className="TaskViewModal-section-title">{t("pages.tasks.modals.view.assignedUsers")}</h4>
                  {task.users && task.users.length > 0 ? (
                    <div className="TaskViewModal-users">
                      {task.users.map((user, index) => (
                        <div key={user.id || index} className="TaskViewModal-user">
                          <div className="TaskViewModal-user-avatar">
                            {user.email ? user.email.charAt(0).toUpperCase() : "U"}
                          </div>
                          <div className="TaskViewModal-user-info">
                            <div className="TaskViewModal-user-name">{user.fullName}</div>
                            <div className="TaskViewModal-user-email">{user.email}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="TaskViewModal-empty-message">{t("pages.tasks.modals.view.noUsers")}</p>
                  )}
                </div>

                <div className="TaskViewModal-section">
                  <h4 className="TaskViewModal-section-title">{t("pages.tasks.modals.view.resources")}</h4>
                  {task.ressources && task.ressources.length > 0 ? (
                    <div className="TaskViewModal-resources">
                      {task.ressources.map((ressource, index) => (
                        <TaskRessourceCard key={ressource.id || index} ressource={ressource} />
                      ))}
                    </div>
                  ) : (
                    <p className="TaskViewModal-empty-message">{t("pages.tasks.modals.view.noResources")}</p>
                  )}
                </div>
                <div className="TaskViewModal-section">
                  <h4 className="TaskViewModal-section-title">{t("pages.tasks.modals.view.timeline")}</h4>
                  <div className="TaskViewModal-dates">
                    <div className="TaskViewModal-date-item">
                      <span className="TaskViewModal-date-label">{t("pages.tasks.modals.view.startDate")}:</span>
                      <span className="TaskViewModal-date-value">
                        {task.estimatedStartDate 
                          ? format(new Date(task.estimatedStartDate), "dd/MM/yyyy HH:mm")
                          : "N/A"}
                      </span>
                    </div>
                    <div className="TaskViewModal-date-item">
                      <span className="TaskViewModal-date-label">{t("pages.tasks.modals.view.endDate")}:</span>
                      <span className="TaskViewModal-date-value">
                        {task.estimatedEndDate 
                          ? format(new Date(task.estimatedEndDate), "dd/MM/yyyy HH:mm")
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="TaskViewModal-section">
                  <h4 className="TaskViewModal-section-title">{t("pages.tasks.modals.view.activity")}</h4>
                  <div className="TaskViewModal-activity">
                    <div className="TaskViewModal-activity-item">
                      <span className="TaskViewModal-activity-label">{t("pages.tasks.modals.view.created")}:</span>
                      <span className="TaskViewModal-activity-value">
                        {task.createdAt
                          ? format(new Date(task.createdAt), "dd/MM/yyyy HH:mm")
                          : "Unknown"}
                      </span>
                    </div>
                    <div className="TaskViewModal-activity-item">
                      <span className="TaskViewModal-activity-label">{t("pages.tasks.modals.view.lastUpdated")}:</span>
                      <span className="TaskViewModal-activity-value">
                        {task.updatedAt
                          ? format(new Date(task.updatedAt), "dd/MM/yyyy HH:mm")
                          : "Not updated yet"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskViewModal;