import React, { useState, useRef, useContext, useEffect } from "react";
import "./clauseComparison.scss"
import ComparisonClause from "./ComparisonClause";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import InputValuesContext from "../../../contexts/InputValuesContext";

function ClauseSelectModal({
  onClose,
  addNewClause,
  clauseId,
  clauseName,
  modalComparableRef,
  setComparableClauses,
  setSelectedClause,
}: {
  onClose?: () => void;
  addNewClause?: any;
  clauseId?: number;
  clauseName?:any,
  modalComparableRef?:any,
  setComparableClauses?:any,
  setSelectedClause,
}) {
  const { t, language } = useTranslation()

  const translationPath = t("modals.selectFromExisting")

  const [currentClause, setCurrentClause] = useState(null)
  const [searchClause, setSearchClause] = useState("")
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredClauses, setFilteredClauses] = useState([])
  const [loading, setLoading] = useState(false)
  const lookup = require("../../../assets/lookup.png")
  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient)
  const searchRef = useRef(null);
  const handleInputChange = (event) => {
    setSearchClause(event.target.value)
    setShowDropdown(true)
  }
  const handleDropdownSelect = (item) => {
    setCurrentClause(item)
    setSearchClause(item.name)
    setFilteredClauses([])
    setShowDropdown(false)
    setSelectedClause(item)
  }
  useEffect(() => {
    if (!searchClause) return;
    const searchSubClauses = async (name: string) => {
      try {
        setLoading(true)
        const result = await contractTemplateClient.searchClauses(searchClause);
        const { rows: clauses } = result;
        setFilteredClauses(clauses)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.error('Error searching clauses:', error);
        toast.error("Error searching clauses");
      }
    };
    searchSubClauses(searchClause)
  }, [searchClause])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [searchRef])
const {
  inputValues,
  fileNames,
  beneficialsMap,
  excludedSubClauses,
} = useContext(InputValuesContext);

  return (
              <div className="body-comparison w-100">
                <div className="clause-comparison-choice mb-3" ref={modalComparableRef} style={{ position: 'relative' }}>
                  <div ref={searchRef} className='project-beneficial-name'>
                    <input
                      type="text"
                      className=""
                      value={searchClause}
                      onChange={(event) => handleInputChange(event)}
                      style={{ 
                        width: "500px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    />
                    <img src={lookup} alt="#" className={`lookup-img ${language == "ar" && "lookup-img-arabic"}`} />
                    {
                      currentClause && (
                        <span onClick={() => {
                          setCurrentClause(null)
                          setSearchClause("")
                          setShowDropdown(false)
                          setSelectedClause(null)
                        }} className={`x-img ${language == "ar" && "x-img-arabic"}`}>X</span>
                      )
                    }

                    {showDropdown && (
                      <div className="list-beneficial-project">
                        {filteredClauses && filteredClauses?.length > 0 ? filteredClauses?.map((item, index) => (
                          <div className="item-beneficial-project" key={index} onClick={() => {handleDropdownSelect(item)}}>
                            <span>{item.name}</span>
                            <span className="item-template-name">
                              {item.clause?.template ? item.clause.template.name: ""}
                              <span className="item-template-name-seperator">{' > '}</span>
                              {item.clause?.name}
                            </span>
                          </div>
                        )) : (
                          <></>

                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="clause-comparison-container" style={{height:"500px"}}>
                  {
                    currentClause&&!showDropdown ? (
                      <ComparisonClause
                        clause={currentClause} inputValues={inputValues} fileNames={fileNames} beneficialsMap={beneficialsMap} excludedSubClauses={excludedSubClauses}                    />
                    ) : <span>{translationPath.noSelection}</span>
                  }
                </div>
              </div>
  );
}

export default ClauseSelectModal;
