import { ClauseEntity } from '../../../../domain/entities';
import { RenderSegments, SegmentedClauseParam, SegmentedClauseParams, SegmentedEnumParam, SegmentedText, SegmentedTextType } from "../../../../domain/types/ClauseParams";
import _ from 'lodash'

export function idfySegmentedText(segmentedText: ClauseEntity['segmentation']['segmentedText'], genId: () => string): ClauseEntity['segmentation']['segmentedText'] {
  return segmentedText.map((segment, idx) => {
    return [genId(), segment[1], segment[2], segment[3]]
  })
}
export function styleSegmentation(oldSegmentation: ClauseEntity['segmentation'], styledTextSegmentation: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): ClauseEntity['segmentation'] {
  let segmentedParams: SegmentedClauseParams = [];
  let segmentedText: SegmentedText = [];
  oldSegmentation.segmentedParams.forEach((paramSegment, idx) => {
    switch (paramSegment.type) {
      case 'boolean':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'boolean',
            args: {
              textIfFalse: styleSegmentation({ segmentedText: paramSegment.args?.textIfFalse, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText,
              textIfTrue: styleSegmentation({ segmentedText: paramSegment.args?.textIfTrue, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText
            }
          }
        )
      case 'enum':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'enum',
            args: paramSegment.args?.map((arg => {
              return {
                option: arg.option,
                text: styleSegmentation({ segmentedText: arg.text, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText
              }
            })),
          }
        )
      case 'static-table':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'static-table',
            args: {
              ...paramSegment.args,
              cells: paramSegment.args.cells.map(row => row.map(cell => {
                return {
                  ...cell,
                  content: styleSegmentation({ segmentedText: cell.content, segmentedParams: [] }, styledTextSegmentation, deletedSegments).segmentedText,
                }
              })),
            }
          }
        )
      default:
        return segmentedParams.push(paramSegment)
    }
  })
  oldSegmentation.segmentedText.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {

    const styledSegments = styledTextSegmentation
      .map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
      .filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId

    // find the indexes of the firs and last appearences of segmentIs
    if (styledSegments.length > 0 && segmentType === SegmentedTextType.STATIC) {
      styledSegments.forEach((styledSegment) => {
        // check if previous item in styledTextSegmentation is a paragraph start
        if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
          segmentedText.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, styledTextSegmentation[styledSegment.index - 1][3]])
        }
        // check if previous item in styledTextSegmentation is a list element start
        if (styledSegment.index > 0 && styledTextSegmentation[styledSegment.index - 1][2] === SegmentedTextType.LIST_ITEM_START) {
          segmentedText.push(["new-list-item", " ", SegmentedTextType.LIST_ITEM_START, styledTextSegmentation[styledSegment.index - 1][3]])
        }
        segmentedText.push([segmentId, styledSegment.segment[1], segmentType, styledSegment.segment[3]])
      })
    } else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
      segmentedText.push([segmentId, segmentText, segmentType, styledSegments[0].segment[3]])
    }else{
      if (!deletedSegments.find((segment) => segment.id === segmentId)) {
        segmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
      }
    }
  });
  return { segmentedText, segmentedParams }
}
export function idfySegmentation(segmentation: ClauseEntity['segmentation'], genId: () => string): ClauseEntity['segmentation'] {
  segmentation = cleanSegmentation(segmentation.segmentedText, segmentation.segmentedParams)
  if (segmentation.segmentedText.length === 0) {
    segmentation.segmentedText = [['tmp', '_', SegmentedTextType.STATIC]]
  }
  let segmentedText = idfySegmentedText(segmentation.segmentedText, genId)
  let segmentedParams: SegmentedClauseParams = [];
  segmentation.segmentedParams.forEach((paramSegment, idx) => {
    switch (paramSegment.type) {
      case 'boolean':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'boolean',
            args: {
              textIfFalse: idfySegmentedText(paramSegment.args?.textIfFalse ?? [], genId),
              textIfTrue: idfySegmentedText(paramSegment.args?.textIfTrue ?? [], genId)
            }
          }
        )
      case 'enum':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'enum',
            args: paramSegment.args?.map((arg => {
              return {
                option: arg.option,
                text: idfySegmentedText(arg.text, genId)
              }
            })),
          }
        )
      case 'static-table':
        return segmentedParams.push(
          {
            ...paramSegment,
            type: 'static-table',
            args: {
              ...paramSegment.args,
              cells: paramSegment.args.cells.map(row => row.map(cell => {
                return {
                  ...cell,
                  content: idfySegmentedText(cell.content.length > 0 ? cell.content : [["id", "_", SegmentedTextType.STATIC]], genId),
                }
              })),
            }
          }
        )
      default:
        return segmentedParams.push(paramSegment)
    }
  })
  return { segmentedText, segmentedParams }
}
export function removeUnusedParams(segmentation: ClauseEntity['segmentation']): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
  let newSegmentedParams = [] as SegmentedClauseParam[]
  segmentation.segmentedParams.forEach(param => {
    let paramName = param.name
    if(param.type === 'enum' || param.type === 'boolean' || param.type === 'static-table'){
      paramName = `${param.name}.${param.definition}`;
    }
    if (isParamUsed(segmentation.segmentedText, segmentation.segmentedParams, paramName)) {
      newSegmentedParams.push(param)
    }
  })
  return { segmentedText: segmentation.segmentedText, segmentedParams: newSegmentedParams }
}
export function isParamUsed(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], paramName: string): boolean {
  let found = false
  segmentedText.forEach(([segmentId, segmentText, segmentType]) => {
    if (segmentType === SegmentedTextType.PARAM && segmentText === paramName) {
      return found = true
    }
  })
  if(segmentedParams.length){
    segmentedParams.forEach((paramSegment, idx) => {
      switch (paramSegment.type) {
        case 'boolean':
          let foundInTrue = false
          let foundInFalse = false
          if (paramSegment.args.textIfTrue.length > 0) {
            foundInTrue = isParamUsed(paramSegment.args.textIfTrue, [], paramName)
          }
          if (paramSegment.args.textIfFalse.length > 0) {
            foundInFalse = isParamUsed(paramSegment.args.textIfFalse, [], paramName)
          }
          if (foundInTrue || foundInFalse) {
            found = true
          }
          break;
        case 'enum':
          paramSegment.args.forEach(arg => {
            if (arg.text.length > 0) {
              if (isParamUsed(arg.text, [], paramName)) {
                found = true
              }
            }
          })
          break;
        case 'static-table':
          paramSegment.args.cells.forEach(row => {
            row.forEach(cell => {
              if (cell.content.length > 0) {
                if (isParamUsed(cell.content, [], paramName)) {
                  found = true
                }
              }
            })
          })
          break;
        default:
          break;
  }
})
}
return found
}
export function cleanSegmentation(segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[]): { segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[] } {
  if (segmentedParams.length) {
    segmentedParams = segmentedParams.map(param => {
      switch (param.type) {
        case 'boolean':
          return {
            ...param,
            args: {
              textIfFalse: cleanSegmentation(param.args?.textIfFalse.length > 0 ? param.args.textIfFalse : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
              textIfTrue: cleanSegmentation(param.args?.textIfTrue.length > 0 ? param.args.textIfTrue : [["", "_", SegmentedTextType.STATIC]], []).segmentedText,
            },
          }
        case 'enum':
          return {
            ...param,
            args: param.args?.map(arg => {
              return {
                option: arg.option,
                text: cleanSegmentation(arg.text.length > 0 ? arg.text : [["", "_", SegmentedTextType.STATIC]], []).segmentedText
              }
            })
          }
        case 'static-table':
          return {
            ...param,
            args: {
              ...param.args,
              cells: param.args.cells.map(row => row.map(cell => {
                return {
                  ...cell,
                  content: cell.content.length > 0 ? cleanSegmentation(cell.content, []).segmentedText : [["", "_", SegmentedTextType.STATIC]],
                }
              })),

            }
          }
        default:
          return param
      }
    })
  }
  // remove empty segments
  segmentedText = segmentedText.filter(segment => {
    const [segmentId, text, type] = segment
    return text.length > 0 || type === SegmentedTextType.PARAGRAPH_START
  })
  // if first or last segments are not static add static segments
  if (segmentedText.length) {
    const contentSegmentedText = segmentedText.filter(([id, text, type]) => type !== SegmentedTextType.PARAGRAPH_START)
    const firstSegment = contentSegmentedText[0]
    const lastSegment = contentSegmentedText[contentSegmentedText.length - 1]
    if (firstSegment && firstSegment[2] !== SegmentedTextType.STATIC) {
      segmentedText.unshift(["id-first", " ", SegmentedTextType.STATIC])
    }
    if (lastSegment && lastSegment[2] !== SegmentedTextType.STATIC) {
      segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
    }
    if (contentSegmentedText.length === 0) {
      segmentedText.push(["id-last", " ", SegmentedTextType.STATIC])
    }
  }
  // if two consecutive segments are both params or both paragraph start then add a static segment between them
  const newSegmentedText = [] as SegmentedText
  for (let i = 0; i < segmentedText.length; i++) {
    const currentSegment = segmentedText[i]
    const nextSegment = segmentedText[i + 1]
    newSegmentedText.push(currentSegment)
    if (currentSegment[2] === SegmentedTextType.PARAM && nextSegment && (nextSegment[2] === SegmentedTextType.PARAM || nextSegment[2] === SegmentedTextType.LIST_ITEM_START)) {
      newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
    }
    if(currentSegment[2] === SegmentedTextType.PARAGRAPH_START && nextSegment && nextSegment[2] === SegmentedTextType.PARAGRAPH_START) {
      newSegmentedText.push(["id-between", " ", SegmentedTextType.STATIC])
    }
  }

  // if a paragraph only contains a param add static segments before and after it
  const cleanedSegmentedText = [] as SegmentedText
  for (let i = 0; i < newSegmentedText.length; i++) {
    const currentSegment = newSegmentedText[i]
    const nextSegment = newSegmentedText[i + 1]
    const staticTables = segmentedParams.filter(param => param.type === 'static-table')
    const previousSegment = newSegmentedText[i - 1]
    if (currentSegment[2] === SegmentedTextType.PARAM) {
      if (previousSegment && previousSegment[2] === SegmentedTextType.PARAGRAPH_START) {
        cleanedSegmentedText.push(["id-before", " ", SegmentedTextType.STATIC])
      }
      cleanedSegmentedText.push(currentSegment)
      const isStaticTable = staticTables.find(param => param.name === currentSegment[1])
      if (!isStaticTable && nextSegment && nextSegment[2] === SegmentedTextType.PARAGRAPH_START) {
        cleanedSegmentedText.push(["id-before", " ", SegmentedTextType.STATIC])
      }
    } else {
      cleanedSegmentedText.push(currentSegment)
    }
  }

  // if two consecutive segments are static and share the same styles then merge them
  const mergedSegmentedText = [] as SegmentedText
  for (let i = 0; i < cleanedSegmentedText.length; i++) {
    const currentSegment = cleanedSegmentedText[i]
    const nextSegment = cleanedSegmentedText[i + 1]
    const [segmentId, text, type, style] = currentSegment
    const [nextSegmentId, nextText, nextType, nextStyle] = nextSegment || []
    if (nextSegment
      && type === SegmentedTextType.STATIC
      && nextType === SegmentedTextType.STATIC
      && _.isEqual(style, nextStyle)
    ) {
      mergedSegmentedText.push([segmentId, text + nextText, type, style])
      i++
    } else {
      mergedSegmentedText.push(currentSegment)
    }
  }
  return { segmentedText: mergedSegmentedText, segmentedParams }
}
export const insertParamInSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, newParam: SegmentedClauseParam, definition: number, textBefore: string, textAfter: string, field: string, segments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	const relatedSegments = segmentedText.filter(([segmentId]) => segmentId === id);
	if (segmentedParams.length) {
		// insert the new param in the segmented params text
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: insertParamInSegment(param.args?.textIfFalse, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
							textIfTrue: insertParamInSegment(param.args?.textIfTrue, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: insertParamInSegment(arg.text, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText
							}
						})
					}

				case 'static-table':
					return {
						...param,
						args: {
							...param.args,
							cells: param.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: insertParamInSegment(cell.content, [], id, newParam, definition, textBefore, textAfter, field, segments, deletedSegments).segmentedText,
								}
							})),
						}
					}
				default:
					return param
			}
		})
	}
	// Split the segment at the insertion index
	//const [segmentId, segmentText, segmentType] = segmentedText[segmentToUpdateIndex];

	// manage the changed elements
	//const updatedSegment = [segmentId, textBefore + " ", segmentType];
	let paramSegment: SegmentedText[number]
	switch (newParam.type) {
		case 'enum':
			paramSegment = ["new-id", `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			const lastEnumOccurence = (segmentedParams as SegmentedEnumParam[]).find((par) => par.name == newParam.name && par.definition == definition - 1)
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: lastEnumOccurence ? lastEnumOccurence.args.map(arg => {
					return {
						option: arg.option,
						text: [['tmp', '_', SegmentedTextType.STATIC]]
					}
				}) : newParam.args.map(arg => {
					return {
						option: arg.option,
						text: [['tmp', '_', SegmentedTextType.STATIC]]
					}
				}),
				definition: definition
			})
			break;
		case 'boolean':
			paramSegment = ["new-id", `${newParam.name}.${definition || 0}`, SegmentedTextType.PARAM];
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				args: {
					textIfTrue: [['tmp', '_', SegmentedTextType.STATIC]],
					textIfFalse: [['tmp', '_', SegmentedTextType.STATIC]],
				},
				definition: definition
			})
			break;
		case 'static-table':
			paramSegment = ["new-id", newParam.name, SegmentedTextType.PARAM];
			segmentedParams.push({
				name: newParam.name,
				label: newParam.label,
				type: newParam.type,
				definition: definition,
				args: {
					...newParam.args,
					cells: newParam.args.cells.map(row => row.map(cell => {
						return {
							...cell,
							content: [['tmp', '_', SegmentedTextType.STATIC]],
						}
					})),
				}
			})

			break;
		case 'beneficial':
		case 'beneficial[]':
			paramSegment = ["new-id", newParam.name + "." + field, SegmentedTextType.PARAM];
			const oldBeneficial = segmentedParams.find((par) => par.name == newParam.name)
			if (!oldBeneficial)
				segmentedParams.push(newParam)
			break;
		default:
			paramSegment = ["new-id", newParam.name, SegmentedTextType.PARAM];
			const exists = segmentedParams.find((par) => par.name == newParam.name)
			if (!exists)
				segmentedParams.push(newParam)
			break;
	}
	const newSegments: ClauseEntity['segmentation']['segmentedText'] = []
	console.log("segmentedText", segmentedText)
	segmentedText.map(([segmentId, segmentText, segmentType, segmentStyle], idx) => {

		const styledSegments = segments
			.map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
			.filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId
		if (styledSegments.length > 0 && (segmentType === SegmentedTextType.STATIC)) {
			styledSegments.forEach((styledSegment) => {
				// check if previous item in styledTextSegmentation is a paragraph start
				if (styledSegment.index > 0 && segments[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
					newSegments.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, segments[styledSegment.index - 1][3]])
				}
				// check if previous item in styledTextSegmentation is a list element start
				if (styledSegment.index > 0 && segments[styledSegment.index - 1][2] === SegmentedTextType.LIST_ITEM_START) {
					newSegments.push(["new-list-item", " ", SegmentedTextType.LIST_ITEM_START, segments[styledSegment.index - 1][3]])
				}
				if (styledSegment.segment[2] === SegmentedTextType.PARAM && segmentId === id) {
					newSegments.push(paramSegment)
				} else {
					newSegments.push([segmentId, styledSegment.segment[1], styledSegment.segment[2], styledSegment.segment[3]])
				}
			})
		} else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
			newSegments.push([segmentId, segmentText, segmentType, styledSegments[0].segment[3]])
		} else {
			if (!deletedSegments.find((segment) => segment.id === segmentId)) {
				newSegments.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	})
	return { segmentedText: newSegments, segmentedParams: segmentedParams };

}

export const deleteSegment = (segmentedText: SegmentedText, segmentedParams: SegmentedClauseParam[], id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments): { segmentedText: any[], segmentedParams: SegmentedClauseParam[] } => {
	if (segmentedParams.length) {
		segmentedParams = segmentedParams.map(param => {
			switch (param.type) {
				case 'boolean':
					return {
						...param,
						args: {
							textIfFalse: deleteSegment(param.args?.textIfFalse, [], id, styledSegments, deletedSegments).segmentedText,
							textIfTrue: deleteSegment(param.args?.textIfTrue, [], id, styledSegments, deletedSegments).segmentedText,
						},
					}
				case 'enum':
					return {
						...param,
						args: param.args?.map(arg => {
							return {
								option: arg.option,
								text: deleteSegment(arg.text, [], id, styledSegments, deletedSegments).segmentedText
							}
						})
					}
				case 'static-table':
					return {
						...param,
						args: {
							...param.args,
							cells: param.args.cells.map(row => row.map(cell => {
								return {
									...cell,
									content: deleteSegment(cell.content, [], id, styledSegments, deletedSegments).segmentedText,
								}
							})),

						}
					}
				default:
					return param
			}
		})
	}
	const newSegmentedtext = segmentedText.filter(e => {
		const [segmentId, text, type] = e
		return segmentId !== id
	})
	const cleanedSegmentedText = [] as SegmentedText
	newSegmentedtext.forEach(([segmentId, segmentText, segmentType, segmentStyle], idx) => {
		const styledTextSegmentation = styledSegments
			.map((styledSegment, index) => ({ segment: styledSegment, index })) // Map to an object with segment and index
			.filter(({ segment }) => segment[0] === segmentId); // Filter based on segmentId

		if (styledTextSegmentation.length > 0 && segmentType === SegmentedTextType.STATIC) {
			styledTextSegmentation.forEach((styledSegment) => {
				// check if previous item in styledTextSegmentation is a paragraph start
				if (styledSegment.index > 0 && styledSegments[styledSegment.index - 1][2] === SegmentedTextType.PARAGRAPH_START) {
					cleanedSegmentedText.push(["new-paragraph", " ", SegmentedTextType.PARAGRAPH_START, styledSegments[styledSegment.index - 1][3]])
				}
				cleanedSegmentedText.push([segmentId, styledSegment.segment[1], segmentType, styledSegment.segment[3]])
			})
		} else if (segmentType === SegmentedTextType.PARAM && styledSegments.length === 1) {
			cleanedSegmentedText.push([segmentId, segmentText, segmentType, styledTextSegmentation[0].segment[3]])
		} else {
			if (!deletedSegments.find((segment) => segment.id === segmentId)) {
				cleanedSegmentedText.push([segmentId, segmentText, segmentType, segmentStyle])
			}
		}
	})
	//** */
	return { segmentedText: newSegmentedtext, segmentedParams }
}