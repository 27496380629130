import React from 'react'
import { ClauseHistoryEntity } from '../../../domain/entities'
import { SegmentedClauseParams } from '../../../domain/types/ClauseParams'
import VersionParameter from './VersionParameter'
import { formatDate } from '../../../helpers/helper'
import { useTranslation } from '../../../contexts/TranslationProvider'
const aiIcon = require('../../../assets/new-design-icons/ai.png')
function VersionsList(
  {
    versions,
    handleSelect,
    setInputValues,
    inputValues,
    chosenVersion
  }: {
    versions: ClauseHistoryEntity[],
    handleSelect: (version: ClauseHistoryEntity) => void,
    setInputValues: (param: any, value: any) => void,
    inputValues: Record<string, any>,
    chosenVersion: ClauseHistoryEntity | null
  }
) {
  const { t } = useTranslation();
  const handleClick = (version) => { handleSelect(version) }
  const translationPath = "modals.generateParamHistory.";

  return (
    <div className='macro-list'>
      {
        versions.length === 0 && <div className='empty-macro-list'>
          {t(translationPath + "noVersions")}
        </div>
      }
      {versions && versions.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).map((version) => {
        const isSelected = chosenVersion?.id === version.id;
        return (
          <div
            id="clause-group"
            className={` edit-contract-clause-input px-4 py-2 mx-1 my-2 ${isSelected ? "selected-input-container" : ""}`}
            style={{ backgroundColor: isSelected ? "#eee3fd" : "" }}
            onClick={() => handleClick(version)}
          >
            <div className="d-flex gap-2" style={{ flexDirection: "column" }}>
              <div className="edit-contract-clause-input-header version-header" style={{display: "flex", width: "100%", alignItems: "space-between"}}>
                <span>
                {formatDate(version.createdAt as any as string)}
                </span>
                <div className={`version-type-tag ${version.type == 'initial' && "version-init-type-tag"}`}>
                  {
                    ['param-generation', 'clause-generation'].includes(version.type) &&
                    <img src={aiIcon} alt="ai-icon" className="ai-icon" />
                  }
                  {version.type}
                </div>
              </div>
              {
                isSelected && <div
                  className="edit-contract-clause-input-header template-param-list"
                  onClick={() => console.log(version.segmentation)}
                >
                  {
                    version.segmentation &&
                    version.segmentation.segmentedParams &&
                    version.segmentation.segmentedParams.map((param) => {
                      return <div className='template-param-element'>
                        <VersionParameter
                          param={param}
                          currentValue={inputValues[param.name]}
                          setInputValues={setInputValues}
                          onDelete={() => { }}
                        />
                      </div>
                    })
                  }
                </div>
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default VersionsList