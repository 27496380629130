import React from "react";
import { ClauseHistoryEntity } from "../../../domain/entities";
import VersionDisplay from "./VersionDisplay";
import { formatDate } from "../../../helpers/helper";
import { useTranslation } from "../../../contexts/TranslationProvider";

interface VersionProps {
  version: ClauseHistoryEntity;
  paramValues?: Record<string, any>;
}
function Version({ version, paramValues }: VersionProps) {
  const translationPath = "modals.generateParamHistory.";
  const { t } = useTranslation();
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", gap: "20px" }}>
      <div
        style={{ height: "100%" }}
        className="clause-comparison-current"
      >
        <div className="d-flex header-clause-current" style={{ maxHeight: "50px" }}>
          <div className="clause-comparison-header mb-4" >
            <span className="clause-comparison-actual-title half-border-bottom" style={{ fontSize: "15px" }}>
              {version ? formatDate(version.updatedAt as any as string) : t(translationPath + "select")}
            </span>
          </div>
        </div>
        <div className="clause-comparison-body">
          {
            version && <VersionDisplay clause={version} paramValues={paramValues}/>
          }
        </div>
      </div>
    </div>
  );
}

export default Version;
