import React, { useEffect, useState, useRef, useMemo } from 'react'
import SVGRender from '../../helpers/SVGRender'
import { close } from '../../assets/icons/close'
import './askYourQuestions.scss'
import Loading from '../../components/common/Loading'
import { ReglementClient } from '../../services/rag/ReglementClient'
import { ReglementEntity, ConversationEntity } from '../../domain/entities'
import { ConversationClient } from '../../services/rag/ConversationClient'
import { useNavigate } from 'react-router-dom'
import chat from "../../assets/new-design-icons/Chat.svg"
import document from "../../assets/new-design-icons/Document.svg"
import ConversationList from './components/ConversationList'
import AskYourQuestionsContext from '../../contexts/AskYourQuestionsContext'
import { toast } from 'react-toastify'
import ConversationChatBody from './components/ConversationChatBody'
import useApiClientWithLoading from '../../services/api/ApiClient'
import { useTranslation } from '../../contexts/TranslationProvider'

function AskYourQuestions() {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false)
  const [conversations, setConversations] = useState<ConversationEntity[]>([]);
  const [reglements, setReglements] = useState([])
  const [currentConversation, setCurrentConversation] = useState<ConversationEntity | null>(null)
  const [currentReglement, setCurrentReglement] = useState<ReglementEntity | null>(null)
  const [query, setQuery] = useState('')
  const [pendingQuestion, setPendingQuestion] = useState(null)
  const [loading, setLoading] = useState(null)
  const { t } = useTranslation()

  const scorllRef = useRef(null)
  const listRef = useRef(null)
  const apiClient = useApiClientWithLoading()
  const reglementClient = new ReglementClient(apiClient)
  const conversationClient = new ConversationClient(apiClient)
  const getAllConversations = async (conversationId = null) => {
    try {
      setLoading(true);
      const result = await conversationClient.getAll();
      if (!result || !result.rows) return;
      setConversations(result.rows.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()));
      if (conversationId) {
        setCurrentConversation(result.rows.find(conversation => conversation.id === conversationId))
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  const getReglements = async () => {
    try {
      setLoading(true);
      const result = await reglementClient.getAll();
      if (!result || !result.rows) return;
      setReglements(result.rows.filter(reglement => reglement.has_vectorstore));
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const initialGet = async () => {
    try {
      setPageLoading(true);
      await getReglements()
      await getAllConversations()
    } catch (err) {
      console.log(err)
    } finally {
      setPageLoading(false)
    }
  }

  useEffect(() => {
    initialGet()
  }, []);

  useEffect(() => {
    if (currentConversation) {
      if (currentConversation.reglement) {
        setCurrentReglement(reglements.find(reglement => reglement.id === currentConversation.reglement))
      } else {
        setCurrentReglement(null)
      }
    }
  }, [currentConversation])

  const handleDelete = async (e, ressource) => {
    e.stopPropagation()
    try {
      if (ressource.type === "conversation") {
        if (currentConversation && (currentConversation.id === ressource.id)) {
          setCurrentConversation(null)
        }
        const data = await conversationClient.delete(ressource.id);
        await getAllConversations()
      }
    } catch (e) {
      console.log(e)
    }
  }
  const handleQueryChange = (e) => {
    setQuery(e.target.value)
  }
  // Helper function to process the streaming response.
  const processStreamResponse = async (response, question) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let accumulatedResponse = "";
    let buffer = ""; // Buffer for incomplete JSON lines
  
    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      if (value) {
        // Append current chunk to the buffer
        buffer += decoder.decode(value, { stream: true });
        // Split the buffer into lines
        const lines = buffer.split("\n");
        // Save the last partial line back to the buffer
        buffer = lines.pop();
        
        for (const line of lines) {
          if (!line.trim()) continue;
          try {
            const data = JSON.parse(line);
            const { operationType, response: partialResponse, conversation, error } = data;
            
            if (error === "MAX_MESSAGES_EXCEEDED") {
              toast.error("Max messages exceeded");
              console.log("Max messages exceeded");
              setPendingQuestion(null);
              return;
            }
            
            if (conversation && conversation.id) {
              await getAllConversations(conversation.id);
            }
            
            // Update the accumulated response (consider concatenating if needed)
            accumulatedResponse = partialResponse;
            setPendingQuestion({
              question,
              response: accumulatedResponse,
              operationType,
            });
          } catch (e) {
            // If error is due to incomplete JSON, you might choose to ignore it until the next chunk
            console.error("Error parsing JSON (might be incomplete):", e);
          }
        }
      }
    }
    
    // Optionally, try to process any remaining data in the buffer
    if (buffer.trim()) {
      try {
        const data = JSON.parse(buffer);
        // process the final data as needed
      } catch (e) {
        console.error("Final JSON parse error:", e);
      }
    }
  };

const handleSubmit = async (comingQuery = "") => {
  if (!query && !comingQuery) return;
  if (!currentReglement) return toast.error("Please select a regulation");
  const question = comingQuery || query;
  setQuery("");
  setPendingQuestion({ question });
  
  try {
    let response;
    if (!currentConversation) {
      response = await conversationClient.queryAndCreateStream(currentReglement.id, question);
    } else {
      response = await conversationClient.queryStream(currentConversation.id, question);
    }
    await processStreamResponse(response, question);
  } catch (error) {
    console.error("Error during streaming:", error);
    setPendingQuestion(null);
    toast.error("An error occurred during streaming.");
  } finally {
    setPendingQuestion(null);
  }
};

  useEffect(() => {
    try {
      // scroll to scrollRef to the top of the list ref
      setTimeout(() => {
        const container = listRef.current;
        if (!container) return;
        const containerRect = container.getBoundingClientRect();
        const element = scorllRef.current;
        if (element) {
          const elementRect = element.getBoundingClientRect();
          const relativeTop = elementRect.top - containerRect.top;
          const newScrollTop = (container && container.scrollTop ? container.scrollTop : 0) + relativeTop;
          container && container.scrollTo({
            top: newScrollTop,
            behavior: "smooth",
          });
        }
      }, 400);
        if (currentConversation) {
          // scroll to bottom of list ref
          setTimeout(() => {
            const container = listRef.current;
            container && container.scrollTo({
              top: container.scrollHeight,
              // not smooth
              behavior: "smooth",
            });
          }, 100);
        }
    } catch (e) {
      console.log(e)
    }
  }, [pendingQuestion, currentConversation])

  const AskYourQuestionsContextValue = useMemo(() => ({
    conversations,
    setConversations,
    reglements,
    setReglements,
    currentConversation,
    setCurrentConversation,
    pendingQuestion,
    setPendingQuestion,
    handleQueryChange,
    handleSubmit,
    query,
    handleDelete,
    currentReglement,
    setCurrentReglement,

  }), [conversations, reglements, setConversations, setReglements, currentConversation, setCurrentConversation, pendingQuestion, setPendingQuestion, handleQueryChange, handleSubmit, query, handleDelete, currentReglement, setCurrentReglement])

useEffect(() => {
  setPendingQuestion(null)
}, [currentReglement])
  return (
    <AskYourQuestionsContext.Provider value={AskYourQuestionsContextValue}>
      <div className='ask-your-question-page-container'>
        {
          pageLoading ?
            <Loading /> :
            <>
              <div className='ask-page-header'>
                <div className='left-section'>
                  <span style={{ cursor: "pointer" }} onClick={() => navigate("/")} className='close'>
                    <SVGRender svgContent={close} />
                  </span>
                  <div className='title'>
                    {
                      reglements && reglements.length > 0 && (
                        <>
                          <div className='reglement-list'>
                            {
                              reglements.map((reglement, index) => (
                                <div key={index} className={`reglement-item ${currentReglement?.id === reglement.id && "reglement-item-active"}`} onClick={() => {
                                  setCurrentReglement(reglement)
                                  setCurrentConversation(null)
                                }}>
                                  <span
                                    className='title'
                                    data-fullname={reglement.name}
                                    title={reglement.name}
                                  >
                                    {reglement.name}
                                  </span>
                                </div>
                              ))
                            }
                          </div>
                        </>
                      )
                    }
                  </div>
                </div>
                <div className='right-section'>
                </div>
              </div>
              <div className='ask-page-body'>
                <div className='body-interactions-container'>
                  {<ConversationChatBody listRef={listRef} scorllRef={scorllRef} />}
                </div>
                <div className='body-conversations-container'>
                  <ConversationList />
                </div>
              </div>
            </>
        }

      </div>
    </AskYourQuestionsContext.Provider>
  )
}

export default AskYourQuestions