import React, { useEffect } from "react";
import { BeneficialEntity, BeneficialMacroEntity, ClauseEntity } from "../../../domain/entities";
import ClauseText from "./ClauseText";
import { LanguageType, useTranslation } from "../../../contexts/TranslationProvider";
import { getName } from "../../../helpers/Translation";
import MacroEditor from "./MacroEditor.tsx";

interface ComparisonClauseProps {
  macro: BeneficialMacroEntity;
  type?: BeneficialEntity['type'];
  setType?: (type: BeneficialEntity['type']) => void;
  handleSegmentationChange: (segmentation: BeneficialMacroEntity['segmentation'], id: BeneficialMacroEntity['id']) => void;
  paramValues?: Record<string, any>;
}
function ComparisonClause({ macro, type, setType, handleSegmentationChange, paramValues }: ComparisonClauseProps) {
  const { t, language } = useTranslation();
  const translationPath = "modals.editMacros.";

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", gap: "20px" }}>
      <div className="exchange-type">
        <div className={`exchange-type-item ${type === "Person" && 'exchange-type-item-active'}`} onClick={() => setType("Person")}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {t(translationPath + "person")}
          </div>
        </div>
        <div className={`exchange-type-item ${type === "Company" && 'exchange-type-item-active'}`} onClick={() => setType("Company")}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {t(translationPath + "company")}
          </div>
        </div>
        <div className={`exchange-type-item ${type === "Minor" && 'exchange-type-item-active'}`} onClick={() => setType("Minor")}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {t(translationPath + "minor")}
          </div>
        </div>
      </div>
      <div
        style={{ height: "100%" }}
        className="clause-comparison-current"
      >
        <div className="d-flex header-clause-current" style={{ maxHeight: "50px" }}>
          <div className="clause-comparison-header mb-4" >
            <span className="clause-comparison-actual-title half-border-bottom" style={{ fontSize: "15px" }}>
              {macro ? macro.name : t(translationPath + "select")}
            </span>
          </div>
        </div>
        <div className="clause-comparison-body">
          {
            macro && <MacroEditor macro={macro} handleSegmentationChange={handleSegmentationChange} paramValues={paramValues}/>
          }
        </div>
      </div>
    </div>
  );
}

export default ComparisonClause;
