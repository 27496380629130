import React, { useContext, useEffect, useRef } from 'react';
import './UserRolePopup.scss';
import { Notification_UserEntity, UserRoleType } from '../../../domain/entities';
import { useTranslation } from "../../../contexts/TranslationProvider";
import { AuthUserContext } from '../../../contexts/AuthUserContext';
import { useNavigate } from 'react-router';
const UserRolePopup = ({ onClose }: {
  notifications: Notification_UserEntity[];
  onClose: () => void;
}) => {
  const { authUser, activeRole, setActiveRole } = useContext(AuthUserContext);

  const popupRef = useRef(null);
  const navigate = useNavigate()
  const { t, language } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef, onClose]);

  const handleRoleClick = async (role: UserRoleType) => {
    setActiveRole(role)
    onClose()
    navigate("/")
  };
  return (
    <div className={`notification-popup ${language == "ar" && "notification-popup-arabic"}`} ref={popupRef}>
      <ul>
        {authUser?.roles?.map((role, index) => (
          <div key={index} style={{ cursor: "pointer" }} className={`${role != activeRole ? '' : 'notification-not-seen'}`} onClick={() => handleRoleClick(role)}>
            <li
              className="notification-message"
            >
              {t('pages.users.popup.userType.' + role)}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};
export default UserRolePopup;
