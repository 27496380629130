import React, { useContext, useState } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
import EditProjectContext from "../../../../contexts/EditProjectContext";
import ValidateProjectModal from "../../../modals/ValidateProjectModal";
import ValidateProjectWorkflowModal from "../../../modals/ValidateProjectWorkflowModal";

function EditionProjectValidateButton() {
  const { project, setProject } = useContext(EditProjectContext)
  const [showValidateProjectModal, setShowValidateProjectModal] = useState(false);
  const { t } = useTranslation();


  return (
    <>
      <button className="navbar-button" onClick={() => setShowValidateProjectModal(true)}>
        {t("navbar.validateProject")}
      </button>
      {showValidateProjectModal && (
        project?.template?.groupsGraph?.steps?.length ? <ValidateProjectWorkflowModal project={project} onClose={() => setShowValidateProjectModal(false)} />
          : <ValidateProjectModal project={project} onClose={() => setShowValidateProjectModal(false)} />
      )
      }
    </>
  )
}

export default EditionProjectValidateButton;
