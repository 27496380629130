import { AxiosInstance } from "axios";
import { AiFileEntity } from "../../domain/entities/AiFile";
import { ModelClient } from "../ModelClient";
import { AiFileConversationEntity } from "../../domain/entities";

export class AiFileConversationClient extends ModelClient<AiFileConversationEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'AiFileConversation')
  }
  async createAndQuery(aiFileId: string, query: string): Promise<any> {
    const response = await this.apiClient.post<{ row: AiFileConversationEntity }>(`${this.modelUrl}/createAndQuery`, { aiFileId, query });
    return response.data;
  }
  async query(conversationId: string, query: string): Promise<any> {
    const response = await this.apiClient.post<{ row: AiFileConversationEntity }>(`${this.modelUrl}/query`, { conversationId, query });
    return response.data;
  }
  async delete(id: string): Promise<{ deleted: boolean; }> {
    const response = await this.apiClient.delete<{ deleted: boolean; }>(`${this.modelUrl}/${id}`);
    return response.data;
  }
  async queryAndCreateStream(aiFileId: string, query: string): Promise<any> {
    return fetch(`${this.modelUrl}/queryAndCreateStream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-token': localStorage.getItem('user-token') ?? ''
      },
      body: JSON.stringify({
        aiFileId,
        query
      }) // Adjust if you need to send data
    });
  }
  async queryStream(conversationId: string, query: string): Promise<any> {
    return fetch(`${this.modelUrl}/queryStream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'user-token': localStorage.getItem('user-token') ?? ''
      },
      body: JSON.stringify({
        conversationId,
        query
      }) // Adjust if you need to send data
    });
  }
}