import { AxiosInstance } from "axios";
import { EntityFilter, ModelClient } from "../ModelClient";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { GroupEntity } from "../../domain/entities";
import { ContractClient } from "./ContractClient";
import { FormClient } from "./FormClient";
import { DocumentClient } from "./DocumentClient";
import { ProjectClient } from "./ProjectClient";
import { RessourceScoringClient } from "./RessourceScoring";




export class RessourceClient extends ModelClient<RessourceEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'Ressource')
  }

  async getAssignedGroupRessources(schema: string = 'nested', filter: EntityFilter<RessourceEntity> = {}) {
    const reponse = await this.apiClient.post<{ count: number, rows: RessourceEntity[], groups: GroupEntity[] }>(`${this.modelUrl}/assignedGroupRessources?schema=${schema}`,
      { where: filter },
    );
    return reponse.data;
  }
  async deleteFromChild(ressource: RessourceEntity) {
    switch (ressource.type) {
      case 'Contract':
        const contractClient = new ContractClient(this.apiClient)
        return await contractClient.delete(ressource.contractId)
      case 'Form':
        const formClient = new FormClient(this.apiClient)
        return await formClient.delete(ressource.formId)
      case 'Document':
        const documentClient = new DocumentClient(this.apiClient)
        return await documentClient.delete(ressource.documentId)
      case 'Project':
        const projectClient = new ProjectClient(this.apiClient)
        return await projectClient.delete(ressource.projectId)
      case 'RessourceScoring':
        const ressourceScoringClient = new RessourceScoringClient(this.apiClient)
        return await ressourceScoringClient.delete(ressource.ressourceScoringId)
      default:
        break;
    }
  }
};

