import { useEffect, useRef, useState } from "react";
import "./searchableDropdown.scss";
import { FaChevronDown } from "react-icons/fa";
import { RessourceEntity } from "../../../domain/entities/Ressource";
import form from "../../../assets/new-design-icons/Form.svg";
import contract from "../../../assets/new-design-icons/Contract.svg";
import stats from "../../../assets/new-design-icons/Stats.svg";
const docIcon = require("../../../assets/new-design-icons/doc.png");
interface SearchableRessourceDropdownProps {
  options: RessourceEntity[]; 
  label: string;
  id: string;
  selectedVal: any[];
  handleChange: (val: any[]) => void;
  multi?: boolean;
}

const SearchableRessourceDropdown: React.FC<SearchableRessourceDropdownProps> = ({
  options = [],
  label,
  id,
  selectedVal = [],
  handleChange,
  multi = false,
}) => {
  const formIcon = form.toString();
  const contractIcon = contract.toString();
  const statsIcon = stats.toString();
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery("");

    if (multi) {
      const isSelected = selectedVal.some((val) => val[id] === option[id]);

      if (isSelected) {
        // Remove if already selected
        handleChange(selectedVal.filter((val) => val[id] !== option[id]));
      } else {
        // Add new selection
        handleChange([...selectedVal, option]);
      }
    } else {
      handleChange(option);
      setIsOpen(false);
    }
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (multi && Array.isArray(selectedVal)) {
      return "";
    }
    return selectedVal ? selectedVal[label] : "";
  };

  const filter = (options) => {
    return options.filter(
      (option) =>
        option[label]?.toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            autoComplete="off"
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              if (!multi) handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <FaChevronDown className="arrow" size={12} />
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => {
          const isSelected = selectedVal.some((val) => val[id] === option[id]);
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${isSelected ? "selected" : ""}`}
              key={`${id}-${index}`}
            >
                {(option.type === "Form" && (
            <img
              src={formIcon}
              alt="icon"
              style={{ width: "30px", height: "30px" }}
            />
          )) ||
            (option.type === "RessourceScoring" && (
              <img
                src={statsIcon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              />
            )) ||
            (option.type === "Contract" && (
              <img
                src={contractIcon}
                alt="icon"
                style={{ width: "30px", height: "30px" }}
              />
            )) ||
            (option.type === "Document" &&
              (option.hasAIProcessing ? (
                <img
                  src={contractIcon}
                  alt="icon"
                  style={{ width: "30px", height: "30px" }}
                />
              ) : (
                <img
                  src={docIcon}
                  alt="icon"
                  style={{ width: "25px", height: "25px" }}
                />
              ))) || <></>}
              {option[label]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableRessourceDropdown;
