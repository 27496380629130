import React, { useEffect, useState } from 'react'
import { ReglementEntity } from '../../../domain/entities'
import { useTranslation } from '../../../contexts/TranslationProvider'
import InputField from '../../../components/common/newDesign/InputField'
import { MdModeEditOutline } from "react-icons/md";
import { FaSave } from "react-icons/fa";
import Loading from '../../../components/common/Loading';

function EditReglement({ reglement, handleSave, isUpdateLoading }: { reglement: ReglementEntity, handleSave: (reglement: ReglementEntity) => void, isUpdateLoading: boolean }) {
  const { t } = useTranslation()
  const [predefinedQuestions, setPredefinedQuestions] = useState<string[]>(reglement.predefined_questions || [])
  const [addNewQuestion, setAddNewQuestion] = useState(false)
  const [newQuestion, setNewQuestion] = useState("")
  const [chunkSize, setChunkSize] = useState(reglement.chunk_size)
  const [similarityThreshold, setSimilarityThreshold] = useState(reglement.similarity_threshold)
  const [chunkOverlap, setChunkOverlap] = useState(reglement.chunk_overlap)
  const [topK, setTopK] = useState(reglement.k)
  const [compressionChunkSize, setCompressionChunkSize] = useState(reglement.compression_chunk_size)
  const [compressionTopK, setCompressionTopK] = useState(reglement.compression_k)
  const [fieldsErrors, setFieldsErrors] = useState({} as any)
  const [isEditing, setIsEditing] = useState(false)
  console.log("chunkSize", chunkSize)
  const validateInputs = () => {
    const errors: any = {};

    // Validate chunk size
    if (chunkSize != null && typeof chunkSize === "number" && (chunkSize < 100 || chunkSize > 10000)) {
      errors.chunkSizeError = [t("pages.pilot.popups.reglement.editForm.chunkSize.error1")];
    }

    // Validate chunk overlap
    if ((chunkOverlap != null && chunkSize != null) && chunkOverlap >= chunkSize) {
      errors.chunkOverlapError = [t("pages.pilot.popups.reglement.editForm.chunkOverlap.error1")];
    }

    // Validate similarity thresholds
    if (similarityThreshold != null && (similarityThreshold < 0.2 || similarityThreshold > 0.8)) {
      errors.similarityThresholdError = [t("pages.pilot.popups.reglement.editForm.similarityThreshold.error1")];
    }

    // Validate compression chunk size
    if (compressionChunkSize != null) {
      if (compressionChunkSize > 1000 || compressionChunkSize < 100) {
        errors.compressionChunkSizeError = [t("pages.pilot.popups.reglement.editForm.compressionChunkSize.error1")];
      }
      else if (chunkSize && compressionChunkSize >= chunkSize) {
        errors.compressionChunkSizeError = [t("pages.pilot.popups.reglement.editForm.compressionChunkSize.error2")];
      }
    }

    // Validate Top K
    if (topK != null && (topK < 1 || topK > 20)) {
      errors.topKError = [t("pages.pilot.popups.reglement.editForm.topK.error1")];
    }
    if (compressionTopK != null && (compressionTopK < 1 || compressionTopK > 10)) {
      errors.compressionTopKError = [t("pages.pilot.popups.reglement.editForm.compressionTopK.error1")];
    }
    console.log("errors", errors)
    return errors;
  };
  useEffect(() => {
    setFieldsErrors({})
    const errors = validateInputs()
    setFieldsErrors(errors)
  }, [chunkSize, similarityThreshold, chunkOverlap, topK, compressionChunkSize, compressionTopK])
  const handleSaveClick = () => {
    if (Object.keys(fieldsErrors).length > 0) {
      return
    }
    handleSave({
      chunk_size: chunkSize,
      similarity_threshold: similarityThreshold,
      chunk_overlap: chunkOverlap,
      k: topK,
      compression_chunk_size: compressionChunkSize,
      compression_k: compressionTopK,
      predefined_questions: predefinedQuestions
    })
    setIsEditing(false)
  }
  return (
    <div className='edit-reglement'>
      {isUpdateLoading && <div className="update-loading">
        <Loading height="50vh" />
      </div>}
      <div className='edit-icon'>
        {!isEditing ?
          <MdModeEditOutline
            onClick={() => setIsEditing(true)}
          /> :
          <FaSave
            onClick={handleSaveClick}
          />
        }
      </div>
      <div style={{ marginTop: "20px", paddingBottom: "20px" }}>
        <span className="section-title">
          {t("pages.pilot.popups.reglement.predifinedQuestions")}:
        </span>
        <div className="suggestions">
          {isEditing && predefinedQuestions && predefinedQuestions.length < 4 && <div onClick={(e) => {
            e.stopPropagation()
            !addNewQuestion && setAddNewQuestion(true)
          }} className="suggest-add" key={-1}>
            {
              addNewQuestion ?
                <>
                  <InputField
                    onChange={(e) => {
                      setNewQuestion(e.target.value)
                    }}
                    value={newQuestion}
                    placeholder={t("pages.pilot.popups.reglement.enterQuestion")}
                    label={t("pages.pilot.popups.reglement.question")}
                    name="question"
                    required={true}
                    type={"text"}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                    <button onClick={(e) => {
                      if (newQuestion) {
                        setPredefinedQuestions([...predefinedQuestions, newQuestion])
                      }
                      setAddNewQuestion(false)
                      setNewQuestion("")
                    }}
                      style={{ marginTop: "10px" }}
                    >{t("pages.pilot.popups.reglement.add")}</button>
                    <button onClick={(e) => {
                      setAddNewQuestion(false)
                      setNewQuestion("")
                    }}
                      style={{ marginTop: "10px" }}
                    >
                      {t("pages.pilot.popups.reglement.cancel")}
                    </button>
                  </div>
                </>
                :
                t("pages.pilot.popups.reglement.addQuestion")
            }
          </div>}
          {predefinedQuestions.map((question, index) => (
            <div className="suggest-card" key={index}>
              {isEditing && <span
                className="delete-suggest-card"
                onClick={() => {
                  setPredefinedQuestions(predefinedQuestions.filter((item, i) => i !== index))
                }}
              >
                X
              </span>}
              {question}
            </div>
          ))}
        </div>
      </div>
      <span className="section-title">
        {t("pages.pilot.popups.reglement.hyperParameters")}
      </span>
      <div className="hyper-params-section">
        <div className="hyper-params-col">
          <div className="hyper-param-container">
            <InputField
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setChunkSize(value)
              }}
              value={chunkSize}
              placeholder={t("pages.pilot.popups.reglement.editForm.chunkSize.placeholder")}
              label={t("pages.pilot.popups.reglement.editForm.chunkSize.name")}
              name="chunkSize"
              type={"number"}
              description={t("pages.pilot.popups.reglement.editForm.chunkSize.description")}
              error={fieldsErrors.chunkSizeError || []}
              disabled={!isEditing || reglement.has_vectorstore}
            />
          </div>
          <div className="hyper-param-container">
            <InputField
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setSimilarityThreshold(value)
              }}
              value={similarityThreshold}
              placeholder={t("pages.pilot.popups.reglement.editForm.similarityThreshold.placeholder")}
              label={t("pages.pilot.popups.reglement.editForm.similarityThreshold.name")}
              name="similarityThreshold"
              description={t("pages.pilot.popups.reglement.editForm.similarityThreshold.description")}
              type={"number"}
              error={fieldsErrors.similarityThresholdError || []}
              disabled={!isEditing}
            />
          </div>
        </div>
        <div className="hyper-params-col">
          <div className="hyper-param-container">
            <InputField
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setChunkOverlap(value)
              }}
              value={chunkOverlap}
              placeholder={t("pages.pilot.popups.reglement.editForm.chunkOverlap.placeholder")}
              label={t("pages.pilot.popups.reglement.editForm.chunkOverlap.name")}
              name="chunkOverlap"
              description={t("pages.pilot.popups.reglement.editForm.chunkOverlap.description")}
              type={"number"}
              error={fieldsErrors.chunkOverlapError || []}
              disabled={!isEditing || reglement.has_vectorstore}
            />
          </div>
          <div className="hyper-param-container">
            <InputField
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setTopK(value)
              }}
              value={topK}
              placeholder={t("pages.pilot.popups.reglement.editForm.topK.placeholder")}
              label={t("pages.pilot.popups.reglement.editForm.topK.name")}
              name="topK"
              description={t("pages.pilot.popups.reglement.editForm.topK.description")}
              type={"number"}
              error={fieldsErrors.topKError || []}
              disabled={!isEditing}
            />
          </div>
        </div>
      </div>
      <div className="compression-params-header">
        <span className="title">
          {t("pages.pilot.popups.reglement.compressionParameters")}
        </span>
      </div>
      <div className="hyper-params-section">
        <div className="hyper-params-col">
          <div className="hyper-param-container">
            <InputField
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setCompressionChunkSize(value)
              }}
              value={compressionChunkSize}
              placeholder={t("pages.pilot.popups.reglement.editForm.compressionChunkSize.placeholder")}
              label={t("pages.pilot.popups.reglement.editForm.compressionChunkSize.name")}
              name="compressionChunkSize"
              type={"number"}
              description={t("pages.pilot.popups.reglement.editForm.compressionChunkSize.description")}
              error={fieldsErrors.compressionChunkSizeError || []}
              disabled={!isEditing}
            />
          </div>
        </div>
        <div className="hyper-params-col">
          <div className="hyper-param-container">
            <InputField
              onChange={(e) => {
                const value = parseFloat(e.target.value)
                setCompressionTopK(value)
              }}
              value={compressionTopK}
              placeholder={t("pages.pilot.popups.reglement.editForm.compressionTopK.placeholder")}
              label={t("pages.pilot.popups.reglement.editForm.compressionTopK.name")}
              name="compressionTopK"
              description={t("pages.pilot.popups.reglement.editForm.compressionTopK.description")}
              type={"number"}
              error={fieldsErrors.compressionTopKError || []}
              disabled={!isEditing}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditReglement