//this function is used to calculate the completionPercentage of VariableInput
// updated so it does not concider calculating the checkboxes as a false and true are considered inputs


import RenderListSegmentedText from "../components/RenderSegmentedText/RenderListSegmentedText";
import { LanguageType } from "../contexts/TranslationProvider";
import { areConditionsMet } from "../domain/FormTemplate";
import { BeneficialCompanyEntity, BeneficialEntity, BeneficialMacroEntity, BeneficialMinorEntity, BeneficialPersonEntity, ClauseEntity, ContractEntity, ContractTemplateEntity, FormEntity, FormTemplatePageEntity } from "../domain/entities";
import { RenderSegment, RenderSegments, SegmentedClauseParam, SegmentedTextType } from "../domain/types/ClauseParams";
import { FormParam } from "../domain/types/FormParams";

import { ContractTemplateParsedData, ParsedClauseParam } from "./ParseTemplateData";
import { getRenderedParams } from "./renderedParams";
import { getRenderSegments } from "./segmentation";

export function isValidInputValue(param: FormParam | SegmentedClauseParam, value: any) {
  switch (param.type) {
    case 'beneficial[]':
    case 'csv':
      return Array.isArray(value) && value.length > 0
    case 'table':
    case 'list':
      return param.args?.length ? Array.isArray(value) && value.length > 0 : true
    case 'enum':
      return param.args?.length ? !(value === null || value === undefined || value === "") : true
    case 'number':
      return !(value === null || value === undefined || value === "" || isNaN(value))
    default:
      return !(value === null || value === undefined || value === "")
  }
}
export function isBeneficialComplete(clauseRenderSegments: RenderSegments, param: FormParam | ParsedClauseParam) {
  const missingFields = getBeneficialMissingFields(clauseRenderSegments, param)
  return missingFields.length === 0
}
export function getBeneficialMissingFields(clauseRenderSegments: RenderSegments, param: FormParam | ParsedClauseParam) {
  return clauseRenderSegments.filter(segment =>
    (segment.type === SegmentedTextType.PARAM && segment.value.split('.')[0] === param.name))
    .map(segment => {
      const parts = segment.value.split('.')
      if (!isNaN(parseInt(parts[parts.length - 1]))) {
        return [...parts.slice(1, parts.length - 1)].join('.')
      }
      return parts.slice(1).join('.')
    })
}
export function calculateFormCompletionPercentage(
  paramValues: FormEntity['paramValues'],
  params: FormTemplatePageEntity['params']
): number {
  let filledVariablesCount = 0;
  const filteredParams = params
    .filter(p => !p.formula)
    .filter(p =>
      p.type != 'list'
      && p.type != 'table'
    )
    .filter(
      (param) =>
        areConditionsMet(param, paramValues)
    );

  const totalVariablesCount = filteredParams.length;

  const validInputParams = filteredParams
    .filter((param) => {
      const value = paramValues[param.name];
      return isValidInputValue(param, value)
    });

  filledVariablesCount = validInputParams.length

  return totalVariablesCount > 0
    ? Math.round((filledVariablesCount / totalVariablesCount) * 100)
    : 0;
}

export function calculateFormPageCompletionPercentage(
  paramValues: FormEntity['paramValues'],
  page: FormTemplatePageEntity,
): number {
  let filledVariablesCount = 0;
  const filteredParams = page.params
    .filter(p => !p.formula)
    .filter(p =>
      p.type != 'list'
      && p.type != 'table'
    )
    .filter(
      (param) =>
        areConditionsMet(param, paramValues)
    );
  const totalVariablesCount = filteredParams.length;

  let unCompleteParams = []
  filteredParams.forEach((param) => {
    const value = paramValues[param.name];
    if (isValidInputValue(param, value)) {
      filledVariablesCount++;
    } else {
      unCompleteParams.push(param)
    }
  });

  // if (unCompleteParams.length){

  //   console.warn(unCompleteParams);
  //   console.warn(filteredParams);
  // }


  return totalVariablesCount > 0
    ? Math.round((filledVariablesCount / totalVariablesCount) * 100)
    : 0;
}

export function calculateCompletionPercentage(
  macros: BeneficialMacroEntity[],
  inputValues: ContractEntity['paramValues'],
  combinedParams: ParsedClauseParam[],
  excludedClauses: ContractEntity['excludedClauses'],
  excludedSubClauses: ContractEntity['excludedClauses'],
  beneficialsMap: Record<string, BeneficialEntity>,
  clauses: ContractTemplateParsedData,
  t: (key: string) => string,
  language: LanguageType
) {
  let filledVariablesCount = 0;

  const filteredCombinedParams = combinedParams
    .filter(p => !p.formula)
    .filter(p =>
      p.type != 'list'
      && p.type != 'table'
    )
    .filter(
      (param) =>
        param.type !== 'index' &&
        param.type !== 'static-table' &&
        !excludedClauses?.includes(param.clauseId) &&
        !(param.subClauseId && excludedSubClauses?.includes(param.subClauseId))
    );

  let totalVariablesCount = filteredCombinedParams.length;

  const filteredBeneficialParams = filteredCombinedParams.filter(param => param.type === 'beneficial')
  const filteredNonBeneficialParams = filteredCombinedParams.filter(param => param.type !== 'beneficial')

  // handle params that are beneficials
  filteredBeneficialParams.forEach((param) => {
    const currentBeneficialId = inputValues[param.name]
    const beneficial = beneficialsMap[currentBeneficialId];
    if (!beneficial) return;
    let beneficialComplete = true;
    for (const clause of clauses) {
      const { segmentation } = clause;

      const clauseRenderSegments = getRenderSegments(
        macros,
        segmentation.segmentedText,
        inputValues,
        {},
        beneficialsMap,
        segmentation.segmentedParams,
        t,
        language
      );

      const isClauseBeneficialComplete = isBeneficialComplete(clauseRenderSegments, param);

      if (!isClauseBeneficialComplete) {
        console.log("detected an incomplete beneficial with missing fields: ", getBeneficialMissingFields(clauseRenderSegments, param))
        beneficialComplete = false;
        break; // No need to check further if clause is incomplete
      }

      for (const subClause of clause.subClauses) {
        const subClauseRenderSegments = getRenderSegments(
          macros,
          subClause.segmentation.segmentedText,
          inputValues,
          {},
          beneficialsMap,
          subClause.segmentation.segmentedParams,
          t,
          language
        );

        const isSubClauseBeneficialComplete = isBeneficialComplete(subClauseRenderSegments, param);

        if (!isSubClauseBeneficialComplete) {
          console.log("detected an incomplete beneficial with missing fields: ", getBeneficialMissingFields(subClauseRenderSegments, param))
          beneficialComplete = false;
          break;
        }
      }

      if (!beneficialComplete) break;
    }

    if (beneficialComplete) {
      filledVariablesCount++;
    }
  });


  // handle params that are NOT beneficials
  filteredNonBeneficialParams.forEach((param) => {
    const value = inputValues[param.name]
    if (isValidInputValue(param, value)) {
      filledVariablesCount++;
    }
  });

  return Math.round((filledVariablesCount / totalVariablesCount) * 100);
}

export function calculateContractClauseCompletionPercentage(
  inputValues: ContractEntity['paramValues'],
  clause: ClauseEntity,
  macros: BeneficialMacroEntity[],
) {
  let filledVariablesCount = 0;
  const paramNames = getRenderedParams(macros, clause.segmentation, inputValues)
  const params = clause.segmentation.segmentedParams
    .filter(p => !p.formula)
    .filter(p =>
      p.type != 'list'
      && p.type != 'table'
    )
    .filter(p => paramNames.includes(p.name))
  const filteredParams = params.filter(
    (param) =>
      param.type !== 'index' &&
      param.type !== 'static-table'
  );

  let totalVariablesCount = filteredParams.length;

  filteredParams.forEach((param) => {
    const value = inputValues[param.name]
    if (isValidInputValue(param, value)) {
      filledVariablesCount++;
    }
  });

  return Math.round((filledVariablesCount / totalVariablesCount) * 100);
}