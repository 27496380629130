import React, { useEffect, useMemo, useRef, useState } from "react";
import "./filePreviewModal.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { DocumentEntity } from "../../../domain/entities";
import { AiOutlineUpload } from "react-icons/ai";
import { UtilsClient } from "../../../services/api/UtilsClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
const lookup = require("../../../assets/lookup.png");

interface FileProps {
  isOpen?: boolean;
  onRequestClose?: any;
  fileDocument?: DocumentEntity | File;
  text?: any;
  onLookup?: (query: string) => void;
  onSelect?: (file: DocumentEntity) => void;
  selectedFileName?: DocumentEntity["name"];
  fileOptions?: DocumentEntity[];
  setSelectedFileName?: (value: string) => void;
  onAddDocument?: (document: DocumentEntity) => void;
  newFile?: File;
  setNewFile?: (file: File) => void;
  setFileToPreview?: (file: DocumentEntity) => void;
  files?: DocumentEntity[]
}
const FilePreviewModal = ({
  isOpen,
  onRequestClose,
  fileDocument,
  text,
  onLookup,
  onSelect,
  fileOptions,
  selectedFileName,
  setSelectedFileName,
  onAddDocument,
  setNewFile,
  newFile,
  setFileToPreview,
  files
}: FileProps) => {
  const { t, language } = useTranslation();
  const translationPath = t("modals.previewFile");
  const [inputValue, setInputValue] = useState<string>("");
  const dropdownRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const apiClient = useApiClientWithLoading()
  const utilsClient = new UtilsClient(apiClient)

  useEffect(() => {
    if ((fileDocument as DocumentEntity)?.file?.url) {
      setPreviewUrl((fileDocument as DocumentEntity)?.file?.url)
    } else if (newFile) {
      switch (newFile.type) {
        case "docx":
        case "doc":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          utilsClient.uploadTmpFile(newFile).then(({ url }) => {
            console.log(url);
            setPreviewUrl(url)
          })
          break;
        default:
          setPreviewUrl(URL.createObjectURL(newFile!))
          break;
      }
    }

  }, [fileDocument, newFile])

  useEffect(() => {
    if (!isOpen) {
      setSelectedFileName && setSelectedFileName("");
      setNewFile && setNewFile(null);
      setInputValue("");
      setFileToPreview && setFileToPreview(null);
      setErrorMessage(null);
    }
  }, [isOpen]);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setInputValue("");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setInputValue]);
  if (!isOpen) {
    return null;
  }


  const renderContentBasedOnFileExtension = (document) => {
    const docType = document?.fileExtenstion
      ? document?.fileExtenstion.toLowerCase()
      : document?.type
    switch (docType) {
      case "pdf":
      case "application/pdf":
        return (
          <div className="document-pdf-viewer-preview">
            <iframe
              src={previewUrl}
              width="100%"
              height="600px"
            />
          </div>
        );
      case "docx":
      case "doc":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <div className="document-docx-viewer-preview">
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${previewUrl}`}
              width="100%"
              height="600px"
            />
          </div>
        );
      case "jpeg":
      case "jpg":
      case "png":
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return (
          <div className="document-image-viewer">
            <img
              src={previewUrl}
              alt={document?.name}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        );
      default:
        return (
          <div className="ai-text-container">
            <div className="ai-text">{text}</div>
          </div>
        );
    }
  };
  const handleInputChange = async (e) => {
    const query = e.target.value;
    setInputValue(query);
    setErrorMessage(null)
    try {
      if (onLookup) {
        onLookup(query);
        setSelectedFileName("");
        setNewFile(null);
        setFileToPreview(null);
        setErrorMessage(null);
      }
    } catch (err) {
      console.error("Error during file lookup:", err);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const fileNameExists = files?.some(
        (existingFile) => existingFile.name === file.name
      );
      if (fileNameExists) {
        setErrorMessage(t("modals.previewFile.fileExistsError"));
        setNewFile(file);
      } else {
        setErrorMessage(null);
        setNewFile(file);
      }
    }
  };
  const handleAddDocument = () => {
    if (onAddDocument && !errorMessage) {
      onAddDocument(fileDocument ? fileDocument : newFile);
    }
  };
  return (
    <>
      <div className="modal-backdrop"></div>
      <div className="modal  d-flex justify-content-center align-items-center">
        <div className="modal-content modal-content-preview-file">
          <div className="modal-header">
            <h5 className="modal-title">
              {onLookup ? translationPath.addTitle : translationPath.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onRequestClose}
              aria-label="Close"
              style={{ marginRight: language === "ar" && "75%" }}
            ></button>
          </div>
          <div
            className="p-3"
            style={{ maxHeight: "600px", marginBottom: "5%" }}
          >
            {onLookup ? (
              <div style={{ position: "relative", display: "flex" }}>
                {" "}
                <input
                  type="text"
                  value={
                    selectedFileName
                      ? selectedFileName
                      : newFile
                        ? newFile.name
                        : inputValue
                  }
                  onChange={handleInputChange}
                  placeholder={translationPath.searchFile}
                  style={{
                    marginBottom: "10px",
                    border: "1px solid #ccc",
                    padding: "0.5rem",
                  }}
                />
                {!inputValue && (
                  <img
                    src={lookup}
                    className={`lookup-img-file ${language === "ar" ? "lookup-img-arabic-file" : ""
                      }`}
                  />
                )}
                <div className="add-new-file-container">
                  <input type="file" onChange={handleFileChange} />
                  <span className="file-upload-link">
                    <AiOutlineUpload
                      style={{
                        marginRight: "2rem",
                        marginLeft: "2rem",
                        color: "#2f14e5",
                        cursor: "pointer",
                        marginTop: "1rem"
                      }}
                    />
                  </span>
                </div>
              </div>
            ) : (
              <h4>{fileDocument?.name}</h4>
            )}
            {!selectedFileName && inputValue && (
              <ul className="dropdown-list-files" ref={dropdownRef}>
                {fileOptions?.length > 0 &&
                  fileOptions?.map((file) => (
                    <li
                      key={file.name}
                      onClick={() => onSelect(file)}
                      className="dropdown-item"
                    >
                      {file.name}
                    </li>
                  ))}
              </ul>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {(fileDocument || newFile) &&
              renderContentBasedOnFileExtension(
                fileDocument ? fileDocument : (newFile as File)
              )}
          </div>
          {onLookup && (
            <button
              disabled={!(newFile || fileDocument)}
              className={`btn-add-file ${!(newFile || fileDocument) ? "disabled-btn" : ""
                }`}
              onClick={handleAddDocument}
            >
              {translationPath.btn}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FilePreviewModal;
