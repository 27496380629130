import { cloneDeep } from "lodash";
import { getClauseName, getSubClauseName } from "../helpers/ParseTemplateData";
import { getRenderSegments } from "../helpers/segmentation";
import { transpose } from "../utils/array";
import { BeneficialMacroEntity, ClauseEntity, ContractEntity, ContractTemplateEntity, SubClauseEntity } from "./entities";
import { ParagraphProps } from "./entities/ParagraphProps";
import { RenderSegment, SegmentedTextType, segmentedTextStyle } from "./types/ClauseParams";
import { isClauseExcluded, isSubClauseExcluded } from "../helpers/helper";
import { countParamsOccurencesUntilPosition, findSegmentByIdInTemplate } from "./ContractTemplate";
import { render } from "@testing-library/react";
import { DEFAULT_PARAM_BOLD_STYLE, LIST_INDENTATION_HANGING, LIST_INDENTATION_LEFT } from "../config"


export type ContractExportText = {
	name: string;
	preambule?: {
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
		revisions?: ({
			type: 'ins' | 'del';
			name: string;
			text: string;
			subClauses: ({
				name: string;
				text: string;
			})[];
		})[];
	};
	clauses: ({
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
		revisions?: ({
			type: 'ins' | 'del';
			name: string;
			text: string;
			subClauses: ({
				name: string;
				text: string;
			})[];
		})[];
	})[];
	signature?: {
		name: string;
		text: string;
		subClauses: ({
			name: string;
			text: string;
		})[];
	};
}
export function getOverridenContractTemplate(contract: ContractEntity): ContractTemplateEntity {
	let contractTemplate = cloneDeep(contract.template);

	// Apply clause exclusion
	const excludedClauses = contract.excludedClauses;
	contractTemplate.clauses = contractTemplate.clauses.filter((clause) => !isClauseExcluded(clause.id, excludedClauses));

	const excludedSubClauses = contract.excludedSubClauses;
	contractTemplate.clauses.forEach((clause) => {
		clause.subClauses = clause.subClauses.filter((subClause) => !isSubClauseExcluded(subClause.id, excludedSubClauses));
	});

	// Apply segmentation override for clauses and sub-clauses
	const clausesOverrides = contract.clausesOverrides;
	contractTemplate.clauses.forEach((clause) => {
		const override = clausesOverrides?.[clause.id!];

		if (override) {
			if (override.segmentation) {
				clause.segmentation = override.segmentation;
			}
			if (override.subClauses) {
				clause.subClauses = Object.values(override.subClauses).map((subClauseOverride, idx) => ({
					id: subClauseOverride.subClauseId,
					index: subClauseOverride.subClauseIndex !== undefined
						? subClauseOverride.subClauseIndex.toString()
						: idx.toString(),
					name: subClauseOverride.subClauseName,
					segmentation: subClauseOverride.segmentation ?? undefined,
				}));

			} else {
				clause.subClauses = [];
			}
		}
	});

	return contractTemplate;
}


export function getContractExportData(macros: BeneficialMacroEntity[], contract: ContractEntity, t: (str: string) => string, paragraphProps: ParagraphProps[]): ContractExportText {
	const template: ContractTemplateEntity = getOverridenContractTemplate(contract)
	const originalTemplate = contract.template

	const clauseTextProps = paragraphProps.find((p) => p.placeholder === 'clause.text')
	const subClauseTextProps = paragraphProps.find((p) => p.placeholder === 'subClause.text')

	let clauses: ContractExportText["clauses"] = []
	template.clauses?.map((clause, clauseIndex) => {
		const originalClause = originalTemplate.clauses[clauseIndex]
		const allParamOccurences = countParamsOccurencesUntilPosition(template, clause.id, null, contract.paramValues, t, "fr")
		const indexParams = clause.segmentation?.segmentedParams.filter(param => param.type == 'index').map(param => param.name)
		const indexOccurences = indexParams.reduce((acc, param) => {
			if (allParamOccurences[param]) {
				acc[param] = allParamOccurences[param]
			}
			return acc
		}, {})
		const renderSegments = getRenderSegments(macros, clause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, clause.segmentation?.segmentedParams!, t, 'fr', undefined, true, indexOccurences)
		const text = renderSegmentsToDocXmlNew(contract, clause, renderSegments, clauseTextProps)
		let clauseDocx: ContractExportText["clauses"][number] = {
			name: getClauseName(template, clause, clauseIndex),
			text: text,
			subClauses: clause.subClauses?.map((subClause, subClauseIndex) => {
				const allParamOccurences = countParamsOccurencesUntilPosition(template, clause.id, subClause.id, contract.paramValues, t, "fr")
				const indexParams = subClause.segmentation?.segmentedParams.filter(param => param.type == 'index').map(param => param.name)
				const indexOccurences = indexParams.reduce((acc, param) => {
					if (allParamOccurences[param]) {
						acc[param] = allParamOccurences[param]
					}
					return acc
				}, {})
				const renderSegments = getRenderSegments(macros, subClause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, subClause.segmentation?.segmentedParams!, t, 'fr', undefined, true, indexOccurences)
				const text = renderSegmentsToDocXmlNew(contract, subClause, renderSegments, subClauseTextProps)
				return {
					name: getSubClauseName(template, clause, subClause, clauseIndex, subClauseIndex),
					text: text,
				}
			}
			)
		}

		if (originalClause.id != clause.id) {
			const renderSegments = getRenderSegments(macros, originalClause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, originalClause.segmentation?.segmentedParams!, t, 'fr', undefined, true)
			let deletedText = `<w:del w:author="Contractzlab" w:date="${new Date(originalClause.updatedAt).toISOString()}">
	${renderSegmentsToDocXmlNew(contract, originalClause, renderSegments, clauseTextProps, false)
					.replaceAll(/\<w:t\>/g, "<w:delText>")
					.replaceAll(/\<w:t\s/g, "<w:delText ")
					.replaceAll(/\<\/w:t\>/g, "</w:delText>")
					.replace(/\<w:delText\>$/g, "<w:t>")
				}
    </w:del>`
				.replaceAll(/>\n\s*</g, "><")

			originalClause.subClauses?.map((subClause, subClauseIndex) => {
				const renderSegments = getRenderSegments(macros, subClause.segmentation?.segmentedText!, contract.paramValues, contract.fileNames, contract.beneficialsMap, subClause.segmentation?.segmentedParams!, t, 'fr', undefined, true)
				deletedText += `<w:del w:author="Contractzlab" w:date="${new Date(originalClause.updatedAt).toISOString()}">
				<w:br>
				${escapeXml(getSubClauseName(template, clause, subClause, clauseIndex, subClauseIndex))}
				<w:br>
					${renderSegmentsToDocXmlNew(contract, subClause, renderSegments, subClauseTextProps, false)
						.replaceAll(/\<w:t\>/g, "<w:delText>")
						.replaceAll(/\<w:t\s/g, "<w:delText ")
						.replaceAll(/\<\/w:t\>/g, "</w:delText>")
						.replace(/\<w:delText\>$/g, "<w:t>")
					}
				</w:del>`
					.replaceAll(/>\n\s*</g, "><")
				return {
					name: getSubClauseName(template, clause, subClause, clauseIndex, subClauseIndex),
					text: text,
				}
			}
			)
			clauseDocx.text = deletedText + clauseDocx.text
		}
		clauses.push(clauseDocx)

	})
	let textExport: ContractExportText = {
		name: contract.name!,
		clauses,
	}
	return textExport
}

export function escapeXml(unsafe) {
	try {
		if (!unsafe)
			return ""
		if (typeof unsafe != "string")
			unsafe = String(unsafe)
		const xmlInvalidCharRegex = /[^\u0009\u000A\u000D\u0020-\uD7FF\uE000-\uFFFD\u{10000}-\u{10FFFF}]/gu;
		return unsafe
			.replace(xmlInvalidCharRegex, "")
			.replace(/&/g, "&amp;")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;")
			.replace(/"/g, "&quot;")
			.replace(/'/g, "&apos;");
	} catch (error) {
		console.error(error);

		console.error(unsafe);

		return ""
	}

}

const applyStyles = (segmentStyle: segmentedTextStyle, fontFamily: string, fontSize: string, fontColor: string) => {
	let styleXml = '<w:rPr>';
	styleXml += `<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>`;
	styleXml += `<w:sz w:val="${fontSize}"/>`;
	styleXml += `<w:color w:val="${fontColor}"/>`;

	if (segmentStyle?.bold) {
		styleXml += '<w:b/>';
	} else {
		styleXml += '<w:b w:val="0"/>';
	}

	if (segmentStyle?.italic) {
		styleXml += '<w:i/>';
	}

	if (segmentStyle?.underline) {
		styleXml += '<w:u w:val="single"/>';
	}

	styleXml += '</w:rPr>';
	return styleXml;
};

function renderParagraph(segments, alignment, contract, clause, paragraphProp) {
	let spacing = paragraphProp?.spacing ?? { before: "0", after: "0", lineSpacing: "240", lineRule: "auto" };
	let indentation = paragraphProp?.indentation ?? { left: "0", right: "0" };
	const paragraphContent = segments.map((segment) => renderSegmentToDocXml(segment, contract, clause, paragraphProp, alignment)).join("");

	return `<w:p>
	<w:pPr>
			<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" w:lineRule="${spacing.lineRule}" w:line="${spacing.lineSpacing}" />
			<w:ind w:left="${indentation.left}" w:right="${indentation.right}" />
			${alignment && `<w:jc w:val="${alignment == "justify" ? "both" : alignment}" />`}
	</w:pPr>
	${paragraphContent}
</w:p>`;
}

export function renderSegmentsToDocXmlNew(contract: ContractEntity, clause: ClauseEntity, renderSegments: RenderSegment[], paragraphProp: ParagraphProps, addParagraphStart = true) {

	const isEmptyClause = renderSegments.length == 0 || renderSegments.every((seg) => (seg.value || "").trim().length == 0)
	if (isEmptyClause) {
		return ""
	}

	let docXml = "";
	let currentParagraph = [];
	let alignment = "left";
	let isInTable = false;
	let insideList = false;
	let currentListElement = { children: [], alignment: "left" };
	let currentList = { children: [], type: "bulleted-list" };

	renderSegments.forEach((segment, idx) => {
		switch (segment.type) {
			case SegmentedTextType.PARAGRAPH_START:
				// If there are segments in the current paragraph, finalize it
				if (currentParagraph.length > 0) {
					docXml += renderParagraph(currentParagraph, alignment, contract, clause, paragraphProp);
					currentParagraph = [];
				}
				alignment = segment.style?.textAlign || "left";
				if (insideList) {
					if (currentListElement.children.length > 0) {
						currentList.children.push(currentListElement);
						currentListElement = { children: [], alignment: "left" };
					}
					if (currentList.children.length > 0) {
						docXml += renderList(contract, clause, currentList, paragraphProp, idx);
						currentList = { type: 'bulleted-list', children: [] };
					}
					insideList = false;
				}
				break;

			case SegmentedTextType.STATIC_TABLE_START:
				insideList = false;
				if (currentParagraph.length > 0) {
					docXml += renderParagraph(currentParagraph, alignment, contract, clause, paragraphProp);
					currentParagraph = [];
				}
				const paramName = segment.paramName;
				const tableEndIndex = renderSegments.findIndex(
					(seg) => seg.type === SegmentedTextType.STATIC_TABLE_END && seg.paramName === paramName
				);
				const tableSegments = renderSegments.slice(idx, tableEndIndex + 1);
				let borderColor = segment.style?.borderColor ?? "#000000";
				borderColor = borderColor === "transparent" ? "FFFFFF" : borderColor.replace("#", "");
				docXml += renderStaticTableXml(tableSegments, contract, clause, {
					...paragraphProp,
					indentation: { left: 0, right: 0 },
				}, borderColor);
				idx = tableEndIndex;
				isInTable = true;
				break;

			case SegmentedTextType.STATIC_TABLE_END:
				isInTable = false;
				break;
			case SegmentedTextType.LIST_ITEM_START:
				if (insideList) {
					if (currentListElement.children.length > 0) {
						currentList.children.push(currentListElement);
						currentListElement = { children: [], alignment: segment?.style?.textAlign };
					}
					const isListNew = segment.style.listType ? true : false;
					if (isListNew) {
						if (currentList.children.length > 0) {
							docXml += renderList(contract, clause, currentList, paragraphProp, idx);
							currentList = { type: segment.style.listType, children: [] };
						}
					}
				} else {
					if (currentParagraph.length > 0) {
						docXml += renderParagraph(currentParagraph, alignment, contract, clause, paragraphProp);
						currentParagraph = [];
					}
					currentList = { type: segment?.style?.listType, children: [] };
					currentListElement = { children: [], alignment: segment?.style?.textAlign };
					insideList = true;
				}
				break;
			default:
				if (!isInTable && !insideList) {
					currentParagraph.push(segment);
				} else {
					if (insideList) {
						currentListElement.children.push(segment);
						return;
					}
				}
				break;
		}
	});
	if (insideList) {
		if (currentListElement.children.length > 0) {
			currentList.children.push(currentListElement);
			currentListElement = { children: [], alignment: "left" };
		}
		if (currentList.children.length > 0) {
			docXml += renderList(contract, clause, currentList, paragraphProp, renderSegments.length);
			currentList = { type: 'bulleted-list', children: [] };
		}
	}
	if (currentParagraph.length > 0) {
		docXml += renderParagraph(currentParagraph, alignment, contract, clause, paragraphProp);
	}
	if (addParagraphStart) {
		docXml += getParagraphStart(paragraphProp)
	}
	return docXml.replaceAll(/>\n\s*</g, "><")
		.replaceAll('\n', '<w:br/>')
}

function getParagraphStart(paragraphProp: ParagraphProps) {
	let alignment = "justify";
	const indentation = paragraphProp?.indentation ?? { left: "0", right: "0" };
	const spacing = paragraphProp?.spacing ?? { before: "0", after: "0", lineSpacing: "240", lineRule: "auto" };
	const fontFamily = paragraphProp?.font?.family ?? "Arial";
	const fontSize = String(Number(paragraphProp?.font?.size) || 22);
	const fontColor = paragraphProp?.font?.color ?? "000000";
	return `<w:p>
		<w:pPr>
				<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" w:lineRule="${spacing.lineRule}" w:line="${spacing.lineSpacing}" />
				<w:ind w:left="${indentation.left}" w:right="${indentation.right}" />
				${alignment && `<w:jc w:val="${alignment == "justify" ? "both" : alignment}" />`}
		</w:pPr>
		<w:r>
		<w:rPr>
				<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>
				<w:sz w:val="${fontSize}"/>
				<w:color w:val="${fontColor}"/>
		</w:rPr>
		<w:t>`
		.replaceAll(/>\n\s*</g, "><")
		.replaceAll('\n', '<w:br/>')
}

function renderList(contract, clause, list, paragraphProp, listIndex) {
	let listType = list?.type ?? "bulleted-list";
	const listTypeId = listType === "numbered-list" ? "7" : "1";
	let listXml = "";
	let spacing = paragraphProp?.spacing ?? { before: "0", after: "0", lineSpacing: "240", lineRule: "auto" };
	let indentation = paragraphProp?.indentation ?? { left: "0", right: "0" };

	list.children.forEach((listElement) => {
		const listElementContent = listElement?.children.map((segment) => renderSegmentToDocXml(segment, contract, clause, paragraphProp, "left")).join("");
		const listElementParagraph = `<w:p>
					<w:pPr>
							<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" w:lineRule="${spacing.lineRule}" w:line="${spacing.lineSpacing}" />
							<w:ind w:left="${(Number(indentation.left) || 0) + LIST_INDENTATION_LEFT}" w:right="${indentation.right}"  w:hanging="${LIST_INDENTATION_HANGING}" />
							${listElement.alignment && `<w:jc w:val="${listElement.alignment == "justify" ? "both" : listElement.alignment}" />`}
							<w:numPr>
          <w:ilvl w:val="0" />
          <w:numId w:val="${listTypeId}" />
        </w:numPr>
					</w:pPr>
					${listElementContent}
			</w:p>`;

		listXml += listElementParagraph;
	});

	return listXml;
}

const renderMultilineText = (text, fontFamily, fontSize, fontColor, alignment, spacing, indentation, style) => {
	if (!text)
		return "";
	const hasMultiline = text.includes("\n");
	if (!hasMultiline) {
		return `
		<w:r>
		${applyStyles(style, fontFamily, fontSize, fontColor)}
		<w:t xml:space="preserve">${text}</w:t>
		</w:r>`;
	} else {
		const firstLine = text.split("\n")[0];
		const breakedTextPrefix = `
		<w:r>
		${applyStyles(style, fontFamily, fontSize, fontColor)}
		<w:t xml:space="preserve">${firstLine}</w:t>
		</w:r>
		</w:p>
		`
		const lastLine = text.split("\n").slice(-1)[0];
		const breakedTextSuffix = `
		<w:p>
		<w:pPr>
				<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" w:lineRule="${spacing.lineRule}" w:line="${spacing.lineSpacing}" />
				<w:ind w:left="${indentation.left}" w:right="${indentation.right}" />
				<w:jc w:val="${alignment == "justify" ? "both" : alignment}" />
		</w:pPr>
		<w:r>
		${applyStyles(style, fontFamily, fontSize, fontColor)}
		<w:t xml:space="preserve">${lastLine}</w:t>
		</w:r>
		`;
		const breakedText = text.split("\n").slice(1).map((line, index) => {
			if (index == text.split("\n").length - 2) {
				return "";
			}
			return `
			<w:p>
			<w:pPr>
					<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" w:lineRule="${spacing.lineRule}" w:line="${spacing.lineSpacing}" />
					<w:ind w:left="${indentation.left}" w:right="${indentation.right}" />
					<w:jc w:val="${alignment == "justify" ? "both" : alignment}" />
			</w:pPr>
			<w:r>
			${applyStyles(style, fontFamily, fontSize, fontColor)}
			<w:t xml:space="preserve">${line}</w:t>
			</w:r>
			</w:p>
			`;
		}).join("")
		return breakedTextPrefix + breakedText + breakedTextSuffix;
	}
}

function renderSegmentToDocXml(segment: RenderSegment, contract: ContractEntity, clause: ClauseEntity | SubClauseEntity, paragraphProp: ParagraphProps, alignment: string = "left") {
	const { id, value, type, style } = segment;
	const text = value;
	const sanitizedText = text?.replaceAll(/\[\s*\]/g, " ");
	const escapedText = escapeXml(sanitizedText);
	let fontFamily = paragraphProp?.font?.family ?? "Arial";
	const fontSize = String(Number(paragraphProp?.font?.size) || 24);
	let fontColor = paragraphProp?.font?.color ?? "000000";
	let spacing = paragraphProp?.spacing ?? { before: "0", after: "0", lineSpacing: "240", lineRule: "auto" };
	let indentation = paragraphProp?.indentation ?? { left: "0", right: "0" };
	switch (type) {
		case SegmentedTextType.STATIC:
			return renderMultilineText(escapedText, fontFamily, fontSize, fontColor, alignment, spacing, indentation, style);
		case SegmentedTextType.COMMENT:
			return "";
		case SegmentedTextType.PARAM:
			const paramPath = value.split(".");
			const paramLabel = clause.segmentation?.segmentedParams.find((param) => param.name == segment.paramName)?.label;
			const paramEscapedText = escapeXml(`${paramLabel ?? value}${paramPath[1] ? `(${paramPath[1]})` : ""}`);
			return `
				<w:r>
				${applyStyles(
				{
					...style,
					bold: DEFAULT_PARAM_BOLD_STYLE ? "1" : style?.bold ?? ""
				},
				fontFamily, fontSize, fontColor)}
				<w:t xml:space="preserve">${paramEscapedText}</w:t>
				</w:r>`;
		case SegmentedTextType.PARAM_VALUE:
			return renderMultilineText(escapedText, fontFamily, fontSize, fontColor, alignment, spacing, indentation, {
				...style,
				bold: DEFAULT_PARAM_BOLD_STYLE ? "1" : style?.bold ?? ""
			});
		case SegmentedTextType.PARAM_TABLE_VALUE:
			let [transposed, tableRows] = JSON.parse(value) as [boolean, string[][]]
			if (transposed) {
				tableRows = transpose(tableRows)
			}
			let [headers, ...rows] = tableRows as string[][]
			return `</w:p>
			<w:tbl>
			<w:tblPr>
			    <w:jc w:val="center"/>
			    <w:tblW w:w="${10000}" w:type="dxa" />
					<w:tblStyle w:val="TableGrid" />
					<w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1" />
			</w:tblPr>
			<w:tblGrid>
					${'<w:gridCol w:w="4601" />'.repeat(tableRows[0]?.length ?? 1)}
			</w:tblGrid>
			${tableRows.map((row) =>
				`<w:tr w:rsidR="007667F4" w14:paraId="4F5EFE64" w14:textId="77777777" w:rsidTr="007667F4">
							${row.map((cell) =>
					`<w:tc>
											<w:tcPr>
													<w:tcW w:w="4601" w:type="dxa" />
													<w:tcBorders>
															<w:top w:val="single" w:sz="4" w:space="0" w:color="000000"/>
															<w:left w:val="single" w:sz="4" w:space="0" w:color="000000"/>
															<w:bottom w:val="single" w:sz="4" w:space="0" w:color="000000"/>
															<w:right w:val="single" w:sz="4" w:space="0" w:color="000000"/>
													</w:tcBorders>
											</w:tcPr>
											<w:p w14:paraId="27D15200" w14:textId="7A92AB9E" w:rsidR="007667F4" w:rsidRDefault="007667F4" w:rsidP="005517FD">
													<w:pPr>
															<w:rPr>
																	<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>
																	<w:sz w:val="${fontSize}"/>
																	<w:color w:val="${fontColor}"/>
															</w:rPr>
													</w:pPr>
													<w:r>
															<w:rPr>
																	<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:cs="${fontFamily}"/>
																	<w:sz w:val="${fontSize}"/>
																	<w:color w:val="${fontColor}"/>
															</w:rPr>
															<w:t xml:space="preserve">${escapeXml(cell)}</w:t>
													</w:r>
											</w:p>
									</w:tc>`).join("")}
					</w:tr>`).join("")}
	</w:tbl>	
		<w:p>
	<w:pPr>
			<w:spacing w:before="${spacing.before}" w:after="${spacing.after}" w:lineRule="${spacing.lineRule}" w:line="${spacing.lineSpacing}" />
			<w:ind w:left="${indentation.left}" w:right="${indentation.right}" />
			${alignment && `<w:jc w:val="${alignment == "justify" ? "both" : alignment}" />`}
	</w:pPr>`.replaceAll(/>\n\s*</g, "><")
			return ""
		case SegmentedTextType.PARAM_COMMENT_VALUE:
			return ""
		default:
			return ""
	}
}

function renderStaticTableXml(tableSegments, contract, clause, paragraphProp, borderColor = "000000") {
	let rowsXml = "";
	let currentRowXml = "";
	let currentCellXml = "";
	let isInRow = false;
	let isInCell = false;
	let columnCount = 0;
	const fullWidth = 10000;

	// First pass: determine the number of columns by finding the maximum columns in any row
	let tempColumnCount = 0;
	tableSegments.forEach(segment => {
		switch (segment.type) {
			case SegmentedTextType.STATIC_TABLE_ROW_START:
				tempColumnCount = 0;
				break;
			case SegmentedTextType.STATIC_TABLE_CELL_START:
				tempColumnCount++;
				break;
			case SegmentedTextType.STATIC_TABLE_ROW_END:
				columnCount = Math.max(columnCount, tempColumnCount);
				break;
			default:
				break;
		}
	});
	let cellAlignment = "left";
	// Second pass: render the table with the calculated column count
	tableSegments.forEach(segment => {
		switch (segment.type) {
			case SegmentedTextType.STATIC_TABLE_ROW_START:
				if (isInRow) {
					rowsXml += `<w:tr>${currentRowXml}</w:tr>`;
					currentRowXml = "";
				}
				isInRow = true;
				break;

			case SegmentedTextType.STATIC_TABLE_CELL_START:
				cellAlignment = segment.style?.textAlign ? segment.style.textAlign : "left";
				cellAlignment = cellAlignment === "justify" ? "both" : cellAlignment;
				if (isInCell) {
					currentRowXml += `<w:tc>
					<w:tcPr>
						<w:tcBorders>
							<w:top w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
							<w:left w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
							<w:bottom w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
							<w:right w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
						</w:tcBorders>
						<w:tcW w:w="${Math.floor(fullWidth / columnCount)}" w:type="dxa"/>
					</w:tcPr>
					<w:p>
					<w:pPr>
						<w:jc w:val="${cellAlignment == "justify" ? "both" : cellAlignment}" />
					</w:pPr>
					${currentCellXml}
					</w:p>
					</w:tc>`;
					currentCellXml = "";
				}
				isInCell = true;
				break;

			case SegmentedTextType.STATIC:
			case SegmentedTextType.PARAM:
			case SegmentedTextType.COMMENT:
			case SegmentedTextType.PARAM_VALUE:
				const tableProps = { ...paragraphProp, spacing: { before: "0", after: "0", lineSpacing: "240", lineRule: "auto" }, indentation: { left: "0", right: "0" } };
				currentCellXml += renderSegmentToDocXml(segment, contract, clause, tableProps, cellAlignment);
				break;

			case SegmentedTextType.STATIC_TABLE_CELL_END:
				if (isInCell) {

					currentRowXml += `<w:tc>
					<w:tcPr>
						<w:tcBorders>
							<w:top w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
							<w:left w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
							<w:bottom w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
							<w:right w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
						</w:tcBorders>
						<w:tcW w:w="${Math.floor(fullWidth / columnCount)}" w:type="dxa"/>
					</w:tcPr>
					<w:p>
					<w:pPr>
						<w:jc w:val="${cellAlignment == "justify" ? "both" : cellAlignment}" />
					</w:pPr>
					${currentCellXml}
					</w:p>
					</w:tc>`;
					currentCellXml = "";
					isInCell = false;
				}
				break;

			case SegmentedTextType.STATIC_TABLE_ROW_END:
				if (isInRow) {
					rowsXml += `<w:tr>${currentRowXml}</w:tr>`;
					currentRowXml = "";
					isInRow = false;
				}
				break;

			default:
				break;
		}
	});

	if (isInCell) {
		currentRowXml += `<w:tc>
		<w:tcPr>
			<w:tcBorders>
				<w:top w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:left w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:bottom w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:right w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
			</w:tcBorders>
			<w:tcW w:w="${Math.floor(fullWidth / columnCount)}" w:type="dxa"/>
		</w:tcPr>
		<w:p>
		${currentCellXml}
		</w:p>
		</w:tc>`;
	}
	if (isInRow) {
		rowsXml += `<w:tr>${currentRowXml}</w:tr>`;
	}

	const columnWidth = Math.floor(fullWidth / columnCount);
	const tableGridXml = Array(columnCount)
		.fill(`<w:gridCol w:w="${columnWidth}" />`)
		.join("");

	return `
	<w:tbl>
		<w:tblPr>
			<w:jc w:val="center"/>
			<w:tblBorders>
				<w:top w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:left w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:bottom w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:right w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:insideH w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
				<w:insideV w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
			</w:tblBorders>
			<w:tblW w:w="${fullWidth}" w:type="dxa" />
			<w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1" />
		</w:tblPr>
		<w:tblGrid>
			${tableGridXml}
		</w:tblGrid>
		${rowsXml}
	</w:tbl>`;
}