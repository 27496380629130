import React, { useContext, useState } from 'react';
import { useTranslation } from '../../../contexts/TranslationProvider';
import Loading from '../../common/Loading';
import { UserEntity } from '../../../domain/entities';
import { addUser } from '../../../services/api/UserAuth';
import { toast } from "react-toastify";
import "./addUser.scss";
import useApiClientWithLoading from '../../../services/api/ApiClient';
import { UserClient } from '../../../services/api/UserClient';
import { AuthUserContext } from '../../../contexts/AuthUserContext';
import { ADMIN_ADD_USER, REACT_APP_DEV_MODE, REACT_APP_IS_PRODUCTION } from '../../../config';
interface AddUserProps {
  onClose: () => void;
  titleEdit?: string;
  setNewUser?: (value: boolean) => void
  user?: UserEntity
}
function AddUser({ onClose, setNewUser, titleEdit, user }: AddUserProps) {
  const [loading, setLoading] = useState(false);
  const { authUser, setAuthUser } = useContext(AuthUserContext)
  const apiClient = useApiClientWithLoading();
  const { t, language } = useTranslation();
  const userClient = new UserClient(apiClient);
  const [userData, setUserData] = useState<UserEntity>({
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    roles: user.roles || ['Client'],
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }) as Partial<UserEntity>);
    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      [name]: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = {} as typeof validationErrors;
    if (!userData.firstName) {
      errors.firstName = t('pages.users.popup.validation.required');
    }
    if (!userData.lastName) {
      errors.lastName = t('pages.users.popup.validation.required');
    }
    if (!userData.email) {
      errors.email = t('pages.users.popup.validation.required');
    }
    if (!userData.roles.length) {
      errors.role = t('pages.users.popup.validation.required');
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      if (Object.keys(user).length === 0) {
        const response = await addUser(apiClient, userData.firstName, userData.lastName, userData.email, userData.roles);
        setLoading(false);
        setNewUser(true);
        toast.success(t("pages.users.popup.messages.success"));
        onClose();
      }
      else {
        const response = await userClient.update(user.id, userData);
        const roles = userData.roles;
        if (ADMIN_ADD_USER) {
          const result = await userClient.setRoles(user.id, roles);
        }
        if (authUser && authUser?.id == user.id) {
          console.log(authUser);
          console.log({
            ...authUser,
            ...response,
            roles,
          });

          setAuthUser({
            ...authUser,
            ...response,
            roles,
          })
        }
        setLoading(false);
        setNewUser(true);
        toast.success(t("pages.users.popup.messages.successUpdate"));
        onClose();
      }
    } catch (error) {
      console.error('Error adding user:', error);
      Object.keys(user).length === 0 ? toast.error(t("pages.users.popup.messages.error")) : toast.error(t("pages.users.popup.messages.errorUpdate"));
      setLoading(false);
    }
  };
  const handleRoleChange = (role: UserEntity['roles'][number], value: boolean) => {
    userData.roles = userData.roles ?? []
    console.log(role);
    console.log(value);

    userData.roles = value ? [...new Set([...userData.roles, role])] as any
      : userData.roles.filter(r => r != role)
    console.log(userData.roles);
    setUserData({ ...userData })
  }
  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="user-add-popup" className='user-add-popup'>
        <div className="modal modal-add-user d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{Object.keys(user).length === 0 ? t("pages.users.popup.title") : t("pages.users.popup.editTitle")}</h5>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ marginRight: language === "ar" && "60%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body body-add-user">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="firstName">{t('pages.users.popup.firstname')}</label>
                      <input type="text" className="form-control" id="firstName" name="firstName" value={userData.firstName} onChange={handleChange} />
                      {validationErrors.firstName && <div className="error-message">{validationErrors.firstName}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">{t('pages.users.popup.lastname')}</label>
                      <input type="text" className="form-control" id="lastName" name="lastName" value={userData.lastName} onChange={handleChange} />
                      {validationErrors.lastName && <div className="error-message">{validationErrors.lastName}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{t('pages.users.popup.email')}</label>
                      <input type="email" className="form-control" id="email" name="email" value={userData.email} onChange={handleChange} />
                      {validationErrors.email && <div className="error-message">{validationErrors.email}</div>}
                    </div>
                    {ADMIN_ADD_USER &&
                      !(REACT_APP_IS_PRODUCTION && user && user.roles?.includes('SuperAdmin')) &&
                      <div className="form-group">
                        <label htmlFor="role">{t('pages.users.popup.userRole')}</label>
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={userData?.roles?.includes('Admin')}
                            id={"role"}
                            onChange={e => (handleRoleChange('Admin', e.target.checked))}
                          />
                          <label htmlFor="dontAskAgain">{t('pages.users.popup.userType.Admin')}</label>
                        </div>
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={userData?.roles?.includes('SeniorManager')}
                            id={"role"}
                            onChange={e => (handleRoleChange('SeniorManager', e.target.checked))}
                          />
                          <label htmlFor="dontAskAgain">{t('pages.users.popup.userType.SeniorManager')}</label>
                        </div>
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={userData?.roles?.includes('Client')}
                            id={"role"}
                            onChange={e => (handleRoleChange('Client', e.target.checked))}
                          />
                          <label htmlFor="dontAskAgain">{t('pages.users.popup.userType.Client')}</label>
                        </div>
                        {validationErrors.role && <div className="error-message">{validationErrors.role}</div>}
                      </div>
                    }
                    <div className='actions-popup-user'>
                      <button type="submit" className="add-signle-user">{Object.keys(user).length === 0 ? t('pages.users.popup.addUser') : t('pages.users.popup.editUser')}</button>
                      <button type="reset" className="cancel-adding-user" onClick={onClose}>{t('pages.users.popup.cancelAddUser')}</button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddUser;
