import { create, all, MathNode, evaluate } from 'mathjs';
import writtenNumber from 'written-number';
import numeral from 'numeral';
import moment from 'moment';
import { floor } from 'lodash';
export const math = create(all);

export function dateDiffY(a: Date, b: Date): number {
  if (arguments.length !== 2)
    throw "Invalid arguments"
  const start = new Date(b);
  const end = new Date(a);

  let yearsDiff = end.getFullYear() - start.getFullYear();
  const monthDifference = end.getMonth() - start.getMonth();

  // Adjust if the anniversary hasn't been reached in the current year
  if (monthDifference < 0 || (monthDifference === 0 && end.getDate() < start.getDate())) {
    yearsDiff--;
  }

  return yearsDiff;
}

// Return the string of a number
export function numberToLetters(num: number): string {
  if (arguments.length !== 1)
    throw "Invalid arguments"
  const letters = writtenNumber(floor(num), { lang: localStorage.getItem('language') || 'fr' });
  return letters;
}

function numberToLettersCAPS(num: number): string {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return numberToLetters(num).toUpperCase();
}
function formatWithSpaces(num: number): string {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
function formatDecimal(num, decimalPlaces = 2) {
  if (arguments.length !== 2 && arguments.length !== 1)
    throw "Invalid arguments"

  return numeral(num).format(`0.${'0'.repeat(decimalPlaces)}`);
};

function formatPercentage(num, decimalPlaces = 2) {
  if (arguments.length !== 2 && arguments.length !== 1)
    throw "Invalid arguments"

  const percentage = parseFloat(num) ? num / 100 : 0;
  return numeral(percentage).format(`0.${'0'.repeat(decimalPlaces)}%`);
};

function formatThousands(num) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return numeral(num).format('0,0');
};

function formatCurrencyUSD(num) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return numeral(num).format('$0,0.00');
};

function formatCurrencyEUR(num) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return `${numeral(num).format('0,0.00')} €`;
};

function formatCurrencyTND(num) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return `${numeral(num).format('0,0.000')} TND`;
};

function formatScientific(num) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return numeral(num).format('0.00e+0');
};
function fmtShortMMDDYYYY(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.locale('fr').format('MM/DD/YYYY');
}

function fmtShortDDMMYYYY(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('DD/MM/YYYY');
}

function fmtISO(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('YYYY-MM-DD');
}

function fmtLongMonthDayYear(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('MMMM DD, YYYY');
}

function fmtLongDayMonthYear(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('DD MMMM YYYY');
}

function fmtWeekdayMonthDayYear(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('ddd, MMM DD, YYYY');
}

function fmtWithTime24(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('YYYY-MM-DD HH:mm');
}

function fmtWithTimeFull24(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('DD/MM/YYYY HH:mm:ss');
}

function fmtWithTimeHMSS(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('MM-DD-YYYY HH:mm:ss');
}

function fmtWithTime12(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('MM/DD/YYYY hh:mm A');
}

function fmtWithTimeFull12(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('DD-MM-YYYY hh:mm:ss A');
}

function fmtMonthDayYear12(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('MMMM DD, YYYY hh:mm A');
}

function fmtWeekdayShort(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('ddd, MM/DD/YYYY');
}

function fmtWeekdayLong(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('dddd, MMMM DD, YYYY');
}

function fmtWeekdayMonthDay(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('ddd MMM DD YYYY');
}

function fmtISOExtended(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}

function fmtRFC(date) {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  if (arguments.length !== 1)
    throw "Invalid arguments"
  const momentDate = moment(date, 'DD/MM/YYYY').locale('fr');
  if (!momentDate.isValid()) {
    return 'Invalid Date';
  }
  return momentDate.format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
}


function LCM(a: number, b: number): number {
  if (arguments.length !== 2)
    throw "Invalid arguments"
  // Function to calculate the Least Common Multiple (LCM)
  const gcd = (x: number, y: number): number => y === 0 ? x : gcd(y, x % y);
  return Math.abs(a * b) / gcd(a, b);
}


function len(a: number | string): number {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  return a.toString().length;
}

// Return current date in YYYY-MM-DD format
function now(): string {
  if (arguments.length !== 0)
    throw "Invalid arguments"

  const today = new Date();
  return today.toISOString().split('T')[0]; // Returns 'YYYY-MM-DD'
}

// Calculate the difference in years between two dates
function dateDiffInYears(startDate: string, endDate: string): number {
  if (arguments.length !== 2)
    throw "Invalid arguments"

  const start = new Date(startDate);
  const end = new Date(endDate);

  let yearsDiff = end.getFullYear() - start.getFullYear();
  const monthDifference = end.getMonth() - start.getMonth();

  // Adjust if the anniversary hasn't been reached in the current year
  if (monthDifference < 0 || (monthDifference === 0 && end.getDate() < start.getDate())) {
    yearsDiff--;
  }

  return yearsDiff;
}

function calAge(dateOfBirth: string): number {
  if (arguments.length !== 1)
    throw "Invalid arguments"

  const dob = new Date(dateOfBirth);
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();
  const monthDifference = today.getMonth() - dob.getMonth();

  // Adjust if the birthday hasn't occurred yet this year
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
    return age - 1;
  }

  return age;
}

function lessThanDate(date1: string, date2: string): boolean {
  if (arguments.length !== 2)
    throw "Invalid arguments"

  return new Date(date1) < new Date(date2);
}

function greaterThanDate(date1: string, date2: string): boolean {
  if (arguments.length !== 2)
    throw "Invalid arguments"

  return new Date(date1) > new Date(date2);
}

function equalDate(date1: string, date2: string): boolean {
  if (arguments.length !== 2)
    throw "Invalid arguments"

  return new Date(date1).getTime() === new Date(date2).getTime();
}

// add functions for string manipulation
math.import({
  dateDiffY,
  numberToLetters,
  numberToLettersCAPS,
  LCM,
  len,
  now,
  dateDiffInYears,
  calAge,
  lessThanDate,
  greaterThanDate,
  equalDate,
  formatWithSpaces,
  formatDecimal,
  formatPercentage,
  formatThousands,
  formatCurrencyUSD,
  formatCurrencyEUR,
  formatCurrencyTND,
  formatScientific,
  fmtShortMMDDYYYY,
  fmtShortDDMMYYYY,
  fmtISO,
  fmtLongMonthDayYear,
  fmtLongDayMonthYear,
  fmtWeekdayMonthDayYear,
  fmtWithTime24,
  fmtWithTimeFull24,
  fmtWithTimeHMSS,
  fmtWithTime12,
  fmtWithTimeFull12,
  fmtMonthDayYear12,
  fmtWeekdayShort,
  fmtWeekdayLong,
  fmtWeekdayMonthDay,
  fmtISOExtended,
  fmtRFC,
  // Return the string of a number

});
