import { getAuthUser } from "../../services/api/UserAuth";
import AdminFunction from "../../components/AdminFunction";
import CreateContractModal from "../../components/modals/CreateContractModal";
import CreateContractAIModal from "../../components/modals/CreateContractAIModal";
import CreateFormModal from "../../components/modals/CreateFormModal";
import { useTranslation } from "../../contexts/TranslationProvider";
import QuestionUseCase from "./components/QuestionUseCase";
import FillDocumentSummaryModal from "../../components/modals/FillDocumentSummaryModal";
import CreateFormFromDocModal from "../../components/modals/CreateFormFromDocModal";
import chat from "../../assets/new-design-icons/Chat.svg";
import { useNavigate } from "react-router-dom";
import "./pilot.scss";
import {
  AI_IMPORT_CONTRACT_ON,
  AI_IMPORT_FORM_ON,
  AI_QUERY_REGULATION_ON,
  ENABLE_PROJECT,
  AI_JOBS_ON,
  AI_COMPARE_CONTRACTS_ON,
  AI_CHAT_WITH_DOCUMENT_ON,
  AI_CONFORMITY_CHECK_ON
} from "../../config";
import ContractIcon from "../../assets/new-design-icons/Contract.svg";
import FormIcon from "../../assets/new-design-icons/Form.svg";
import EvaluateIcon from "../../assets/new-design-icons/Stats.svg";
import ChatIcon from "../../assets/new-design-icons/Chat.svg";
import ProjectIcon from "../../assets/new-design-icons/Projects.svg";
import EvaluateModal from "../../components/modals/EvaluateModal";
import { useContext } from "react";
import { AuthUserContext } from "../../contexts/AuthUserContext";

const icons = {
  Contract: ContractIcon,
  Form: FormIcon,
  Evaluate: EvaluateIcon,
  Chat: ChatIcon,
  Project:ProjectIcon
};
function PilotPage() {
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const { user } = getAuthUser();
  const chatIcon = chat.toString();
  const { activeRole } = useContext(AuthUserContext)
  const isCoContractor = activeRole === "CoContractor";
  return (
    <div
      className={`page ${language == "ar" && "arabic-page"}`}
      style={{ position: "relative" }}
    >
      <div className="px-5">
        <section id="greetings">
          <div className="mt-2 mb-2">
            <span style={{ marginTop: "10px" }} className="current-pathname">
              {t("pages").pilot.title}
            </span>
          </div>
          <h2>
            {t("pages").pilot.greeting}{" "}
            <span>
              {" "}
              <strong>{user?.firstName}, </strong>
            </span>
          </h2>
          <p>{t("pages.pilot.explanation")}</p>
        </section>
        <section id="main-function" className="pt-3">
          <div className="row mt-5">
            {(AI_IMPORT_CONTRACT_ON && !isCoContractor) ? (
              <>
                <div className="col-6">
                  <AdminFunction
                    icon={icons.Contract}
                    title={t("pages.pilot.cards.contract.title")}
                    description={t("pages.pilot.cards.contract.descriptionImp")}
                    buttonTitle={t("pages.pilot.cards.contract.button")}
                    secondButtonTitle={t(
                      "pages.listener.cards.contract.button"
                    )}
                    render={(onClose) => (
                      <CreateContractModal onClose={onClose} />
                    )}
                    secondRender={(onClose) => (
                      <FillDocumentSummaryModal onClose={onClose} />
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-6">
                  <AdminFunction
                    icon={icons.Form}
                    title={t("pages.pilot.cards.contract.title")}
                    description={t("pages.pilot.cards.contract.description")}
                    buttonTitle={t("pages.pilot.cards.contract.button")}
                    render={(onClose) => (
                      <CreateContractModal onClose={onClose} />
                    )}
                  />
                </div>
              </>
            )}
            {(AI_IMPORT_FORM_ON && !isCoContractor) ? (
              <>
                {" "}
                <div className="col-6">
                  <AdminFunction
                    icon={icons.Form}
                    title={t("pages.pilot.cards.form.title")}
                    description={t("pages.pilot.cards.form.descriptionImp")}
                    buttonTitle={t("pages.pilot.cards.form.button")}
                    render={(onClose) => <CreateFormModal onClose={onClose} />}
                    secondButtonTitle={t("pages.listener.cards.form.button")}
                    secondRender={(onClose) => (
                      <CreateFormFromDocModal onClose={onClose} />
                    )}
                  />
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="col-6">
                  <AdminFunction
                    title={t("pages.pilot.cards.form.title")}
                    description={t("pages.pilot.cards.form.description")}
                    buttonTitle={t("pages.pilot.cards.form.button")}
                    render={(onClose) => <CreateFormModal onClose={onClose} />}
                  />
                </div>
              </>
            )}
             {ENABLE_PROJECT &&(<>
              <div className="col-6 mt-4 ">
                <AdminFunction
                  icon={icons.Project}
                  title={t("pages.pilot.cards.project.title")}
                  description={t("pages.pilot.cards.project.description")}
                  buttonTitle={t("pages.pilot.cards.project.button")}
                  path="/edit-project"
                />
              </div>
            </>)}
            {(AI_JOBS_ON && !isCoContractor) && (AI_COMPARE_CONTRACTS_ON || AI_CHAT_WITH_DOCUMENT_ON || AI_CONFORMITY_CHECK_ON) && (
              <div className="col-6 mt-4">
                <AdminFunction
                  icon={icons.Evaluate}
                  title={t("pages.pilot.cards.evaluate.title")}
                  description={t("pages.pilot.cards.evaluate.description")}
                  buttonTitle={t("pages.pilot.cards.evaluate.button")}
                  render={(onClose) => (
                    <EvaluateModal onClose={onClose}/>
                  )}
                />
              </div>
            )}
            {(AI_QUERY_REGULATION_ON && !isCoContractor) &&(<>
              <div className="col-6 mt-4 ">
                <AdminFunction
                  icon={icons.Chat}
                  title={t("pages.pilot.cards.chat.title")}
                  description={t("pages.pilot.cards.chat.description")}
                  buttonTitle={t("pages.pilot.cards.chat.button")}
                  path="/chat"
                />
              </div>
            </>)}
            <div className="col-xxl-3 col-0"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default PilotPage;
