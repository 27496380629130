import React, { useState, useContext, useEffect, useMemo } from 'react'
import { FiPlusCircle } from "react-icons/fi";
import "./addSubClausePlaceholder.scss";
import EditContractTemplateContext from '../../../contexts/EditContractTemplateContext';
import EventManager from '../../../services/EventManager';
import { useTranslation } from '../../../contexts/TranslationProvider';
import SubClauseSelectModal from '../../../components/modals/SubClauseSelectModal';
import { toast } from 'react-toastify';

function AddSubClausePlaceholder({ clauseId, subClauseIndex, isSelected }) {
  const [subClauseName, setSubClauseName] = useState("")
  const [subClauseSelector, setSubClauseSelector] = useState(false)
  const [error, setError] = useState(false)
  const { insertSubClause, contractTemplate } = useContext(EditContractTemplateContext);
  const clause = useMemo(() => contractTemplate.clauses.find(clause => clause.id === clauseId), [contractTemplate, clauseId])
  const [isAdding, setIsAdding] = useState(false)
  const { t, language } = useTranslation()
  const translationPath = "pages.contractTemplateParam.addSubClausePlaceholder"
  const handleAddClause = () => { 
    try{
    const result = insertSubClause(subClauseIndex, clauseId, subClauseName)
    if (!result) return;
    setIsAdding(false);
    setSubClauseName("");
    toast.success(t(translationPath + ".success"))
    }catch(err){
      console.log(err)
      toast.error(t(translationPath + ".error"))
    }
  }
  const handleClose = () => {
    setSubClauseSelector(false)
    setIsAdding(false)
  }
  useEffect(() => {
    const existsAlready = clause.subClauses.some((subClause) => subClause.name === subClauseName)
    if(existsAlready){
      setError(true)
    }else{
      setError(false)
    }
  }, [subClauseName])

  return (
    <>
      <div key={"placeholder-" + clauseId + subClauseIndex} className={`add-subclause-container ${isAdding && "full-width"}`}>
      {subClauseSelector && (
        <SubClauseSelectModal
          onClose={handleClose}
          addNewClause={subClauseIndex}
          clauseId={clauseId}
        />
      )}
        {!isAdding ? <>
          <span className='add-subclause-icon' style={{backgroundColor : isSelected ? "#eee3fd" : "white"}}>
            <FiPlusCircle />
          </span>
          <span onClick={() => setIsAdding(true)} className='add-subclause-text fw-bold'>{t(translationPath + ".title")}</span>
        </> :
          <>
            <div className="add-clause-form">
              <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="add-clause-name">
                <label className="add-clause-name-label">{t(translationPath + ".name")}</label>
                <input value={subClauseName} onChange={(event) => setSubClauseName(event.target.value)} className="add-clause-name-input" type="text" placeholder={t(translationPath + ".placeholder")} />
              </div>
              {error && <div className="error-clause-name">
                {t(translationPath + ".nameExists")}
                </div>}
              </div>
              <div className="add-clause-actions">
                <button className={`add-clause-button ${error && "disabled-button"}`} onClick={handleAddClause} disabled={error}>
                  {t(translationPath + ".add")}
                  </button>
                <button className="add-clause-button" onClick={() => setIsAdding(false)}>{t(translationPath + ".cancel")}</button>
                <button className="select-clause-button" onClick={() => setSubClauseSelector(true)}>{t("pages.contractTemplateParam.addClausePlaceholder.selectFromExisting")}</button>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default AddSubClausePlaceholder