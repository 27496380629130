import React, { useContext, useState } from "react";
import "./contractSignModal.scss";
import { useTranslation } from "../../../contexts/TranslationProvider";
import InputValuesContext from "../../../contexts/InputValuesContext";
import {
  ContractClient,
  ContractSignRequest,
} from "../../../services/api/ContractClient";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import { updateContract } from "../../../helpers/UpdateContract";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { ContractDocxExportClient } from "../../../services/api/ContractDocxExportClient";
import { toast } from "react-toastify";
import MacroContext from "../../../contexts/MacroContext";
import { getContractExportData } from "../../../domain/Contract";
import { FaDownload, FaUpload } from "react-icons/fa";
import { downloadFile } from "../../../utils/file";
import { DocumentClient } from "../../../services/api/DocumentClient";
import { ContractEntity } from "../../../domain/entities";
import Loading from "../../common/Loading";

type Signer = ContractSignRequest["signers"][number];

function ContractSignModal({ onClose }: { onClose: () => void }) {
  const {
    inputValues,
    templateId,
    commentsOverrides,
    excludedSubClauses,
    excludedClauses,
    contract,
    beneficialsMap,
    status,
    setContractStatus,
    setContract
  } = useContext(InputValuesContext);

  const { macros } = useContext(MacroContext);

  const [signers, setSigners] = useState<(Signer & { id: number })[]>(
    Object.values(beneficialsMap).map((b) => ({
      id: b.id,
      email: b.email,
      name: b.name,
    }))
  );

  let [idCounter, setCounter] = useState(signers.length);

  const { t, language } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [cancelESignatureLoading,setCancelESignatureLoading]=useState<boolean>(false);
  const apiClient = useApiClientWithLoading(setLoading);
  const contractClient = new ContractClient(apiClient);
  const documentClient = new DocumentClient(apiClient);
  const [esignature, setEsignature] = useState<boolean>(false);
  const [firstStep, setFirstStep] = useState<boolean>(true);
  const [manualSignature, setManualSignature] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const removeRow = (rowIdx: number) => () => {
    const newSigners = [
      ...signers.slice(0, rowIdx),
      ...signers.slice(rowIdx + 1),
    ];
    setSigners([...newSigners]);
  };
  const addRow = (rowIdx: number) => () => {
    // Create a copy of the original tableData array
    const newSigners = [...signers];
    // Insert a new row with initial values
    newSigners.splice(rowIdx + 1, 0, { id: idCounter++, email: "", name: "" });
    setCounter(idCounter);
    // Update the state with the new tableData
    setSigners(newSigners);
  };

  const onSaveRow = (rowIdx: number) => (rowData: Signer) => {
    signers[rowIdx].email = rowData.email;
    signers[rowIdx].name = rowData.name;
    setSigners([...signers]);
  };

  const processParamValues = useParamProcessing();

  const createContractUpdateBody = async (
    inputValues,
    templateId,
    commentsOverrides,
    excludedSubClauses,
    excludedClauses,
    contract
  ) => {
    const { processedParamValues } = await processParamValues(inputValues);

    return {
      templateId,
      paramValues: processedParamValues,
      commentsOverrides,
      excludedSubClauses,
      excludedClauses,
      status: contract.status,
      name: inputValues.contractName,
    };
  };
  const closeWithToast = (message) => {
    toast(message);
    setLoading(false);
    onClose();
  };

  const handleSign = async () => {
    setLoading(true);

    try {
      const body = await createContractUpdateBody(
        inputValues,
        templateId,
        commentsOverrides,
        excludedSubClauses,
        excludedClauses,
        contract
      );
      const updatedContract = await updateContract(
        contractClient,
        contract?.id,
        body,
        "full"
      );
      let contractDocxExportId = updatedContract.template?.contractDocxExportId;
      const contractDocxExportClient = new ContractDocxExportClient(apiClient);
      if (!contractDocxExportId) {
        contractDocxExportId =
          (await contractDocxExportClient.getDefault())?.id ?? null;
        if (!contractDocxExportId)
          return closeWithToast("No default ContractDocxExport");
      }

      const paragraphsProps = await contractDocxExportClient.getDocxProps(
        contractDocxExportId
      );
      const data = getContractExportData(
        macros,
        updatedContract,
        t,
        paragraphsProps
      );
      if (!data)
        return closeWithToast("No data available for Docx conversion.");
      const url = await contractDocxExportClient.dataToDocx(
        contractDocxExportId,
        data
      );

      await contractClient.sign(contract?.id, {
        signers,
        url,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error in GenerateDocxUrl:", error);
      toast("An error occurred while generating the document.");
    }
  };
  const handleSelectEsignature = () => {
    setFirstStep(false);
    setEsignature(true);
  };

  const handleSelectManualSignature = () => {
    setFirstStep(false);
    setManualSignature(true);
  };

  function handleGoBack() {
    setFirstStep(true);
    setEsignature(false);
    setManualSignature(false);
  }

  async function handleUploadSignedDoc() {
    if (selectedFile) {
      try {
        setUploadLoading(true);
        const {
          row: { id: documentId },
        } = await documentClient.upsertMultiForm({
          file: selectedFile,
          name: `${contract.name} (Signed)`,
        });
        setContractStatus("Signed");
        const body = {
          ...contract,
          status: "Signed" as ContractEntity["status"],
        };
        const updatedContract = await contractClient.update(contract?.id, body);
        toast.success(t("pages.contracts.updating.messages.successStatus"));
        setUploadLoading(false);
      } catch (error) {
        toast.error(
          error?.message ??
            error ??
            t("pages.viewProject.popups.importDocument.messages.error")
        );
        setUploadLoading(false);
        onClose();
      }
    }
  }
  const getDefaultContractDocxExportId = async (client) => {
    const defaultContractDocxExport = await client.getDefault();
    return defaultContractDocxExport?.id || null;
  };

  const handleDownloadDoc = async () => {
    setDownloadLoading(true);
    try {
      const body = await createContractUpdateBody(
        inputValues,
        templateId,
        commentsOverrides,
        excludedSubClauses,
        excludedClauses,
        contract
      );
      const updatedContract = await updateContract(
        contractClient,
        contract?.id,
        body,
        "full"
      );
      let contractDocxExportId = updatedContract.template?.contractDocxExportId;
      const contractDocxExportClient = new ContractDocxExportClient(apiClient);
      if (!contractDocxExportId) {
        contractDocxExportId = await getDefaultContractDocxExportId(
          contractDocxExportClient
        );
        if (!contractDocxExportId)
          return closeWithToast("No default ContractDocxExport");
      }

      const paragraphsProps = await contractDocxExportClient.getDocxProps(
        contractDocxExportId
      );
      const data = getContractExportData(
        macros,
        updatedContract,
        t,
        paragraphsProps
      );
      if (!data)
        return closeWithToast("No data available for Docx conversion.");
      const url = await contractDocxExportClient.dataToDocx(
        contractDocxExportId,
        data
      );
      url
        ? downloadFile(url, `${updatedContract?.name}.docx`)
        : toast("Failed to generate the document.");
      setDownloadLoading(false);
    } catch (error) {
      console.error("Error in GenerateDocxUrl:", error);
      toast("An error occurred while generating the document.");
    }
  };
  const handleCancelSignature = async () => {
    try{
      setCancelESignatureLoading(true)
      const cancelESignature = await contractClient.cancelSign(contract.id);
      setCancelESignatureLoading(false)
      window.location.reload();
    }catch(error){
      setCancelESignatureLoading(false)
      console.error("Error canceling the signature", error);
      toast("An error occurred while canceling the signature.");
    }
  }

  const allowedToSign = status === "Negotiation" || status === "ToBeSigned";
  const alreadySigned = status === "Signed";
  let hasESignatureInProgress = false;
  console.log(contract.ressource);
  if (
    contract.ressource.signatures.some(
      (signature) => signature.status === "inProgress"
    )
  ) {
    hasESignatureInProgress = true;
  }

  return (
    <>
      <div className="modal-backdrop"></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language === "ar" && "88%" }}
                ></button>
              </div>

              <div className="modal-body">
                <div style={{ maxHeight: "59vh", overflowY: "auto" }}>
                  {/* Step 1: Choose Signature Type */}
                  {firstStep && (
                    <div className="text-center">
                      <h2>{t("signatureModal.chooseSignatureMethod")}</h2>
                      <p className="text-muted">
                        {t("signatureModal.selectHowToSign")}
                      </p>
                      {!allowedToSign && !alreadySigned && (
                        <div className="alert alert-warning mt-3">
                          {t("signatureModal.notAllowedToSignMessage")}
                        </div>
                      )}
                      {alreadySigned && (
                        <div className="alert alert-info mt-3">
                          {t("signatureModal.alreadySignedMessage")}
                        </div>
                      )}
                      {hasESignatureInProgress && (
                        <>
                          <div className="alert alert-info mt-3">
                            {t("signatureModal.hasESignatureInProgress")}
                            <button className="cancel-button" onClick={handleCancelSignature} disabled={cancelESignatureLoading}>{t("signatureModal.cancel")}</button>
                          </div>
                        </>
                      )}
                      <div className="manual-signature__steps d-flex flex-column align-items-center mt-3">
                        <button
                          className="manual-signature__button-primary w-75 mb-2"
                          onClick={handleSelectEsignature}
                          disabled={!allowedToSign || hasESignatureInProgress}
                        >
                          {t("signatureModal.eSignature")}
                          <p className="manual-signature__description">
                            {t("signatureModal.eSignatureDescription")}
                          </p>
                        </button>

                        <button
                          className="manual-signature__button-secondary w-75"
                          onClick={handleSelectManualSignature}
                          disabled={!allowedToSign || hasESignatureInProgress}
                        >
                          {t("signatureModal.manualSignature")}
                          <p className="manual-signature__description-outline">
                            {t("signatureModal.manualSignatureDescription")}
                          </p>
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Step 2: E-Signature Flow */}
                  {esignature && (
                    <>
                      {loading ? (
                        <>
                          <Loading height="40px" />
                        </>
                      ) : (
                        <div>
                          <table
                            className="my-0"
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <thead>
                              <tr>
                                <th className="thead-table">
                                  {t("signatureModal.name")}
                                </th>
                                <th className="thead-table">
                                  {t("signatureModal.email")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {signers.length > 0 ? (
                                signers.map((signer, signerIdx) => (
                                  <tr key={signer.id}>
                                    <td>
                                      <input
                                        style={{ paddingLeft: "2px" }}
                                        placeholder={t("signatureModal.name")}
                                        type="text"
                                        className="form-control custom-placeholder"
                                        value={signer.name}
                                        onChange={(e) => {
                                          onSaveRow(signerIdx)({
                                            ...signer,
                                            name: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        style={{ paddingLeft: "2px" }}
                                        placeholder={t("signatureModal.email")}
                                        type="email"
                                        className="form-control custom-placeholder"
                                        value={signer.email}
                                        onChange={(e) => {
                                          onSaveRow(signerIdx)({
                                            ...signer,
                                            email: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-light btn-handler"
                                        style={{ boxShadow: "none" }}
                                        onClick={addRow(signerIdx)}
                                      >
                                        +
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-light btn-handler"
                                        style={{ boxShadow: "none" }}
                                        onClick={removeRow(signerIdx)}
                                      >
                                        -
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <td>
                                  <button
                                    className="btn btn-light btn-handler"
                                    onClick={addRow(0)}
                                  >
                                    +
                                  </button>
                                </td>
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}

                  {/* Step 2: Manual Signature Flow */}
                  {manualSignature && (
                    <div className="manual-signature">
                      <h6 className="manual-signature__title">
                        {t("signatureModal.manualSignatureProcess")}
                      </h6>
                      <p className="manual-signature__description">
                        {t("signatureModal.manualSignatureInstructions")}
                      </p>

                      <div className="manual-signature__steps">
                        <div className="manual-signature__step">
                          <span className="manual-signature__step-number">
                            1
                          </span>
                          <p className="manual-signature__step-text">
                            {t("signatureModal.downloadAndPrint")}
                          </p>
                        </div>
                        {downloadLoading ? (
                          <>
                            <Loading height="40px" />
                          </>
                        ) : (
                          <>
                            {" "}
                            <button
                              className="manual-signature__button manual-signature__button--primary"
                              onClick={handleDownloadDoc}
                              disabled={downloadLoading}
                            >
                              <FaDownload className="manual-signature__icon" />
                              {t("signatureModal.downloadDocument")}
                            </button>
                          </>
                        )}

                        <div className="manual-signature__step">
                          <span className="manual-signature__step-number">
                            2
                          </span>
                          <p className="manual-signature__step-text">
                            {t("signatureModal.signManually")}
                          </p>
                        </div>

                        <div className="manual-signature__step">
                          <span className="manual-signature__step-number">
                            3
                          </span>
                          <p className="manual-signature__step-text">
                            {t("signatureModal.scanAndUpload")}
                          </p>
                        </div>
                        {uploadLoading ? (
                          <>
                            <Loading height="40px" />
                          </>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="fileUpload"
                              className="manual-signature__input"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />

                            <label
                              htmlFor="fileUpload"
                              className="manual-signature__button manual-signature__button--outline"
                            >
                              <FaUpload className="manual-signature__icon" />
                              {t("signatureModal.uploadSignedDocument")}
                            </label>
                            {selectedFile && (
                              <p className="manual-signature__filename">
                                {selectedFile.name}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Footer */}
              <div className="modal-footer-signature">
                {(esignature || manualSignature) && (
                  <button
                    type="button"
                    className="manual-signature__button manual-signature__button--outline modal-footer__button-left"
                    onClick={handleGoBack}
                  >
                    {t("signatureModal.back")}
                  </button>
                )}
                {esignature && (
                  <button
                    type="submit"
                    className="manual-signature__button manual-signature__button--primary modal-footer__button-right"
                    onClick={handleSign}
                  >
                    {t("signatureModal.validate")}
                  </button>
                )}
                {manualSignature && (
                  <button
                    type="submit"
                    className="manual-signature__button manual-signature__button--primary modal-footer__button-right"
                    onClick={handleUploadSignedDoc}
                  >
                    {t("signatureModal.validate")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractSignModal;
