import { LanguageType } from "../contexts/TranslationProvider";
import { GroupEntity, NotificationEntity, ProjectTemplateEntity, ProjectTemplateRequirementEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity, UserEntity } from "../domain/entities";
import { RuleEntity } from "../domain/entities/Rule";

export function getName(entity: TypeLevel1Entity
  | ProjectTemplateEntity
  | ProjectTemplateRequirementEntity
  | TypeLevel2Entity
  | TypeLevel3Entity
  | GroupEntity
  | RuleEntity
  ,
  language: string
) {
  return entity?.translations?.find(tr => tr.language == language)?.name ?? entity?.name ?? ""
}
export function getMessage(entity: NotificationEntity
  | RuleEntity
  ,
  language: LanguageType
) {
  const translation = entity?.translations?.find(tr => tr.language == language)
  if (translation) {
    console.log(translation.message);

  }
  return translation ? translation.message : entity?.message || ""
}