import React, {useEffect} from 'react'
import { BeneficialMacroEntity } from '../../../domain/entities'
import { SegmentedClauseParams } from '../../../domain/types/ClauseParams'
import { companyEntitySample, generateParamsFromEntity, minorEntitySample, personBeneficialEntitySample } from './MacroEditor.tsx/beneficialHelper'
import MacroParameter from './MacroParameter'

function MacrosList(
  {
    macros,
    handleSelect,
    currentMacro,
    currentType,
    setInputValues,
    inputValues
  }: {
    macros: BeneficialMacroEntity[],
    handleSelect: (macroName: string) => void,
    currentMacro: string,
    currentType: string,
    setInputValues: (param: any, value: any) => void,
    inputValues: Record<string, any>
  }
) {
  const handleClick = (macroName) => { handleSelect(macroName) }
  const [macroParams, setMacroParams] = React.useState<SegmentedClauseParams>([]);
  useEffect(() => {
    if (!currentType) return;
    const type = currentType;
    let sample = null;
    switch (type) {
      case 'Person':
        sample = personBeneficialEntitySample;
        break;
      case 'Company':
        sample = companyEntitySample;
        break;
      case 'Minor':
        sample = minorEntitySample;
        break;
      default:
        sample = personBeneficialEntitySample;
        break;
    }
    setMacroParams(generateParamsFromEntity(sample, 'Beneficial.', 'Beneficial ').filter((param) => (param.type === 'boolean' || param.type === 'enum')));
  }, [macros]);

  return (
    <div className='macro-list'>
      {/* <button onClick={()=>console.log(generateParamsFromEntity(personBeneficialEntitySample, "Beneficial.", ""))}>clcick params</button> */}
      {macros && macros.sort((macroA, macroB) => macroA.name > macroB.name ? 1 : -1).map((macro, index) => {
        const isSelected = currentMacro === macro.name;
        return (
          <div
            id="clause-group"
            className={` edit-contract-clause-input px-4 py-2 mx-1 my-2 ${isSelected ? "selected-input-container" : ""}`}
            style={{ backgroundColor: isSelected ? "#eee3fd" : "" }}
            onClick={() => handleClick(macro.name)}
          >
            <div className="d-flex gap-2" style={{flexDirection: "column"}}>
              <div className="edit-contract-clause-input-header">
                {macro.name}
              </div>
              {
                isSelected && <div className="edit-contract-clause-input-header template-param-list">
                 {
                    macroParams.map((param) => {
                      return (param.type === 'boolean' || param.type === 'enum') &&
                      <div className='template-param-element'>
                      <MacroParameter 
                      param={param} 
                      currentValue={inputValues[param.name]}
                      setInputValues={setInputValues} 
                      onDelete={() => {}} 
                      />
                      </div>
                    })
                 }
                </div>
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MacrosList