import "./projectList.scss";
import "../index.scss";
import { BsSearch } from "react-icons/bs";
import { useEffect, useState } from "react";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { useTranslation } from "../../contexts/TranslationProvider";
import Loading from "../../components/common/Loading";
import project from "../../assets/new-design-icons/Projects.svg";
import { ProjectTemplateEntity, TypeLevel1Entity } from "../../domain/entities";
import { ProjectTemplateClient } from "../../services/api/ProjectTemplateClient";
import { getName } from "../../helpers/Translation";
import ProjectTemplateCard from "../../components/common/newDesign/ProjectTemplateCard";
import LayoutFilter from "../../components/LayoutFilter";
import AdvancedTable from "../../components/AdvancedTable";
import { useNavigate } from "react-router-dom";
import { formatDate, getTypeLevels } from "../../helpers/helper";
import ConfirmDeleteMessage from "../../components/modals/ConfirmDeteleMessage";
import ActionsCell from "./ActionsCell";
const ProjectTemplates = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [confirmDeleteMessage, setConfirmDelete] = useState(false);
  const [projectTemplate, setProjectTemplate] = useState<
    ProjectTemplateEntity[]
  >([]);
  const projectIcon = project.toString();

  const [searchTerm, setSearchTerm] = useState("");
  const [updateInProjectsList, setProjectListChanged] = useState(false);
  const [templateOpenPopupId, setTemplateOpenPopupId] = useState<string | number | null>(
    null
  );
  const [projectToDelete, setProjectToDelete] = useState<ProjectTemplateEntity | null>(null);
  const { t, language } = useTranslation();
  const apiClient = useApiClientWithLoading();
  const [displayCards, setDisplayCards] = useState("grid");
  const projectTemplateClient = new ProjectTemplateClient(apiClient);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await projectTemplateClient.getAll();
        const dataTemplate = await projectTemplateClient.getAll();
        setProjectTemplate(dataTemplate?.rows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contracts: ", error);
        setLoading(false);
      }
    };
    fetchData();
    setProjectListChanged(false);
  }, [updateInProjectsList]);
  const filteredProjects = projectTemplate?.filter((project) =>
    project?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedProjects = filteredProjects.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  );
  const projectsPerLevel1: Record<
    TypeLevel1Entity["name"],
    ProjectTemplateEntity[]
  > = {};

  const levels1Names = [
    ...new Set(
      filteredProjects.map((template) => getName(template.level1, language))
    ),
  ];
  levels1Names.forEach((level1Name) => (projectsPerLevel1[level1Name] = []));

  sortedProjects.forEach((template) => {
    projectsPerLevel1[getName(template.level1, language)].push(template);
  });
  const handleDesactivateProjectTemplate=async(id)=>{
    await projectTemplateClient.desactivate(id);
    setProjectListChanged(true)
  }
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  const handleClick = (id) => {
    if (id) {
      navigate(`/edition-project-template/${id}`);
    }
  };
  const handleDelete = (id) => {
    setProjectToDelete(projectTemplate.find((project) => project.id === id));
    setConfirmDelete(true);
  };
  const togglePopup = (e, id) => {
    e.stopPropagation();
    setTemplateOpenPopupId(templateOpenPopupId == id ? null : id);
  }
  return (
    <div className="projects px-3 pt-2">
      <section id="projets">
        <div className="search-zone">
          <div className="d-flex mt-4 ps-1 align-items-center justify-content-between">
            <div className="search-bar-projectTemplate">
              <BsSearch
                className="search-icon search-project"
                style={{ marginRight: language == "ar" && "89%"}}
              />
              <input
                type="text"
                placeholder={t("pages.ProjectTemplates.search")}
                className="search-input"
                style={{ minWidth: "350px" }}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />

        <div className="mt-5">
          {loading ? (
            <Loading height="75vh" />
          ) : displayCards=="grid"?(
            <div className="row gap-5">
              {levels1Names?.map((level1Name) => (
                <div className="col projects-space" key={level1Name}>
                  <h3 className="m-2 projects-space-title">{level1Name}</h3>
                  <div className="project-group-container">
                    <div className="projects-per-level">
                      {projectsPerLevel1[level1Name]?.map((item) => (
                        <div key={item?.id} className="m-2">
                          <div className="project-group">
                            <ProjectTemplateCard
                              onDesactivate={item?.activated ? () => handleDesactivateProjectTemplate(item.id):undefined}
                              data={item}
                              key={item?.id}
                              setProjectListChanged={setProjectListChanged}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ):
          <>
          <AdvancedTable
         columns={[
           {
             display: t("pages.projects.tableHeaders.name"),
             accessorKey: "name",
             render: (row) => {
               return (
                 <div className="title-table-item">
                     <img
                       src={projectIcon}
                       alt="icon"
                       style={{ width: "30px", height: "30px" }}
                     />
                   <div style={{ marginLeft: "20px" }}>{row.name}</div>
                 </div>
               )
             },
             width: 3
           },
           {
             display: t("pages.projects.tableHeaders.type"),
             accessorKey: "type",
             width: 2
           },
           {
            display: t("pages.projects.tableHeaders.status"),
            accessorKey: "status",
            width: 2,
            render: (row) => {
              return row.activated != undefined ? (
                <>{
                    <div className={`${row.activated ? "status-active" : "status"}`}>
                      {(row.activated ? t("pages.ressources.cardLabels.activeTrue") : t("pages.ressources.cardLabels.activeFalse"))}
                    </div>
                }</>
              ) : (
                ""
              );
            },
            filterOptions: [
              { value: t("pages.ressources.cardLabels.activeTrue"), display: <div className="status-active">{t("pages.ressources.cardLabels.activeTrue")}</div> },
              { value: t("pages.ressources.cardLabels.activeFalse"), display: <div className="status">{t("pages.ressources.cardLabels.activeFalse")}</div> },
            ],
          },
           {
             display: t("pages.projects.tableHeaders.createdAt"),
             accessorKey: "createdAt",
             render: (row) => {
               if (row.createdAt) {
                 return formatDate(row.createdAt);
               }
             },
             width: 2,
             isDate: true
           },
           {
             display: t("pages.projects.tableHeaders.updatedAt"),
             accessorKey: "updatedAt",
             render: (row) => {
               if (row.updatedAt) {
                 return formatDate(row.updatedAt);
               }
             },
             width: 2,
             isDate: true
           },
           {
             display: t("pages.projects.tableHeaders.actions"),
             accessorKey: "actions",
             sortable: false,
             filtrable: false,
             width: 2
           }
         ]}
         originalData={sortedProjects.map((ressource) => ({
           ...ressource,
           status: ressource.activated ? t("pages.ressources.cardLabels.activeTrue") : t("pages.ressources.cardLabels.activeFalse"),
           type: getTypeLevels(ressource)
               .map((level) => {
                 const translatedName = level.translations.find(
                   (t) => t.language === language
                 );
                 if (translatedName) {
                   return translatedName.name;
                 } else {
                   return level.name;
                 }
               })
               .join("/"),
           actions: (
             <ActionsCell
             handleClick={handleClick}
             handleDelete={handleDelete}
             handleDesactivate={ressource.activated ? () => handleDesactivateProjectTemplate(ressource.id):undefined}
             ressourceId={ressource.id}
             />
           ),
       onEdit: () => handleClick(ressource.id),
         }))}
       />
       {confirmDeleteMessage && (
        <ConfirmDeleteMessage
          onClose={() => {
            setConfirmDelete(false)
            setTemplateOpenPopupId(null)
          }}
          messageDelete={t("pages.viewProject.deleteMessage")}
          projectTemplate={projectToDelete}
          setNewChange={setProjectListChanged}
        />
      )}
  </>
          }
        </div>
      </section>
    </div>
  );
};

export default ProjectTemplates;
