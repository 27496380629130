import "./ourDocs.scss";
import { BsSearch } from "react-icons/bs";
import { useCallback, useEffect, useState } from "react";
import apiClientWithLoading from "../../services/api/ApiClient";
import Loading from "../../components/common/Loading";
import GroupRessourcesList from "./GroupRessourcesList";
import { useTranslation } from '../../contexts/TranslationProvider';
import CustomCombobox from "../../components/common/CustomCombobox";
import form from "../../assets/new-design-icons/Form.svg"
import contract from "../../assets/new-design-icons/Contract.svg"
import LayoutFilter from "../../components/LayoutFilter";
import GroupRessourceTable from "./GroupRessourceTable";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { RessourceClient } from "../../services/api/RessourceClient";

function OurDocuments() {
  const formIcon = form.toString()
  const contractIcon = contract.toString()
  const { t, language } = useTranslation();
  const types = [
    {
      name: t("pages.ressources." + "types.contract"),
      id: "Contract",
      icon: contractIcon
    },
    {
      name: t("pages.ressources." + "types.form"),
      id: "Form",
      icon: formIcon
    }
  ]
  const [loading, setLoading] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState({ type: null });
  const [groups, setGroups] = useState([]);
  const [ressources, setRessources] = useState([]);
  const [filteredRessources, setFilteredRessources] = useState<RessourceEntity[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState(null);
  const apiClient = apiClientWithLoading();
  const ressourceClient = new RessourceClient(apiClient)
  const translationPath = "pages.groupContracts."
  const [displayCards, setDisplayCards] = useState("grid");

  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const fetchData = useCallback(async () => {
    try {
      const ressourceData = await ressourceClient.getAssignedGroupRessources("nested", {
        type: ['Contract', 'Form'],
      });
      const combinedRessources = ressourceData.rows.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      const groups = ressourceData.groups;
      setRessources(combinedRessources);
      setFilteredRessources(combinedRessources);
      setGroups(groups);
      setLoading(false); // Set loading to false after ressources are fetched
    } catch (error) {
      console.error("Error fetching ressources: ", error);
      setLoading(false); // Also set loading to false in case of error
    }
  }, []);
  useEffect(() => {
  }, [filteredRessources]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filteredRessources = ressources;
    if (searchTerm) {
      filteredRessources = ressources.filter((r) =>
        r.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (typeFilter) {
      if (typeFilter === 'Contract') {
        filteredRessources = filteredRessources.filter(r => (r.type === 'Contract' || r.type === 'Summary'));
      } else {
        filteredRessources = filteredRessources.filter(r => r.type.includes(typeFilter));
      }
    }
    setFilteredRessources(filteredRessources);
  }, [searchTerm, appliedFilters]);
  const handleTypeFilterChange = (type) => {
    setAppliedFilters(prev => {
      return { ...prev, type: type }
    });
    setTypeFilter(types.find(t => t.id === type));
  }
  const handleLayoutChange = (layout) => {
    setDisplayCards(layout);
  };
  return (
    <div className="group-ressources-page">
      <section id="ressources" className="pt-2 px-2">
        <span className="current-pathname">{t("pages.groupContracts.title")}</span>
        <div style={{ padding: "20px" }} className="search-zone">
          <div className="ressources-filters">
            <div className="filter-item">
              <CustomCombobox
                canDeselect
                label=""
                onDropdownOpen={() => { }}
                options={types}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                optionValue={(option) => option?.id || null}
                optionDisplay={(option) => (<>
                  {option ? <div className="filter-type-item">
                    <div className='icon'>
                      {
                        option.id === 'Form' ? <img src={formIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                          option.id === 'Contract' || option.id === 'ContractAI' || option.id === 'Summary' ? <img src={contractIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> :
                            <></>
                      }
                    </div>
                    <span>
                      {option.name}
                    </span>
                  </div> :
                    <span>
                      {t(translationPath + "type")}
                    </span>
                  }
                </>)}
                fullWidth={true}
                isOpen={openCombobox === "ressourceFilter"}
                setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "ressourceFilter" : null)}
              />
            </div>
          </div>
          <div className="search-section">
            <div className="search w-75">
              <BsSearch className="search-icon" />
              <input
                type="text"
                placeholder={t("pages.ressources.search")}
                className="search-input"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
          </div>
        </div>
        <LayoutFilter onLayoutChange={handleLayoutChange} />
        <div className="ressources-list">
          {loading ? (
            <div style={{ width: "83vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Loading height="75vh" />
            </div>
          ) : displayCards == "grid" ? (
            <div>
              <GroupRessourcesList reloadRessources={fetchData} ressources={filteredRessources} groups={groups} />
            </div>
          ) : <GroupRessourceTable reloadRessources={fetchData} ressources={filteredRessources} groups={groups} />}
        </div>
      </section>
    </div>

  );
}

export default OurDocuments;