import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { TypeLevel1Client } from "../../../services/api/TypeLevel1Client";
import { getName } from "../../../helpers/Translation";
import {
  ProjectTemplateEntity,
  ProjectTemplateRequirementEntity,
  TypeLevel1Entity,
  TypeLevel2Entity,
  TypeLevel3Entity,
} from "../../../domain/entities";
import { useForm } from "react-hook-form";
import PendingAi from "../../../components/common/newDesign/PendingAi";
import CustomCombobox from "../../../components/common/CustomCombobox";
import "../../../components/modals/commonmodal.scss";
import useSelectTypeLevels from "../../../hooks/useSelectTypeLevels";

export type ProjectRequirementProps = {
  projectTemplate: ProjectTemplateEntity;
  requirement: ProjectTemplateRequirementEntity;
  loading: boolean;
  setRessources: React.Dispatch<React.SetStateAction<ProjectTemplateRequirementEntity[]>>;
  ressources: ProjectTemplateRequirementEntity[]
  setLoading: (loading: boolean) => void;
  onClose: () => void;
}
function RessourceContract({
  projectTemplate,
  requirement,
  loading,
  setRessources,
  ressources,
  setLoading,
  onClose,
}: ProjectRequirementProps) {
  const [error, setError] = useState("");
  const [templateExistsError, setTemplateExistsError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [requirementData, setRequirementData] = useState<ProjectTemplateRequirementEntity>(
    requirement || {
      name: "",
    });

  const {
    options,
    selected,
    setLevels1,
    handleLevel1Change,
    handleLevel2Change,
    handleLevel3Change,
  } = useSelectTypeLevels()

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await typeLevel1Client.getAllWithContractTemplate(language);
      setLevels1(data.rows,
        requirement.level1Id,
        requirement.level2Id,
        requirement.level3Id,
      )
    };
    setLoading(false);
    fetchData();
  }, []);

  useEffect(() => {
    setRequirementData({
      ...requirementData,
      level1Id: selected.level1?.id || null,
      level2Id: selected.level2?.id || null,
      level3Id: selected.level3?.id || null,

      level1: selected.level1 || null,
      level2: selected.level2 || null,
      level3: selected.level3 || null,
      index: requirementData.index || Date.now().toString(),
    });
    setError("");
    return () => { }
  }, [selected])

  const [required, setChecked] = useState(requirement ? !requirement.isOptional : true);
  const handleCheckboxChange = () => {
    setChecked(!required);
  };

  const { watch, register } = useForm();
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);
  const { t, language } = useTranslation();
  const translationPath = t("pages.admin.actionsOptionProjectTemplate");

  const apiClient = useApiClientWithLoading();
  const typeLevel1Client = new TypeLevel1Client(apiClient);
  const handleSave = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Veuillez remplir tous les champs nécessaires");
      return;
    }
    const isNewResource = !requirement?.name;
    const dataRequirement = {
      ...requirementData,
      isOptional: !required,
      types: [2],
      index: isNewResource ? Date.now().toString() : requirement.index,
    };
    setRessources((prev) => {
      if (isNewResource) {
        return [...prev, dataRequirement];
      } else {
        return prev.map((r) => {
          if (r.index === requirement.index) {
            console.log('Updating resource with matching index:', r.index);
            return { ...r, ...dataRequirement };
          }
          return r;
        });
      }
    });

    onClose();
  };

  const validateForm = () => {
    const isValid =
      requirementData?.name &&
      !templateExistsError &&
      requirementData.level1Id != null &&
      (options.level2.length === 0 || requirementData.level2Id != null) &&
      (options.level3.length === 0 || requirementData.level3Id != null);
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [requirementData, templateExistsError, options]);

  useEffect(() => {
    if (requirementData?.name) {
      const existing = ressources?.find(
        (r) =>
          r.name.trim() === requirementData.name.trim()
          &&
          (r.id !== requirementData.id || (r.id == null && r.index !== requirementData.index))
      );
      setTemplateExistsError(!!existing);
    }
  }, [requirementData?.name]);
  console.log('requirementData :!!!!!!!!!!!!>> ', requirementData);
  return (
    <div>
      {loading ? (
        <PendingAi height="100%" />
      ) : (
        <form
          action=""
          className="form-group w-100 mb-3"
          style={{ maxHeight: "80vh" }}
          onSubmit={handleSave}
        >
          <p className="fw-bolder">
            {t("pages.listener.popups.contract.required")}
          </p>
          <div className="form-input-content">
            <div className="form-input-first-content" style={{ width: "100%" }}>
              <label htmlFor="contractName" className="case-form-labelName">
                {t("pages.listener.popups.contract.inputs.contractName.name")}{" "}
              </label>
              <div className="form-floating mb-3 w-100">
                <input
                  type="text"
                  value={requirementData.name}
                  className="form-control custom-color-input input-popup"
                  id="contractName"
                  placeholder={t(
                    "pages.listener.popups.contract.inputs.contractName.placeholder"
                  )}
                  {...register("templateName", { required: true })}
                  onChange={(e) => {
                    setError("");
                    setRequirementData({ ...requirementData, name: e.target.value });
                  }}
                />
                {templateExistsError && (
                  <p className="text-danger py-0 my-0 py-0 my-0">
                    {t("modals.createBlankTemplate.unicityError")}
                  </p>
                )}
              </div>
            </div>
            <div className="form-input-last-content" style={{ width: "80%" }}>
              <CustomCombobox
                fullWidth
                label={t("pages.pilot.popups.contract.inputs.type1.name")}
                options={options.level1.map((item) => item)}
                optionDisplay={(option) => getName(option, language)}
                value={selected.level1}
                onChange={(value) => handleLevel1Change(value)}
                isOpen={openCombobox === "level1"}
                setIsOpen={(isOpen) =>
                  setOpenCombobox(isOpen ? "level1" : null)
                }
              />

              {(options.level2.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type2.name")}
                  options={options.level2.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  value={selected.level2}
                  onChange={(value) => handleLevel2Change(value)}
                  isOpen={openCombobox === "level2"}
                  setIsOpen={(isOpen) =>
                    setOpenCombobox(isOpen ? "level2" : null)
                  }
                />
              )}
              {(options.level3.length > 0) && (
                <CustomCombobox
                  fullWidth
                  label={t("pages.pilot.popups.contract.inputs.type3.name")}
                  options={options.level3.map((item) => item)}
                  optionDisplay={(option) => getName(option, language)}
                  value={selected.level3}
                  onChange={(value) => handleLevel3Change(value)}
                  isOpen={openCombobox === "level3"}
                  setIsOpen={(isOpen) =>
                    setOpenCombobox(isOpen ? "level3" : null)
                  }
                />
              )}
            </div>
          </div>
          <p>
            <label className="case-form-labelName">
              {translationPath.required}
            </label>{" "}
            <input
              type="checkbox"
              checked={required}
              id="checkbox-required"
              value={required.toString()}
              onChange={handleCheckboxChange}
            />
          </p>
          {!isFormValid && error && (
            <p style={{ color: "red", fontSize: "13px" }}>{error}</p>
          )}
          <div className="modal-footer footer-form-pilotage">
            <button type="submit" className="btn btn-primary btn-display"
              disabled={templateExistsError}
            >
              {requirement.name ? "Editer " : translationPath.contract.btn}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default RessourceContract;
