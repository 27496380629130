import { NavigateFunction } from "react-router-dom";
import { RessourceEntity } from "../domain/entities/Ressource";


export function navigateToRessource(navigate: NavigateFunction, newTab?: boolean) {
  return (ressource: RessourceEntity) => {
    let url = "";

    switch (ressource.type) {
      case "Form":
        url = `/edit-form/${ressource.formId}`;
        break;
      case "Contract":
        url = `/edition-contrat/${ressource.contractId}`;
        break;
      case "RessourceScoring":
        url = ressource.ressourceScoring?.operationType === "SIMILARITY"
          ? `/contract-compliance/${ressource.ressourceScoringId}`
          : `/ai-document-response/${ressource.ressourceScoring.useCaseResponseId}`;
        break;
      case "Document":
        url = ressource.hasAIProcessing
          ? `/document-summary/${ressource.documentId}`
          : `/document/${ressource.documentId}`;
        break;
      default:
        return;
    }

    if (newTab) {
      window.open(url, "_blank"); // Ouvre dans un nouvel onglet
    } else {
      navigate(url); // Navigue dans le même onglet
    }
  };
}
