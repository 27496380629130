import React from "react";
import { TaskEntity } from "../../../domain/entities/Task";
import { useTranslation } from "../../../contexts/TranslationProvider";

interface TaskDeleteModalProps {
  onClose: () => void;
  task: TaskEntity;
  onDeleteClick: (task: TaskEntity) => void;
}

const TaskDeleteModal = ({
  onClose,
  task,
  onDeleteClick,
}: TaskDeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-backdrop" onClick={onClose}></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                {t("pages.tasks.modals.delete.title")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body pb-3">
                <h1
                  className="question-modal-delete"
                  style={{ padding: "10px" }}
                >
                   {t("pages.tasks.modals.delete.confirmation")}{" "}
                  {task?.name}
                </h1>

                <div>
                  <div className="modal-buttons">
                    <button className="btn submit-delete" onClick={()=>onDeleteClick(task)}>{t("pages.tasks.modals.delete.deleteButton")}</button>

                    <button
                      className="btn btn-secondary cancel-delete"
                      style={{
                        marginLeft: "20px",
                        backgroundColor: "gray",
                      }}
                      onClick={()=>onClose()}
                    >
                     {t("pages.tasks.modals.delete.cancelButton")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskDeleteModal;
