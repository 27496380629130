import { AxiosInstance } from "axios";
import { ModelClient } from "../ModelClient";
import { ClauseEntity, ClauseHistoryEntity } from "../../domain/entities";


export class ClauseHistoryClient extends ModelClient<ClauseHistoryEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'ClauseHistory')
  }
    async revertVersion(clauseId: ClauseEntity['id'], versionId: ClauseHistoryEntity['id']){
      const url = new URL(`${this.modelUrl}/revertVersion`)
      url.searchParams.append("clauseId", String(clauseId))
      url.searchParams.append("versionId", String(versionId))
      const response = await this.apiClient.post<{row: ClauseEntity}>(url.href, {});
      return response.data;
    }
};

