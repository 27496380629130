import { AxiosInstance } from "axios";
import { RessourceScoringEntity, ClauseScoringEntity, DocumentEntity, ContractTemplateEntity, ContractEntity, TypeLevel1Entity, TypeLevel2Entity, TypeLevel3Entity } from "../../domain/entities";
import { ModelClient } from "../ModelClient";

export class RessourceScoringClient extends ModelClient<RessourceScoringEntity> {

  constructor(apiClient: AxiosInstance) {
    super(apiClient, 'RessourceScoring')
  }
  async createFromDocument(documentId: DocumentEntity['id'], name: string, templateId: ContractTemplateEntity['id']){
    const response = await this.apiClient.post<RessourceScoringEntity>(`${this.modelUrl}/createFromDocument`, { documentId, name, templateId });
    return response.data;
  }
  async createFromContract(contractId: ContractEntity['id'], name: string, templateId: ContractTemplateEntity['id']){
    const response = await this.apiClient.post<RessourceScoringEntity>(`${this.modelUrl}/createFromContract`, { contractId, name, templateId });
    return response.data;
  }
  async mapRessourceClauses(ressourceId: RessourceScoringEntity['id'], threshold: number = 0.4){
    const url = new URL(`${this.modelUrl}/mapRessourceClauses`)
    url.searchParams.append("id", String(ressourceId))
    url.searchParams.append("threshold", String(threshold))
    const response = await this.apiClient.post<RessourceScoringEntity>(url.href);
    return response.data;
  }
  async analyzeRessourceClause(clauseId: ClauseScoringEntity['id']){
    const url = new URL(`${this.modelUrl}/analyseClauseScoring`)
    url.searchParams.append("id", String(clauseId))
    const response = await this.apiClient.post<ClauseScoringEntity>(url.href);
    return response.data;
  }
  async analyzeRessourceClauses(ressourceId: RessourceScoringEntity['id']){
    const url = new URL(`${this.modelUrl}/analyseRessourceScoring`)
    url.searchParams.append("id", String(ressourceId))
    const response = await this.apiClient.post<RessourceScoringEntity>(url.href);
    return response.data;
  }
  async exportToContract(name: string, ressourceId: RessourceScoringEntity['id'], level1Id?: TypeLevel1Entity['id'], level2Id?: TypeLevel2Entity['id'], level3Id?: TypeLevel3Entity['id']){
    const url = new URL(`${this.modelUrl}/exportToContract`)
    url.searchParams.append("id", String(ressourceId))
    url.searchParams.append("name", name)
    level1Id && url.searchParams.append("level1Id", String(level1Id))
    level2Id && url.searchParams.append("level2Id", String(level2Id))
    level3Id && url.searchParams.append("level3Id", String(level3Id))
    const response = await this.apiClient.post<ContractEntity>(url.href);
    return response.data;
  }
}