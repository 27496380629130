import { useState, useEffect } from 'react';
import React from 'react'
import AskYourQuestionsContext from '../../../contexts/AskYourQuestionsContext'
import { AiFileEntity, ConversationEntity } from '../../../domain/entities';
import ReglementConversationList from './ReglementConversationList';

function ConversationList() {
  const { conversations, currentConversation, setCurrentConversation, setPendingQuestion, handleDelete, reglements, currentReglement } = React.useContext(AskYourQuestionsContext)
  const [groupedConversations, setGroupedConversations] = useState<{ [key: string]: ConversationEntity[] }>({})
  useEffect(() => {
    const groupedConversations: { [key: string]: ConversationEntity[] } = {}
    const fileConversations: AiFileEntity[] = []
    conversations.forEach((conversation) => {
      if (groupedConversations[conversation.reglement]) {
        groupedConversations[conversation.reglement].push(conversation)
      } else {
        groupedConversations[conversation.reglement] = [conversation]
      }
    })
    // clean groupedConnversations where the key is a missing regement
    Object.keys(groupedConversations).forEach((key) => {
      if (!reglements.find((reglement) => reglement.id === key)) {
        delete groupedConversations[key]
      }
    })
    setGroupedConversations(groupedConversations)
  }, [conversations])

  return (
    <div className='conversations-container-by-reglement'>
      <div className='grouped-conversation-list'>
        {Object.keys(groupedConversations).map((key, index) => {
          return (
            <>
              <ReglementConversationList
                conversations={groupedConversations[key]}
                reglement={reglements.find((reglement) => reglement.id === key)}
                currentConversation={currentConversation}
                setCurrentConversation={setCurrentConversation}
                setPendingQuestion={setPendingQuestion}
                handleDelete={handleDelete}
                currentReglement={currentReglement}
                key={index}
              />
            </>
          )
        })}
      </div>


    </div>
  )
}

export default ConversationList