import React, { useMemo, useState, useEffect } from 'react'
import EditReglementContext from '../../contexts/EditReglementContext';
import { ReglementEntity } from '../../domain/entities';
import { ReglementClient } from '../../services/rag/ReglementClient';
import { useParams } from 'react-router-dom';
import { set } from 'react-hook-form';
import Loading from '../../components/common/Loading';
import './reglement.scss'
import SVGRender from '../../helpers/SVGRender';
import { close } from '../../assets/icons/close'
import chat from "../../assets/new-design-icons/Chat.svg"
import RessourceCard from './components/RessourceCard';
import AddFile from './components/AddFile';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../contexts/TranslationProvider';
import useApiClientWithLoading from '../../services/api/ApiClient';
import EditReglement from './components/EditReglement';
import { MdModeEditOutline } from "react-icons/md";
import { FaSave } from "react-icons/fa";
function ReglementPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const chatIcon = chat.toString()
  const apiClient = useApiClientWithLoading();
  const reglementClient = new ReglementClient(apiClient)
  const { reglementId } = useParams();
  const [reglement, setReglement] = useState<ReglementEntity | null>(null);
  const [loading, setLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const getReglement = async () => {
    try {
      setLoading(true);
      const row = await reglementClient.getById(reglementId);
      if (!row) return;
      setReglement(row);
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getReglement()
  }, []);

  const EditReglementContextValue = useMemo(() => ({
    reglement,
    setReglement
  }), [
    reglement,
    setReglement
  ]);

  const uploadFile = async (file: File) => {
    try {
      setLoading(true);
      await reglementClient.addFile(reglementId, file);
      getReglement();
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const handleDesactivate = async () => {
    if (!reglement || !reglement.has_vectorstore) return;
    try {
      setLoading(true);
      await reglementClient.desactivate(reglementId);
      getReglement();
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const handleCommit = async () => {
    if (!reglement || reglement.has_vectorstore || reglement.files.length === 0) return;
    try {
      setLoading(true);
      await reglementClient.commit(reglementId);
      getReglement();
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }
  const handleUpdateReglement = async (data: ReglementEntity) => {
    const reglementData = {
      name: data.name ? data.name : reglement?.name,
      chunk_size: data.chunk_size ? data.chunk_size : reglement?.chunk_size,
      chunk_overlap: data.chunk_overlap ? data.chunk_overlap : reglement?.chunk_overlap,
      similarity_threshold: data.similarity_threshold ? data.similarity_threshold : reglement?.similarity_threshold,
      k: data.k ? data.k : reglement?.k,
      compression_chunk_size: data.compression_chunk_size ? data.compression_chunk_size : reglement?.compression_chunk_size,
      compression_k: data.compression_k ? data.compression_k : reglement?.compression_k,
      predefined_questions: data.predefined_questions ? data.predefined_questions : reglement?.predefined_questions,
      ...data
    }
    try {
      setIsUpdateLoading(true);
      const newData = await reglementClient.update(reglementId, reglementData);
      if (!newData) return;
      setReglement(prev => { return { ...prev, ...newData } });
    } catch (err) {
      console.log(err)
    } finally {
      setIsUpdateLoading(false);
    }
  }
  const handleUpdateName = async (name: string) => {
    try {
      const newData = await reglementClient.update(reglementId, { name });
      if (!newData) return;
      setReglement(prev => { return { ...prev, ...newData } });
    } catch (err) {
      console.log(err)
    }
  }
  const handleDeleteFile = async (fileId: string) => {
    if (reglement?.has_vectorstore) return;
    try {
      setLoading(true);
      await reglementClient.deleteFile(reglementId, fileId);
      getReglement();
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  return (
    <EditReglementContext.Provider value={EditReglementContextValue}>
      <div style={{ width: "100%" }}>
        <div className='reglement-page'>
          <div className='reglement-page-header'>
            <div className='left-section'>
              <span style={{ cursor: "pointer" }} onClick={() => navigate("/reglements")} className='close'>
                <SVGRender svgContent={close} />
              </span>
              <div className='title'>
                <img src={chatIcon} alt="icon" style={{ width: "45px", height: "45px" }} />
                <div className='name-section'>
                  {
                    isNameEditing ? (<div className='edit-name'>
                      <input className='edit-input' type='text' value={reglement?.name} onChange={(e) => setReglement(prev => { return { ...prev, name: e.target.value } })} />
                    </div>) :
                      (<span className='name'>{reglement?.name}</span>)
                  }
                  <div className='edit-icon'>
                    {!isNameEditing ?
                      <MdModeEditOutline
                        onClick={() => setIsNameEditing(true)}
                      /> :
                      <FaSave
                        onClick={()=>{
                          setIsNameEditing(false)
                          handleUpdateName(reglement?.name || "")
                        }}
                      />
                    }
                  </div>
                </div>

              </div>
            </div>
            <div className='right-section'>
              {
                reglement && reglement.has_vectorstore && (
                  <div className='status-active'>
                    <span>
                      {t("pages.reglement.active")}
                    </span>
                  </div>
                )
              }
              {
                reglement && !reglement.has_vectorstore && (
                  <div className='status-inactive'>
                    <span>
                      {t("pages.reglement.inactive")}
                    </span>
                  </div>
                )
              }
            </div>
          </div>
          <div className='reglement-page-body' style={{ paddingTop: "20px" }}>
            {loading ? (<Loading />) : (
              <>
                <span className='section-title'>
                  {t("pages.reglement.files")}
                </span>
                <div className='reglement-files-list'>
                  {
                    reglement && !reglement.has_vectorstore && (
                      <AddFile handleUpload={uploadFile} />
                    )
                  }
                  {
                    reglement && reglement.files?.map(file => (
                      <RessourceCard key={file.id} ressource={file} handleDelete={handleDeleteFile} isDeleteOn={!reglement.has_vectorstore} />
                    ))
                  }
                </div>
                <div className='reglement-editor'>
                  {reglement && <EditReglement reglement={reglement} handleSave={handleUpdateReglement} isUpdateLoading={isUpdateLoading} />}
                </div>
                <div className='actions'>
                  {!(reglement && reglement.has_vectorstore) && <div onClick={handleCommit} className={`commit-button ${(!reglement || reglement.has_vectorstore || reglement.files.length === 0) && "disabled"}`}>
                    {t('pages.reglement.activate')}
                  </div>}
                  {reglement && reglement.has_vectorstore && <div onClick={handleDesactivate} className={`commit-button `}>
                    {t('pages.reglement.desactivate')}
                  </div>}
                </div>
              </>)}
          </div>
        </div>
      </div>
    </EditReglementContext.Provider>
  )
}

export default ReglementPage