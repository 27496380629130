import { WorkflowGraph } from "../Workflow";
import { BaseValidator, ValidationError } from "./BaseValidator";



export class WorkflowGraphValidator extends BaseValidator<WorkflowGraph> {
  validate(graph: WorkflowGraph): ValidationError[] {
    const errors: ValidationError[] = [];

    // Track incoming flows for each step
    const incomingFlows: Record<string, number> = {};

    // Initialize incoming flow counts for all steps
    graph.steps.forEach((step) => {
      incomingFlows[step.id] = 0;
    });

    // Count incoming flows for each step
    graph.transitions.forEach((transition) => {
      if (incomingFlows[transition.toId] !== undefined) {
        incomingFlows[transition.toId]++;
      }
    });

    // Find steps without incoming flows
    const stepsWithoutIncomingFlows = graph.steps.filter(
      (step) => incomingFlows[step.id] === 0
    );

    // Validate that there is exactly one step without incoming flows
    if (stepsWithoutIncomingFlows.length !== 1) {
      errors.push({
        code: "InitialStepError",
        message: `The workflow must have exactly one initial step without incoming flows, but found ${stepsWithoutIncomingFlows.length}.`,
      }
      );
    }

    return errors;
  }
}