import moment from "moment"
import { IndexParam, RenderSegment, RenderSegments, SegmentedClauseParam, SegmentedText, SegmentedTextType, segmentedTextStyle } from "../domain/types/ClauseParams"
import { getDeepValue } from "../utils/object"
import { beneficialMacros, virtualbeneficialGetters } from "./Beneficial"
import { BeneficialCompanyEntity, BeneficialEntity, BeneficialMinorEntity, BeneficialPersonEntity, ContractEntity } from "../domain/entities"
import { evaluateParamFormat } from "../domain/Form"
import { splitArrayToMatrix } from "../utils/array"
import { pathHead } from "../utils/string"
import { LanguageType } from "../contexts/TranslationProvider"
import { isValidInputValue } from "./CompletionPercentage"

function countIndex(indexParam: IndexParam, start?: number) {
	if (start == undefined || start == null) return indexParam.args.start
	return start + indexParam.args.step
}
type Scope = {
	beneficialParamName?: string;
	beneficial?: BeneficialEntity;
}
export function getValueFromInputs<T extends SegmentedClauseParam>(inputPath: string, inputs: any,
	params: T[]) {
	const [paramName, valuePath, parts] = pathHead(inputPath)
	let parentParam = params.find((param) => param.name === paramName)
	let value = null
  let fullParamName = parts.join(".")
  let fullParam = params.find((param) => param.name === fullParamName || param.name === [...parts].splice(0, parts.length - 1).join("."))
	
	if (parentParam) {
		switch (parentParam.type) {
			case 'boolean':
			case 'enum':
				const definitions = params.filter((param) => param.name === paramName && param.type == parentParam.type)
				parentParam = definitions.find((param) => ((param as any).definition || 0) === (Number(valuePath) || 0))
				value = inputs[paramName]
				break;
			case 'static-table':
				const table = params.find((param) => param.name === paramName)
				value = table
				break;
			case 'index':
				value = countIndex(parentParam, inputs[paramName])
				break;
			default:
				value = inputs[paramName]
				break;
		}
	}else if(fullParam){
    switch(fullParam.type){
      case 'boolean':
      case 'enum':
				const bool_enumName = [...parts].splice(0, parts.length -1).join(".")
        const definitions = params.filter((param) => param.name === bool_enumName && param.type == fullParam.type)
        const definition = parts[parts.length - 1]
        parentParam = definitions.find((param) => ((param as any).definition || 0) === (Number(definition) || 0))
        value = inputs[bool_enumName]
        break;
      case 'static-table':
        value = fullParam
        break;
      case 'index':
        value = countIndex(fullParam, inputs[fullParamName])
        break;
      default:
        value = inputs[fullParamName]
        break;
    }
  }
	return { value, parentParam: parentParam ? parentParam : fullParam }
};
export function getMacroRenderSegments(segmentedText: SegmentedText, inputs: any,
	params: SegmentedClauseParam[], t: (key: any) => any, language: LanguageType, scope?: Scope,
	indexCounters: Record<string, number> = {}
): RenderSegments {
	let outputSegmentedText = [] as RenderSegments
	segmentedText.map((segment) => {
		switch (segment[2]) {
			case SegmentedTextType.LIST_ITEM_START:
				outputSegmentedText.push({
					type: SegmentedTextType.LIST_ITEM_START,
					id: segment[0],
					value: segment[1],
					style: segment[3]
				})
				break;
			case SegmentedTextType.PARAGRAPH_START:
				outputSegmentedText.push({
					type: SegmentedTextType.PARAGRAPH_START,
					id: segment[0],
					value: segment[1],
					style: segment[3],
				})
				break;
			case SegmentedTextType.STATIC:
				outputSegmentedText.push({
					type: SegmentedTextType.STATIC,
					id: segment[0],
					value: segment[1],
					style: segment[3]
				})
				break;
			case SegmentedTextType.PARAM:
				const inputPath = segment[1];
				const { value: input, parentParam } = getValueFromInputs(inputPath, inputs, params);
				if (!parentParam) {
				  // console.log("getRenderSegments: parentParam not found", inputPath, segment);
					return outputSegmentedText.push({
						type: SegmentedTextType.STATIC,
						id: segment[0],
						value: segment[1],
						style: segment[3]
					})
				}
				if (!isValidInputValue(parentParam, input)) {
					return outputSegmentedText.push({
						type: SegmentedTextType.PARAM,
						id: segment[0],
						value: segment[1],
						paramName: parentParam.name,
						style: segment[3]
					})
				}
				const defaultSegment = {
					type: SegmentedTextType.PARAM,
					id: segment[0],
					value: segment[1],
					paramName: parentParam.name,
					style: segment[3]
				}
				const startSegment = {
					type: SegmentedTextType.PARAM_START,
					id: `${defaultSegment.id}-START`,
					paramName: defaultSegment.paramName,
					value: " ",
					inputValue: input
				}
				const endSegment = {
					type: SegmentedTextType.PARAM_END,
					id: `${defaultSegment.id}-END`,
					paramName: defaultSegment.paramName,
					value: " ",
					inputValue: input
				}
				switch (parentParam.type) {
					case 'index':
						const indexParam = parentParam as IndexParam
						const count = countIndex(indexParam, indexCounters[parentParam.name])
						indexCounters[parentParam.name] = count
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: `${count}`,
							paramName: parentParam.name,
							style: segment[3]
						})
					case 'boolean':
						const content = input ? parentParam.args.textIfTrue : parentParam.args.textIfFalse
						return outputSegmentedText.push(
							startSegment,
							...getMacroRenderSegments(content,
								inputs, params, t, language, scope),
							endSegment
						)

					case 'date':
						const reformattedDate = moment(new Date(input)).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY")
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: parentParam.format ? String(evaluateParamFormat(parentParam, reformattedDate)) : reformattedDate,
								paramName: parentParam.name,
								style: segment[3]
							}
						)
					case 'file':
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name,
							style: segment[3]
						})
					case 'comment':
						return outputSegmentedText.push({
							type: SegmentedTextType.PARAM_COMMENT_VALUE,
							id: segment[0],
							value: input,
							paramName: parentParam.name,
							style: segment[3]
						})
					case 'enum':
						const arg = parentParam.args[input]
						if (!arg)
							return outputSegmentedText.push(defaultSegment)
						const segmentedText = arg.text;
						try {
							return outputSegmentedText.push(
								startSegment,
								...getMacroRenderSegments(segmentedText
									, inputs, params, t, language, scope),
								endSegment,
							)
						} catch (error) {
							console.error({ input, segmentedText });
							throw error;
						}
					case 'static-table':
						const tableArg = parentParam.args;
						if (!tableArg.cells || !tableArg.cells.length) {
							return outputSegmentedText.push(defaultSegment);
						}

						// Start of the static table
						outputSegmentedText.push({
							type: SegmentedTextType.STATIC_TABLE_START,
							id: `${defaultSegment.id}-TABLE-START`,
							paramName: defaultSegment.paramName,
							value: " ",
							inputValue: input,
							style: tableArg.style
						});

						const cells = tableArg.cells;

						cells.forEach((row, rowIndex) => {
							// Start of the row
							outputSegmentedText.push({
								type: SegmentedTextType.STATIC_TABLE_ROW_START,
								id: `${defaultSegment.id}-ROW-${rowIndex}-START`,
								paramName: defaultSegment.paramName,
								value: " ",
							});

							row.forEach((cell, cellIndex) => {
								const cellAlignment = cell.cellStyle?.textAlign;
								// Start of the cell
								outputSegmentedText.push({
									type: SegmentedTextType.STATIC_TABLE_CELL_START,
									id: `${defaultSegment.id}-ROW-${rowIndex}-CELL-${cellIndex}-START`,
									paramName: defaultSegment.paramName,
									value: " ",
									style: {
										...tableArg.style,
										textAlign: cellAlignment,
									},
									rowIdx: rowIndex,
									cellIdx: cellIndex,
								});

								const cellValue = cell.content;
								if (cellValue) {
									// Recursively handle nested segments within the cell
									cellValue.forEach((content) => {
										outputSegmentedText.push(...getMacroRenderSegments(
											[content]
											, inputs, params, t, language, scope
										));
									});
								}

								// End of the cell
								outputSegmentedText.push({
									type: SegmentedTextType.STATIC_TABLE_CELL_END,
									id: `${defaultSegment.id}-ROW-${rowIndex}-CELL-${cellIndex}-END`,
									paramName: defaultSegment.paramName,
									value: " ",
									style: {
										textAlign: cellAlignment
									},
								});
							});

							// End of the row
							outputSegmentedText.push({
								type: SegmentedTextType.STATIC_TABLE_ROW_END,
								id: `${defaultSegment.id}-ROW-${rowIndex}-END`,
								paramName: defaultSegment.paramName,
								value: " "
							});
						});

						// End of the static table
						outputSegmentedText.push({
							type: SegmentedTextType.STATIC_TABLE_END,
							id: `${defaultSegment.id}-TABLE-END`,
							paramName: defaultSegment.paramName,
							value: " ",
							inputValue: input
						});

						return outputSegmentedText;

					case 'list':
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: input.map(idx => parentParam.args[idx]?.option).join("\n"),
								paramName: parentParam.name,
								style: segment[3]
							}
							: defaultSegment)
					case 'table':
						const headers = parentParam.args.map(arg => arg.header)
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_TABLE_VALUE,
								id: segment[0],
								value: JSON.stringify([!!parentParam.transposed, [headers, ...input]]),
								paramName: parentParam.name,
								style: segment[3]
							}
						)
					case 'csv':
						return outputSegmentedText.push((Array.isArray(input)) ?
							{
								type: SegmentedTextType.PARAM_TABLE_VALUE,
								id: segment[0],
								value: JSON.stringify([input[0], input[1]]),
								paramName: parentParam.name,
								style: segment[3]
							}
							: defaultSegment)
					case 'number':

						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: parentParam.format ? String(evaluateParamFormat(parentParam, input)) : input,
								paramName: parentParam.name,
								style: segment[3]
							})

					case 'string':
					case 'property':
						return outputSegmentedText.push(
							{
								type: SegmentedTextType.PARAM_VALUE,
								id: segment[0],
								value: String(input),
								paramName: parentParam.name,
								style: segment[3]
							})
					default:
						console.warn(parentParam);
						console.warn(input);
						console.warn(segment);
						return
				}
			case SegmentedTextType.COMMENT:
				return outputSegmentedText.push({
					type: SegmentedTextType.COMMENT,
					id: segment[0],
					value: segment[1],
					style: segment[3]
				})
			default:
				break;
		}

	})

	return outputSegmentedText.filter(seg => seg).map((seg) =>
	({
		...seg,
		value: String(seg.value)
	})
	);
}
export function getTableSegments(rows: RenderSegment[][], defaultSegment: RenderSegment, { paramName, style, cellStyle }: {
	paramName?: string;
	style?: segmentedTextStyle;
	cellStyle?: segmentedTextStyle;
} = {}
): RenderSegments {
	let outputSegmentedText = [] as RenderSegments
	// Start of the static table
	outputSegmentedText.push({
		type: SegmentedTextType.STATIC_TABLE_START,
		id: `${defaultSegment.id}-TABLE-START`,
		paramName,
		style,
		value: " ",
	});

	rows.forEach((row, rowIndex) => {
		// Start of the row
		outputSegmentedText.push({
			type: SegmentedTextType.STATIC_TABLE_ROW_START,
			id: `${defaultSegment.id}-ROW-${rowIndex}-START`,
			paramName,
			value: " "
		});

		row.forEach((cell, cellIndex) => {
			// Start of the cell
			outputSegmentedText.push({
				type: SegmentedTextType.STATIC_TABLE_CELL_START,
				id: `${defaultSegment.id}-ROW-${rowIndex}-CELL-${cellIndex}-START`,
				paramName,
				value: " ",
				rowIdx: rowIndex,
				cellIdx: cellIndex,
				style: cellStyle ? cellStyle : cell.style
			});
			outputSegmentedText.push(cell)

			// End of the cell
			outputSegmentedText.push({
				type: SegmentedTextType.STATIC_TABLE_CELL_END,
				id: `${defaultSegment.id}-ROW-${rowIndex}-CELL-${cellIndex}-END`,
				paramName,
				value: " ",
				style: cellStyle ? cellStyle : cell.style
			});
		});

		// End of the row
		outputSegmentedText.push({
			type: SegmentedTextType.STATIC_TABLE_ROW_END,
			id: `${defaultSegment.id}-ROW-${rowIndex}-END`,
			paramName,
			value: " "
		});
	});

	// End of the static table
	outputSegmentedText.push({
		type: SegmentedTextType.STATIC_TABLE_END,
		id: `${defaultSegment.id}-TABLE-END`,
		paramName,
		value: " ",
		inputValue: ""
	});
	return outputSegmentedText
}

function prepareSegmentedTextExport(segmentedText: SegmentedText): SegmentedText {
	if (!segmentedText) return []
	if (segmentedText.length == 1 && segmentedText[0][2] == SegmentedTextType.STATIC && segmentedText[0][1] == "_") {
		// return a static segment with space as value
		return [[segmentedText[0][0], " ", SegmentedTextType.STATIC, segmentedText[0][3]]]
	}
	return segmentedText
}