import React from 'react'
import RenderSegmentedText from '../../RenderSegmentedText'
import { ClauseHistoryEntity } from '../../../domain/entities'
import './versionDisplay.scss'
import { useTranslation } from '../../../contexts/TranslationProvider'

function VersionDisplay({ clause, paramValues }: { clause: ClauseHistoryEntity, paramValues: Record<string, any> }) {
  const { language } = useTranslation();
  return (
    <div className='version-display'>
    <RenderSegmentedText
      segmentation={clause.segmentation}
      fileNames={[]}
      beneficialsMap={{}}
      inputValues={paramValues}
      clauseId={0}
      isSelected={false}
      isEditing={false}
    />
    {
      clause.subClauses && 
      clause.subClauses.length > 0 &&
      clause.subClauses.sort((a, b) => parseInt(a.index) - parseInt(b.index)).map((subClause, index) => (
        <>
        <span className='subclause-title' style={{float: language == "ar" ? "right" : "left"}}>{subClause.name}</span>
        <RenderSegmentedText
          key={index}
          segmentation={subClause.segmentation}
          fileNames={[]}
          beneficialsMap={{}}
          inputValues={paramValues}
          clauseId={0}
          isSelected={false}
          isEditing={false}
        />
        </>
      ))
    }
    </div>
  )
}

export default VersionDisplay