import { createContext } from "react";
import { ClauseEntity, ContractEntity, ContractTemplateEntity, GroupEntity, ProjectEntity, ProjectTemplateRequirementEntity, SubClauseEntity } from "../domain/entities";
import { ContractTemplateParsedData } from "../helpers/ParseTemplateData";
import { BeneficialEntity } from "../domain/entities";
import { RenderSegments, SegmentedClauseParam } from "../domain/types/ClauseParams";

export interface InputValuesContextType {
  contract: ContractEntity;
  setContract: React.Dispatch<React.SetStateAction<ContractEntity>>;
  status:ContractEntity['status'];
  clausesOverrides:ContractEntity['clausesOverrides'];
  setClausesOverrides:React.Dispatch<React.SetStateAction<ContractEntity['clausesOverrides']>>;
  setContractStatus:React.Dispatch<React.SetStateAction<ContractEntity['status']>>;
  contractTemplate: ContractTemplateEntity;
  setContractTemplate: React.Dispatch<React.SetStateAction<ContractTemplateEntity>>;
  templateData: ContractTemplateParsedData;
  inputValues: ContractEntity['paramValues'];
  setInputValues: React.Dispatch<React.SetStateAction<ContractEntity['paramValues']>>;
  fileNames: ContractEntity['fileNames'];
  setFileNames: React.Dispatch<React.SetStateAction<ContractEntity['fileNames']>>;
  beneficialsMap: Record<string, BeneficialEntity>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<ContractEntity['beneficialsMap']>>;
  commentsOverrides: ContractEntity['commentsOverrides'];
  setCommentsOverrides: React.Dispatch<React.SetStateAction<ContractEntity['commentsOverrides']>>;
  completionPercentage: number,
  setCompletionPercentage: React.Dispatch<React.SetStateAction<number>>;
  excludedSubClauses: ContractEntity['excludedSubClauses'];
  setExcludedSubClauses: React.Dispatch<React.SetStateAction<ContractEntity['excludedSubClauses']>>;
  excludedClauses: ContractEntity['excludedClauses'];
  setExcludedClauses: React.Dispatch<React.SetStateAction<ContractEntity['excludedClauses']>>;
  templateId: ContractTemplateEntity['id'],
  setTemplateId: React.Dispatch<React.SetStateAction<ContractTemplateEntity['id']>>;
  groups: GroupEntity[];
  setGroups: React.Dispatch<React.SetStateAction<GroupEntity[]>>;
  contractId: ContractEntity['id'],
  setContractId: React.Dispatch<React.SetStateAction<ContractEntity['id']>>;
  contractListParentRef: any,
  userGroup: number[],
  setUserGroup: React.Dispatch<React.SetStateAction<number[]>>;
  setAccessUser: React.Dispatch<React.SetStateAction<Record<ClauseEntity['index'], boolean | 'view'>>>
  accessUser: Record<ClauseEntity['index'], boolean | 'view'>,
  groupContract: string,
  setGroupContract: React.Dispatch<React.SetStateAction<string>>,
  project?: ProjectEntity
  setProject?: React.Dispatch<React.SetStateAction<ProjectEntity>>;
  requirement?: ProjectTemplateRequirementEntity,
  setRequirement?: React.Dispatch<React.SetStateAction<ProjectTemplateRequirementEntity>>
  requirementType?: number,
  setRequirementType?: React.Dispatch<React.SetStateAction<number>>,
  onSegmentDelete: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => void;
  onAddParam: (clauseId: ClauseEntity['id'], subClauseId: ClauseEntity['id'], id: string, newParam: SegmentedClauseParam, textBefore: string, textAfter: string, styledSegments: ClauseEntity['segmentation']['segmentedText'], field: string, deletedSegments: RenderSegments) => void;
  combinedTemplateParams: SegmentedClauseParam[];
  onApplySegmentation: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], styledSegments: ClauseEntity['segmentation']['segmentedText'], deletedSegments: RenderSegments) => void;
  onParamChanged: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], param: SegmentedClauseParam) => void;
  onSegmentChange: (clauseId: ClauseEntity['id'], subClauseId: SubClauseEntity['id'], id: string, text: string) => void
}
const InputValuesContext = createContext<InputValuesContextType>({

} as any);

export default InputValuesContext;
