import { genderArray, maritalStatusArray, nationalyArray } from "../../../../domain/entities";
import { SegmentedClauseParams } from "../../../../domain/types/ClauseParams";
export const baseBeneficialEntitySample = {
  gender: genderArray,
	firstName: 'string',
	lastName: 'string',
	fullName: 'string',
	nationality: nationalyArray,
  dateOfBirth: new Date(),
	placeOfBirth: 'string',
	maritalStatus: maritalStatusArray,
	cin: 'string',
	cinDeliveryDate: new Date(),
	cinDeliverPlace: 'string',
	jobTitle: 'string',
	email: 'string',
  addressString: 'string',
}
export const personBeneficialEntitySample = {
  ...baseBeneficialEntitySample,
  passport: 'string',
  hasAttorney: true,
  attorney: {
    gender: genderArray,
    firstName: 'string',
    lastName: 'string',
    fullName: 'string',
    cin: 'string',
    cinDeliveryDate: new Date(),
    cinDeliverPlace: 'string',
    regionalFinancialOffice: 'string',
    powerOfAttorneyDate: new Date(),
    powerOfAttorneyReceitNumber: 'string',
    powerOfAttorneyRegistrationNumber: 'string',
  },
  hasSpouse: true,
  spouse: {
    gender: genderArray,
    firstName: 'string',
    lastName: 'string',
    fullName: 'string',
    cin: 'string',
    cinDeliveryDate: new Date(),
    cinDeliverPlace: 'string',
  },
  hasSpouseAttorney: true,
  spouseAttorney: {
    gender: genderArray,
    firstName: 'string',
    lastName: 'string',
    fullName: 'string',
    cin: 'string',
    cinDeliveryDate: new Date(),
    cinDeliverPlace: 'string',
    regionalFinancialOffice: 'string',
    powerOfAttorneyDate: new Date(),
    powerOfAttorneyReceitNumber: 'string',
    powerOfAttorneyRegistrationNumber: 'string',
  },
}
export const companyEntitySample = {
  ...baseBeneficialEntitySample,
  companyName: 'string',
  socialCapital: 0,
  socialCapitalCurrency: 'TND',
  postalAddress: 'string',
  legalForm: 'string',
  registrationNumber: 'string',
  specialization: 'string',
  hasAttorney: true,
  attorney: {
    gender: genderArray,
    firstName: 'string',
    lastName: 'string',
    fullName: 'string',
    cin: 'string',
    cinDeliveryDate: new Date(),
    cinDeliverPlace: 'string',
    regionalFinancialOffice: 'string',
    powerOfAttorneyDate: new Date(),
    powerOfAttorneyReceitNumber: 'string',
    powerOfAttorneyRegistrationNumber: 'string',
  },
}
export const minorEntitySample = {
  ...baseBeneficialEntitySample,
  minorFirstName: 'string',
  minorLastName: 'string',
  minorFullName: 'string',
  minorDateOfBirth: new Date(),
  minorPlaceOfBirth: 'string',
  minorNationality: nationalyArray,
  minorGender: genderArray,
  minorOrderNumber: 'string',
  minorOrderDate: new Date(),
  minorOrderName: 'string',
  minorOrderCourt: 'string',
};

export function generateParamsFromEntity<T extends object>(
  entity: T,
  prefix: string = '',
  labelPrefix: string = ''
): SegmentedClauseParams {
  const params: SegmentedClauseParams = [];

  function processAttributes(
      obj: Record<string, any>,
      prefix: string = '',
      labelPrefix: string = ''
  ) {
      for (const [key, value] of Object.entries(obj)) {
          const paramName = prefix ? `${prefix}.${key}` : key;
          const paramLabel = labelPrefix ? `${labelPrefix} (${key})` : key;

          if (value === null || value === undefined) continue;
          if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
            // Handle enums (arrays of strings)
            params.push({
              name: paramName,
              label: paramLabel,
              type: 'enum',
              args: value.map((option) => ({ option, text: [] })), // Generate enum options
              definition: 0,
            });
          } else if (typeof value === 'string') {
            // Handle strings
            params.push({
              name: paramName,
              label: paramLabel,
              type: 'string',
            });
          } else if (typeof value === 'boolean') {
              // Handle booleans
              params.push({
                  name: paramName,
                  label: paramLabel,
                  type: 'boolean',
                  args: { textIfTrue: [], textIfFalse: [] },
                  definition: 0,
              });
          } else if (typeof value === 'number') {
              // Handle numbers
              params.push({
                  name: paramName,
                  label: paramLabel,
                  type: 'number',
              });
          } else if (value instanceof Date) {
              // Handle dates
              params.push({
                  name: paramName,
                  label: paramLabel,
                  type: 'date',
              });
          } else if (typeof value === 'object' && !Array.isArray(value)) {
              // Handle nested objects
              processAttributes(value, paramName, paramLabel);
          }
      }
  }

  processAttributes(entity);
  return params;
}