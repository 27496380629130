import React, { useEffect, useState, useRef, useMemo } from "react";

import "./chatWithDocument.scss";
import Loading from "../../components/common/Loading";
import { useParams } from "react-router-dom";
import {
  DocumentEntity,
  AiFileConversationEntity,
} from "../../domain/entities";
import { AiFileClient } from "../../services/rag/AiFileClient";
import { AiFileEntity } from "../../domain/entities/AiFile";
import { toast } from "react-toastify";
import { DocumentClient } from "../../services/api/DocumentClient";
import useApiClientWithLoading from "../../services/api/ApiClient";
import { fetchTextFileUrl } from "../../helpers/helper";
import ChatWithDocumentContext from "../../contexts/ChatWithDocumentContext";
import { AiFileConversationClient } from "../../services/rag/AiFileConversationClient";
import FileChatBody from "./FileChatBody";
import FileChatHistory from "./FileChatHistory";
import { set } from "lodash";

function ChatWithDocumentPage() {
  const [pageLoading, setPageLoading] = useState(false);
  const [conversations, setConversations] = useState<AiFileConversationEntity[]>([]);
  const [currentFile, setCurrentFile] = useState<AiFileEntity | null>(null);
  const [currentConversation, setCurrentConversation] = useState<AiFileConversationEntity | null>(null);
  const [query, setQuery] = useState("");
  const [pendingQuestion, setPendingQuestion] = useState(null);

  const scorllRef = useRef(null);
  const listRef = useRef(null);
  const apiClient = useApiClientWithLoading();
  const aiFileConversationClient = new AiFileConversationClient(apiClient);
  const aiFileClient = new AiFileClient(apiClient);
  const { documentId } = useParams<{ documentId?: string }>() || {};
  const documentClient = new DocumentClient(apiClient);
  const [document, setDocument] = useState<DocumentEntity>(null);
  const [text, setText] = useState<string>(null);

  const initialGet = async () => {
    try {
      const row = await documentClient.getById(parseInt(documentId));
      const text = await fetchTextFileUrl(row?.textFile?.url);
      setDocument(row);
      setText(text);
      if (row && row.aiFileId) {
        const result = await aiFileClient.getById(row.aiFileId);
        if (result && result.id) {
          setCurrentFile(result);
          setConversations(result.conversations);
        } else {
          toast.error("Error loading document conversations");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Error loading document");
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    setPageLoading(true);
    initialGet();
  }, []);


  const handleDelete = async (e, ressource) => {
    e.stopPropagation();
    try {
      if (!ressource.id) return
      if (currentConversation?.id === ressource.id) setCurrentConversation(null);
      await aiFileConversationClient.delete(ressource.id);
      const newAiFile = await aiFileClient.getById(currentFile.id);
      setConversations(newAiFile.conversations);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (currentFile) {
      // scroll to bottom of list ref
      setTimeout(() => {
        try {
          const container = listRef.current;
          container && container.scrollTo({
            top: container.scrollHeight,
            // not smooth
            behavior: "smooth",
          });
        } catch (err) {
          console.log(err);
        }
      }, 100);
    }
  }, [currentFile, currentConversation, pendingQuestion]);
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };
  // Helper function to process the streaming response.
  const processStreamResponse = async (response, question) => {
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let accumulatedResponse = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      if (value) {
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n").filter(line => line.trim() !== "");
        for (const line of lines) {
          try {
            const data = JSON.parse(line);
            const { operationType, response: partialResponse, conversation } = data;
            if (conversation && conversation.id) {
              const newAiFile = await aiFileClient.getById(currentFile.id);
              setConversations(newAiFile.conversations);
              setCurrentConversation(conversation);
            }
            accumulatedResponse = partialResponse;
            setPendingQuestion({
              question,
              response: accumulatedResponse,
              operationType,
            });
          } catch (e) {
            toast.error("Error processing stream data.");
            setPendingQuestion(null);
            console.error("Error parsing JSON:", e);
          }
        }
      }
    }
  };

  const handleSubmit = async (comingQuery = "") => {
    if (!query && !comingQuery) return;
    if (!currentFile) return toast.error("Error loading document.");
    const question = comingQuery || query;
    setQuery("");
    setPendingQuestion({ question });

    try {
      let response;
      if (!currentConversation) {
        response = await aiFileConversationClient.queryAndCreateStream(currentFile.id, question);
      } else {
        response = await aiFileConversationClient.queryStream(currentConversation.id, question);
      }
      await processStreamResponse(response, question);
      setPendingQuestion(null);
    } catch (error) {
      console.error("Error during streaming:", error);
      setPendingQuestion(null);
      toast.error("An error occurred during streaming.");
    } finally {
      setPendingQuestion(null);
    }
  };
  const ChatWithDocumentContextValue = useMemo(
    () => ({
      conversations,
      setConversations,
      currentConversation,
      setCurrentConversation,
      pendingQuestion,
      setPendingQuestion,
      handleQueryChange,
      query,
      handleSubmit,
      handleDelete,
      currentFile,
      setCurrentFile,
    }),
    [
      conversations,
      setConversations,
      currentConversation,
      setCurrentConversation,
      pendingQuestion,
      setPendingQuestion,
      handleQueryChange,
      query,
      handleSubmit,
      handleDelete,
      currentFile,
      setCurrentFile,
    ]
  );
  const renderContentBasedOnFileExtension = () => {
    switch (document?.fileExtenstion.toLowerCase()) {
      case "pdf":
        return (
          <div className="document-pdf-viewer">
            <iframe src={document?.file.url} width="100%" height="87vh" />
          </div>
        );
      case "docx":
      case "doc":
        return (
          <div className="document-docx-viewer">
            <iframe
              src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                document?.file.url
              )}`}
              width="100%"
              height="87vh"
            />
          </div>
        );
      case "jpeg":
      case "jpg":
      case "png":
        return (
          <div className="document-image-viewer">
            <img
              src={document?.file.url}
              alt={document?.name}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        );
      default:
        return (
          <div className="ai-text-container">
            <div className="ai-text">{text}</div>
          </div>
        );
    }
  };
  return (
    <ChatWithDocumentContext.Provider value={ChatWithDocumentContextValue}>
      <div className="chat-with-document-page-container">
        {pageLoading ? (
          <Loading />
        ) : (
          <>
            <div className="ask-page-body">
              <div className="body-conversations-container">
                <div className="document-page-text-section">
                  <h1 className="contract-name">{document?.name}</h1>
                  {renderContentBasedOnFileExtension()}
                </div>
              </div>
              <div className="body-interactions-container" style={{ height: "87vh" }}>
                <FileChatBody listRef={listRef} scorllRef={scorllRef} displayFileName={false} />
              </div>
              <div style={{ flex: 1, padding: "10px" }}>
                <FileChatHistory />
              </div>
            </div>
          </>
        )}
      </div>
    </ChatWithDocumentContext.Provider>
  );
}

export default ChatWithDocumentPage;
