import { API_BASE_URL } from "../config";
import { AxiosInstance } from 'axios'


export class BaseClient {
	protected apiUrl: string;
	constructor(protected apiClient: AxiosInstance) {
		const baseUrl = apiClient.defaults.baseURL
		this.apiUrl = baseUrl || API_BASE_URL
	}


}