import { AxiosInstance } from "axios";
import { BaseClient } from "../BaseClient";

export class UtilsClient extends BaseClient {

  constructor(apiClient: AxiosInstance) {
    super(apiClient)
  }
  async uploadTmpFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.apiClient.post<{ key: string, url: string }>(`${this.apiUrl}/utils/uploadTmpFile`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;

  }

}