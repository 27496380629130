import React, { useEffect, useState, useContext } from "react";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import { intersectionByKey } from "../../../utils/array";
import CustomCombobox from "../../common/CustomCombobox";
import { useNavigate } from "react-router-dom";
import { getUserGroups } from "../../../services/api/UserAuth";
import InputValuesContext from "../../../contexts/InputValuesContext";
import { useParamProcessing } from "../../../hooks/useParamProcessing";
import { useSubmitContract } from "../../../hooks/useSubmitContract";
import { handleContractSaveLogic } from "../SaveDraftModal";
import { GroupEntity, UserEntity } from "../../../domain/entities";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { GroupClient } from "../../../services/api/GroupClient";
import { UserClient } from "../../../services/api/UserClient";
import { ContractClient } from "../../../services/api/ContractClient";
import { getName } from "../../../helpers/Translation";

function ValidateContractModal({ onClose }) {
  const {
    contract,
    contractTemplate,
    inputValues,
    commentsOverrides,
    excludedClauses,
    excludedSubClauses,
    templateId,
    completionPercentage,
  } = useContext(InputValuesContext);
  const processParamValues = useParamProcessing();
  const { t, language } = useTranslation();
  const submitContract = useSubmitContract(null);
  const contractId = contract?.id;
  const [validationData, setValidationData] = useState({
    validateGroupId: contract?.assignedToGroupId,
    assignToGroupId: undefined,
    assignToUserId: undefined,
  });
  const [invalidData, setInvalidData] = useState(false);


  const [userGroups, setUserGroups] = useState<GroupEntity[]>([]);
  const [openCombobox, setOpenCombobox] = useState<string | null>(null);

  const validateGroupOptions: GroupEntity[] = intersectionByKey(
    contractTemplate?.groups ?? [],
    userGroups,
    "id"
  );
  const [assignToUserOptions, setAssignToUserOptions] = useState<UserEntity[]>([]);

  const currentValidateGroupOption = validateGroupOptions.find(
    (o) => o.id == validationData.validateGroupId
  );

  const currentAssignToGroupOption = contractTemplate?.groups.find(
    (o) => o.id == validationData.assignToGroupId
  );
  const currentAssignToUserOption = assignToUserOptions.find(
    (o) => o.id == validationData.assignToUserId
  );

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const apiClient = useApiClientWithLoading(setLoading);
  useEffect(() => {
    getUserGroups(apiClient).then(({ rows }) => {
      setUserGroups(rows);
      if (validationData.validateGroupId && !rows.find(g => g.id == validationData.validateGroupId)) {
        setValidationData((prev) => {
          return {
            ...prev,
            validateGroupId: null,
          }
        })
      }
    });
  }, []);

  const contractClient = new ContractClient(apiClient)
  const groupClient = new GroupClient(apiClient)
  const userClient = new UserClient(apiClient)

  useEffect(() => {
    setLoading(true);
    setAssignToUserOptions([]);
    if (validationData.assignToGroupId) {
      groupClient.getUsers(validationData.assignToGroupId).then(
        ({ rows }) => {
          setAssignToUserOptions(rows);
          if (rows.find((row) => row.id == contract?.assignedToUserId))
            setValidationData({
              ...validationData,
              assignToUserId: contract?.assignedToUserId,
            });
          else
            setValidationData({
              ...validationData,
              assignToUserId: null,
            });
          setLoading(false);
        }
      );
    } else
      setAssignToUserOptions([])
  }, [validationData.assignToGroupId]);

  const handleInputChange = (field) => (value) => {
    setValidationData({
      ...validationData,
      [field]: value,
    });
  };

  const submitValidationData = async () => {
    try {
      if (validationData.assignToGroupId && validationData.assignToUserId && validationData.assignToGroupId) {
        const savedContractId = await handleContractSaveLogic(
          inputValues,
          processParamValues,
          submitContract,
          setLoading,
          templateId,
          commentsOverrides,
          excludedSubClauses,
          excludedClauses,
          completionPercentage,
          contractId
        );
        setLoading(true);
        await contractClient.validate(savedContractId, validationData);
        setLoading(false);
        toast.success(t("modals.validateContract.messages.success"));
        onClose();
      }
      else {
        setInvalidData(true)
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("modals.validateContract.messages.error"));
      console.error(error);
      onClose();
    }
  };

  useEffect(() => {
    setInvalidData(false)
  }, [validationData])

  return (
    <>
      <div
        className="modal-backdrop"
      ></div>
      <div id="contractz-lab">
        <div className="modal d-flex justify-content-center align-items-center">
          <div>
            <div className="modal-content" style={{ width: "960px" }}>
              <div className="modal-header">
                <h5 className="modal-title">{t("modals.validateContract.title")}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                  aria-label="Close"
                  style={{ marginRight: language == "ar" && "88%" }}
                ></button>
              </div>
              {loading ? (
                <Loading height="50vh" />
              ) : (
                <div className="modal-body" style={{ paddingBottom: "5%" }}>
                  <div className="form-group validate">
                    <div className="form-floating mb-3">
                      <CustomCombobox
                        isValid={(invalidData && !validationData.assignToGroupId) ? false : true}
                        label={t("modals.validateContract.destination")}
                        onDropdownOpen={() => { }}
                        options={contractTemplate?.groups ?? []}
                        value={currentAssignToGroupOption}
                        onChange={handleInputChange("assignToGroupId")}
                        optionValue={(option) => option?.id || null}
                        optionDisplay={(option) => getName(option, language)}
                        isOpen={openCombobox === "destination"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "destination" : null)}
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <CustomCombobox
                        canDeselect
                        label={t("modals.validateContract.source")}
                        onDropdownOpen={() => { }}
                        options={validateGroupOptions}
                        value={currentValidateGroupOption}
                        onChange={handleInputChange("validateGroupId")}
                        optionValue={(option) => option?.id || null}
                        optionDisplay={(option) => getName(option, language)}
                        optionIsEnabled={(option) =>
                          !contract?.validationGroups?.find(vg => vg.id == option.id)
                        }
                        isOpen={openCombobox === "source"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "source" : null)}
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <CustomCombobox
                        isValid={(invalidData && !validationData.assignToUserId) ? false : true}
                        label={t("modals.validateContract.assignee")}
                        onDropdownOpen={() => { }}
                        options={assignToUserOptions}
                        value={currentAssignToUserOption}
                        onChange={handleInputChange("assignToUserId")}
                        optionValue={(option) => option?.id || null}
                        optionDisplay={(option: UserEntity) => option?.fullName}
                        isOpen={openCombobox === "assignee"}
                        setIsOpen={(isOpen) => setOpenCombobox(isOpen ? "assignee" : null)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary submit-validate-contract-btn"
                  onClick={submitValidationData}
                >
                  {t("modals.validateContract.validate")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ValidateContractModal;
