import React from 'react'
import { FileEntity } from '../../../domain/entities'
import { FaTrashAlt } from "react-icons/fa";
const docIcon = require('../../../assets/new-design-icons/doc.png')
function RessourceCard({ressource, handleDelete, isDeleteOn}: {ressource: FileEntity, handleDelete: (string) => void, isDeleteOn: boolean}) {
  return (
    <div className='ressource-card'>
      {
      isDeleteOn && 
      <FaTrashAlt 
      className='delete-icon' 
      onClick={() => handleDelete(ressource.id)}
      />
      }
      <img src={docIcon} alt="icon" style={{ width: "30px", height: "30px" }} /> 
      <span className='title'>
        {ressource.name}
      </span>
    </div>
  )
}

export default RessourceCard