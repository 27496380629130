import {
  IoCreateOutline,
  IoEllipsisHorizontal,
  IoTimeOutline,
} from "react-icons/io5";
import "./task.scss";
import { TaskEntity } from "../../domain/entities/Task";
import { TbAlertTriangleFilled, TbChevronsDown, TbChevronsUp, TbEqual } from "react-icons/tb";
import { format } from "date-fns";
import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BiCalendar, BiCalendarX } from "react-icons/bi";
import { useTranslation } from "../../contexts/TranslationProvider";

interface TaskProps {
  task: TaskEntity;
  provided: any;
  onTaskClick: (task: TaskEntity) => void;
  onEditClick: (task: TaskEntity) => void;
  onDeleteClick: (task: TaskEntity) => void;
}
const renderPriorityIcon = (priority: string) => {
  switch (priority) {
    case "critical":
      return <TbAlertTriangleFilled className="priority-icon priority-critical" size={24}/>;
    case "high":
      return <TbChevronsUp className="priority-icon priority-high" size={24} />;
    case "medium":
      return <TbEqual className="priority-icon priority-medium" size={24} />;
    default:
      return (
        <TbChevronsDown className="priority-icon priority-low" size={24} />
      );
  }
};

const Task = ({
  task,
  provided,
  onTaskClick,
  onEditClick,
  onDeleteClick,
}: TaskProps) => {
  const { t,language } = useTranslation();
  const [menuVisible, setMenuVisible] = useState<boolean>(false); 
  const handleEditClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEditClick(task);
    setMenuVisible(false); 
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteClick(task);
    setMenuVisible(false);
  };

  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuVisible((prev) => !prev); 
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="task-card-container"
      style={{
        ...provided.draggableProps.style,
        borderColor: task.color,
        borderWidth: "10px 1px 1px 1px",
      }}
      onClick={() => onTaskClick(task)}
    >
      <div
        style={{ position: "relative", width: "100%" }}
        className="task-card"
      >
        <div
          className="task-menu-icon"
          onClick={handleMenuClick}
          style={{
            position: "absolute",
            top: "8px",
            [language === "ar" ? "left" : "right"]: "8px", 
            cursor: "pointer",
            zIndex: 10,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            borderRadius: "50%",
            padding: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IoEllipsisHorizontal size={16} color="#666" />
        </div>

      
        {menuVisible && (
          <div className="task-menu" style={{[language === "ar" ? "left" : "right"]: "8px"}}>
            <div onClick={handleEditClick} className="task-menu-item">
              <IoCreateOutline size={16} color="#666" />{t("pages.tasks.buttons.edit")}
            </div>
            <div onClick={handleDeleteClick} className="task-menu-item">
              <AiFillDelete size={16} color="#666" />
              {t("pages.tasks.buttons.delete")}
            </div>
          </div>
        )}
        <div className="task-creator">
          <div className="avatar-user">
            {task.client?.email
              ? task.client.email.charAt(0).toUpperCase()
              : ""}
            <span className="tooltip">
              {task.client?.firstName} {task.client?.lastName}
            </span>
          </div>

          <span className="creator-label">{t("pages.tasks.card.creator")}</span>
        </div>

        <div className="task-content">
          <span className="task-title">{task.name}</span>
          <span className="task-description">{task.comment}</span>
          <span></span>
          <span className="task-createdAt">
          {t("pages.tasks.card.createdOn")}:{" "}
            {task.createdAt
              ? format(new Date(task.createdAt), "dd/MM/yyyy HH:mm")
              : "N/A"}
          </span>
          <span className="task-updatedAt">
          {t("pages.tasks.card.lastUpdated")}:{" "}
            {task.updatedAt
              ? format(new Date(task.updatedAt), "dd/MM/yyyy HH:mm")
              : "N/A"}
          </span>
        </div>

        <div className="task-divider"></div>

        <div className="task-footer">
          <div className="avatar-container">
            <div className="avatar-container">
              {task.users?.slice(0, 2).map((user, index) => (
                <div key={index} className="avatar-users">
                  {user.email ? user.email.charAt(0).toUpperCase() : ""}
                </div>
              ))}

              {task.users && task.users.length > 3 && (
                <div className="avatar-users more-users">
                  +{task.users.length - 2}
                </div>
              )}
            </div>
          </div>
          <div className="task-time">
            <div>
              <div>
                <BiCalendar color={"#666"} width="19px" height="19px" />
                <span>
                {t("pages.tasks.card.esd")}:{" "}
                  {task.estimatedStartDate
                    ? format(
                        new Date(task.estimatedStartDate),
                        "dd/MM/yyyy HH:mm"
                      )
                    : "N/A"}
                </span>
              </div>
              <div>
                <BiCalendarX color={"#666"} width="19px" height="19px" />
                <span>
                {t("pages.tasks.card.eed")}:{" "}
                  {task.estimatedEndDate
                    ? format(
                        new Date(task.estimatedEndDate),
                        "dd/MM/yyyy HH:mm"
                      )
                    : "N/A"}
                </span>
              </div>
            </div>
          </div>
          <div className="task-priority">
            {renderPriorityIcon(task.priority)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Task;
