import React from 'react'
import { RenderSegment, SegmentedTextType } from '../../../domain/types/ClauseParams';
import { renderStyledSegment } from '../../../helpers/renderSegment';
import { ClauseEntity } from '../../../domain/entities';

function RenderTemplateSingleSegmentedText({ segment, idx, isSelected, segmentation, changedParamBoundaries }: {
    segment: RenderSegment;
    idx: number;
    isSelected: boolean;
    segmentation: ClauseEntity['segmentation'];
    changedParamBoundaries: [number, number][];
}) {
    const isHighlighted = changedParamBoundaries.some((b) => b[0] < idx && idx < b[1]);
    const { id, value, type } = segment;
    const key = idx;
    const text = value
    const styledSegment = renderStyledSegment(segment);
    const breakedHTMLText = text?.replaceAll(/\n/g, "<br/>");
    switch (type) {
        case SegmentedTextType.PARAM_START:
        case SegmentedTextType.PARAM_END:
            return <React.Fragment key={key}></React.Fragment>;
        case SegmentedTextType.STATIC:
            return (
                <React.Fragment key={key}>
                    <span className={`${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}>
                        {styledSegment}
                    </span>
                </React.Fragment>
            );
        case SegmentedTextType.COMMENT:
            return (
                <React.Fragment key={key}>
                    <span className={`comment-prefix ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}>☞ &nbsp;</span>
                    <span className={`comment ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`} dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
                </React.Fragment>
            );
        case SegmentedTextType.PARAM:
            const paramPath = value.split(".");
            const param = segmentation.segmentedParams.find((param) => param.name === segment.paramName);
            const paramLabel = param?.label;
            const paramText = `${paramLabel ?? value} ${(param.type === 'beneficial' || param.type === 'beneficial[]') && paramPath[1] ? `(${paramPath[1]})` : ''}`;
            const styledParamVal = renderStyledSegment({ ...segment, value: paramText, style: segment.style });
            return (
                <span id={`param-${segment.paramName}`} key={key} className={`param ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}>
                    {styledParamVal}
                </span>
            );
        case SegmentedTextType.PARAM_VALUE:
            const styledParamValue = renderStyledSegment({
                ...segment,
                value: text,
                style: segment.style,
            });
            return (
                <React.Fragment key={key}>
                    <span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`param-value ${isSelected ? "selected-text" : ""} ${isHighlighted ? "highlighted-text" : ""} preserve-spaces`}>
                        {styledParamValue}
                    </span>
                </React.Fragment>
            );
        case SegmentedTextType.PARAM_TABLE_VALUE:
            const [transposed, tableRows] = JSON.parse(value);
            const [headers, ...rows] = tableRows as string[][];
            return transposed ? (
                <table id={`param-${segment.paramName}`} key={key} className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`} style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody>
                        {headers.map((header, idx) => (
                            <tr key={idx}>
                                <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{header}</th>
                                {rows.map((row, rowIndex) => (
                                    <td key={rowIndex} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{row[idx]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <table id={`param-${segment.paramName}`} key={key} className={`my-4 ${isHighlighted ? "highlighted-text" : ""}`} style={{ borderCollapse: "collapse", width: "100%" }}>
                    <thead>
                        <tr>
                            {headers.map((header, idx) => (
                                <th key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, idx) => (
                            <tr key={idx}>
                                {row.map((cell, idx) => (
                                    <td key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        case SegmentedTextType.PARAM_COMMENT_VALUE:
            return (
                <React.Fragment key={key}>
                    <span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`comment ${isHighlighted ? "highlighted-text" : ""}`}>{text}</span>
                </React.Fragment>
            );
        default:
            return null;
    }
}

export default RenderTemplateSingleSegmentedText