import React from 'react'
import { useTranslation } from '../../../../../contexts/TranslationProvider'
import { formatDate } from '../../../../../helpers/helper'
const aiIcon = require('../../../../../assets/new-design-icons/ai.png')

function RessourceScoringBody({ data, type, updatedSince, createdSince }) {
  const { t, language } = useTranslation()
  const translationPath = "pages.ressources."
  return (
    <div className='body'>
      <div className='body-section'>
        <span>
          <span className='label'>
          {t(translationPath + "cardLabels.type")}
        </span>
        <span className='value'>
          {data.ressourceScoring && data.ressourceScoring.operationType=='SIMILARITY'&&
          <span>{t(translationPath + "cardLabels.similarity")} </span> 
          }
          {data.ressourceScoring && data.ressourceScoring.operationType=='COMPLIANCE'&&
          <span>{t(translationPath + "cardLabels.compliance")} </span> 
          }
        </span>
        </span>
      </div>

      <div className='body-section'>
        <span>
        <span className='label'>
          {t(translationPath + "cardLabels.source")}
          </span>
          <span className='value'>
            
              <span>{t("pages.contracts.filters.AI")} </span> 
            
          </span>
        </span>
        </div>
      <div className='body-section'>

      </div>
      <div className='timestamp'>
        <div>
          <span className='value'>
          {t(translationPath + "cardLabels.updatedAt")}
          </span>
          <span className='value'>
            {formatDate(data.updatedAt as any as string)}
          </span>
        </div>
        <div>
          <span className='value'>
          {t(translationPath + "cardLabels.createdAt")}
          </span>
          <span className='value'>
         {formatDate(data.createdAt as any as string)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default RessourceScoringBody