import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { FormEntity, FormTemplateEntity, FormTemplatePageEntity, GroupEntity, ProjectEntity, ProjectTemplateRequirementEntity } from "../domain/entities";
import { BeneficialEntity } from "../domain/entities";
import { FormParam } from "../domain/types/FormParams";
import { useLocation, useParams } from "react-router-dom";


type SelectedFormTemplatePageType = {
  pageId: FormTemplatePageEntity['id'];
  paramName?: FormParam['name'];
}
export interface EditFormContextType {
  form: FormEntity;
  setForm: React.Dispatch<React.SetStateAction<FormEntity>>;
  formId: FormEntity['id'],
  setFormId: React.Dispatch<React.SetStateAction<FormEntity['id']>>;
  template: FormTemplateEntity;
  setTemplate: React.Dispatch<React.SetStateAction<FormTemplateEntity>>;
  templateId: FormTemplateEntity['id'],
  setTemplateId: React.Dispatch<React.SetStateAction<FormTemplateEntity['id']>>;
  name: FormEntity['name'];
  setName: React.Dispatch<React.SetStateAction<FormEntity['name']>>;
  paramValues: FormEntity['paramValues'];
  setParamValues: React.Dispatch<React.SetStateAction<FormEntity['paramValues']>>;
  fileNames: FormEntity['fileNames'];
  setFileNames: React.Dispatch<React.SetStateAction<FormEntity['fileNames']>>;
  beneficialsMap: Record<string, BeneficialEntity>;
  setBeneficialsMap: React.Dispatch<React.SetStateAction<FormEntity['beneficialsMap']>>;
  groups: GroupEntity[];
  setGroups: React.Dispatch<React.SetStateAction<GroupEntity[]>>;
  completionPercentage: number,
  setCompletionPercentage: React.Dispatch<React.SetStateAction<number>>
  selected: SelectedFormTemplatePageType;
  setSelected: React.Dispatch<React.SetStateAction<SelectedFormTemplatePageType>>;
  project?: ProjectEntity
  setProject?: React.Dispatch<React.SetStateAction<ProjectEntity>>;
  requirement?: ProjectTemplateRequirementEntity,
  setRequirement?: React.Dispatch<React.SetStateAction<ProjectTemplateRequirementEntity>>
  requirementType?: number,
  groupForm: string,
  setGroupForm: React.Dispatch<React.SetStateAction<string>>,
  setRequirementType?: React.Dispatch<React.SetStateAction<number>>
  accessUser: Record<FormTemplatePageEntity['index'], boolean | 'view'>,
  setAccessUser: React.Dispatch<React.SetStateAction<Record<FormTemplatePageEntity['index'], boolean | 'view'>>>
}

const EditFormContext = createContext<EditFormContextType>({} as any);

export const EditFormContextProvider: React.FC<any> = ({ children }: { children: ReactNode[] }) => {
  const location = useLocation();


  const [form, setForm] = useState<FormEntity>(null);
  const [groupForm, setGroupForm] = useState<string>();

  const [formId, setFormId] = useState<FormEntity['id']>(null);
  const [template, setTemplate] = useState<FormTemplateEntity>();
  const [templateId, setTemplateId] = useState(null);

  const [name, setName] = useState<FormEntity['name']>("");
  const [paramValues, setParamValues] = useState<FormEntity['paramValues']>();
  const [fileNames, setFileNames] = useState<FormEntity['fileNames']>({});
  const [beneficialsMap, setBeneficialsMap] = useState<FormEntity['beneficialsMap']>({});
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [selected, setSelected] = useState<EditFormContextType['selected']>({ pageId: null, paramName: null });
  const [project, setProject] = useState<ProjectEntity>();
  const [requirement, setRequirement] = useState<ProjectTemplateRequirementEntity>();
  const [requirementType, setRequirementType] = useState<number>();
  const [accessUser, setAccessUser] = useState<Record<string, boolean>>({});



  const editFormContext: EditFormContextType = useMemo(
    () => ({
      form, setForm,
      groupForm, setGroupForm,
      formId, setFormId,
      template, setTemplate,
      name, setName,
      paramValues, setParamValues,
      fileNames, setFileNames,
      beneficialsMap, setBeneficialsMap,
      templateId, setTemplateId,
      groups, setGroups,
      completionPercentage, setCompletionPercentage,
      selected, setSelected,
      project, setProject,
      requirement, setRequirement,
      requirementType, setRequirementType,
      accessUser, setAccessUser
    }),
    [
      form, setForm,
      groupForm, setGroupForm,
      formId, setFormId,
      template, setTemplate,
      name, setName,
      paramValues, setParamValues,
      fileNames, setFileNames,
      beneficialsMap, setBeneficialsMap,
      templateId, setTemplateId,
      groups, setGroups,
      completionPercentage, setCompletionPercentage,
      selected, setSelected,
      project, setProject,
      requirement, setRequirement,
      requirementType, setRequirementType,
      accessUser, setAccessUser
    ]
  );

  return (
    <EditFormContext.Provider value={editFormContext} >
      {children}
    </EditFormContext.Provider>
  );
}
export default EditFormContext;
