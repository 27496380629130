import React, { useEffect, useState } from "react";
import "./taskModal.scss";
import { TaskEntity } from "../../../domain/entities/Task";
import { RessourceEntity } from "../../../domain/entities/Ressource";
import { UserEntity } from "../../../domain/entities";
import SearchableDropdown from "../../common/SearchableDropdown/SearchableDropdown";
import TaskRessourceCard from "./TaskRessourceCard";
import { useTranslation } from "../../../contexts/TranslationProvider";
import SearchableRessourceDropdown from "../../common/SearchableDropdown/searchableRessourceDropdown";
import { TaskConfigEntity } from "../../../domain/entities/TaskConfig";

interface TaskModalProps {
  onClose: () => void;
  users: UserEntity[];
  ressources: RessourceEntity[];
  statuses?:TaskConfigEntity['statuses'];
  categories?:TaskConfigEntity['categories']
  onSubmit: (task: TaskEntity) => void;
  task?: TaskEntity; 
  isEdit?: boolean; 
}
const PRIORITY_COLORS = {
  critical: "#FF0000", 
  high: "#FF8800",     
  medium: "#FFCC00",   
  low: "#00CC00"   
};
const DEFAULT_TASK: TaskEntity = {
  name: "",
  status: "",
  category:"",
  priority: "medium",
  color: PRIORITY_COLORS.medium,
  comment: "",
  estimatedStartDate: new Date(),
  estimatedEndDate: new Date(),
  users: [],
  ressources: [],
};

const TaskModal = ({
  onClose,
  users,
  ressources,
  onSubmit,
  task: initialTask,
  isEdit = false,
  statuses,
  categories
}: TaskModalProps) => {
  const {t} = useTranslation();
  const taskToUse = initialTask || DEFAULT_TASK;
  const [userDropdownValue, setUserDropdownValue] = useState(taskToUse.users || []);
  const [ressourceDropdownValue, setRessourceDropdownValue] = useState(taskToUse.ressources || []);
  const getDateString = (date: Date | string): string => {
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };
  const getTimeString = (date: Date | string): string => {
    const d = new Date(date);
    return d.toTimeString().substring(0, 5);
  };
  const [startDate, setStartDate] = useState<string>(getDateString(taskToUse.estimatedStartDate));
  const [startTime, setStartTime] = useState<string>(getTimeString(taskToUse.estimatedStartDate));
  const [endDate, setEndDate] = useState<string>(getDateString(taskToUse.estimatedEndDate));
  const [endTime, setEndTime] = useState<string>(getTimeString(taskToUse.estimatedEndDate));
  const [task, setTask] = useState<TaskEntity>(taskToUse);
  useEffect(() => {
    if (task.priority) {
      setTask(prev => ({
        ...prev,
        color: PRIORITY_COLORS[prev.priority]
      }));
    }
  }, [task.priority]);
  const handleChange = (field: keyof TaskEntity, value: any) => {
    setTask((prev) => ({ ...prev, [field]: value }));
  }; 
  const handleDateTimeChange = (type: "start" | "end", date: string, time: string) => {
    if (date && time) {
      const combinedDateTime = new Date(`${date}T${time}`);
      if (type === "start") {
        handleChange("estimatedStartDate", combinedDateTime);
      } else {
        handleChange("estimatedEndDate", combinedDateTime);
      }
    }
  };  
  useEffect(() => {
    handleDateTimeChange("start", startDate, startTime);
  }, [startDate, startTime]);
  useEffect(() => {
    handleDateTimeChange("end", endDate, endTime);
  }, [endDate, endTime]);
useEffect(() => {
  const userIds = userDropdownValue
    .map(user => user.id)
    .filter(id => id !== undefined);
  setTask(prev => ({
    ...prev,
    usersId: userIds
  }));
}, [userDropdownValue]);
useEffect(() => {
  const resourceIds = ressourceDropdownValue
    .map(resource => resource.id)
    .filter(id => id !== undefined);
  setTask(prev => ({
    ...prev,
    ressourcesId: resourceIds
  }));
}, [ressourceDropdownValue]);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(task);
    onClose();
  };
  const removeUser = (userToRemove: any) => {
    setUserDropdownValue(
      userDropdownValue.filter((user) => user.id !== userToRemove.id)
    );
  }; 
  const removeRessource = (ressourceToRemove: any) => {
    setRessourceDropdownValue(
      ressourceDropdownValue.filter((ressource) => ressource.id !== ressourceToRemove.id)
    );
  };
  return (
    <>
      <div className="modal-backdrop" onClick={onClose}></div>
      <div className="modal d-flex justify-content-center align-items-center">
        <div className="modal-content" style={{ width: "80%" }}>
          <div className="modal-header">
            <h5 className="modal-title">{isEdit ? t("pages.tasks.modals.addEdit.editTitle") : t("pages.tasks.modals.addEdit.addTitle")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <form  onSubmit={handleSubmit}>
            <div className="TaskModal-body">
            <div className="form-grid">
              {/* Left Column */}
              <div className="form-column">
                {/* Task Name */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.addEdit.taskName")}</label>
                  <input
                    className="TaskModal-input"
                    value={task.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                    required
                  />
                </div>
                {/* Comment */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.view.description")}</label>
                  <textarea
                    className="TaskModal-text-area"
                    value={task.comment}
                    onChange={(e) => handleChange("comment", e.target.value)}
                  />
                </div>
                {/* Estimated Start Date */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">
                  {t("pages.tasks.modals.addEdit.startDate")}
                  </label>
                  <div className="grid-container">
                    <input
                      type="date"
                      className="TaskModal-input"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                    />
                    <input
                      type="time"
                      className="TaskModal-input"
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      required
                    />
                  </div>
                </div>

                {/* Estimated End Date */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.addEdit.endDate")}</label>
                  <div className="grid-container">
                    <input
                      type="date"
                      className="TaskModal-input"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      required
                    />
                    <input
                      type="time"
                      className="TaskModal-input"
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="form-column">
                {/* Status Dropdown */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.addEdit.status")}</label>
                  <select
                      value={task.status}
                      className="TaskModal-select"
                      onChange={(e) =>
                        handleChange("status", e.target.value as TaskEntity["status"])
                      }
                    >
                      {statuses?.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                </div>
                  {/* category Dropdown */}
                  <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.addEdit.category")}</label>
                  <select
                      value={task.category}
                      className="TaskModal-select"
                      onChange={(e) =>
                        handleChange("category", e.target.value as TaskEntity["category"])
                      }
                    >
                      {categories?.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                </div>

                {/* Priority Dropdown */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.view.priority")}</label>
                  <select
                    value={task.priority}
                    className="TaskModal-select"
                    onChange={(e) =>
                      handleChange(
                        "priority",
                        e.target.value as TaskEntity["priority"]
                      )
                    }
                  >
                    <option value="low">{t("pages.tasks.modals.addEdit.priorityOptions.low")}</option>
                    <option value="medium">{t("pages.tasks.modals.addEdit.priorityOptions.medium")}</option>
                    <option value="high">{t("pages.tasks.modals.addEdit.priorityOptions.high")}</option>
                    <option value="critical">{t("pages.tasks.modals.addEdit.priorityOptions.critical")}</option>
                  </select>
                </div>
                {/* Users Dropdown */}
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.view.assignedUsers")}</label>
                  <SearchableDropdown
                    multi={true}
                    options={users}
                    label="fullName"
                    id="id"
                    selectedVal={userDropdownValue}
                    handleChange={(val) => {
                      setUserDropdownValue(val);
                    }}
                  />
                  {userDropdownValue.length > 0 && (
                    <div className="selected-users">
                      {userDropdownValue.map((user) => (
                        <div className="badge-user" key={user.id}>
                          {user.fullName}{" "}
                          <span
                            className="remove-badge"
                            onClick={() => removeUser(user)}
                          >
                            x
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="TaskModal-input-group">
                  <label className="TaskModal-label">{t("pages.tasks.modals.view.resources")}</label>

                  <SearchableRessourceDropdown
                    multi={true}
                    options={ressources}
                    label="name"
                    id="id"
                    selectedVal={ressourceDropdownValue}
                    handleChange={(val) => {
                      setRessourceDropdownValue(val);
                    }}
                  />
                  {ressourceDropdownValue.length > 0 && (
                    <div className="selected-ressources">
                      {ressourceDropdownValue.map((resource) => (
                     <TaskRessourceCard
                        key={resource.id}
                        ressource={resource}
                        removeRessource={removeRessource}
                      />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            </div>
            {/* Submit Button */}
            <div className="modal-footer">
              <button
                type="submit"
                className="TaskModal-button TaskModal-submit"
              >
               {isEdit ? t("pages.tasks.modals.addEdit.updateButton") : t("pages.tasks.modals.addEdit.submitButton")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default TaskModal;