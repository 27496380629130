import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../contexts/TranslationProvider";
import apiClientWithLoading from "../../services/api/ApiClient";
import { RessourceClient } from "../../services/api/RessourceClient";
import ConfirmDeletePopup from "../../components/modals/ConfirmDetelePopup";
import AdvancedTable from "../../components/AdvancedTable";
import { formatDate, getTypeLevels } from "../../helpers/helper";
import form from "../../assets/new-design-icons/Form.svg";
import ressource from "../../assets/new-design-icons/Contract.svg";
import calculate from "../../assets/new-design-icons/Calculate.svg";
import { FiMoreHorizontal } from "react-icons/fi";
import ContractCardActionsModal from "../../components/modals/ContractCardActionsModal";
import { toast } from "react-toastify";
import { RessourceEntity } from "../../domain/entities/Ressource";
import { GroupEntity } from "../../domain/entities";
import { navigateToRessource } from "../../helpers/navigation";

function GroupRessourceTable({ ressources, groups, reloadRessources }: {
  ressources: RessourceEntity[];
  groups: GroupEntity[];
  reloadRessources: () => void;
}) {
  const formIcon = form.toString();
  const ressourceIcon = ressource.toString();
  const docIcon = calculate.toString();
  const navigate = useNavigate();
  const { t, language } = useTranslation();
  const apiClient = apiClientWithLoading();
  const ressourceClient = new RessourceClient(apiClient);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [ressourceToHandle, setRessourceToHandle] = useState(null);
  const [openActionPopup, setOpenActionPopup] = useState<RessourceEntity | null>(null);
  const tableItems = useMemo(() => {
    const tableItems = [];
    groups.map((group) => {
      const groupRessources = ressources.filter(
        (ressource) => ressource.assignedToGroupId === group.id
      );
      groupRessources.map((ressource) => {
        tableItems.push({
          ...ressource,
          name: ressource.name,
          group: group.name,
          ressourceType: ressource.type,
          type: getTypeLevels(ressource)
            .map((level) => {
              const translatedName = level.translations.find(
                (t) => t.language === language
              );
              if (translatedName) {
                return translatedName.name;
              } else {
                return level.name;
              }
            })
            .join("/"),
          status: ressource.status ? t("status." + ressource.status) : "",
          createdAt: ressource.createdAt,
          updatedAt: ressource.updatedAt,
          onEdit: () => {
            handleClick(ressource)
          },
          onDelete: () => {
            setRessourceToHandle(ressource);
            setConfirmDelete(true);
          },
          actions: (
            <div className="actions">
              <button className="plus-icon" onClick={(e) => togglePopup(e, ressource)}>
                <FiMoreHorizontal size={20} />
              </button>
              {openActionPopup?.type === ressource.type &&
                openActionPopup?.id === ressource.id && (
                  <ContractCardActionsModal
                    onView={() => handleClick(ressource)}
                    onDelete={() => {
                      setRessourceToHandle(ressource);
                      setConfirmDelete(true);
                    }}
                    isOpen={true}
                    onClose={() => setOpenActionPopup(null)}
                  />
                )}
            </div>
          )
        });
      });
    });
    return tableItems;
  }, [ressources, groups, openActionPopup, language]);
  const handleClick = navigateToRessource(navigate)
  const handleDelete = async (ressource) => {
    try {
      const { deleted } = await ressourceClient.deleteFromChild(ressource);
      if (deleted) {
        await reloadRessources();
      }
      toast.success(t("pages.docxExport.popup.messages.successDelete"));
    } catch (err) {
      console.error("Error deleting resource:", err);
    }
  };
  const togglePopup = (e: any, ress: RessourceEntity) => {
    e.stopPropagation();
    if (!ress) return setOpenActionPopup(null);
    if (openActionPopup?.id === ress.id)
      return setOpenActionPopup(null);
    setOpenActionPopup(ress);
  };

  return (
    <section id="#ressources-table" className="ressources-table">
      <AdvancedTable
        columns={[
          {
            display: t("pages.projects.tableHeaders.name"),
            accessorKey: "name",
            render: (row) => {
              const displayType = row.ressourceType;
              return (
                <div className="title-table-item">
                  {displayType === "Form" ? (
                    <img
                      src={formIcon}
                      alt="icon"
                      style={{ width: "30px", height: "30px" }}
                    />
                  ) : displayType === "Ressource" ||
                    displayType === "RessourceAI" ||
                    displayType === "Summary" ? (
                    <img
                      src={ressourceIcon}
                      alt="icon"
                      style={{ width: "30px", height: "30px" }}
                    />
                  ) : displayType === "Document" ? (
                    <img
                      src={docIcon}
                      alt="icon"
                      style={{ width: "25px", height: "25px" }}
                    />
                  ) : (
                    <></>
                  )}
                  <div style={{ marginLeft: "20px" }}>{row.name}</div>
                </div>
              )
            },
            width: 3
          },
          {
            display: t("pages.groupRessources.tableHeaders.group"),
            accessorKey: "group",
            width: 2
          },
          {
            display: t("pages.projects.tableHeaders.type"),
            accessorKey: "type",
            width: 2
          },
          {
            display: t("pages.projects.tableHeaders.status"),
            accessorKey: "status",
            width: 2,
            render: (row) => {
              return row.status ? (
                <div className="status">{row.status}</div>
              ) : (
                ""
              );
            }
          },
          {
            display: t("pages.projects.tableHeaders.createdAt"),
            accessorKey: "createdAt",
            render: (row) => {
              if (row.createdAt) {
                return formatDate(row.createdAt);
              }
            },
            width: 2,
            isDate: true
          },
          {
            display: t("pages.projects.tableHeaders.updatedAt"),
            accessorKey: "updatedAt",
            render: (row) => {
              if (row.updatedAt) {
                return formatDate(row.updatedAt);
              }
            },
            width: 2,
            isDate: true
          },
          {
            display: t("pages.projects.tableHeaders.actions"),
            accessorKey: "actions",
            sortable: false,
            filtrable: false,
            width: 2
          }
        ]}
        originalData={tableItems}
      />

      {showConfirmDelete && ressourceToHandle && (
        <ConfirmDeletePopup
          msgDelete={t("pages.ressources.msgDeleteRessource")}
          onClose={() => {
            setConfirmDelete(false);
            setRessourceToHandle(null);
            setOpenActionPopup(null);
          }}
          entityToDelete={ressourceToHandle.name || ""}
          onDelete={() => handleDelete(ressourceToHandle)}
        />
      )}
    </section>
  );
}

export default GroupRessourceTable;
