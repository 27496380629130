import React, {useContext} from 'react'
import { ClauseEntity } from '../../../domain/entities'
import { getName } from '../../../helpers/Translation';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { RenderSegments, SegmentedText, SegmentedTextType } from '../../../domain/types/ClauseParams';
import { getRenderSegments } from '../../../helpers/segmentation';
import DisplayClause from './ClauseText';
import DiffMatchPatch from 'diff-match-patch';
import { render } from 'sass';
import MacroContext from '../../../contexts/MacroContext';

function ClauseComparator({referenceClause, comparisonClause}: {
  referenceClause: ClauseEntity,
  comparisonClause: ClauseEntity
}) {
  const { language } = useTranslation();
  const {macros} = useContext(MacroContext);
  return (
    <>
      <div
        className="clause-comparison-current"
      >
        <div className="d-flex header-clause-current" style={{maxHeight: "50px"}}>
          <div className="clause-comparison-header mb-4" >
            <span className="clause-comparison-actual-title half-border-bottom" style={{fontSize: "15px"}}>
              {referenceClause.name}
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column"}}>
            {referenceClause?.template?.level1?.name && (
              <span className="level-types-template" style={{fontSize: "12px"}}>
                {getName(referenceClause?.template?.level1, language) +
                  (referenceClause?.template?.level2
                    ? "/" + getName(referenceClause?.template?.level2, language)
                    : "") +
                  (referenceClause?.template?.level3
                    ? "/" + getName(referenceClause?.template?.level3, language)
                    : "")}
              </span>
            )}
            <span style={{fontSize: "12px"}}>{referenceClause.template?.name}</span>
          </div>
        </div>
        <div className="clause-comparison-body">
          <DisplayClause segmentation={comparisonClause.segmentation} />
          {
            comparisonClause?.subClauses?.map((subClause, scIdx) => {
              const comparisonSubClause = comparisonClause.subClauses[scIdx]
              return (
                !comparisonSubClause ? (
                  <div
                    key={subClause.id}
                    className="my-2"
                    id={`${referenceClause.id}-${subClause.id}`}
                  >
                    <h5 className="fw-bold">{subClause.name}</h5>
                    <div>
                      {subClause.segmentation?.segmentedText?.length > 0 && (
                        <DisplayClause segmentation={subClause.segmentation} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    key={subClause.id}
                    className="my-2"
                    id={`${referenceClause.id}-${subClause.id}`}
                  >
                    <h5 className="fw-bold">{subClause.name}</h5>
                    <div>
                      <DisplayClause segmentation={subClause.segmentation}  />
                    </div>
                  </div>
                )
              );
            })
          }
        </div>
      </div>
    </>
  );
}
const ClauseVersionText = ({originalSegmentation, newSegmentation}: { originalSegmentation: ClauseEntity['segmentation'], newSegmentation: ClauseEntity['segmentation'] }) => {
  const { t, language } = useTranslation();
  const {macros} = useContext(MacroContext);
  const dmp = new DiffMatchPatch();
  const renderSegments: RenderSegments = getRenderSegments(macros, newSegmentation.segmentedText, [],
    [], [], newSegmentation.segmentedParams, t, language)
    .map(seg => {
        return {
            ...seg,
            value: seg.value?.replaceAll(/\r\n/g, "\n"),
        }
    })
  const originalRenderSegments: RenderSegments = getRenderSegments(macros, originalSegmentation.segmentedText, [],
    [], [], originalSegmentation.segmentedParams, t, language)

    return (
      <>
          {renderSegments.map((segment, idx) => {
              const { id, value, type } = segment
              const key = idx;
              const text = value
              const breakedHTMLText = text?.replaceAll(/\n/g, "<br/>")
              switch (type) {
                  case SegmentedTextType.STATIC:
                    const originalSegment = originalRenderSegments.find((seg) => seg.id === id);
                    if(!originalSegment){
                      return <span key={id} style={{backgroundColor: 'lightgreen'}}>{text}</span>
                    }
                    const originalText = originalSegment?.value;
                    const diffrenceResult = dmp.diff_main(originalText, text);
                    dmp.diff_cleanupSemantic(diffrenceResult);
                      return <>
                      {diffrenceResult.map(([diffType, diffText], diffIdx) => {
                        switch(diffType) {
                          case DiffMatchPatch.DIFF_EQUAL:
                            return <span key={diffIdx}>{diffText}</span>
                          case DiffMatchPatch.DIFF_INSERT:
                            return <span key={diffIdx} style={{backgroundColor: 'lightgreen'}}>{diffText}</span>
                          case DiffMatchPatch.DIFF_DELETE:
                            return <span className="deleted-text" data-full-text={diffText}>
                          </span>
                        }
                      })}
                      </>
                  case SegmentedTextType.COMMENT:
                      return <React.Fragment key={key}>
                          <span className="comment-prefix">☞ &nbsp;</span>
                          <span className='comment' dangerouslySetInnerHTML={{ __html: breakedHTMLText }}></span>
                      </React.Fragment>
                  case SegmentedTextType.PARAM:
                      const paramPath = value.split(".");
                      const paramLabel = newSegmentation.segmentedParams.find((param) => param.name == segment.paramName)?.label;
                      return (
                          <span id={`param-${segment.paramName}`} key={key} className={`param`}>
                              {paramLabel ?? value}{paramPath[1] && `(${paramPath[1]})`}
                          </span>
                      );
                  case SegmentedTextType.PARAM_VALUE:
                      return <React.Fragment key={key}>
                          <span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`param-value`}>{text}</span>
                      </React.Fragment>
                  case SegmentedTextType.PARAM_TABLE_VALUE:
                      const [transposed, tableRows] = JSON.parse(value)
                      const [headers, ...rows] = tableRows as string[][]
                      return transposed ? (
                          <table id={`param-${segment.paramName}`} key={key} className="my-4" style={{ borderCollapse: "collapse", width: "100%" }}>
                              <tbody>
                                  {headers.map((header, idx) => (
                                      <tr key={idx}>
                                          <th style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
                                              {header}
                                          </th>
                                          {rows.map((row, rowIndex) => (
                                              <td key={rowIndex} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }}>
                                                  {row[idx]}
                                              </td>
                                          ))}
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                      ) :
                          (
                              <table id={`param-${segment.paramName}`} key={key} className="my-4" style={{ borderCollapse: "collapse", width: "100%" }}>
                                  <thead>
                                      <tr>
                                          {headers.map((header, idx) =>
                                              <th key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
                                                  {header}
                                              </th>)}
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {rows.map((row, idx) =>
                                          <tr key={idx} >
                                              {row.map((cell, idx) =>
                                                  <td key={idx} style={{ border: "1px solid black", padding: "8px", textAlign: "left" }} >
                                                      {cell}
                                                  </td>)}
                                          </tr>)
                                      }
                                  </tbody>
                              </table>
                          );
                  case SegmentedTextType.PARAM_COMMENT_VALUE:
                      return <React.Fragment key={key}>
                          <span id={`param-${segment.paramName}`} style={{ whiteSpace: 'pre-line' }} className={`comment`}>{text}</span>
                      </React.Fragment>
                  default:
                      break;
              }
          })}
      </>
  );
}
export default ClauseComparator