import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactFlow, {
  Node,
  Edge,
  Connection,
  addEdge,
  Background,
  ReactFlowProvider,
  Handle,
  Position,
  reconnectEdge,
  NodeMouseHandler,
  ReactFlowInstance,
} from 'reactflow';

import 'reactflow/dist/style.css';
import './workflowSettings.scss';
import { ClauseEntity, FormTemplatePageEntity, GroupEntity, ProjectTemplateRequirementEntity } from '../../../domain/entities';
import EditTransition from './EditTransition';
import { FormParam } from '../../../domain/types/FormParams';
import { WorkflowGraph, WorkflowStep, WorkflowTransition, WorkflowType } from '../../../domain/Workflow';
import EditStep from './EditStep';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from '../../../contexts/TranslationProvider';
import { WorkflowGraphValidator } from '../../../domain/validators/WorkflowGraphValidator';
import { ValidationError } from '../../../domain/validators/BaseValidator';
import ConfirmationPopup from '../ConfirmationPopup';


const calculateMidpoint = (sourcePos: { x: number; y: number }, targetPos: { x: number; y: number }) => {
  return {
    x: (sourcePos.x * 0.25 + targetPos.x * 0.75),
    y: (sourcePos.y * 0.25 + targetPos.y * 0.75),
  };
};

type NodeContextMenuProps = {
  label: string;
  style: React.HTMLAttributes<HTMLDivElement>['style'];
  actions: {
    label: string,
    onAction: () => void
  }[];
}
type StepNodeType = Node<WorkflowStep & {
  assignGroup: GroupEntity;
}>
  & { type: 'StepNode' }
type TransitionNodeType = Node<WorkflowTransition>
  & { type: 'TransitionNode' }


export type WorkflowNode = StepNodeType | TransitionNodeType
export function NodeContextMenu({
  label,
  style,
  actions
}: NodeContextMenuProps) {

  return (
    <div
      style={{ ...style }}
      className="context-menu"
    >
      <p style={{ margin: '0.5em' }}>
        <small>{label}</small>
      </p>
      {actions.map((action, idx) => (
        <button key={idx} onClick={action.onAction}>{action.label}</button>
      ))}
    </div>
  );
}


type WorkflowSettingsModalProps = {
  type: WorkflowType;
  groups: GroupEntity[];
  groupsGraph: WorkflowGraph;
  params: FormParam[];
  clauses?: ClauseEntity[];
  pages?: FormTemplatePageEntity[];
  requirements?: ProjectTemplateRequirementEntity[];
  onClose: () => void;
  onSave: (groupsGraph: WorkflowGraph) => void;

}

export const useWorkflowGraph = ({ groupsGraph, groups, includeTransitionNodes = true, stepNodeStyle, transitionEdge }: {
  groupsGraph: WorkflowGraph;
  groups: GroupEntity[];
  includeTransitionNodes?: boolean;
  stepNodeStyle?: (step: WorkflowStep) => React.HTMLAttributes<HTMLDivElement>["style"];
  transitionEdge?: (transition: WorkflowTransition) => Partial<Edge>;

}) => {
  const ref = useRef(null);
  const [steps, setSteps] = useState<WorkflowStep[]>(groupsGraph?.steps ?? []);

  const [random, setRandom] = useState<boolean>(false);
  const [stepNodes, setStepNodes] = useState<StepNodeType[]>([]);
  const [transitionNodes, setTransitionNodes] = useState<TransitionNodeType[]>([]);

  const [transitions, setTransitions] = useState<WorkflowTransition[]>(groupsGraph?.transitions ?? []);

  const [edges, setEdges] = useState<Edge[]>([]);

  const [menu, setMenu] = useState<NodeContextMenuProps | null>(null);

  const [selectedNode, setSelectedNode] = useState<WorkflowNode | null>(null);

  // Custom connectable node
  const StepNode = ({ data }: { data: StepNodeType['data'] }) => (

    <div style={{
      textAlign: 'center', padding: '10px',
      ...(stepNodeStyle?.(data) ?? {}),
    }}
    >
      <Handle type="target" position={Position.Left} style={{ background: '#555' }} />
      <div>{data.name}</div>
      <Handle type="source" position={Position.Right} style={{ background: '#555' }} />
    </div>
  );

  const TransitionNode = ({ data }: { data: TransitionNodeType['data'] }) => {
    return (
      <div style={{ opacity: 0 }}>
      </div>
    )
  };
  // Register the custom node type
  const nodeTypes = { StepNode, TransitionNode };

  useEffect(() => {

    setEdges(groupsGraph?.transitions?.map((transition) => ({
      id: `${transition.fromId}-${transition.toId}`,
      source: transition.fromId?.toString() || '',
      target: transition.toId?.toString() || '',
      animated: true,
      style: { stroke: '#A1C4FD', strokeWidth: 2 },
      deletable: true,
      type: '',
      ...(transitionEdge?.(transition) ?? {}),

    })) ?? [])
  }, [random, groupsGraph?.transitions])

  useEffect(() => {

    if (!includeTransitionNodes) {
      return
    }
    const transitionNodes = edges.map((edge, index) => {
      const sourceNode = stepNodes.find((node) => node.id === edge.source);
      const targetNode = stepNodes.find((node) => node.id === edge.target);
      const transition = transitions.find(t => edge.source == String(t.fromId) && edge.target == String(t.toId))
      if (sourceNode && targetNode && transition) {
        const midpoint = calculateMidpoint(sourceNode.position, targetNode.position);
        const conditionsLength = transition.conditions.length
        return {
          id: `midpoint-${index}`,
          position: midpoint,
          data: transition,
          style: { backgroundColor: conditionsLength ? '#FFD700' : "green", borderRadius: '50%', width: 10, height: 10 },
          type: 'TransitionNode',
          selectable: false,
          draggable: false,
        } as TransitionNodeType;
      }
      return null;
    }).filter(Boolean);
    setTransitionNodes(transitionNodes);
  }, [random, edges, stepNodes, transitions]);

  useEffect(() => {

    const updatedStepNodes: StepNodeType[] = steps.map((step, index) => {
      const assignGroup = groups.find(g => g.id == step.assignGroupId)
      return {
        id: step.id,
        data: {
          ...step,
          assignGroup,
        },
        position: step?.position ?? { x: Math.random() * 300, y: Math.random() * 300 },
        style: selectedNode?.id == step.id ? { backgroundColor: '#A4F4A4', borderRadius: '10px', padding: '10px', width: 'auto' }
          : { backgroundColor: '#D4F1F4', borderRadius: '5px', padding: '10px', width: 'auto' },
        type: 'StepNode',
        selectable: false,
        deletable: false,
      }
    })
    setStepNodes(updatedStepNodes);
  }, [random, groups, steps, selectedNode]);

  const allNodes = useMemo(() => [...stepNodes, ...transitionNodes], [stepNodes, transitionNodes])

  const edgeReconnectSuccessful = useRef(true)

  // Close the context menu if it's open whenever the window is clicked.
  const onPaneClick = useCallback(() => {
    setMenu(null)
    setSelectedNode(null)
  }, [setMenu, setSelectedNode]);

  // Handle node connections
  const onConnect = (params: Connection) => {
    setEdges((prevEdges) => {
      const isDuplicate = prevEdges?.some(
        (edge) => edge.source === params.source && edge.target === params.target
      );
      if (!isDuplicate) {
        return addEdge({ ...params, animated: true, style: { stroke: '#A1C4FD', strokeWidth: 2 } }, prevEdges);
      }
      return prevEdges;
    });
  };

  const onReconnect = useCallback((oldEdge, newConnection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, []);

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);



  const onReconnectEnd = useCallback((_, edge) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeReconnectSuccessful.current = true;
  }, []);


  const refreshWorkflowGraph = () => {

    setRandom(prev => !prev)
  }
  return {
    ref,
    steps, setSteps,
    stepNodes, setStepNodes,
    transitionNodes, setTransitionNodes,
    transitions, setTransitions,
    edges, setEdges,
    allNodes,
    menu, setMenu,
    selectedNode, setSelectedNode,
    StepNode, TransitionNode, nodeTypes,
    onPaneClick,
    onConnect,
    onReconnect, onReconnectStart, onReconnectEnd,
    refreshWorkflowGraph
  }
}


const WorkflowSettingsModal: React.FC<WorkflowSettingsModalProps> = ({
  type,
  params,
  groups,
  groupsGraph,
  clauses,
  pages,
  requirements,
  onClose,
  onSave,
}) => {

  const [transitionToEdit, setTransitionToEdit] = useState<WorkflowTransition | null>(null);
  const [stepToEdit, setStepToEdit] = useState<WorkflowStep | null>(null);
  const [errors, setErrors] = useState<ValidationError[]>([])

  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false)

  const { t } = useTranslation()

  const workflowGraphValidator = new WorkflowGraphValidator()

  const {
    ref,
    steps, setSteps,
    stepNodes, setStepNodes,
    transitionNodes, setTransitionNodes,
    transitions, setTransitions,
    edges, setEdges,
    allNodes,
    menu, setMenu,
    selectedNode, setSelectedNode,
    StepNode, TransitionNode, nodeTypes,
    onPaneClick,
    onConnect,
    onReconnect, onReconnectStart, onReconnectEnd
  } = useWorkflowGraph({ groupsGraph, groups })

  //handle new or deleted edges
  useEffect(() => {
    let updatedTransitions = [...(groupsGraph?.transitions ?? []), ...transitions].filter((t) => edges.find(edge => edge.source == String(t.fromId) && edge.target == String(t.toId)))
    edges.forEach((edge, index) => {
      let transition = updatedTransitions.find(t => edge.source == String(t.fromId) && edge.target == String(t.toId))
      if (!transition) {
        console.warn(edge);
        console.warn(transitions);
        console.warn(updatedTransitions);

        transition = {
          fromId: edge.source,
          toId: edge.target,
          conditions: []
        }
        updatedTransitions.push(transition)
      }
    })
    updatedTransitions = updatedTransitions.filter(t => {
      const from = steps.find(s => s.id == t.fromId)
      const to = steps.find(s => s.id == t.toId)
      return from && to
    })
    setTransitions(updatedTransitions);
  }, [edges]);

  useEffect(() => {
    if (groupsGraph) {
      const updatedGroupsGraph: WorkflowGraph = {
        steps: stepNodes.map((s) => {
          const { assignGroup, ...step } = s.data
          return step
        }),
        transitions,
      }

      const validator = new WorkflowGraphValidator();
      const validationErrors = validator.validate(updatedGroupsGraph);
      // Post-process errors to translate the messages
      const translatedErrors = validationErrors.map((error) => {
        if (error.code === "InitialStepError") {
          return {
            ...error,
            message: `${t("workflow.missingInitialStepErrorMessage")} ${error.message.split(" ").pop()}`,
          };
        }
        return error;
      });

      setErrors(translatedErrors);
    }
    return () => {
    }
  }, [stepNodes, transitions])




  const handleSave = async () => {
    try {
      if (errors.length > 0)
        return
      const updatedGroupsGraph: WorkflowGraph = {
        steps: stepNodes.map((s) => {
          const { assignGroup, ...step } = s.data
          return step
        }),
        transitions: transitions
      }
      console.log(updatedGroupsGraph);

      onSave(updatedGroupsGraph)
      onClose()
      return
    } catch (error) {
      console.error('Error saving transitions:', error);
    }
  };
  const handleSaveTransition = (transition: WorkflowTransition) => {
    let updatedTransitions = transitions.map((t) => t.fromId == transition.fromId && t.toId == transition.toId ?
      transition : t)
    setTransitions(updatedTransitions)
  }

  const handleSaveStep = (step: WorkflowStep) => {
    if (!step.id) {
      step.id = `${uuidv4()}`
      setSteps([...steps, step])
      return
    }
    let updatedSteps = steps.map((s) => s.id == step.id ?
      step : s)
    setSteps(updatedSteps)
  }



  const addStep = () => {
    const newStep: WorkflowStep = {
      id: "",
      name: "",
      position: { x: Math.random() * 300, y: Math.random() * 300 },
      status: 'Draft',
      assignGroupId: null,
      validateGroupId: null,
    }
    setStepToEdit(newStep)
  }


  const onNodeClick: NodeMouseHandler = (event: any, element: WorkflowNode) => {

    if (element.type == 'TransitionNode') {
      setTransitionToEdit(element.data)
    } else if (element.type == 'StepNode') {
    }
  }

  const editStep = () => {
    if (selectedNode && selectedNode.type == 'StepNode') {
      setStepToEdit({ ...selectedNode.data, position: selectedNode.position })
      setSelectedNode(null)
    }
  }

  const deleteStep = () => {
    if (selectedNode && selectedNode.type == 'StepNode') {
      setSteps(prev => prev.filter(gn => gn.id != selectedNode.id))
      setSelectedNode(null)
    }
  }


  const onNodeContextMenu = useCallback(
    (event, node: WorkflowNode) => {

      // Prevent native context menu from showing
      event.preventDefault();
      if (node.type == 'StepNode') {
        setSelectedNode(node)
      }
    },
    [setMenu],
  );

  return (
    <ReactFlowProvider>
      <div>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div>
              <div className="modal-content" style={{ width: '960px' }}>
                <div className="modal-header">
                  <h5 className="modal-title">{t("workflow.settings")}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowConfirmationPopUp(true)}
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ paddingBottom: '5%', position: 'relative', height: '500px' }}
                >
                  <ReactFlow
                    ref={ref}
                    nodes={allNodes}
                    edges={edges}
                    style={{ width: '100%', height: '100%' }}
                    onNodeClick={onNodeClick}
                    onConnect={onConnect}
                    onReconnect={onReconnect}
                    onReconnectStart={onReconnectStart}
                    onReconnectEnd={onReconnectEnd}
                    onNodeContextMenu={onNodeContextMenu}
                    nodeTypes={nodeTypes}
                    onPaneClick={onPaneClick}
                    onNodeDragStop={(event, node) => {
                      setSteps(
                        steps.map((step) => {
                          if (step.id == node.id)
                            step.position = node.position
                          return step
                        })
                      )
                    }}
                  >
                    {menu && <NodeContextMenu
                      {...menu} />}
                    <Background />
                  </ReactFlow>
                </div>
                {errors.length > 0 && errors.map(error =>
                  <div className="error-message-dialog">{error.message}</div>
                )}
                {transitionToEdit && <EditTransition
                  type={type}
                  conditionTypes={type == 'Contract' && ['constraint', 'clause']
                    || type == 'Form' && ['constraint', 'page']
                    || type == 'Project' && ['requirement-status', 'requirement-validation']
                  }
                  groups={groups}
                  transition={transitionToEdit}
                  onClose={() => setTransitionToEdit(null)}
                  onSave={handleSaveTransition}
                  params={params}
                  clauses={clauses}
                  pages={pages}
                  requirements={requirements}
                />}
                {stepToEdit && <EditStep
                  type={type}
                  groups={groups}
                  step={stepToEdit}
                  onClose={() => setStepToEdit(null)}
                  onSave={handleSaveStep}
                  params={params}
                />}
                <div className="modal-footer">
                  <button className="btn btn-secondary" onClick={addStep}>
                    {t("workflow.addStep")}
                  </button>
                  <button className="btn btn-primary" disabled={errors.length > 0} onClick={handleSave}>
                    {t("workflow.save")}
                  </button>
                  {selectedNode &&
                    <button className="btn btn-primary" onClick={editStep}>
                      {t("workflow.edit")}
                    </button>
                  }
                  {selectedNode &&
                    <button className="btn btn-primary" onClick={deleteStep}>
                      {t("workflow.delete")}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmationPopUp && <ConfirmationPopup
        isVisible={showConfirmationPopUp}
        message={t("workflow.confirmSaveMessage")}
        onConfirm={handleSave}
        onCancel={onClose}
        title={t("workflow.save")}
      />}
    </ReactFlowProvider>
  );
};

export default WorkflowSettingsModal;


