import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import '../buttons.scss'
import ValidateFormModal from "../../../modals/ValidateFormModal";
import ValidateFormWorkflowModal from "../../../modals/ValidateFormWorkflowModal";
import EditFormContext from "../../../../contexts/EditFormContext";

function EditionFormValidateButton() {

  const [showValidateFormModal, setShowValidateFormModal] = useState(false);
  const { t } = useTranslation();
  const {
    form,
  } = useContext(EditFormContext);

  useEffect(() => console.log(form), [form])

  return (
    <>
      <button className="navbar-button" onClick={() => setShowValidateFormModal(true)}>
        {t("navbar.validateForm")}
      </button>
      {showValidateFormModal &&
        (form?.template?.groupsGraph?.steps?.length ? <ValidateFormWorkflowModal form={form} onClose={() => setShowValidateFormModal(false)} />
          : form && <ValidateFormModal form={form} onClose={() => setShowValidateFormModal(false)} />)
      }
    </>
  )
}

export default EditionFormValidateButton;
