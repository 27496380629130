import React, { useContext } from "react";
import { ClauseEntity, ContractEntity } from "../../../domain/entities";
import ClauseText from "./ClauseText";
import { isSubClauseExcluded } from "../../../helpers/helper";
import { useTranslation } from "../../../contexts/TranslationProvider";
import InputValuesContext from "../../../contexts/InputValuesContext";

interface ComparisonClauseProps {
  inputValues: Record<string, any>;
  fileNames: ContractEntity["fileNames"];
  beneficialsMap: ContractEntity["beneficialsMap"];
  clause: ClauseEntity;
  excludedSubClauses: ContractEntity["excludedSubClauses"];
}
function ComparisonClause({
  fileNames,
  beneficialsMap,
  inputValues,
  clause,
  excludedSubClauses,
}: ComparisonClauseProps) {
  const { t, language } = useTranslation();
  const { clausesOverrides } = useContext(InputValuesContext);
  return (
    <>
      <div className="clause-comparison-current">
        <div className=" mb-4" style={{ marginBottom: "10px" }}>
          <span className="clause-comparison-cummon-title">
            {t("modals.clauseComparaison.clauseClausier")}
          </span>
          <br />
          <span className="clause-comparison-actual-title half-border-bottom pb-3">
            {clause.name}
          </span>
        </div>
        <div className="clause-comparison-body mt-3">
          {clausesOverrides?.[clause.id] ? (
            <ClauseText
              segmentation={clausesOverrides?.[clause.id]?.segmentation}
              fileNames={fileNames}
              beneficialsMap={beneficialsMap}
              inputValues={inputValues}
            />
          ) : (
            <ClauseText
              segmentation={clause.segmentation}
              fileNames={fileNames}
              beneficialsMap={beneficialsMap}
              inputValues={inputValues}
            />
          )}

          {clausesOverrides?.[clause.id] ? (
            <>
              {clausesOverrides?.[clause.id]?.subClauses &&
                Object.values(clausesOverrides[clause.id].subClauses).map(
                  (subClause) => (
                    <>
                      <div
                        key={subClause.subClauseId}
                        className="my-2"
                        id={`${clause.id}-${subClause.subClauseId}`}
                      >
                        <h5 className="fw-bold">{subClause.subClauseName}</h5>
                        <div>
                          {subClause.segmentation?.segmentedText?.length >
                            0 && (
                            <ClauseText
                              segmentation={subClause.segmentation}
                              fileNames={fileNames}
                              beneficialsMap={beneficialsMap}
                              inputValues={inputValues}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )
                )}
            </>
          ) : (
            <>
              {clause.subClauses.map((subClause, subClauseIndex) => {
                const isExcludedSubclause = isSubClauseExcluded(
                  subClause.id,
                  excludedSubClauses
                );
                return (
                  !isExcludedSubclause && (
                    <div
                      key={subClause.id}
                      className="my-2"
                      id={`${clause.id}-${subClause.id}`}
                    >
                      <h5 className="fw-bold">{subClause.name}</h5>
                      <div>
                        {subClause.segmentation?.segmentedText?.length > 0 && (
                          <ClauseText
                            segmentation={subClause.segmentation}
                            fileNames={fileNames}
                            beneficialsMap={beneficialsMap}
                            inputValues={inputValues}
                          />
                        )}
                      </div>
                    </div>
                  )
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ComparisonClause;
