import React, { useState } from "react";
import "./adminFunction.scss";
import { useNavigate } from "react-router-dom";

function AdminFunction({
  title,
  description,
  buttonTitle,
  secondButtonTitle,
  render,
  secondRender,
  path,
  icon,
}: {
  title: string;
  description: string;
  buttonTitle: string;
  secondButtonTitle?: string;
  render?: (onClose: () => void) => React.JSX.Element;
  secondRender?: (onClose: () => void) => React.JSX.Element;
  path?: string;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}) {
  let contractIcon = "assets/noimage.png";
  if (icon) {
    contractIcon = icon.toString();
  }

  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const component = render && render(() => setShowModal(false));
  const secondComponent =
    secondRender &&
    showSecondModal &&
    secondRender(() => setShowSecondModal(false));

  const navigate = useNavigate();
  return (
    <div className="main-function admin-main-functions">
      {icon ? (
        <>
        <div className="card-title-icon">
          <img
            src={contractIcon}
            alt="icon"
            style={{ width: "50px", height: "50px" }}
          />{" "}
          <h2>{title}</h2>
          </div>
        </>

      ) : (
        <>
          {" "}
          <h2>{title}</h2>
        </>
      )}

      <p className="mt-1 w-100" style={{  maxWidth:"90%",textAlign:"justify" }}>
        {description}
      </p>
      <div className="btn-action-holder">
        {secondButtonTitle && (
          <button
            className="btn-outline-pilot mt-2 btn-action-admin ml-2"
            onClick={() => setShowSecondModal(true)}
          >
            {secondButtonTitle}
          </button>
        )}
        <button
          className="btn-pilot mt-2 btn-action-admin"
          onClick={() => {
            path && navigate(path);
            setShowModal(true);
          }}
        >
          {buttonTitle}
        </button>
      </div>

      {showModal && component}
      {showSecondModal && secondComponent}
    </div>
  );
}

export default AdminFunction;
