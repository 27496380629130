import moment from "moment";
import { LanguageType } from "../contexts/TranslationProvider";
import { BeneficialBaseEntity, BeneficialCompanyEntity, BeneficialEntity, BeneficialMacroEntity, BeneficialMinorEntity, BeneficialPersonEntity, genderArray, maritalStatusArray } from "../domain/entities";
import { getCountryByA3 } from "../utils/country";
import { getCurrencyByCode } from "../utils/currency";
import { COMPANY_CODE } from "../config";
import { evalWithObject } from "../utils/string";
import { RenderSegments, SegmentedTextType } from "../domain/types/ClauseParams";
import { getMacroRenderSegments } from "./macroSegmentation";

const companyCode = COMPANY_CODE || 'default'

const tFromTranslations = (key, translations) => {
  const keys = key.split('.');
  let result = translations;

  for (const k of keys) {
    if (result && result[k] !== undefined) {
      result = result[k];
    } else {
      return key;
    }
  }

  return result;
}
export const hasAttorneyProperty = (entity: BeneficialEntity): entity is BeneficialPersonEntity | BeneficialCompanyEntity => {
  return entity.type === 'Person' || entity.type === 'Company';
};
export const filterPersonEntityData = (beneficials: BeneficialEntity[]): BeneficialBaseEntity[] => {
  return beneficials.map((entity) => {
    const {
      name,
      gender,
      firstName,
      description,
      lastName,
      fullName,
      signature,
      nationality,
      dateOfBirth,
      placeOfBirth,
      maritalStatus,
      cin,
      cinDeliveryDate,
      cinDeliverPlace,
      jobTitle,
      email,
      profilePhoto,
      address,
      addressString,
    } = entity;
    return {
      name,
      gender,
      firstName,
      description,
      lastName,
      fullName,
      signature,
      nationality,
      dateOfBirth,
      placeOfBirth,
      maritalStatus,
      cin,
      cinDeliveryDate,
      cinDeliverPlace,
      jobTitle,
      email,
      profilePhoto,
      address,
      addressString,
    };
  });
};
export const filterAttorneyEntityData = (
  beneficials: BeneficialEntity[]
): Partial<BeneficialBaseEntity>[] => {
  return beneficials
    .filter(
      (entity): entity is BeneficialPersonEntity | BeneficialCompanyEntity =>
        'attorney' in entity && entity.hasAttorney && entity.attorney !== null
    )
    .map((entity) => {
      const {
        attorney: {
          gender,
          firstName,
          lastName,
          fullName,
          cin,
          cinDeliveryDate,
          cinDeliverPlace,
          regionalFinancialOffice,
          powerOfAttorneyDate,
          powerOfAttorneyReceitNumber,
          powerOfAttorneyRegistrationNumber,
        } = {},
      } = entity;

      return {
        gender,
        firstName,
        lastName,
        fullName,
        cin,
        cinDeliveryDate,
        cinDeliverPlace,
        regionalFinancialOffice,
        powerOfAttorneyDate,
        powerOfAttorneyReceitNumber,
        powerOfAttorneyRegistrationNumber,
      };
    });
};
export const filterSpouseEntityData = (
  beneficials: BeneficialEntity[]
): Partial<BeneficialBaseEntity>[] => {
  return beneficials
    .filter(
      (entity): entity is BeneficialPersonEntity =>
        'spouse' in entity && entity.hasSpouse && entity.spouse !== null
    )
    .map((entity) => {
      const {
        spouse: {
          gender,
          firstName,
          lastName,
          fullName,
          cin,
          cinDeliveryDate,
          cinDeliverPlace,
        } = {},
      } = entity;

      return {
        gender,
        firstName,
        lastName,
        fullName,
        cin,
        cinDeliveryDate,
        cinDeliverPlace,
      };
    });
};
export const filterSpouseAttorneyEntityData = (
  beneficials: BeneficialEntity[]
): Partial<BeneficialBaseEntity>[] => {
  return beneficials
    .filter(
      (entity): entity is BeneficialPersonEntity =>
        'spouseAttorney' in entity &&
        entity.hasSpouseAttorney &&
        entity.spouseAttorney !== null
    )
    .map((entity) => {
      const {
        spouseAttorney: {
          gender,
          firstName,
          lastName,
          fullName,
          cin,
          cinDeliveryDate,
          cinDeliverPlace,
          regionalFinancialOffice,
          powerOfAttorneyDate,
          powerOfAttorneyReceitNumber,
          powerOfAttorneyRegistrationNumber,
        } = {},
      } = entity;

      return {
        gender,
        firstName,
        lastName,
        fullName,
        cin,
        cinDeliveryDate,
        cinDeliverPlace,
        regionalFinancialOffice,
        powerOfAttorneyDate,
        powerOfAttorneyReceitNumber,
        powerOfAttorneyRegistrationNumber,
      };
    });
};
export const filterCompanyEntityData = (
  beneficials: BeneficialCompanyEntity[]
): BeneficialCompanyEntity[] => {
  return beneficials.map((entity) => {
    const {
      companyName,
      socialCapital,
      socialCapitalCurrency,
      postalAddress,
      legalForm,
      registrationNumber,
      signature,
      specialization,
    } = entity;

    return {
      companyName,
      socialCapital,
      signature,
      socialCapitalCurrency,
      postalAddress,
      legalForm,
      registrationNumber,
      specialization,
    };
  });
};

export const mapData = <T>(data: T | T[]): T[] => {
  if (Array.isArray(data)) {
    return data;
  }
  return data ? [data] : [];
};


export const virtualbeneficialGetters = {
  honorific(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    return tFromTranslations(`beneficialHonorific.${beneficial.gender}`, translations)
  },
  minorHonorific(beneficial: BeneficialMinorEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    return tFromTranslations(`beneficialHonorific.${beneficial.minorGender}`, translations)
  },
  spouseHonorific(beneficial: BeneficialPersonEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    return tFromTranslations(`beneficialHonorific.${beneficial.spouse?.gender}`, translations)
  },
  attorneyHonorific(beneficial: BeneficialPersonEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    return tFromTranslations(`beneficialHonorific.${beneficial.attorney?.gender}`, translations)
  },
  spouseAttorneyHonorific(beneficial: BeneficialPersonEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    return tFromTranslations(`beneficialHonorific.${beneficial.spouseAttorney?.gender}`, translations)
  },
  nationality(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    const country = getCountryByA3(beneficial.nationality || 'FRA')
    return country[`demonym_${language}`] || country.demonym_en
  },
  minorNationality(beneficial: BeneficialMinorEntity, t: (string) => string, language: LanguageType) {
    const country = getCountryByA3(beneficial.minorNationality || 'FRA')
    return country[`demonym_${language}`] || country.demonym_en
  },
  maritalStatus(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    if (beneficial?.maritalStatus == 'Other')
      return ""
    return t(`pages.editionContract.popups.benificial.options.maritalStatusOptions.${beneficial?.maritalStatus}`)
  },
  socialCapitalCurrency(beneficial: BeneficialCompanyEntity, t: (string) => string, language: LanguageType) {
    const currency = getCurrencyByCode(beneficial.socialCapitalCurrency || 'TND')
    return currency?.specialCharacter ?? currency?.code ?? ""
  },
  fullName(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType) {
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    const template = tFromTranslations(`beneficialFullName.${beneficial.type}`, translations) || ''
    return evalWithObject(template, beneficial)
  },
}
function formatDate(value: string | Date, language: LanguageType) {
  const date = new Date(value)
  if (isNaN(date.getTime()))
    return ""
  return moment(date).format(language == 'ar' ? "YYYY/MM/DD" : "DD/MM/YYYY")
}
function flattenObject(obj, parentKey = '', result = {}) {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (value && typeof value === 'object' && !Array.isArray(value) && value !== null) {
      // Recursively process nested objects
      flattenObject(value, newKey, result);
    } else {
      if(key === "gender"){
        if(value === "M" || value === "F"){
          result[newKey] = genderArray.indexOf(value);
        }
      }else if (key === "martialStatus"){
        result[newKey] = maritalStatusArray.indexOf(value as BeneficialEntity['maritalStatus']);
      }else{
        result[newKey] = value;
      }
    }
  }
  return result;
}
function fillBeneficialMacroData(segmentation: BeneficialMacroEntity['segmentation'], beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): RenderSegments {
  const inputValues = flattenObject(beneficial);
  if(hasAttorneyProperty(beneficial) && beneficial.attorney){
    inputValues["attorney.fullName"] = `${beneficial.attorney.firstName} ${beneficial.attorney.lastName}`
  }
  const segments = getMacroRenderSegments(segmentation.segmentedText, inputValues, segmentation.segmentedParams, t, language)
  return segments
}
export const beneficialMacros = {
  descritpion(macros: BeneficialMacroEntity[], beneficial: BeneficialEntity, t: (string) => string, language: LanguageType, paramName: string): RenderSegments {
    const beneficialDescriptionMacro = macros.find(macro => macro.name === "beneficialDescription" && macro.type === beneficial.type && macro.language === language)
    const translations = require(`../../templates/${companyCode}/${language}.json`)
    const descriptionTemplate = tFromTranslations(`beneficialDescription.${beneficial.type}`, translations) || ''
    if (!beneficialDescriptionMacro)
      return []
    const descriptionSegmentation = beneficialDescriptionMacro?.segmentation
    if(!descriptionSegmentation || 
      !descriptionSegmentation.segmentedText || 
      !descriptionSegmentation.segmentedText.length ||
      (descriptionSegmentation.segmentedText.length === 1 
        && descriptionSegmentation.segmentedText[0][1] === '_')
    ){
      console.log(descriptionSegmentation)
      return [
        {
          id: "0",
          type: SegmentedTextType.PARAM_VALUE,
          value: fillBeneficialData(descriptionTemplate, beneficial, t, language),
          paramName: paramName
        }
      ]
    }else{
      return fillBeneficialMacroData(descriptionSegmentation, beneficial, t, language)
    }
  },
  fullName(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
    const translations = require(`../../templates/${companyCode}/${language}.json`)

    const descriptionTemplate = tFromTranslations(`beneficialFullName.${beneficial.type}`, translations) || ''
    const description = fillBeneficialData(descriptionTemplate, beneficial, t, language)
    return description
  },
  signature(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
    const translations = require(`../../templates/${companyCode}/${language}.json`)

    const descriptionTemplate = tFromTranslations(`beneficialSignature.${beneficial.type}`, translations) || ''
    const description = fillBeneficialData(descriptionTemplate, beneficial, t, language)
    return description
  },
}

function fillBeneficialData(template: string, beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
  const regex = /{(.*?)}/g;
  let data = { ...beneficial }
  for (const key in virtualbeneficialGetters) {
    data[key] = virtualbeneficialGetters[key](beneficial, t, language)
  }
  for (const key in data) {
    if (!data[key])
      data[key] = ""
    if (
      data[key] &&
      (key == 'cinDeliveryDate'
        || key == 'dateOfBirth'
        || key == 'createdAt'
        || key == 'updatedAt'
        || key == 'minorDateOfBirth'
        || key == 'minorOrderDate'
        || key == 'spouseCinDeliveryDate'
        || key == 'attorneyCinDeliveryDate'
      )
    ) {
      data[key] = formatDate(data[key], language)
    }
  }
  if (hasAttorneyProperty(data) && data.attorney) {
    data.attorney.cinDeliveryDate = formatDate(data.attorney.cinDeliveryDate, language) as any
    data.attorney.powerOfAttorneyDate = formatDate(data.attorney.powerOfAttorneyDate, language) as any
  }

  if (data.type == 'Person') {
    if (data.spouse) {
      data.spouse.cinDeliveryDate = formatDate(data.spouse.cinDeliveryDate, language) as any

    }
    if (data.spouseAttorney) {
      data.spouseAttorney.cinDeliveryDate = formatDate(data.spouseAttorney.cinDeliveryDate, language) as any
      data.spouseAttorney.powerOfAttorneyDate = formatDate(data.spouseAttorney.powerOfAttorneyDate, language) as any
    }

  }
  try {
    return evalWithObject(template, data)

  } catch (error) {
    console.warn({
      template,
      beneficial,
      data
    });
    console.error(error)
    return ""

  }

}
export function getBeneficialDescritption(beneficial: BeneficialEntity, t: (string) => string, language: LanguageType): string {
  const translations = require(`../../templates/${companyCode}/${language}.json`)

  const descriptionTemplate = tFromTranslations(`beneficialDescription.${beneficial.type}`, translations) || ''
  const description = fillBeneficialData(descriptionTemplate, beneficial, t, language)
  return description

}