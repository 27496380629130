import React, { useState } from "react";
import { useTranslation } from "../../../contexts/TranslationProvider";
import { TaskConfigEntity } from "../../../domain/entities/TaskConfig";
import "./taskConfigModal.scss";
interface TaskDeleteModalProps {
  onClose: () => void;
  taskConfig: TaskConfigEntity;
  onUpdate: (updatedConfig: TaskConfigEntity) => void;
}

const TaskConfigModal = ({
  onClose,
  taskConfig,
  onUpdate,
}: TaskDeleteModalProps) => {
  const { t } = useTranslation();
  const [statuses, setStatuses] = useState<string[]>(taskConfig.statuses);
  const [categories, setCategories] = useState<string[]>(taskConfig.categories);
  const [newStatus, setNewStatus] = useState("");
  const [newCategory, setNewCategory] = useState("");

  const removeItem = (index: number, type: "status" | "category") => {
    if (type === "status") {
      setStatuses((prev) => prev.filter((_, i) => i !== index));
    } else {
      setCategories((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const addItem = (type: "status" | "category") => {
    if (type === "status" && newStatus.trim()) {
      setStatuses((prev) => [...prev, newStatus.trim()]);
      setNewStatus("");
    }
    if (type === "category" && newCategory.trim()) {
      setCategories((prev) => [...prev, newCategory.trim()]);
      setNewCategory("");
    }
  };

  const moveItem = (
    index: number,
    direction: "up" | "down",
    type: "status" | "category"
  ) => {
    const list = type === "status" ? [...statuses] : [...categories];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    if (targetIndex < 0 || targetIndex >= list.length) return;

    [list[index], list[targetIndex]] = [list[targetIndex], list[index]];
    type === "status" ? setStatuses(list) : setCategories(list);
  };

  return (
    <>
      <div className="modal-backdrop" onClick={onClose}></div>
      <div id="contractz-lab" className="confirm-delete-popup">
        <div className="modal d-flex justify-content-center align-items-center">
          <div className="modal-dialog">
            <div className="modal-content p-4">
              <div className="modal-header">
                <h5 className="modal-title">{t("pages.tasks.modals.config.title")}</h5>
                <button className="btn-close" onClick={onClose}></button>
              </div>
              <div className="modal-body">
                <div className="config-section">
                  <h5 className="section-title">{t("pages.tasks.modals.config.statuses")}</h5>
                  <div className="config-list">
                    {statuses.map((status, index) => (
                      <div key={index} className="config-item">
                        <span>{status}</span>
                        <div className="status-actions">
                          <button
                            className="status-button"
                            onClick={() => moveItem(index, "up", "status")}
                          >
                            ↑
                          </button>
                          <button
                            className="status-button"
                            onClick={() => moveItem(index, "down", "status")}
                          >
                            ↓
                          </button>
                          <button
                            className="status-button remove"
                            onClick={() => removeItem(index, "status")}
                          >
                            ✕
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="add-item">
                    <input
                      type="text"
                      className="status-input"
                      placeholder={t("pages.tasks.modals.config.add_status")}
                      value={newStatus}
                      onChange={(e) => setNewStatus(e.target.value)}
                    />
                    <button
                      className="add-button"
                      onClick={() => addItem("status")}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="config-section">
                  <h5 className="section-title">{t("pages.tasks.modals.config.categories")}</h5>
                  <div className="config-list">
                    {categories.map((cat, index) => (
                      <div key={index} className="config-item">
                        <span>{cat}</span>
                        <div className="category-actions">
                          <button
                            className="category-button"
                            onClick={() => moveItem(index, "up", "category")}
                          >
                            ↑
                          </button>
                          <button
                            className="category-button"
                            onClick={() => moveItem(index, "down", "category")}
                          >
                            ↓
                          </button>
                          <button
                            className="category-button remove"
                            onClick={() => removeItem(index, "category")}
                          >
                            ✕
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="add-item">
                    <input
                      type="text"
                      className="category-input"
                      placeholder={t("pages.tasks.modals.config.add_category")}
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                    />
                    <button
                      className="add-button"
                      onClick={() => addItem("category")}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Buttons */}
                <div className="modal-buttons mt-4 d-flex justify-content-end gap-2">
                  <button
                    onClick={() =>
                      onUpdate({
                        ...taskConfig,
                        statuses,
                        categories,
                      })
                    }
                  >
                    {t("pages.tasks.modals.config.update")}
                  </button>
                  <button className="btn btn-secondary" onClick={onClose}>
                  {t("pages.tasks.modals.config.cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskConfigModal;
