import React, {useState} from 'react'
import { FiMoreHorizontal } from "react-icons/fi";
import ContractCardActionsModal from '../../components/modals/ContractCardActionsModal';

function ActionsCell({handleClick, handleDelete, handleDesactivate, ressourceId}:{
  handleClick: (id: string | number)=> void;
  handleDelete: (id: string | number)=> void;
  handleDesactivate?: (id: string | number)=> void;
  ressourceId: string | number
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const togglePopup = ()=>{
    setIsPopupOpen(true)
  }
  return (
    <div className="actions">
                <button className="plus-icon" onClick={(e) => togglePopup()}>
                  <FiMoreHorizontal size={20} />
                </button>
                {isPopupOpen && (
                  <div className="">
                    <ContractCardActionsModal
                      onView={() => handleClick(ressourceId)}
                      onDelete={() => handleDelete(ressourceId)}
                      onDesactivate={handleDesactivate ? ()=>handleDesactivate(ressourceId) : undefined}
                      onClose={()=>setIsPopupOpen(null)}
                      isOpen={isPopupOpen}
                    />
                  </div>
                )}
    </div>
  )
}

export default ActionsCell