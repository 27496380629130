export type ValidationError = {
  code: string;
  message: string;
}

export abstract class BaseValidator<T> {

  constructor() {
  }

  abstract validate(toValidate: T): ValidationError[];
}