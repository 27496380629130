import React, { useState, useRef, useContext, useEffect } from "react";
import "./exchangeClause.scss";
import ComparisonClause from "./ComparisonClause";
import { useTranslation } from "../../../contexts/TranslationProvider";
import EditContractTemplateContext from "../../../contexts/EditContractTemplateContext";
import { ContractTemplateClient } from "../../../services/api/ContractTemplateClient";
import useApiClientWithLoading from "../../../services/api/ApiClient";
import { toast } from "react-toastify";
import { getName } from "../../../helpers/Translation";
import RegenerateClause from "./RegenerateClause";
import { ClauseEntity } from "../../../domain/entities";
import Loading from "../../common/Loading";
import ClauseComparator from "./ClauseComparator";
import { AI_CLAUSE_GENERATION_ON, AI_GENERATE_CLAUSE_PARAMS_ON } from "../../../config";
import { GenerateClauseParamType } from "../../../domain/types/Clause";
import EventManager from "../../../services/EventManager";
import GenerateClauseParamsSection from "./GenerateClauseParamsSection";

function ClauseSelectModal({
  onClose,
  clauseIndex,
  currentClause,
}: {
  onClose: () => void;
  clauseIndex: any;
  currentClause: any;
}) {
  const [foundClause, setFoundClause] = useState(null);
  const [generatedClause, setGeneratedClause] = useState<ClauseEntity | null>(null);
  const [exchangeType, setExchangeType] = useState<'Regenerate' | 'Swap' | 'Generate-params'>('Swap');
  const [searchClause, setSearchClause] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [generationLoading, setGenerationLoading] = useState(false)
  const [filteredClauses, setFilteredClauses] = useState([]);
  const [loading, setLoading] = useState(false);
  const lookup = require("../../../assets/lookup.png");
  const { t, language } = useTranslation();
  const translationPath = "modals.clauseExchange.";
  const {
    contractTemplate,
    onClauseSwap,
    updateClauseContent,
    setContractTemplate,
  } = useContext(EditContractTemplateContext);
  const apiClient = useApiClientWithLoading();
  const contractTemplateClient = new ContractTemplateClient(apiClient);
  const searchRef = useRef(null);
  const handleInputChange = (event) => {
    setSearchClause(event.target.value);
    setShowDropdown(true);
  };
  const handleDropdownSelect = (item) => {
    setFoundClause(item);
    setSearchClause(item.name);
    setFilteredClauses([]);
    setShowDropdown(false);
  };
  const generateParamsWithAIHandler = async (promptTypes: GenerateClauseParamType[], comments: string) => {
    try {
      //if(clause.params.length !== 0) return;
      setLoading(true)
      EventManager.invoke('SaveContractTemplate', true)
      const result = await contractTemplateClient.generateClauseParams(currentClause.id, promptTypes, comments);
      const { warnings, row: updatedClause } = result;
      const newContractTemplate = { ...contractTemplate }
      const clauseIdx = newContractTemplate.clauses.findIndex(c => c.id == currentClause.id)
      newContractTemplate.clauses[clauseIdx] = updatedClause
      setContractTemplate(newContractTemplate)
      setLoading(false)
      toast.success(t("pages.contractTemplateParam.toasts.saveClause.success"));
    } catch (error) {
      setLoading(false)
      console.error('Error saving template:', error);
      toast.error(t("pages.contractTemplateParam.toasts.saveClause.error"));
    }
  };
  useEffect(() => {
    if (!searchClause) return;
    const searchClauses = async (name: string) => {
      try {
        setLoading(true);
        const result = await contractTemplateClient.searchClauses(searchClause);
        const { rows: clauses } = result;
        setFilteredClauses(
          clauses.filter((clause) => clause.template.id !== contractTemplate.id)
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error searching clauses:", error);
        toast.error("Error searching clauses");
      }
    };
    searchClauses(searchClause);
  }, [searchClause]);

  const handleConfirm = async () => {
    try {
      if (!foundClause) return;
      await onClauseSwap(
        clauseIndex,
        currentClause.id,
        foundClause.id,
        foundClause.name
      );
      toast.success("Clause replaced successfully");
    } catch (err) {
      console.error("Error exchanging clause:", err);
      toast.error("Error exchanging clause");
    }
  };

  // handle click outside of searchRef
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchRef]);

  const handleAcceptGeneration = async () => {
    try {
      if (!generatedClause) return;
      const operation = await contractTemplateClient.acceptAiGeneration(generatedClause.id, generatedClause);
      if (operation) {
        toast.success(t(translationPath + "toasts.success"));
        updateClauseContent(generatedClause.id, generatedClause);
        EventManager.invoke("SaveContractTemplate", true);
      }
    } catch (err) {
      console.error("Error exchanging clause:", err);
      toast.error(t(translationPath + "toasts.error"));
    }
  }
  const handleResolveRegenerate = async (clause: ClauseEntity) => {
    setGeneratedClause(clause)
  }
  return (
    <>
      <div className="exchange-modal" onClick={() => onClose()}>
        <div className="modal-backdrop"></div>
        <div id="contractz-lab">
          <div className="modal d-flex justify-content-center align-items-center">
            <div className="clause-comparison-modal-content" onClick={(e)=>e.stopPropagation()}>
              <div className="modal-header" style={{position: "relative"}}>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"
                  style={{ position: "absolute", right: "10px", top: "30px" }}
                ></button>
                <div className="exchange-type">
                  {AI_CLAUSE_GENERATION_ON && <div
                    className={`exchange-type-item ${exchangeType === 'Regenerate' && 'exchange-type-item-active'}`}
                    onClick={() => setExchangeType('Regenerate')}
                  >
                    {t(translationPath + "generateWithAi")}
                  </div> }
                  {AI_GENERATE_CLAUSE_PARAMS_ON && <div
                    className={`exchange-type-item ${exchangeType === 'Generate-params' && 'exchange-type-item-active'}`}
                    onClick={() => setExchangeType('Generate-params')}
                  >
                    {t(translationPath + "generateParams")}
                  </div> }
                  <div
                    className={`exchange-type-item ${exchangeType === 'Swap' && 'exchange-type-item-active'}`}
                    onClick={() => setExchangeType('Swap')}
                  >
                    {t(translationPath + "swap")}
                  </div>
                </div>
              </div>
              <div className="body-comparison">
                <div className="clause-comparison-container">
                  <div className="clause-exchange-current">
                    {currentClause ? (
                      <ComparisonClause clause={currentClause} />
                    ) : (
                      <span>{t(translationPath + "noClause")}</span>
                    )}
                  </div>
                  <div className="clause-exchange-found">
                    {
                      exchangeType === 'Regenerate'
                      && (<>
                      {generatedClause ? 
                      <ClauseComparator referenceClause={currentClause} comparisonClause={generatedClause} />
                       : null}
                      <RegenerateClause
                        clause={currentClause}
                        handleResolveRegenerate={handleResolveRegenerate}
                        generationLoading={generationLoading}
                        setGenerationLoading={setGenerationLoading}
                        generatedClause={generatedClause}
                      />
                      </>)
                    }

                    {exchangeType === 'Swap' && <>
                      <div
                        ref={searchRef}
                        style={{ marginBottom: "10px" }}
                        className="project-beneficial-name"
                      >
                        <input
                          type="text"
                          className=""
                          value={searchClause}
                          onChange={(event) => handleInputChange(event)}
                          style={{ width: "100%" }}
                        />
                        <img
                          src={lookup}
                          alt="#"
                          className={`lookup-img ${language == "ar" && "lookup-img-arabic"
                            }`}
                        />
                        {currentClause && (
                          <span
                            onClick={() => {
                              setFoundClause(null);
                              setSearchClause("");
                              setShowDropdown(false);
                            }}
                            className={`x-img ${language == "ar" && "x-img-arabic"
                              }`}
                          >
                            X
                          </span>
                        )}

                        {showDropdown && (
                          <div className="list-beneficial-project">
                            {filteredClauses && filteredClauses.length > 0 ? (
                              filteredClauses.map((item, index) => (
                                <div
                                  className="item-beneficial-project"
                                  key={index}
                                  onClick={() => handleDropdownSelect(item)}
                                >
                                  <span>{item.name}</span>
                                  <div className="d-flex flex-col">
                                    {item?.template?.level1?.name && (
                                      <span className="level-types-template">
                                        {getName(
                                          item?.template?.level1,
                                          language
                                        ) +
                                          (item?.template?.level2
                                            ? "/" +
                                            getName(
                                              item?.template?.level2,
                                              language
                                            )
                                            : "") +
                                          (item?.template?.level3
                                            ? "/" +
                                            getName(
                                              item?.template?.level3,
                                              language
                                            )
                                            : "")}
                                      </span>
                                    )}
                                    <span className="item-template-name">
                                      {item.template?.name}
                                    </span>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </div>
                      {foundClause ? (
                        <ComparisonClause clause={foundClause} height="41vh" />
                      ) : (
                        <span>{t(translationPath + "noClause")}</span>
                      )}
                    </>
                    }

                    {exchangeType === 'Generate-params' && <>
                    <GenerateClauseParamsSection
                      generateParamsWithAIHandler={generateParamsWithAIHandler}
                      loading={loading}
                      clause={currentClause}
                    />
                    </>}

                  </div>
                </div>
              </div>
              <div className="modal-actions">
                {exchangeType === 'Regenerate' && <>
                  {generatedClause && <button
                    className={`confirm-button`}
                    onClick={handleAcceptGeneration}
                    disabled={!generatedClause}
                  >
                    {t(translationPath + "accept")}
                  </button>}
                  {generatedClause && <button
                    className={`confirm-button ${true && "disabled-confirm-button"}`}
                    onClick={() => setGeneratedClause(null)}
                    style={{ cursor: "pointer" }}
                  >
                    {t(translationPath + "discard")}
                  </button>}
                </>}
                  {exchangeType === 'Swap' &&
                  <button
                    disabled={!foundClause}
                    className={`confirm-button ${!foundClause && "disabled-confirm-button"}`}
                    onClick={handleConfirm}
                  >
                    {t(translationPath + "swap")}
                  </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClauseSelectModal;
