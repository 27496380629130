import React, {useState, useContext, useEffect} from 'react'
import useApiClientWithLoading from "../../../../services/api/ApiClient";
import PromptInput from './PromptInput'
import Loading from '../../../../components/common/Loading'
import { ClauseEntity, ClauseScoringEntity } from '../../../../domain/entities'
import AIResponseOnClause from '../AIResponseOnClause';
function RegenerateClause({clause, generatedClause, handleResolveRegenerate, generationLoading, setGenerationLoading}: {
  clause: ClauseScoringEntity,
  generatedClause: ClauseScoringEntity,
  handleResolveRegenerate: (query: string) => void,
  generationLoading: boolean,
  setGenerationLoading: (loading: boolean) => void
}) {
  const [instructions, setInstructions] = useState('')
  const apiClient = useApiClientWithLoading()
  const handleSubmit = async () => {
    if(generationLoading || instructions === ''){
      return
    }
    setGenerationLoading(true)
    try{
      handleResolveRegenerate(instructions)
    }catch(err){
      console.log(err)
    }
  }
  return (
    <div className='regenerate-container' style={{maxHeight: "300px"}}>
      {
        generationLoading ? ( <Loading /> ) : (<>
          <div className='content-section'>
          <div className='regenerate-section'>
            {
              clause && clause.scoring && clause.scoring.comment && (
                <AIResponseOnClause
                  response={clause.scoring}
                />
              )
            }
          </div>
          </div>
          <div className='prompt-section'>
            <PromptInput 
            handleSubmit={() => handleSubmit()} 
            loading={generationLoading} 
            query={instructions}
            handleQueryChange={(query) => setInstructions(query)}
            />
          </div>
            </>
          )    
      }
    </div>
  )
}

export default RegenerateClause