import React, { useState } from 'react'
import { IoArrowUpCircleSharp } from "react-icons/io5";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import Tooltip from '../../common/newDesign/Tooltip';
import { useTranslation } from '../../../contexts/TranslationProvider';

function PromptInput({
  loading,
  handleSubmit,
  handleQueryChange,
  query,
  triggerSpecialAction,
  specialActionOn,
  enableSpecialAction = true
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        return;
      } else {
        event.preventDefault();
        if (!loading && query) {
          handleSubmit();
        }
      }
    }
  };
  const { t } = useTranslation();
  const translationPath = "modals.clauseExchange.";
  return (
    <div>
      <div className='body-interactions-input-container'>
        {enableSpecialAction && <Tooltip id='1' infoText="Advanced" noArrow={true}>
          <div onClick={triggerSpecialAction} className="add-file" style={{ backgroundColor: specialActionOn ? "gray" : "lightgray" }}>
            <div className="d-flex">
              <MdOutlineDashboardCustomize
                style={{
                  width: "30px",
                  height: "30px",
                  color: "rgb(239, 238, 243)",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                className='icon'
              />
            </div>
          </div>
        </Tooltip>}
        <div className='input-section'>
          <textarea
            className='query-input'
            placeholder={t(translationPath + 'promptInputPlaceholder')}
            onChange={(e) => handleQueryChange(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            value={query} />
        </div>
        <div className='button-section'>
          <button onClick={() => handleSubmit()} className='ask-btn'>
            <IoArrowUpCircleSharp className='icon' style={{
              color: (enableSpecialAction && (loading || !query)) ? "lightgray" : "gray",
            }} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default PromptInput