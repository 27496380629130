import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./tooltip.scss";

const Tooltip = ({
  id,
  infoText, noArrow = false, children }: {
    infoText: string;
    id: string;
    noArrow?: boolean;
    children?: React.ReactNode | React.ReactNode[];
  }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <div
        data-tooltip-id={"my-tooltip-" + id}
        className="tooltip-container"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {!noArrow && <div className={`arrow-down ${showTooltip ? "open" : ""}`} />}
        {children}
      </div>
      <ReactTooltip
        id={"my-tooltip-" + id}
        place="bottom"
        content={infoText}
      />
    </>

  );
};

export default Tooltip;