import React from 'react'
import EditContractTemplateContext from "../../../../contexts/EditFormTemplateContext";
import { useTranslation } from "../../../../contexts/TranslationProvider";
import { sortByOriginalOrder } from '../../../../utils/array';
import { beneficialTypes, FormTemplatePageEntity } from '../../../../domain/entities';
import { lowerCase } from 'lodash';
import { FormType } from '../../../../domain/types/FormParams';

function BeneficialListRender({ children, param }: {
  children: React.JSX.Element;
  param: FormType.BeneficialListParam;
}) {
  const { onParamChanged } = React.useContext(EditContractTemplateContext);
  const { t, language } = useTranslation();
  const translationPath = t("pages.editionFormTemplate.edition");
  const translationTypes = "pages.contractTemplateParam.params"

  type BeneficialType = 'Person' | 'Company' | 'Minor';
  const updatedParam = { ...param };

  if (!updatedParam.args?.beneficialTypes || updatedParam.args.beneficialTypes.length === 0) {
    updatedParam.args = {
      ...updatedParam.args,
      beneficialTypes,
    };
    onParamChanged(param.name, updatedParam);
  }
  const handleCheckboxChange = (checked: boolean, type: BeneficialType) => {
    const updatedParam = { ...param };
    if (!updatedParam.args) {
      updatedParam.args = { beneficialTypes: ['Company', 'Minor', 'Person'] };
    }

    if (checked) {
      updatedParam.args.beneficialTypes = sortByOriginalOrder(beneficialTypes, [...new Set([...updatedParam.args.beneficialTypes, type])])
    } else {
      updatedParam.args.beneficialTypes = updatedParam.args.beneficialTypes.filter((i: BeneficialType) => i !== type);
    }
    if (!updatedParam.args?.beneficialTypes?.length) {
      updatedParam.args = { beneficialTypes: ['Company', 'Minor', 'Person'] };
    }

    onParamChanged(param.name, updatedParam);
  };

  return (
    <div className={`input-param`}>
      <div className="input-type">{t(`${translationTypes}.${param.type}`)}</div>
      {children}
      {beneficialTypes.map((type) => (
        <div className="checkbox-beneficial">
          <input
            type="checkbox"
            checked={param?.args?.beneficialTypes?.includes(type) || false}
            name={type}
            onChange={(e) => handleCheckboxChange(e.target.checked, type)}
            disabled={false}
          />
          <label htmlFor={type}>{translationPath.beneficial[lowerCase(type)]}</label>
        </div>
      ))}
    </div>
  )
}

export default BeneficialListRender;
