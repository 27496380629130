import { createContext, Dispatch, ReactNode, useEffect, SetStateAction, useMemo, useState } from 'react';
import { BeneficialMacroEntity } from '../domain/entities';
import useApiClientWithLoading from '../services/api/ApiClient';
import { BeneficialMacroClient } from '../services/api/BeneficialMacroClient';

export interface MacroContextType {
  macros: BeneficialMacroEntity[];
  setMacros: Dispatch<SetStateAction<BeneficialMacroEntity[]>>;
}

const MacroContext = createContext<MacroContextType>({
} as any);

export const MacroProvider: React.FC<any> = ({ children }: { children: ReactNode }) => {
  const [macros, setMacros] = useState<BeneficialMacroEntity[]>([]);
  const apiClient = useApiClientWithLoading();
  const beneficialMacroClient = new BeneficialMacroClient(apiClient);
  const fetchMacros = async () => {
    try{
    const macros = await beneficialMacroClient.getAll();
    setMacros(macros.rows);
    }catch(error){
      console.error(error);
    }
    
  }
  const macroContext: MacroContextType = useMemo(
    () => ({
      macros: macros,
      setMacros: setMacros
    }),
    [macros]
  );

  useEffect(() => {
    fetchMacros();
  }, []);

  return (
    <MacroContext.Provider value={macroContext}>
      {children}
    </MacroContext.Provider>
  );
}

export default MacroContext;