import "./admin.scss";
import { getAuthUser } from "../../services/api/UserAuth";
import AdminFunction from "../../components/AdminFunction";
import { useTranslation } from "../../contexts/TranslationProvider";
import CreateContractTemplateModal from "../../components/modals/CreateContractTemplateModal";
import CreateFormTemplateModal from "../../components/modals/CreateFormTemplateModal";
import CreateReglementModal from "../../components/modals/CreateReglementModal";
import { ENABLE_PROJECT, AI_MANAGE_REGULATION_ON } from "../../config";
import React, { useContext } from "react";
import { AuthUserContext } from "../../contexts/AuthUserContext";

function AdminPage() {
  const { user } = getAuthUser();
  const { t } = useTranslation();
  const { activeRole } = useContext(AuthUserContext)

  const translationPath = t('pages.admin');
  return (
    <div className="page">
      <div className="px-5">
        <section id="greetings">
          <div className="mb-2 mt-2">
            <span className="current-pathname mb-2">{translationPath.greeting.role} </span>
          </div>
          <h2>
            {translationPath.greeting.message} {" "}
            <span><strong>{user?.firstName}, </strong></span>
          </h2>
          <p></p>
        </section>
        <section id="main-function" className="pt-4">
          <div className="row mt-5">
            {activeRole == 'Admin' &&
              <React.Fragment>
                <div className="col-5 mb-5">
                  <AdminFunction title={translationPath.titleContractTemplate} description={translationPath.descContractTemplate} buttonTitle={t(translationPath.btnContractTemplate)}
                    render={(onClose) => <CreateContractTemplateModal onClose={onClose} />} />
                </div>
                <div className="col-5 mb-5">
                  <AdminFunction
                    title={translationPath.titleFormTemplate}
                    description={translationPath.descFormTemplate}
                    buttonTitle={translationPath.btnFormTemplate}
                    render={(onClose) => <CreateFormTemplateModal onClose={onClose} />}
                  />
                </div>
                {ENABLE_PROJECT && <div className="col-5 mb-5">
                  <AdminFunction
                    title={translationPath.titleProject}
                    description={translationPath.descProject}
                    buttonTitle={translationPath.btnProject}
                    path="/admin/create-project-template"
                  />
                </div>
                }
              </React.Fragment>
            }
            {AI_MANAGE_REGULATION_ON && <div className="col-5 mb-5">
              <AdminFunction
                title={translationPath.titleReglements}
                description={translationPath.desReglements}
                buttonTitle={translationPath.btnReglements}
                render={(onClose) => <CreateReglementModal onClose={onClose} />}
              />
            </div>}
            <div className="col-xxl-3 col-0"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AdminPage;
